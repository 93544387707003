import React, { useState, useEffect } from 'react';
import { useLocation, withRouter, useRouteMatch } from "react-router-dom";
import { Accordion } from 'react-bootstrap'
import axios from 'axios';
//component
import ShowLogo from './ShowLogo';
import Loading from '../../components/Loading';
import DownloadFile from '../../assets/javascript/downloadFile'
import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc';

//會有三層下拉選單
const FilesSin = (props) => {
    useEffect(() => {
        if (props.location.pathname == '/files/life-法國巴黎人壽') {
            window.location.href = 'https://my.cardif.com.tw/B2B/BrokerDownload'
            return
        }
    }, [])

    const match = useRouteMatch();
    let showLogoData = match.url.split('/')[2].split('-')

    let [isLoading, setIsLoading] = useState(true)
    let [allData, setAllData] = useState([])

    //GET API
    useEffect(() => {
        LineLogin(`${match.url}`); //LINE 登入狀態驗證
        Countfunc('文件', 5); //觸及數器
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=2`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', `${match.url}`);
                        window.location.href = '/login'
                        return
                    } else {
                        setAllData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);
    //GET API END

    //資料處理
    let filterData = []; //最終資料處理完成 DATA
    const splitData = (data) => {
        let splitAry = [];
        data.forEach((item, index) => {
            splitAry.push(item.split('/'))
        });

        splitAry.forEach((item, index) => {
            if (item[1] === '行政文件' && item[3].split('.')[1] === showLogoData[1]) {
                filterData.push({
                    title: item[1],
                    insuranceType: item[2],
                    company: item[3].split('.')[1],
                    firstOption: item[4].split('.')[1],
                    secondOption: item.length > 6 ? item[5].split('.')[1] : '',
                    thirdOption: item.length > 7 ? item[6].split('.')[1] : '',
                    fourOption: item.length > 8 ? item[7] : '',
                    fileName: item[item.length - 1],
                    numberCompany: item[3],
                    downloadUrl: item.join('/')
                })
            }
        })
    }
    splitData(allData)
    //資料處理 END
    let [firstOptionAry, setFirstOptionAry] = useState([]);
    let [secondOptionAry, setSecondOptionAry] = useState([]);
    let [thirdOptionAry, setThirdOptionAry] = useState([]);

    let [firstSelect, setFirstSelect] = useState('新契約');
    let [secondSelect, setSecondSelect] = useState('');
    let [thirdSelect, setThirdSelect] = useState(thirdOptionAry[0] || '');

    useEffect(() => {
        if (showLogoData[0] === 'life') {
            if(showLogoData[1] === '富邦人壽'){
                setSecondSelect('')
            }else {
                setSecondSelect('要保書')
            }
            if (showLogoData[1] === '康健人壽' || showLogoData[1] === '第一金人壽') {
                setSecondSelect('傳統型要保書')
            } else if (showLogoData[1] === '南山人壽') {
                setSecondSelect('')
            } else if (showLogoData[1] === '國泰人壽') {
                setSecondSelect('團體險')
            }
        } else {
            setSecondSelect('其他表單')
        }
    }, [secondOptionAry])

    //處理 Select
    const filterFun = (data) => {
        let firstAry = [];
        let secondAry = [];
        let thirdAry = [];

        data.forEach((item, index) => {
            firstAry.push(item.firstOption)

            //判第 2 個 select
            if (firstSelect === item.firstOption) {
                secondAry.push(item.secondOption)
            }

            //判第 3 個 select
            if (firstSelect === item.firstOption && secondSelect === item.secondOption && item.thirdOption !== '' && item.thirdOption !== undefined) {
                thirdAry.push(item.thirdOption)
            }
        })
        //---過濾
        //-----第一層
        firstOptionAry = firstAry.filter((item, index, ary) => {
            if (item == '' || item == undefined) {

            } else {
                return ary.indexOf(item) === index
            }
        })
        firstOptionAry.push('公勝新契約')
        //-----第一層 END

        //-----第二層
        secondOptionAry = secondAry.filter((item, index, ary) => {
            if (item == '' || item == undefined) {

            } else {
                return ary.indexOf(item) === index
            }
        })
        //-----第二層 END

        //-----第三層
        thirdOptionAry = thirdAry.filter((item, index, ary) => {
            if (item == '' || item == undefined) {

            } else {
                return ary.indexOf(item) === index
            }
        })
        //-----第三層 END
        //---過濾 END
    }
    //處理 Select END
    filterFun(filterData)

    let wowoData = []; //旺旺友聯_新鴻運保條款_11004
    const filterThirdNotData = (data) => {
        data.forEach((item, index) => {
            if (item.thirdOption === undefined) {
                wowoData.push(item.fourOption)
            }
        })
    }
    filterThirdNotData(filterData)

    //事件
    //--- 第一層
    const handleFirstChange = (e) => {
        let { value } = e.target
        setFirstSelect(value)

        if (value === '公勝新契約') {
            window.location.href = '/golden_docs'
        }
        let secondAry = [];
        let ThirdAry = [];
        filterData.forEach((item, index) => {
            if (value === item.firstOption) {
                setSecondSelect(item.secondOption)
                setThirdSelect(item.thirdOption)
                secondAry.push(item.secondOption)
                ThirdAry.push(item.thirdOption)

            }
        })
    }
    //--- 第一層 END

    //--- 第二層
    const handleSecondChange = (e) => {
        let { value } = e.target
        setSecondSelect(value)
        let thirdAry = [];
        filterData.forEach((item, index) => {
            if (value === item.secondOption) {
                setThirdSelect(item.thirdOption)
                thirdAry.push(item.thirdOption)
            }
        })
    }
    //--- 第二層 END

    //--- 第三層
    const handleThirdChange = (e) => {
        let { value } = e.target
        setThirdSelect(value)
    }
    //--- 第三層 END
    //事件 END
    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/files' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setIsLoading)
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="position-fixed w-100 bg-light" style={{ zIndex: '100' }}>
                <ShowLogo PropCompany={showLogoData}></ShowLogo>
                <div className="d-flex pb-2 container px-lg-5">
                    <select className="form-select" onChange={handleFirstChange} value={firstSelect}>
                        {
                            firstOptionAry.map((item, index) => {
                                return (
                                    <option key={item} value={item} data-first={`${String(index + 1 + '.' + item)}`}>{item}</option>
                                )
                            })
                        }
                    </select>
                    <select className={`form-select ms-2 ${secondOptionAry.length === 0 ? 'd-none' : 'd-block'}`} onChange={handleSecondChange} value={secondSelect}>
                        {
                            secondOptionAry.map((item, index) => {
                                return (
                                    <option key={item} value={item} data-second={`${String(index + 1 + '.' + item)}`}>{item}</option>
                                )
                            })
                        }
                    </select>
                    <select className={`form-select ms-2 ${thirdOptionAry.length === 0 ? 'd-none' : 'd-block'}`} onChange={handleThirdChange} value={thirdSelect}>
                        {
                            thirdOptionAry.map((item, index) => {
                                return (
                                    <option key={item} value={item} data-third={`${String(index + 1 + '.' + item)}`}>{item}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="d-table company-header-height"></div>
            <div className="container mt-3 bg-mask px-lg-5 mt-md-6 mt-lg-0">
                <ul className="list-unstyled page-content-link">
                    {
                        filterData.map((item) => {
                            if (firstSelect === item.firstOption && secondSelect === item.secondOption && thirdSelect === item.thirdOption) {
                                return (
                                    <li className="border-bottom py-3" key={item.fileName}>
                                        <a href="#" data-download={item.downloadUrl} data-filename={item.fileName} className="d-block fw-bolder file-icon" onClick={handleDownloadFile}>
                                            {item.fileName}
                                        </a>
                                    </li>
                                )
                            }
                            return true
                        })
                    }
                </ul>
                <div className={`py-2 ${wowoData.length > 0 && firstSelect === '行銷資料' && secondSelect === '條款樣張' ? 'd-block' : 'd-none'}`}>
                    {
                        wowoData.filter((item, index, ary) => {
                            return ary.indexOf(item) === index
                        }).map((item, index) => {
                            return (
                                <Accordion className="my-2" key={item}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <div className="row w-100">
                                                <p className="fw-bolder text-golden-brown">{item}</p>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="list-unstyled p-0">
                                                {
                                                    filterData.filter((items) => {
                                                        if (items.fourOption === item) {
                                                            return item
                                                        }
                                                        return true
                                                    }).map((kidItem, kidIndex) => {
                                                        return (
                                                            <li className="border-bottom py-3" key={kidItem.fileName}>
                                                                <a href="#" data-download={kidItem.downloadUrl} data-filename={kidItem.fileName} className="d-block fw-bolder file-icon text-dark-blue" onClick={handleDownloadFile}>
                                                                    {kidItem.fileName}
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}
export default withRouter(FilesSin);