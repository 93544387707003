import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading';
import { toCurrency, minYearsToDate, dateChange } from 'assets/javascript/calTool'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import ExcelJs from "exceljs";
//日期選擇器
import DatePicker from 'react-datepicker'
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import zhTW from 'date-fns/locale/zh-TW';

const Event = () => {
    registerLocale('zhTW', zhTW)
    let [isLoading, setIsLoading] = useState(true)
    //modal
    let [modalShow, setModalShow] = useState(false)
    let [modalState, setModalState] = useState('新增') //功能狀態(新增or修改)
    //名單 modal
    let [modalEventUserList, setModalEventUserList] = useState(false) //名單 list
    let [modalEventName, setModalEventName] = useState(null) //名單點擊的名稱
    let [eventUserData, setEventUserData] = useState([]) //名單點擊的名稱

    //新增、修改活動
    let [event, setEvent] = useState({ name: '', donate: '' })
    let [startDate, setStartDate] = useState('')
    let [endDate, setEndDate] = useState('')
    //修改活動 id
    let [eventId, setEventId] = useState('')
    let [allData, setAllData] = useState([]) //全部噴回來的資料 儲存
    const handleEvent = {
        getData: function () { //取得所有 data
            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}get_sp_event`
            setIsLoading(true)
            axios.get(API)
                .then((res) => {
                    if (res.data.code == '0') {
                        setIsLoading(false)
                        setAllData(res.data.data)
                    } else {
                        setIsLoading(false)
                        setAllData([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        sendData: function (e) { //送出資料(新增or修改)
            let { value, dataset } = e.target
            if (event.name == '') {
                alert('活動名稱不能為空')
                return
            } else if (event.donate == '') {
                alert('活動捐贈額不能為空')
                return
            } else if (startDate == '') {
                alert('開始日期不能為空')
                return
            } else if (endDate == '') {
                alert('結束日期不能為空')
                return
            }
            if (Number(event.donate) % 1 !== 0) {
                alert('活動捐贈額只能以【整數】輸入!!')
                setEvent({ ...event, donate: '' })
                return
            }

            // 判斷新增 or 修改
            if (value == '新增') {
                setIsLoading(true)
                let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}add_sp_event`
                let params = new URLSearchParams();
                params.append('s_start_date', moment(startDate).format('YYYY-MM-DD'));
                params.append('s_end_date', moment(endDate).format('YYYY-MM-DD'));
                params.append('s_event_name', event.name);
                params.append('s_event_donate', event.donate);
                axios.post(API, params)
                    .then((res) => {
                        if (res.data.code == '0') {
                            setIsLoading(false)
                            handleEvent.getData()
                            setModalShow(false)
                        } else {
                            setIsLoading(false)
                            alert('新增失敗!!')
                            setModalShow(false)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        alert('新增失敗!!')
                        setModalShow(false)
                    })

            } else if (value == '修改') {
                setIsLoading(true)
                let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}update_sp_event/${eventId}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}/${event.name}/${event.donate}`
                axios.patch(API)
                    .then((res) => {
                        if (res.data.code == '0') {
                            setIsLoading(false)
                            handleEvent.getData()
                            setModalShow(false)
                        } else {
                            setIsLoading(false)
                            alert('修改失敗!!')
                            setModalShow(false)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        alert('修改失敗!!')
                        setModalShow(false)
                    })
            }
        },
        delEventData: function (e) { //刪除活動
            let { dataset } = e.target
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}update_sp_event/${dataset.id}`
            axios.delete(API)
                .then((res) => {
                    if (res.data.code == '0') {
                        setIsLoading(false)
                        handleEvent.getData();
                    } else {
                        alert('刪除錯誤!!')
                        setIsLoading(false)
                        handleEvent.getData();
                    }
                })
                .catch((err) => {
                    alert('刪除錯誤!!')
                    setIsLoading(false)
                    console.log(err)
                })
        },
        getEventList: function (item) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}get_event_list/${item.s_id}`
            axios.get(API)
                .then((res) => {
                    if (res.data.code == '0') {
                        setIsLoading(false)
                        setEventUserData(res.data.data)
                        setModalEventUserList(true)
                        setModalEventName(item?.s_event_name)
                    } else {
                        setIsLoading(false)
                        setModalEventUserList(false)
                        setModalEventName(null)
                        setEventUserData([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                })

        },
        clickEventUserList: function ({ e, item }) {
            handleEvent.getEventList(item)
        },
        //modal
        showModal: function (e) {
            let { value, dataset } = e.target;
            if (!modalShow) {
                setModalShow(!modalShow)
            }
            setModalState(value)
            if (value == '新增') {
                setEvent({ name: '', donate: '' })
                setStartDate('')
                setEndDate('')
            } else if (value == '修改') {
                setEvent({ name: dataset.eventname, donate: dataset.eventdonate })
                let formentStartDate = dataset.startdate.split('-');
                let formentEndDate = dataset.enddate.split('-');
                setStartDate(new Date(`${formentStartDate[1]}/${formentStartDate[2]}/${formentStartDate[0]}`)) //日期轉換
                setEndDate(new Date(`${formentEndDate[1]}/${formentEndDate[2]}/${formentEndDate[0]}`)) //日期轉換
                setEventId(dataset.id)
            }
        },
        closeModal: function (e) {
            setModalShow(false)
        },
        //download excel
        downloadFile: function (e) {
            e.preventDefault();
            const workbook = new ExcelJs.Workbook();
            downloadData.forEach((sheetData) => {
                const sheet = workbook.addWorksheet(sheetData.sheetName);
                sheet.addTable({
                    name: sheetData.sheetName,
                    ref: `A1`, // 從A1開始
                    headerRow: true,
                    columns: sheetData.thead.map((s) => {
                        return { name: s };
                    }),
                    rows: sheetData.tbody
                });
                if (sheetData.columnWidths) {
                    sheetData.columnWidths.forEach((column) => {
                        sheet.getColumn(column.number).width = column.width;
                    });
                }
            });

            // 表格裡面的資料都填寫完成之後，訂出下載的callback function
            // 異步的等待他處理完之後，創建url與連結，觸發下載
            workbook.xlsx.writeBuffer().then((content) => {
                const link = document.createElement("a");
                const blob = new Blob([content], {
                    type: "application/vnd.ms-excel;charset=utf-8;"
                });
                link.download = `${moment().format('YYYY-MM-DD')}_特殊活動項目.xlsx`;
                link.href = URL.createObjectURL(blob);
                link.click();
            });
        }
    }

    let downloadData = allData &&
        [{
            sheetName: '特殊活動項目',
            thead: ["活動名稱", "活動捐贈額", "參加人數", "開始日期", "結束日期"],
            tbody: allData.map((item, index) => {
                return [
                    item.s_event_name,
                    item.s_event_donate,
                    item.total_event,
                    moment(item.s_start_date).format('YYYY-MM-DD'),
                    moment(item.s_end_date).format('YYYY-MM-DD')
                ]
            }),
            columnWidths: [
                { number: 1, width: 30 },
                { number: 2, width: 15 },
                { number: 3, width: 10 },
                { number: 4, width: 20 },
                { number: 5, width: 20 }
            ]
        }]

    useEffect(() => { }, [event])

    useEffect(() => {
        handleEvent.getData();
    }, [])
    useEffect(() => {
        if (allData.length <= 0) {
            return
        }
    }, [allData])

    let activeStyle = {
        borderBottom: '2px solid #fff',
        paddingBottom: '4px'
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="bg-challenges-blue h-100">
                <div className='container p-4 challenge position-relative'>
                    <div>
                        <h3 className='challenge-title text-light text-center'>特殊活動項目</h3>
                    </div>
                    <button className='btn btn-challenges-yellow fw-bolder position-absolute' style={{ right: '170px', top: '24px' }} value='新增' onClick={handleEvent.showModal}>
                        新增特殊活動
                    </button>
                    <button className='btn btn-challenges-yellow fw-bolder position-absolute' style={{ right: '33px', top: '24px' }} onClick={handleEvent.downloadFile}>
                        <FontAwesomeIcon icon={faFileExcel} className='me-2' />
                        下載 Excel
                    </button>
                    {
                        allData.length == 0 ?
                            <p className='text-light text-center py-3'>無資料</p> :
                            <div className='table-responsive-xxl text-center mt-1 mt-sm-3' style={{ height: '80vh', overflow: 'scroll' }}>
                                {
                                    <table className='table table-striped table-light align-middle'>
                                        <thead>
                                            <tr>
                                                <th className='text-center' width='200' style={{ whiteSpace: 'nowrap' }}>活動名稱</th>
                                                <th className='text-center' width='130' style={{ whiteSpace: 'nowrap' }}>活動捐贈額</th>
                                                <th className='text-center' width='100' style={{ whiteSpace: 'nowrap' }}>參加人數</th>
                                                <th className='text-center' width='130' style={{ whiteSpace: 'nowrap' }}>開始日期</th>
                                                <th className='text-center' width='130' style={{ whiteSpace: 'nowrap' }}>結束日期</th>
                                                <th className='text-center' width='130' style={{ whiteSpace: 'nowrap' }}>功能</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allData.sort((a, b) => {
                                                    return moment(b.s_start_date).valueOf() - moment(a.s_start_date).valueOf()
                                                }).map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td className='text-dark-blue fw-bolder' style={{ whiteSpace: 'nowrap' }}>{item.s_event_name}</td>
                                                            <td className='text-dark-blue fw-bolder' style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.s_event_donate)}</td>
                                                            <td className='text-dark-blue fw-bolder' style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.total_event)} 位</td>
                                                            <td className='text-dark-blue' style={{ whiteSpace: 'nowrap' }}>{moment(item.s_start_date).format('YYYY-MM-DD')}</td>
                                                            <td className='text-dark-blue' style={{ whiteSpace: 'nowrap' }}>{moment(item.s_end_date).format('YYYY-MM-DD')}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }} >
                                                                <button type='button' className='btn p-0 mx-3 fw-bolder' value='名單' onClick={e => handleEvent.clickEventUserList({ e, item })}>名單</button>
                                                                <button type='button' onClick={handleEvent.showModal} className='btn p-0 mx-3 fw-bolder' value='修改'
                                                                    data-id={item.s_id}
                                                                    data-eventname={item.s_event_name}
                                                                    data-eventdonate={item.s_event_donate}
                                                                    data-startdate={dateChange(item.s_start_date)}
                                                                    data-enddate={dateChange(item.s_end_date)}
                                                                >修改</button>
                                                                <button type='button' onClick={handleEvent.delEventData} className='btn p-0 text-danger del-btn mx-3' value='刪除'
                                                                    data-id={item.s_id}
                                                                ></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                    }
                </div>
            </div>
            <Modal show={modalShow} onHide={handleEvent.closeModal} style={{ zIndex: '10000' }}>
                <Modal.Header closeButton>{modalState}</Modal.Header>
                <Modal.Body>
                    <div className='mb-3'>
                        <label htmlFor='eventName' className='fw-bolder'>活動名稱<span className='text-danger'>*</span></label>
                        <input id='eventName' name='eventName' type='text' className={`form-control`}
                            onChange={e => setEvent({ ...event, name: e.target.value })}
                            defaultValue={event.name} />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='eventDonate' className='fw-bolder'>活動捐贈額<span className='text-danger'>*</span></label>
                        <input id='eventDonate' name='eventDonate' type='tel' className={`form-control`}
                            onChange={e => setEvent({ ...event, donate: e.target.value })}
                            value={event.donate} />
                    </div>
                    <div className='my-3'>
                        <label htmlFor='startDate' className='fw-bolder'>開始日期<span className='text-danger'>*</span></label>
                        <DatePicker locale='zhTW'
                            // disabled={calFormDis ? 'disabled' : ''}
                            className={`form-control`}
                            dateFromat='YYYY-MM-dd'
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            minDate={minYearsToDate(new Date(), 1)}
                            // maxDate={new Date()}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            placeholderText='MM (月)/ DD (日)/ YYYY (西元年)'>
                        </DatePicker>
                    </div>
                    <div className='my-3'>
                        <label htmlFor='endDate' className='fw-bolder'>結束日期<span className='text-danger'>*</span></label>
                        <DatePicker locale='zhTW'
                            // disabled={calFormDis ? 'disabled' : ''}
                            className={`form-control`}
                            dateFromat='YYYY-MM-dd'
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            minDate={minYearsToDate(new Date(), 1)}
                            // maxDate={new Date()}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            placeholderText='MM (月)/ DD (日)/ YYYY (西元年)'>
                        </DatePicker>
                    </div>
                    <div className='text-center py-3'>
                        <button type='button' className='btn btn-challenges-yellow fw-bolder w-100'
                            onClick={handleEvent.sendData} value={modalState}>{modalState}</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* 名單 list */}
            <Modal size="lg" show={modalEventUserList} onHide={e => (setModalEventUserList(false), setModalEventName(null), setEventUserData([]))} style={{ zIndex: '10000' }}>
                <Modal.Header closeButton><p className='fw-bolder'>{modalEventName}</p></Modal.Header>
                <Modal.Body style={{ height: '500px', overflowY: 'scroll' }}>
                    {
                        eventUserData.length > 0 &&
                        <table className='table table-striped table-light align-middle'>
                            <thead>
                                <tr>
                                    <th className='text-center' width='200' style={{ whiteSpace: 'nowrap' }}>單位</th>
                                    <th className='text-center' width='130' style={{ whiteSpace: 'nowrap' }}>參與者</th>
                                    <th className='text-center' width='100' style={{ whiteSpace: 'nowrap' }}>時間</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    eventUserData.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className='text-dark-blue fw-bolder text-center' style={{ whiteSpace: 'nowrap' }}>{item.r_buscode}</td>
                                                <td className='text-dark-blue fw-bolder text-center' style={{ whiteSpace: 'nowrap' }}>{item.r_username}</td>
                                                <td className='text-dark-blue fw-bolder text-center' style={{ whiteSpace: 'nowrap' }}>{moment(item.r_timest).format('YYYY-MM-DD')}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Event