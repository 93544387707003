//@ 權限管理
export function getRole(type) { //取的權限編號名稱
    switch (type) {
        // 最高權限
        case '0': return ('最高管理者')
        // 發訊中心
        case '1': return ('發訊小編')
        case '2': return ('發訊審核者')
        // 五大挑戰
        case '4': return ('五大挑戰隊長')
        case '6': return ('五大挑戰人員')
        // 財顧、業務服務、其他
        case '3': return ('寵物險後勤者')
        case '5': return ('財顧管理者')
        case '7': return ('業務服務部')
        case '8': return ('推薦商品管理')
        default: break
    }
}

//@ 各權限指向路徑
export function returnRoute(role) {
    switch (role) {
        case '0':
        case '1':
        case '2': return 'message_center';
        case '4':
        case '6': return 'challenges_rank';
        case '5': return 'goldenfp_list';
        case '7': return 'nps_assistant';
        case '8': 
        default: return 'login'
    }
}

/* children 跟著 main，設定"all"就屬所屬 main 所有。其他為需要設定權限 key */
export function setAuthorization(type, isMain) { //設定權限
    let data = {
        permissions: '0',
        sales: '0',
        message_center: '0,1',
        message_check: '0,1,2',
        push_statistics: '0,2',
        challenges_rank: '0,4,6',
        challenges_award: '0,4,6',
        challenges_event: '0,4,6',
        goldenfp_list: '0,5',
        goldenfp_question: '0,5',
        nps_data: '0,7',
        nps_assistant: '0,7',
        vip_service: '0,7'
    }

    let isType = type?.split('/')
    if (isType !== undefined) {
        return (data?.[isType[2]])
    }
}