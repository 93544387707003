import React, { useEffect, useState } from "react";
import axios from "axios";

//components
import BackgroundWrapper from "../../components/BackgroundWrapper";
import Loading from "../../components/Loading";

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPercentage } from '@fortawesome/free-solid-svg-icons';

import LineLogin from "../../assets/javascript/lineLogin";
import Countfunc from "../../assets/javascript/countfunc";

const Attendance = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [ATT, setATT] = useState('');
    let [data, setData] = useState({})
    let [noDataText, setNoDataText] = useState('')
    useEffect(() => {
        LineLogin('/attendance'); //LINE 登入狀態驗證
        Countfunc('出席', 39)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}Att`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/attendance');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data)
                        setIsLoading(false)
                        setATT(res.data.ATT.toFixed(1))
                    } else if (res.data.ResponseCode === '-20') {
                        setNoDataText('無資料')
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);
    return (
        <>
            <Loading isLoading={isLoading} />
            <BackgroundWrapper>
                <div className="top-nav w-100 py-3">
                    <p className="text-light text-center fw-bolder">出席率</p>
                </div>
                <div className="container mt-3">
                    <div className="row">
                        <div className="insurance-box col-md-6">
                            <h6 className="box-header bg-dark-blue border-dark-blue">年度總出席率</h6>
                            <div className="box-body border-dark-blue">
                                <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faClock} /><span className="text-golden-brown box-num">{ATT === '' || ATT === null || ATT === undefined ? '0' : ATT}</span>
                                <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faPercentage} />
                            </div>
                        </div>
                        <div className="col-12">
                            <hr />
                            <h6 className="fw-bolder">公會訓練時數</h6>
                            <div className="row">
                                <div className="insurance-box col-6">
                                    <h6 className="box-header bg-dark-blue border-dark-blue">壽險</h6>
                                    <div className="box-body border-dark-blue fw-bolder">
                                        <p className="text-golden-brown">完成</p>
                                        <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faClock} /><span className="text-golden-brown box-num">{data.REG === '' || data.REG === null || data.REG === undefined ? '0' : data.REG}</span>
                                        <p className="text-golden-brown">小時</p>
                                    </div>
                                </div>
                                <div className="insurance-box col-6">
                                    <h6 className="box-header bg-dark-blue border-dark-blue">產險</h6>
                                    <div className="box-body border-dark-blue fw-bolder">
                                        <p className="text-golden-brown">完成</p>
                                        <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faClock} /><span className="text-golden-brown box-num">{data.PPT === '' || data.PPT === null || data.PPT === undefined ? '0' : data.PPT}</span>
                                        <p className="text-golden-brown">小時</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundWrapper>
        </>
    )
}

export default Attendance;