import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Loading from 'components/Loading';

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
const Sales = (props) => {
    //登入角色判斷
    useEffect(() => {
        let loginRole = localStorage.getItem('role');
        if (loginRole === '1' || loginRole === '2') {
            window.location.href = '/dashboard/message_center'
        }
    }, []);

    let [isLoading, setLoading] = useState(true);

    //搜尋人員
    let [searchInput, setSearchInput] = useState('');
    //---GET 取得全體人員
    let [memberData, setMemberData] = useState([])
    const getAllSales = () => {
        let API = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/GetAllSales`;
        axios.get(API)
            .then((res) => {
                setMemberData(res.data);
                setLoading(false)
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }
    useEffect(() => {
        getAllSales();
    }, []);

    // 業務員
    let mapSaleData = [];
    const saleDataFun = (data) => {
        data.forEach((item, index) => {
            item.AllDepts.forEach((dItem, dIndex) => {
                dItem.Line.forEach((lItem, lIndex) => {
                    mapSaleData.push({
                        loc: item.Loc,
                        dept: dItem.Dept,
                        sale: lItem
                    })
                })

            })
        })
    }
    saleDataFun(memberData)

    let [putLoading, setPutLoading] = useState(false)
    //更改繳費狀態
    const handleChangePay = (e) => {
        setPutLoading(true)
        let { dataset } = e.target;
        let payData = {
            LineId: dataset.line,
            pay: dataset.pay
        }

        if (dataset.pay === '0') {
            payData.pay = '1'
        } else if (dataset.pay === '1') {
            payData.pay = '0'
        }
        let API = `${process.env.REACT_APP_GOLDEN_BACK_API}LineLogin/UpdateLineIdPay`
        axios.put(API, payData)
            .then((res) => {
                if (res.data.ResponseCode === '0') {
                    getAllSales();
                    setPutLoading(false)
                }
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }

    //更改權限
    const handleChangeValid = (e) => {
        setPutLoading(true)
        let { dataset } = e.target;
        let data = {
            LineId: dataset.line,
            valid: dataset.valid
        }
        if (dataset.valid === '0') {
            data.valid = '1'
        } else if (dataset.valid === '1') {
            data.valid = '0'
        }
        let API = `${process.env.REACT_APP_GOLDEN_BACK_API}LineLogin/UpdateLineIdValid`
        axios.put(API, data)
            .then((res) => {
                if (res.data.ResponseCode === '0') {
                    getAllSales();
                    setPutLoading(false)
                }
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }

    //搜尋
    let searchData = mapSaleData.filter((item, index) => {
        if (searchInput === '') {
            return item
        } else if (item.loc.includes(searchInput) || item.dept.includes(searchInput) || item.sale.Name.includes(searchInput)) {
            return item
        } else if (searchInput === '已繳費' || searchInput === '已' || searchInput === '已繳') {
            return item.sale.Pay === '1'
        } else if (searchInput === '未繳費' || searchInput === '未' || searchInput === '未繳') {
            return item.sale.Pay === '0'
        } else if (searchInput === '啟用' || searchInput === '啟') {
            return item.sale.Valid === '1'
        } else if (searchInput === '停用' || searchInput === '停') {
            return item.sale.Valid === '0'
        }
    })
    useEffect(() => {
    }, [searchInput]);

    //分頁邏輯 start
    //分頁初始資料
    let [currentPage, setCurrentPage] = useState(1); //當前頁數
    let [itemsPerPage] = useState(10); //預設一頁顯示筆數
    let [pageNumberLimit] = useState(5); //每次顯示頁數限制
    let [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5) //最大頁數限制
    let [minPageNumberLimit, setMinPageNumberLimit] = useState(0) //最小頁數限制

    const handleCurrentPage = (e) => {
        e.preventDefault();
        let { id } = e.target;
        setCurrentPage(Number(id))
    }

    let pages = []; //總共分成幾頁
    for (let i = 1; i <= Math.ceil(searchData.length / itemsPerPage); i++) {
        pages.push(i);
    }

    //預設 currentPage 為 1; itemsPerPage 為 10 筆
    const indexOfLastItem = currentPage * itemsPerPage; //10
    const indexOfFirstItem = indexOfLastItem - itemsPerPage; //0
    const currentItems = searchData.slice(indexOfFirstItem, indexOfLastItem); // MAP 的資料
    const renderPageNumbers = pages.map((item) => {
        if (item < maxPageNumberLimit + 1 && item > minPageNumberLimit) {
            return (
                <li className={`page-item`} key={item} id={item} onClick={handleCurrentPage}>
                    <a className={`page-link ${currentPage === item ? "page-active" : ""}`} id={item}>{item}</a>
                </li>
            )
        } else {
            return null
        }
    })

    const handleNextBtn = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    }
    const handlePretBtn = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    }

    let pageAddBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageAddBtn = <li className={`page-item`} >
            <a className={`page-link px-2`} onClick={handleNextBtn}>&hellip;</a>
        </li>
    }
    let pageReduceBtn = null;
    if (minPageNumberLimit >= 1) {
        pageReduceBtn = <li className={`page-item`} >
            <a className={`page-link px-2`} onClick={handlePretBtn}>&hellip;</a>
        </li>
    }
    //分頁邏輯 end

    const handleSearchInput = (e) => {
        setSearchInput(e.target.value.replace(/\s+/g, ""))
        searchData = []
        // setFilterData([])
        setCurrentPage(1)
    }

    useEffect(() => {
    }, [memberData, mapSaleData, currentItems, currentPage]);
    return (
        <>
            <Loading isLoading={isLoading} />
            <Loading isLoading={putLoading} />
            <h2 className='fs-4 text-dark-blue fw-bolder p-3'>{props.title}</h2>
            <div className="container position-relative px-3">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-5 mt-4">
                        <input type="text" className="form-control" placeholder="搜尋輸入" onChange={handleSearchInput} />
                    </div>
                    <p className="mt-2 text-danger text-center">＊提供業務員姓名、地區、部門、LINE 繳費狀態(已繳費或未繳費)、權限狀態(停用或啟用)查詢</p>
                    <div className="col-12 py-3">
                        <div className="table-responsive-xxl text-center">
                            <table className="table table-striped table-hover">
                                <thead className="text-golden-brown">
                                    <tr>
                                        <th width="180">業務員姓名</th>
                                        <th width="150">地區</th>
                                        <th width="150">部門</th>
                                        <th width="150"></th>
                                        <th width="180" className="text-start">LINE 繳費狀態</th>
                                        <th width="180" className="text-start">權限狀態</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentItems.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.sale.Name}</td>
                                                    <td>{item.loc}</td>
                                                    <td>{item.dept}</td>
                                                    <td></td>
                                                    <td className="text-start">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id={`${item.sale.ID}-pay`} checked={item.sale.Pay === '0' ? '' : 'checked'} onChange={handleChangePay} data-line={item.sale.ID} data-pay={item.sale.Pay} />
                                                            <label className={`form-check-label fw-bolder ${item.sale.Pay === '0' ? 'text-danger' : 'text-success'}`} htmlFor={`${item.sale.ID}-pay`} data-line={item.sale.ID} data-pay={item.sale.Pay}>{item.sale.Pay === '0' ? '未繳費' : '已繳費'}</label>
                                                        </div>
                                                    </td>
                                                    <td className="text-start">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id={`${item.sale.ID}-valid`} checked={item.sale.Valid === '0' ? '' : 'checked'} onChange={handleChangeValid} data-line={item.sale.ID} data-valid={item.sale.Valid} />
                                                            <label className={`form-check-label fw-bolder ${item.sale.Valid === '0' ? 'text-danger' : 'text-success'}`} htmlFor={`${item.sale.ID}-valid`} data-line={item.sale.ID} data-valid={item.sale.Valid}>{item.sale.Valid === '0' ? '停用' : '啟用'}</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="py-3">
                            <nav aria-label="Page navigation">
                                <ul className="pagination justify-content-center">
                                    <li className={`page-item`} >
                                        <a className={`page-link ${currentPage === pages[0] ? "page-disabled d-none" : ""}`} onClick={handlePretBtn}><FontAwesomeIcon icon={faChevronLeft} /></a>
                                    </li>
                                    {pageReduceBtn}
                                    {renderPageNumbers}
                                    {pageAddBtn}
                                    <li className={`page-item`} >
                                        <a className={`page-link ${currentPage === pages[pages.length - 1] ? "page-disabled d-none" : ""}`} onClick={handleNextBtn}><FontAwesomeIcon icon={faChevronRight} /></a>
                                    </li>
                                </ul>
                                {/* <div className="d-flex justify-content-center my-3">
                            <button type="button" className={`btn btn-outline-dark-blue text-center ${currentItems.length < 10 || currentItems.length == resultData.length ? 'd-none' : 'd-block'}`} onClick={handleLoadMore}>一次瀏覽更多</button>
                        </div> */}
                            </nav>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default Sales