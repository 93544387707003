import React, { useEffect, useState } from 'react';
import LineLogin from 'assets/javascript/lineLogin';
import Countfunc from 'assets/javascript/countfunc';
import axios from 'axios';
import moment from 'moment';
import Loading from 'components/Loading';
import NpsModel from './NpsModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
const NpsAssistant = (prop) => {
    let numberList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
    let [isLoading, setIsLoading] = useState(false),
        [apiText, setApiText] = useState(''), //API 文字
        [qToken, setQToken] = useState(null),
        [isOpenQ, setIsOpenQ] = useState(false) // 是否開放問卷

    let [userData, setUserData] = useState(null),
        [assistantData, setAssistantData] = useState(null),
        [chooseNum, setChooseNum] = useState('0'),
        [improveData, setImproveData] = useState({
            reason: '',
            improve: ''
        })

    // 填寫狀態
    let [state, setState] = useState('') // 填寫狀態: 0 填寫完成, 1 填表單 , 2 資料取得失敗, 3 已離職

    // 登入驗證
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token === '') {
        localStorage.setItem('page', window.location.pathname);
        window.location.href = '/login'
    }

    useEffect(() => {
        LineLogin(window.location.pathname)
        Countfunc('行專 NPS 問卷', 55)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', window.location.pathname);
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                console.log(err)
                // alert('請重新整理')
            })

        // 判斷問卷開放時間
        let thisTime = moment().valueOf()
        let thisTimeRule = {
            date: moment().format('yyyy-MM-DD'),
            thisTime: moment().valueOf(),
            minTime: moment(`${moment().format('yyyy-MM-DD')} 08:30:00`).valueOf(),
            maxTime: moment(`${moment().format('yyyy-MM-DD')} 17:30:00`).valueOf(),
            weekday: moment().day() // 0 ~ 6 (0 為週日)
        }

        if ((thisTime >= thisTimeRule.minTime && thisTime <= thisTimeRule.maxTime) && (thisTimeRule.weekday !== 0 && thisTimeRule.weekday !== 6)) {
            setIsOpenQ(true)
            setUserData(JSON?.parse(unescape(token)))
            setQToken(window.location.pathname.split('/')[2])
        } else {
            setIsOpenQ(false)
        }
    }, []);

    const handleAPI = {
        addAssistantNum: function () {
            setIsLoading(true)
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}add_assistant_num`

            let postParams = new URLSearchParams();
            postParams.append('SAL_MST_NAME', assistantData?.SAL_MST_NAME);
            postParams.append('SAL_MST_YEAR', moment().format('YYYY'));
            postParams.append('SAL_MST_MONTH', moment().format('MM'));
            postParams.append('SAL_MST_NUM', chooseNum);
            postParams.append('SAL_USER_NAME', userData?.Name);
            postParams.append('SAL_USER_ID', userData?.Id);
            postParams.append('SAL_NUM_REASON', improveData?.reason == '' ? null : improveData?.reason);
            postParams.append('SAL_NUM_IMPROVE', improveData?.improve == '' ? null : improveData?.improve);
            axios.post(API, postParams)
                .then((res) => {
                    let { code } = res.data
                    if (code == '0') {
                        setIsLoading(false)
                        setState('0')
                        // reset
                        setChooseNum('0')
                        setImproveData({ reason: '', improve: '' })
                    } else {
                        setIsLoading(false)
                        alert(`送出表單錯誤。(add_assistant_num:${code})`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    alert(`網路連線錯誤，請聯繫管理員。(add_assistant_num:err)`)
                })
        },
        getAssistantName: function () {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}get_assistant_name/${qToken}`
            axios.get(API)
                .then((res) => {
                    let { code, data } = res.data
                    if (code == '0') {
                        setIsLoading(false)
                        if (data[0]?.SAL_MST_LAY_OFF_DATE !== null) { //已離職
                            setState('3')
                            setApiText('')
                        } else { //在職
                            setState('1')
                            setApiText('')
                            setAssistantData(data[0])
                        }
                    } else {
                        setIsLoading(false)
                        setState('2')
                        setApiText(`get_assistant_name：(${code})`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    alert(`網路連線錯誤，請聯繫管理員。(get_assistant_name:err)`)
                })
        }
    }

    const handleUI = {
        renderUI: function (state) {
            switch (state) {
                case '0': return ( // 填寫完成
                    <div style={{ height: '240px' }}>
                        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                            <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '40px', color: '#176DE5' }} />
                            <div className='text-center' style={{ color: '#052D66' }}>
                                <h2 className='fw-bolder my-3' style={{ fontSize: '20px' }}>您已完成問卷</h2>
                                <p className='fw-bolder'>本次問卷已完成，感謝您撥空填答！</p>
                            </div>
                        </div>
                    </div>
                )
                case '1': return ( // 填表單
                    <>
                        <p className='fw-bolder text-center' style={{ fontSize: '18px', color: '#176DE5' }}>{userData?.Name}，您好！</p>
                        <hr />
                        <div className='my-4'>
                            <p className='fw-bolder' style={{ color: '#052D66' }}>你有多大可能向同事推薦{assistantData?.SAL_MST_NAME}行專？</p>
                            <div className="btn-group w-100 my-3">
                                {
                                    numberList.map((item, index) => {
                                        return (
                                            <button key={`number-${item}`} type="button" className={`btn p-1 ${chooseNum == item ? 'btn-primary' : 'btn-outline-primary'}`}
                                                onClick={e => setChooseNum(item)}>{item}</button>
                                        )
                                    })
                                }
                            </div>
                            <div className='fw-bolder d-flex justify-content-between' style={{ color: '#052D66' }}>
                                <p>0：非常低</p>
                                <p>10：非常高</p>
                            </div>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <p className='fw-bolder mb-3' style={{ color: '#052D66' }}>您評分的緣由是什麼?</p>
                            <textarea className="form-control" rows='3'
                                value={improveData.reason}
                                onChange={e => setImproveData({ ...improveData, reason: e.target.value })}></textarea>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <p className='fw-bolder mb-3' style={{ color: '#052D66' }}>我們應該如何改進，才能使您擁有更好的體驗?</p>
                            <textarea className="form-control" rows='3'
                                value={improveData.improve}
                                onChange={e => setImproveData({ ...improveData, improve: e.target.value })}></textarea>
                        </div>
                        <div className='text-center'>
                            <button className='btn fw-bolder text-light' style={{ background: '#176DE5' }} onClick={e => handleAPI.addAssistantNum()}>送出</button>
                        </div>
                    </>
                )
                case '2': return ( //取得資料錯誤，請重新進行掃描
                    <div className='py-3' style={{ height: '240px' }}>
                        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                            <FontAwesomeIcon icon={faExclamationCircle} className='text-danger' style={{ fontSize: '40px' }} />
                            <div className='text-center' style={{ color: '#052D66' }}>
                                <h2 className='fw-bolder my-3' style={{ fontSize: '20px' }}>取得資料錯誤</h2>
                                <p className='fw-bolder'>請重新進行掃描</p>
                            </div>
                        </div>
                        <p className='text-center' style={{ color: 'rgba(0,0,0,.5)' }}>{apiText}</p>
                    </div>
                )
                case '3': return ( //已離職
                    <div className='py-3' style={{ height: '240px' }}>
                        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                            <FontAwesomeIcon icon={faExclamationCircle} className='text-danger' style={{ fontSize: '40px' }} />
                            <div className='text-center' style={{ color: '#052D66' }}>
                                <h2 className='fw-bolder my-3' style={{ fontSize: '20px' }}>查無此行專資料</h2>
                            </div>
                        </div>
                    </div>
                )
                case '4': return ( //尚未開放
                    <div className='py-3' style={{ height: '240px' }}>
                        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                            <FontAwesomeIcon icon={faExclamationCircle} className='text-dark-blue' style={{ fontSize: '40px' }} />
                            <div className='text-center' style={{ color: '#0b528b' }}>
                                <h2 className='fw-bolder my-3' style={{ fontSize: '20px' }}>尚未開放填寫</h2>
                            </div>
                        </div>
                    </div>
                )
                default: break;
            }
        }
    }

    useEffect(() => {
        if (qToken !== null) {
            handleAPI.getAssistantName()
        }
    }, [qToken])

    return (
        <>
            <Loading isLoading={isLoading} />
            <NpsModel name={assistantData?.SAL_MST_NAME}>
                <div className='bg-light px-3 py-4' style={{ borderRadius: '20px' }}>
                    {isOpenQ ? handleUI.renderUI(state) : handleUI.renderUI('4')}
                </div>
            </NpsModel>
        </>
    )
}
export default NpsAssistant