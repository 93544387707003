import React from 'react';

import BackgroundWrapper from '../components/BackgroundWrapper';
import goldenLogo from "../assets/images/goldennet_logo.svg";

const UpdateSuccess = () => {
    let style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
    }
    return (
        <BackgroundWrapper>
            <div style={style}>
                <div className="text-center py-5"><img src={goldenLogo} className="img-fluid" alt="Golden-LOGO" width="250px" /></div>
                <h3 className="fw-bolder text-golden-brown text-center">404 無此頁面!!</h3>
            </div>
        </BackgroundWrapper>
    );
};

export default UpdateSuccess