import React, { useState, useEffect } from "react";
import moment from 'moment';
//components
import BackgroundWrapper from "../../components/BackgroundWrapper";
import Loading from "../../components/Loading";
import { toCurrency } from "../../assets/javascript/calTool"
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import axios from "axios";
import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc'
import { Encrypt } from "../../assets/javascript/AESTool";
import base64url from "base64url";

//100 產險 ; 1 壽險
// 如果沒有資料顯示[無]

const SalaryQuery = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [yearOption, setYearOption] = useState(moment().format('YYYY'))
    let [MonthOption, setMonthOption] = useState('01')
    //@ Review PDF
    let [numPages, setNumPages] = useState(null);
    let [fileUrl, serFileUrl] = useState(null)
    let [fileName, serFileName] = useState(null)

    //產生年月 select start
    let year = [];
    let month = [];
    const yearMonthFun = () => {
        for (let i = 2012; i <= moment().format('YYYY'); i++) {
            year.push(i)
        }
        for (let i = 1; i <= 12; i++) {
            month.push(i)
        }
    }
    yearMonthFun();
    //帶入select
    const getYearOption = year.map((item, index) => {
        return <option key={index} value={item} selected={moment().format('YYYY')}>{item}年</option>
    })
    const getMonthOption = month.map((item, index) => {
        if (item < 10) {
            return <option key={index} value={`0${item}`}>{item}月</option>
        }
        return <option key={index} value={item}>{item}月</option>
    })
    //產生年月 select end

    const handleYearSelect = (e) => {
        serFileUrl(null)
        serFileName(null)
        LineLogin('/salary_query'); //LINE 登入狀態驗證
        let { value } = e.target;
        setYearOption(value);
        setMonthOption('01')
        setIsLoading(true)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}SS/Y=${value}`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/salary_query');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data.Data)
                        setIsLoading(false)
                    } else if (res.data.ResponseCode === '-20') {
                        setData([])
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }

    const handleMonthSelect = (e) => {
        let { value } = e.target
        setMonthOption(value)
        serFileUrl(null)
        serFileName(null)
    }

    let [data, setData] = useState([])
    useEffect(() => {
        LineLogin('/salary_query'); //LINE 登入狀態驗證
        Countfunc('薪資查詢', 21)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}SS/Y=${yearOption}`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/salary_query');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '-20') {
                        setData([])
                        setIsLoading(false)
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data.Data)
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);

    const handleDownloadFile = (e, type) => {
        serFileUrl(null)
        serFileName(null)
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;

        let key = process.env.REACT_APP_KEY;
        let iv = process.env.REACT_APP_IV;
        Encrypt(dataset.filename, key, iv)
        let fileURL = base64url(Encrypt(dataset.filename, key, iv).toString())
        let API = `${process.env.REACT_APP_GOLDEN_API5000}SS/file=${fileURL}`
        axios.get(API)
            .then((res) => {
                if (res.data.ResponseCode === '0') {
                    serFileUrl(`data:application/pdf;base64,${res.data.Data}`)
                    serFileName(`薪資檔：${type} ${yearOption}-${MonthOption}`)
                }
                else if (res.data.ResponseCode === '-27') {
                    alert('檔案已不存在!!')
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                    window.location.href = API
                }
            })
            .catch((err) => {
                alert('請重新整理')
            })

    }

    const handleReviewPDF = { // Review PDF function
        onDocumentLoadSuccess: function ({ numPages }) {
            setNumPages(numPages);
            setIsLoading(false)
        }
    }

    const handleShowPDF = (file, fileName) => {
        return (
            <>
                <div className="mx-auto">
                    <p className="mb-3 fw-bolder">{fileName}</p>
                    <div className="d-flex justify-content-center" style={{ height: '500px', overflowY: 'scroll' }}>
                        <Document file={file} onLoadSuccess={handleReviewPDF.onDocumentLoadSuccess}>
                            {Array.from(
                                new Array(numPages),
                                (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        height='1100'
                                        pageNumber={index + 1}
                                        className={`${index > 0 && 'mt-3'}`}
                                    >
                                    </Page>
                                )
                            )}
                        </Document>
                    </div>
                </div>
            </>
        )
    }

    const handleUrlBase64 = (e, url, type) => {
        serFileUrl(null)
        serFileName(null)
        setIsLoading(true)
        e.preventDefault()
        let API = `${process.env.REACT_APP_GOLDEN_API5000}URLBase64`
        let param = new URLSearchParams();
        param.append('URL', url);
        axios.post(API, param, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                if (res?.data?.ResponseCode == '0') {
                    serFileUrl(`data:application/pdf;base64,${res.data.Data}`)
                    serFileName(`薪資檔：${type} ${yearOption}-${MonthOption}`)
                } else {
                    alert(`下載資料錯誤!${res?.data?.ResponseCode}`)
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                alert('網路連線錯誤，請聯繫管理員')
                setIsLoading(false)
            })
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <BackgroundWrapper>
                <div className="position-fixed w-100 bg-light" style={{ zIndex: '30' }}>
                    <div className="top-nav w-100 py-3">
                        <p className="text-light text-center fw-bolder">薪資查詢</p>
                    </div>
                    <div className="container pt-3">
                        <div className="d-flex">
                            <select className="form-select w-50 mx-2" aria-label="year select" onChange={handleYearSelect}>
                                {getYearOption}
                            </select>
                            <select className="form-select w-50 mx-2" aria-label="month select" value={MonthOption} onChange={handleMonthSelect}>
                                {getMonthOption}
                            </select>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className="d-table" style={{ height: '140px' }}></div>
                <div className="container">
                    <div className="table-responsive-xxl text-center">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className="text-start" style={{ whiteSpace: 'nowrap' }}>類別</th>
                                    <th className="text-end" style={{ whiteSpace: 'nowrap' }}>個人FYC</th>
                                    <th className="text-end" style={{ whiteSpace: 'nowrap' }}>個人累積FYC</th>
                                    <th className="text-end" style={{ whiteSpace: 'nowrap' }}>壽險/產險<br />首年佣金</th>
                                    <th className="text-end" style={{ whiteSpace: 'nowrap' }}>壽險/產險<br />續年佣金</th>
                                    <th className="text-end" style={{ whiteSpace: 'nowrap' }}>服務津貼</th>
                                    <th className="text-end" style={{ whiteSpace: 'nowrap' }}>年終獎金</th>
                                    <th className="text-end" style={{ whiteSpace: 'nowrap' }}>其他銷售獎勵</th>
                                    <th className="text-end" style={{ whiteSpace: 'nowrap' }}>薪資檔</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={data.length === 0 ? '' : 'd-none'}>
                                    <td className={`text-start ${data.length === 0 ? '' : 'd-none'}`} colSpan='9'>{data.length === 0 ? '無資料' : ''}</td>
                                </tr>
                                {
                                    data.filter((val) => {
                                        if (MonthOption == val.mm && val.jb == 1) {
                                            return val
                                        }
                                    }).map((item, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td className="text-start">壽險</td>
                                                    <td className="text-end text-dark-blue">{toCurrency(Math.round(item['個人FYC']))}</td>
                                                    <td className="text-end text-dark-blue">{toCurrency(Math.round(item['個人累計FYC']))}</td>
                                                    <td className="text-end text-dark-blue">{toCurrency(Math.round(item['壽險/產險首年佣金']))}</td>
                                                    <td className="text-end text-dark-blue">{toCurrency(Math.round(item['壽險/產險續年佣金']))}</td>
                                                    <td className="text-end text-dark-blue">{toCurrency(Math.round(item['服務津貼']))}</td>
                                                    <td className="text-end text-dark-blue">{toCurrency(Math.round(item['年終獎金']))}</td>
                                                    <td className="text-end text-dark-blue">{toCurrency(Math.round(item['其他銷售獎勵']))}</td>
                                                    <td className="text-center text-dark-blue">
                                                        {
                                                            item.SRA_URL == null || item.SRA_URL == undefined || item.SRA_URL == '' ?
                                                                <a href="#" data-download={item.SRA_FULLPATH} data-filename={item.SRA_FULLPATH} target="_blank" className="paperclip-icon d-block text-golden-brown" onClick={e => handleDownloadFile(e, '壽險')}></a>
                                                                :
                                                                <a href="#" className="paperclip-icon d-block text-golden-brown" onClick={e => (handleUrlBase64(e, `${item.SRA_URL}?${item.SRA_PARA}${item.SRA_KEY}`, '壽險'))}></a>
                                                        }
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                                {
                                    data.filter((val) => {
                                        if (MonthOption == val.mm && val.jb == 100) {
                                            return val
                                        }
                                    }).map((item, index) => {
                                        return (
                                            <tr>
                                                <td className="text-start">產險</td>
                                                <td className="text-end text-dark-blue">{toCurrency(Math.round(item['個人FYC']))}</td>
                                                <td className="text-end text-dark-blue">{toCurrency(Math.round(item['個人累計FYC']))}</td>
                                                <td className="text-end text-dark-blue">{toCurrency(Math.round(item['壽險/產險首年佣金']))}</td>
                                                <td className="text-end text-dark-blue">{toCurrency(Math.round(item['壽險/產險續年佣金']))}</td>
                                                <td className="text-end text-dark-blue">{toCurrency(Math.round(item['服務津貼']))}</td>
                                                <td className="text-end text-dark-blue">{toCurrency(Math.round(item['年終獎金']))}</td>
                                                <td className="text-end text-dark-blue">{toCurrency(Math.round(item['其他銷售獎勵']))}</td>
                                                <td className="text-center text-dark-blue">
                                                    {
                                                        item.SRA_URL == null || item.SRA_URL == undefined || item.SRA_URL == '' ?
                                                            <a href="#" data-download={item.SRA_FULLPATH} data-filename={item.SRA_FULLPATH} target="_blank" className="paperclip-icon d-block text-golden-brown" onClick={e => handleDownloadFile(e, '產險')}></a>
                                                            :
                                                            <a href="#" className="paperclip-icon d-block text-golden-brown" onClick={e => (handleUrlBase64(e, `${item.SRA_URL}?${item.SRA_PARA}${item.SRA_KEY}`, '產險'))}></a>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    {fileUrl !== null && handleShowPDF(fileUrl, fileName)}
                </div>
            </BackgroundWrapper >
        </>
    )
}

export default SalaryQuery