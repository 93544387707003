import React, { useEffect, useState } from 'react';
import UiModal from '../components/uiModal';
import Loading from '../../../components/Loading';
import axios from 'axios'
import AlertMes from '../../../components/Alert';
import EventIdentityCheck from '../components/eventIdentityCheck';
const EventCheckOut = () => {

    //@ value
    let [isLoading, setIsLoading] = useState(true),
        [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' }),
        [checkStep, setCheckStep] = useState(''), //goCheckInStep:可簽退流程; cantCheckStep:不可簽退流程
        [checkState, setCheckState] = useState('default'), //先預設狀態
        [userData, setUserData] = useState(null)

    let setState = { setUserData, setIsLoading, setAlertMes, setCheckState, setCheckStep }
    useEffect(() => {
        EventIdentityCheck('OUT', setState)
    }, []);

    useEffect(() => {
        if (checkStep == 'goCheckInStep') {
            handleAPI.ACTSignOut()
        }
    }, [checkStep])

    //@ API
    const handleAPI = {
        ACTSignOut: function () {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/ACTSignOut`
            let postData = {
                LineId: userData?.lineId,
                CU: userData?.cu
            }
            axios.post(API, postData)
                .then((res) => {
                    let { ResponseCode } = res.data
                    if (ResponseCode == '0') {
                        setCheckState('0')
                        setIsLoading(false)
                    } else if (ResponseCode == '-1') {
                        setCheckState('-1')
                        setIsLoading(false)
                    } else if (ResponseCode == '-94') {
                        setCheckState('-94')
                        setIsLoading(false)
                    } else {
                        setAlertMes({ mes: `簽退過程中出現錯誤，請重新進行簽退(${ResponseCode})`, show: true, color: 'danger' })
                        localStorage.setItem('_eventCheckUrl', `/checkIn/out?cu=${userData?.cu}`)
                        setTimeout(() => { window.location.href = '/checkIn/login' }, 2000)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setCheckState('-100')
                    setIsLoading(false)
                })
        }
    }
    //@ Event
    const handleEvent = {
        stateText: function (code) {
            switch (code) {
                case '0': return ('簽退成功')
                case '-1': return ('系統內部錯誤')
                case '-89': return ('已超過課程簽退時間')
                case '-90': return ('您已成功簽退，請勿重複簽退')
                case '-91': return ('請先進行簽到')
                case '-93': return ('查無此課程')
                case '-94': return ('查無您業務員資料及iSmart帳號')
                case '-95': return ('學員未在名單內')
                case '-96': return ('尚未開放簽退')
                case '-97': return ('尚未開放簽退')
                case '-99': return ('簽退出現錯誤!!')
                case '-100': return ('網路連線錯誤，請聯繫管理員')
                default: break;
            }
        }
    }

    return (
        <>
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} setAlertMes={setAlertMes} />
            <Loading isLoading={isLoading} />
            <UiModal>
                {
                    checkState !== 'default' &&
                    <div className='my-3'>
                        <h3 className={`fw-bolder check-state ${checkState == '0' && 'bg-success text-light'}`}>{handleEvent.stateText(checkState)}</h3>
                    </div>
                }
            </UiModal>
        </>
    )
}
export default EventCheckOut