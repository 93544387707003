import React from 'react';
import './assets/stylesheet/all.scss';

import AppRoute from "./router/AppRoute";
import DashboardAppRoute from './Dashboard/router/AppRoute';

const App = () => {
  return (
    window.location.pathname.includes('dashboard') ? <DashboardAppRoute /> : <AppRoute />
  );
}
export default App;
