import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NoFound from 'views/404';
import Login from "../views/Login"; //登入
import StatePage from "../views/useIsmart" //登入成功頁面
import AppPrivacy from "../views/AppPrivacy"
import FinancialCalculator from "../views/products/FinancialCalculator"
/*公勝文件*/
import Golden from "./Golden"; //公勝文件 --> 作業文件,辦法規定,公司通訊,通知
/*保險公司*/
import InsuranceCompany from "./InsuranceCompany"; //保險公司 --> 文件,繳費,窗口,報備,經代專區
import Summary from "../views/insuranceCompany/Summary"; //作業總表

/*商品資訊*/
import Products from "./Products"; //商品資訊 --> 財傳退休,長期照護,推薦總表
/* 現售商品查詢 */
import CurrentProd from '../views/products/CurrentProd';
import CurrentProdRead from '../views/products/CurrentProdRead'; //查詢結果頁
import ProductEncyclo from '../views/products/ProductEncyclo'; //商品百科 
import ProductCompare from '../views/products/ProductCompare'; //商品比較 
import ProductDM from '../views/products/ProductDM'; //商品DM 
import ShareExcel from '../views/products/ShareExcel' //線上試算表
import ShareEasyTalk from '../views/products/ShareEasytalk' //罐頭組合
import ShareSaving from '../views/products/ShareSaving' //儲蓄險分析

/*訓練活動*/
import TrainingCases from "./TrainingCases"; //訓練活動(課程教案)
import AnsQuest from '../views/groupTraining/MorningAnsQuest'; //早會問卷
import AnsQuestState from '../views/groupTraining/MorningAnsQuestState'; //早會問卷-狀態
import Attendance from "../views/groupTraining/Attendance"; //出席

/*保單專區*/
import CustomerQuery from '../views/insurancePolicy/CustomerQuery'; //客戶查詢
import CustomerQueryRead from '../views/insurancePolicy/CustomerQueryRead'; //客戶查詢 -- 結果
import CustomerQueryVisCustomer from '../views/insurancePolicy/CustomerQueryVisCustomer' //客戶查詢 -- 約訪
import InsurancePolicy from '../views/insurancePolicy/InsurancePolicy'; //保單查詢
import InsurancePolicyRead from '../views/insurancePolicy/InsurancePolicyRead'; //保單查詢 -- 結果
import SalaryQuery from '../views/insurancePolicy/SalaryQuery'; //薪資查詢
import Notification from '../views/insurancePolicy/Notification'; //照會查詢

import Personal from './insurancePolicy/Personal'; //個人資訊
import Remind from './Remind'; //重要提醒

import Ranking from './insurancePolicy/Ranking'; //競賽業績 -- 排行
import CompetitionPerformance from './insurancePolicy/CompetitionPerformance'; //競賽業績

/*增員素材*/
import Mission from "../views/addMembers/Mission" //使命優勢
import Charity from "../views/addMembers/Charity" //公益關懷
import GoldenAdvantage from "../views/addMembers/GoldenAdvantage" //公勝優勢
import PublicityVideo from "../views/addMembers/PublicityVideo" //宣傳影片
import PublicityFiles from '../views/addMembers/PublicityFiles'; //文宣手冊
import Emi from '../views/addMembers/Emi' //電子報聘

//@ 問卷
/*典範主管(問卷)*/
import ModelSupervisor from '../views/questionnaire/modelSupervisor/ModelSupervisor'
import ModelSupervisorState from '../views/questionnaire/modelSupervisor/ModelSupervisorState'
/*行專 NPS */
import NpsAssistant from 'views/questionnaire/nps/NpsAssistant'

//@ 簽到
import CheckLogin from '../views/checkIn/Login'
/*策略會議簽到*/
import ConferenceRegistration from '../views/checkIn/conferenceRegistration/ConferenceRegistration'
/*財富管理*/
import CheckInGoldenfp from '../views/checkIn/goldenfp/CheckInGoldenfp'
import QuestionGoldenfp from '../views/checkIn/goldenfp/QuestionGoldenfp'
/*活動餐到*/
import EventCheckIn from '../views/checkIn/event/in'
import EventCheckOut from '../views/checkIn/event/out';

/* 樂退完美人生 */
import HappyRetiredLife from "../views/happyRetiredLife/HappyRetiredLife";
/* 公勝30五大挑戰 */
import NewChallengesofGolden from '../router/ChallengesofGoldenRouter'
/* 阿爾法智能理財 */
import TrustCalculator from '../views/otherTool/TrustCalculator'

import UpdateIsmart from '../views/UpdateIsmart'
import Conference from '../views/Conference'
import CardifFireIns from '../views/otherTool/CardifFireIns';
import InsuranceSend from 'views/insurancePolicy/InsuranceSend';
import VipService from 'views/otherTool/VipService';

const AppRoute = (props) => {
    return (
        <Router>
            <Switch>
                <Route exact path="/update_ismart_plus"><UpdateIsmart /></Route> {/* 更新業務員 LINE ID */}
                <Route exact path="/Conference"><Conference /></Route> {/* 更新業務員 LINE ID */}

                <Route exact path="/login"><Login /></Route> {/* 登入 */}
                <Route exact path="/"><Login /></Route> {/* 登入 */}
                <Route exact path="/ismart_state"><StatePage /></Route> {/* 狀態頁面 */}

                <Route exact path="/APP_Privacy"><AppPrivacy /></Route> {/* AppPrivacy */}

                {/* 公勝文件 golden */}
                <Route exact path="/golden_docs"><Golden /></Route> {/* 作業文件 */}
                <Route exact path="/golden_rules"><Golden /></Route> {/* 辦法規定 */}
                <Route exact path="/golden_directory"><Golden /></Route> {/* 公司通訊 */}
                <Route exact path="/history"><Golden /></Route> {/* 服務通知 */}

                {/* 保險公司 insuranceCompany */}
                <Route exact path="/files"><InsuranceCompany /></Route> {/* 文件 */}
                <Route exact path="/files/:id"><InsuranceCompany /></Route> {/* 文件子頁面 */}
                <Route exact path="/paymentinfo"><InsuranceCompany /></Route> {/* 繳費 */}
                <Route exact path="/paymentinfo/:id"><InsuranceCompany /></Route> {/* 繳費子頁面 */}
                <Route exact path="/contacts"><InsuranceCompany /></Route> {/* 窗口 */}
                <Route exact path="/contacts/:id"><InsuranceCompany /></Route> {/* 窗口子頁面 */}
                <Route exact path="/report"><InsuranceCompany /></Route> {/* 報備 */}
                <Route exact path="/report/:id"><InsuranceCompany /></Route> {/* 報備子頁面 */}
                <Route exact path="/CIB_agent"><InsuranceCompany /></Route> {/* 經代專區 */}
                <Route exact path="/CIB_agent/:id"><InsuranceCompany /></Route> {/* 經代專區 */}
                <Route exact path="/summary"><Summary /></Route> {/* 作業總表 */}
                {/* 商品資訊 products */}
                <Route exact path="/current_prod"><CurrentProd /></Route>{/* 商品佣金 */}
                <Route exact path="/current_prod_read"><CurrentProdRead /></Route> {/*商品佣金 -- 查詢結果頁面*/}
                <Route exact path="/products/main1"><Products /></Route> {/* 主力商品 -- 財傳退休 */}
                <Route exact path="/products/main1/:id"><Products /></Route>
                <Route exact path="/products/main2"><Products /></Route> {/* 主力商品 -- 長期照護 */}
                <Route exact path="/products/main2/:id"><Products /></Route>
                <Route exact path="/products/main3"><Products /></Route> {/* 主力商品 -- 更多商品 */}
                <Route exact path="/products/main3/:id"><Products /></Route>
                <Route exact path="/products/main4"><Products /></Route> {/* 主力商品 -- 推薦總表 */}
                <Route exact path="/product_encyclo"><ProductEncyclo /></Route> {/* 商品百科 */}
                <Route exact path="/product_compare"><ProductCompare /></Route> {/* 商品比較 */}
                <Route exact path="/product_dm"><ProductDM /></Route> {/* 商品DM */}
                <Route exact path="/share_excel"><ShareExcel /></Route> {/* 線上試算表 */}
                <Route exact path="/share_easytalk"><ShareEasyTalk /></Route> {/* 罐頭組合 */}
                <Route exact path="/share_saving"><ShareSaving /></Route> {/* 儲蓄險分析 */}
                <Route exact path="/financial_calculator"><FinancialCalculator /></Route> {/* 財務計算機 */}

                {/* 訓練活動 groupTraining */}
                <Route exact path="/morningmeet"><TrainingCases /></Route> {/* 早會 */}
                <Route exact path="/topics"><TrainingCases /></Route> {/* 專題 */}
                <Route exact path="/lecture"><TrainingCases /></Route> {/* 講座 */}
                <Route exact path="/attendance"><Attendance /></Route> {/* 出席 */}
                <Route exact path="/satisfied"><TrainingCases /></Route> {/* 滿意度 */}
                <Route exact path="/ans_quest"><AnsQuest /></Route> {/* 早會問卷 */}
                <Route exact path="/ans_quest_state"><AnsQuestState /></Route> {/* 早會問卷-狀態 */}

                {/* 保單專區 insurancePolicy */}
                <Route exact path="/insurance_send"><InsuranceSend /></Route> {/* 電子保單回報 */}
                <Route exact path="/customer_query"><CustomerQuery /></Route> {/* 客戶查詢 */}
                <Route exact path="/customer_query_read/:id"><CustomerQueryRead /></Route> {/* 客戶查詢 -- 基本資料 */}
                <Route exact path="/customer_query_read/:id/visit"><CustomerQueryVisCustomer /></Route> {/* 客戶查詢 -- 約訪紀錄 */}
                <Route exact path="/insurance_policy"><InsurancePolicy /></Route> {/* 保單查詢 */}
                <Route exact path="/insurance_policy_read/:id/:id"><InsurancePolicyRead /></Route> {/* 保單查詢 -- 結果頁面 */}
                <Route exact path="/notification"><Notification /></Route> {/* 照會查詢 */}
                <Route exact path="/salary_query"><SalaryQuery /></Route> {/* 薪資查詢 */}
                <Route exact path="/license"><Personal /></Route> {/* 個人訊息 -- 證照 */}
                <Route exact path="/e_card"><Personal /></Route> {/* 個人訊息 -- 電子名片 */}
                <Route exact path="/promotion"><Personal /></Route> {/* 個人訊息 -- 晉升進度 */}
                <Route exact path="/cust_age"><Remind /></Route> {/* 重要提醒 -- 近期增歲 */}
                <Route exact path="/cust_birthday"><Remind /></Route> {/* 重要提醒 -- 近期生日 */}
                <Route exact path="/renewal_m"><Remind /></Route> {/* 重要提醒 -- 續保產險單 */}
                <Route exact path="/annual_m"><Remind /></Route> {/* 重要提醒 -- 週年壽險單 */}

                <Route exact path="/performance_rank"><Ranking /></Route> {/* 競賽業績 -- 業績排行 */}
                <Route exact path="/products_rank"><Ranking /></Route> {/* 競賽業績 -- 商品排行 */}
                <Route exact path="/cases_rank"><Ranking /></Route> {/* 競賽業績 -- 案件排行 */}
                <Route exact path="/performance"><CompetitionPerformance /></Route> {/* 競賽業績 -- 個人業績 */}
                <Route exact path="/competition"><CompetitionPerformance /></Route> {/* 競賽業績 -- 競賽 */}
                <Route exact path="/competition_info"><CompetitionPerformance /></Route> {/* 競賽業績 -- 競賽獎勵資訊 */}

                {/* 增員素材 addMember */}
                <Route exact path="/mission"><Mission /></Route> {/* 使命優勢 */}
                <Route exact path="/charity"><Charity /></Route> {/* 公益關懷 */}
                <Route exact path="/publicity_video"><PublicityVideo /></Route> {/* 宣傳影片 */}
                <Route exact path="/publicity_files"><PublicityFiles /></Route> {/* 文宣手冊 */}
                <Route exact path="/golden_advantage"><GoldenAdvantage /></Route> {/* 文宣手冊(公勝優勢) */}
                <Route exact path="/emi"><Emi /></Route> {/*電子報聘*/}

                {/* -- 簽到 -- */}
                <Route exact path="/checkIn/login"><CheckLogin /></Route> {/*簽到登入*/}
                {/* 策略會議 conferenceRegistration */}
                <Route exact path="/registration"><ConferenceRegistration /></Route>
                {/* 財顧管理 goldenfp */}
                <Route exact path="/checkIn/goldenfp"><CheckInGoldenfp /></Route>
                <Route exact path="/question/goldenfp"><QuestionGoldenfp /></Route>
                {/* 特殊活動 */}
                <Route exact path="/checkIn/in"><EventCheckIn /></Route>
                <Route exact path="/checkIn/out"><EventCheckOut /></Route>

                {/* -- 問卷 -- */}
                {/* 典範主管推薦 modelSupervisor*/}
                <Route exact path="/modelSupervisor"><ModelSupervisor /></Route> {/*典範主管*/}
                <Route exact path="/modelSupervisorState"><ModelSupervisorState /></Route> {/*典範主管-狀態*/}
                {/* 行專 NPM */}
                <Route exact path="/nps_assistant/:id"><NpsAssistant /></Route>

                {/* 樂退完美人生 */}
                <Route exact path="/HappyRetiredLife"><HappyRetiredLife /></Route> {/* 樂退完美人生 */}
                {/* 其他功能 */}
                <Route exact path="/alphaCalculator"><TrustCalculator /></Route> {/* 阿爾法智能理財 */}
                <Route exact path="/cardif_fireIns"><CardifFireIns /></Route> {/* 法巴產 火險 */}
                <Route exact path="/vip_service"><VipService /></Route> {/* 尊榮預約通道 */}

                {/* Golden30FiveChallenges */}
                <Route exact path="/Challenges_of_Golden"><NewChallengesofGolden /></Route> {/* 首頁 */}
                <Route exact path="/Challenges_of_Golden_Form"><NewChallengesofGolden /></Route>{/* 活動登入 */}
                <Route exact path="/Challenges_of_Golden_Award"><NewChallengesofGolden /></Route> {/* 個人成果 */}
                <Route exact path="/Challenges_of_Golden_Rank"><NewChallengesofGolden /></Route> {/* 狂人排行 */}
                <Route exact path="/Challenges_of_Golden_RankDept"><NewChallengesofGolden /></Route> {/* 區部排行 */}

                <Route exact path="*"><NoFound /></Route>
            </Switch>
        </Router>
    )
}
export default AppRoute
