import moment from "moment"
import { Decrypt } from "../../../assets/javascript/AESTool"
import axios from 'axios'

export default function EventIdentityCheck(type, setState) { //特殊活動_身分確認 (type：IN 簽到、OUT 簽退
    let loginCookies = document?.cookie?.replace(/(?:(?:^|.*;\s*)_eventCheckLogin\s*=\s*([^;]*).*$)|^.*$/, '$1'),
        loginLocal = localStorage?.getItem('_eventCheckLogin')

    const getUrlString = window.location.href;
    const url = new URL(getUrlString);
    let cu = url.searchParams.get('cu'),
        cuName = url.searchParams.get('cuName'),
        cuType = url.searchParams.get('cuType')

    if (cu == null) { //* 未抓取到編號
        alert('抓取編號錯誤，請重新點擊連結')
    } else {
        if (loginCookies == '' || loginLocal == null) { //* 未登入
            localStorage.setItem('_eventCheckUrl', `/checkIn/${type?.toLowerCase()}?cu=${cu}&cuName=${cuName}&cuType=${cuType}`)
            setTimeout(() => { window.location.href = '/checkIn/login' }, 1200)
        } else { //* 已登入
            let userData = JSON.parse(Decrypt(loginLocal, process.env.REACT_APP_KEY, process.env.REACT_APP_IV))
            userData.cu = cu
            userData.cuName = cuName
            userData.cuType = cuType
            setState?.setUserData(userData)
            ACTCheck(userData)
        }
    }

    function ACTCheck(data) {
        setState?.setIsLoading(true)
        let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/ACTCheck`,
            postData = {
                LineId: data.lineId,
                CU: data.cu,
                Check: type
            }

        axios.post(API, postData)
            .then((res) => {
                let { ResponseCode } = res.data
                if (ResponseCode == '0' || ResponseCode == '-91') {
                    setState?.setIsLoading(false)
                    setState?.setCheckStep('goCheckInStep')
                    setState?.setUserData({ ...data, ResponseCode: ResponseCode, step: 'success' })
                } else if (ResponseCode == '-99') {
                    setState?.setAlertMes({ mes: `簽到過程中出現錯誤，請重新進行簽到`, show: true, color: 'danger' })
                    localStorage.setItem('_eventCheckUrl', `/checkIn/${type?.toLowerCase()}?cu=${cu}`)
                    setTimeout(() => { window.location.href = '/checkIn/login' }, 2000)
                } else {
                    setState?.setIsLoading(false)
                    setState?.setCheckStep('cantCheckStep')
                    setState?.setCheckState(ResponseCode)
                    setState?.setUserData({ ...data, ResponseCode: ResponseCode, step: 'error' })
                }
            })
            .catch((err) => {
                console.log(err)
                setState?.setIsLoading(false)
                setState?.setCheckStep('cantCheckStep')
                setState?.setCheckState('-100')
                setState?.setUserData({ ...data, ResponseCode: '-100', step: 'error' })
            })
    }
}