import React, { useState, useEffect } from "react";
import axios from "axios";

//component
import Loading from '../../../components/Loading'

//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import saveEcard from "../../../assets/javascript/saveEcard";
import goldennetLogo from "../../../assets/images/goldennet_logo.svg";
import userImgUrl from "../../../assets/images/user.jpg";

import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'

const Ecard = () => {
    saveEcard();
    const fontStyle = {
        /* ios15 文字問題 */
        fontFamily: "Helvetica, Tahoma, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Heiti SC', STXihei, 'Microsoft YaHei',SimHei",
    }

    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState({})
    useEffect(() => {
        LineLogin('/e_card'); //LINE 登入狀態驗證
        Countfunc('個人資料_電子名片', 23)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}e_Card`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/e_card');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data.Data[0])
                        setIsLoading(false)
                    } else if (res.data.ResponseCode === '-20') {
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);

    return (
        <>
            <Loading isLoading={isLoading} />
            <p className={`m-3 ${Object.keys(data).length === 0 ? 'd-block' : 'd-none'}`}>無資料</p>
            <div className={`${Object.keys(data).length === 0 ? 'd-none' : ''}`}>
                <div className={`e-card mt-4 mx-auto bg-light`} id="downloadCard" style={fontStyle}>
                    <div className="container">
                        <img src={goldennetLogo} className="mt-3" alt="Golden-LOGO" width="180px" />
                        <div className="row align-items-center">
                            <div className="col-5 my-3 text-center text-dark-blue info-header">
                                <img src={userImgUrl} className="img-fluid" alt="user" />
                            </div>
                            <div className="col-7 my-3 text-center text-dark-blue info-header">
                                <p>{data['職稱']}</p>
                                <h2 className="fw-bolder">{data['姓名']}</h2>
                                <p className="fs-5">{data['行動電話']}</p>
                            </div>
                        </div>
                        <hr className="mt-1" />
                        <div className="my-3">
                            <ul className="list-unstyled info-list mb-2">
                                <li><span className="text-golden-brown fw-bolder">業務中心地址：</span>{data['業務中心地址']}</li>
                                <li className={`${data['LineId'] === null ? 'd-none' : 'd-block'}`}>Line ID：{data['LineId']}</li>
                                <li><span className="text-golden-brown fw-bolder">E-mail：</span>{data['Email']}</li>
                            </ul>
                            <ul className="list-unstyled info-list special mb-4">
                                <li className={`${data['個人資歷'] === null ? 'd-none' : 'd-block'}`}>個人資歷：{data['個人資歷']}</li>
                                <li className={`${data['歷史榮耀'] === null ? 'd-none' : 'd-block'}`}>歷史榮耀：{data['歷史榮耀']}</li>
                            </ul>
                            <div className="d-table" style={{ height: '20px' }}></div>
                            <h4 className="fs-6 text-end mt-2 golden-company">公勝保險經紀人股份有限公司</h4>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <button type="button" className="btn btn-dark-blue my-3 download-icon" id="save-btn"></button>
                </div>
            </div>
            <div id="img-out" className="ecard-img mx-5 pt-5 text-center"></div>
            <div id="text"></div>
        </>
    )
}
export default Ecard