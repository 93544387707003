import React, { useState, useEffect } from "react";
import Loading from "../../../components/Loading";

import { dateChange } from "../../../assets/javascript/calTool";
import moment from 'moment';
import axios from "axios";
import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'
import CalendarList from "../../../components/CalendarList";

const CustBirthday = () => {

    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState([])
    useEffect(() => {
        LineLogin('/cust_birthday'); //LINE 登入狀態驗證
        Countfunc('重要提醒_近期生日', 26)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CusList`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/cust_birthday');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data.Data)
                        setIsLoading(false)
                    } else if (res.data.ResponseCode === '-20') {
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);


    return (
        <>
            <Loading isLoading={isLoading} />
            <CalendarList data={data} />
        </>
    )
}

export default CustBirthday;