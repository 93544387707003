import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//component
import Nav from '../components/Nav';
import BackgroundWrapper from '../components/BackgroundWrapper';

import Retired from "../views/products/Retired"; //財傳退休
import LongTermCare from '../views/products/LongTermCare'; //長期照護
import Assessment from "../views/products/Assessment"; //保費試算

import ProductSummary from "../views/products/ProductSummary"; //推薦總表
import MoreProduct from "../views/products/MoreProduct"; //推薦總表

const Products = (props) => {
    const navItem = [
        {
            text: '利變壽險',
            to: 'products/main1'
        },
        {
            text: '分紅壽險',
            to: 'products/main2'
        },
        {
            text: '期繳投資型',
            to: 'products/main3'
        },
        {
            text: '推薦總表',
            to: 'products/main4'
        }
    ];

    return (
        <>
            <BackgroundWrapper>
                <Router>
                    <Nav navItem={navItem}></Nav>
                    <Switch>
                        <Route exact path="/products/main1"><Retired /></Route>
                        <Route exact path="/products/main1/:id"><Assessment /></Route>
                        <Route exact path="/products/main2"><LongTermCare /></Route>
                        <Route exact path="/products/main2/:id"><Assessment /></Route>
                        <Route exact path="/products/main3"><MoreProduct /></Route>
                        <Route exact path="/products/main4"><ProductSummary /></Route>
                    </Switch>
                </Router>
            </BackgroundWrapper>
        </>
    );
}
export default Products;