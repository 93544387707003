import React, { useEffect, useState } from 'react';
import UiModal from '../components/uiModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../components/Loading';
import axios from 'axios'
import AlertMes from '../../../components/Alert';
import Compressor from 'compressorjs';
import EventIdentityCheck from '../components/eventIdentityCheck';
const EventCheck = () => {
    /** RULE
    * @ param   cu     課程ID 
    * @ param   cuName 課程名稱 
    * @ param   cuType 類型：0(僅簽到) 1(簽到+上傳照片) 
    
    * @ url     /checkIn/in?cu=課程ID&cuName=課程名稱&type=0  僅簽到
    * @ url     /checkIn/in?cu=課程ID&cuName=課程名稱&type=1  簽到 + 上傳照片
    */

    //@ value
    let [isLoading, setIsLoading] = useState(true),
        [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' }),
        [isDisabled, setIsDisabled] = useState(true),
        [checkStep, setCheckStep] = useState('cantCheckStep'), //goCheckInStep:可簽到流程; cantCheckStep:不可簽到流程
        [checkState, setCheckState] = useState('default'), //先預設狀態
        [userData, setUserData] = useState(null)
    //圖片
    let [isUploadImg, setIsUploadImg] = useState(false),
        [imgLoading, setImgLoading] = useState(false),
        [responseImgName, setResponseImgName] = useState(null) //API /iSmart/ACTImage 回傳檔名儲存器

    let setState = { setUserData, setIsLoading, setAlertMes, setCheckState, setCheckStep, userData }
    useEffect(() => {
        EventIdentityCheck('IN', setState)
    }, []);

    useEffect(() => {
        if (userData !== null) {
            if (userData?.cuType == '0' && userData?.step == 'success') { //僅簽到
                handleAPI.ACTSignIn(isDisabled)
            }
        }
    }, [userData])

    //@ API
    const handleAPI = {
        ACTImage: function (e, type) { //上傳檔案
            e.preventDefault();
            let { files } = e.target;
            setImgLoading(true)
            //@壓縮器
            const compressFile = (blob) => {
                return new Promise((resolve, reject) => {
                    new Compressor(blob, {
                        quality: 0.6,
                        success: resolve,
                        error: reject,
                    });
                }).catch((err) => {
                    setImgLoading(false)
                    setAlertMes({ mes: '圖片上傳錯誤，請重新上傳', show: true, color: 'danger' })
                    console.error('Compress error: ' + err.message);
                });
            };
            const blobToData = async (blob) => {
                const file = await compressFile(blob);
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(file);
                });
            };
            function dataURLtoFile(dataurl, filename) {
                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, { type: mime })
            }
            const handleChange = async () => {
                const resData = await blobToData(e.target.files[0]);
                //@size 限制
                let fileMixSize = 2 * 1024 * 1024 //限制2mb
                if (dataURLtoFile(resData, files[0].name).size >= fileMixSize) {
                    setImgLoading(false)
                    setAlertMes({ mes: '圖片大小超過限制(請小於2MB)!! 請重新上傳', show: true, color: 'danger' })
                    return
                }
                //@判斷副檔名
                let subFileName = files[0].name.split('.')[1].toLowerCase()
                if (subFileName !== 'jpg' && subFileName !== 'jpeg' && subFileName !== 'png' && subFileName !== 'bmp' && subFileName !== 'gif') {
                    setImgLoading(false)
                    setAlertMes({ mes: '檔案格式僅能為 .jpg,.jpeg,.png,.bmp,.gif', show: true, color: 'danger' })
                    return
                }
                //@POST API 上傳檔案
                const formData = new FormData();
                formData.append('Files', dataURLtoFile(resData, files[0].name));
                let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/ACTImage`
                axios.post(API, formData)
                    .then((res) => {
                        let { ResponseCode, ImageName } = res.data
                        if (ResponseCode === '0') {
                            setResponseImgName(ImageName) //檔名儲存器
                            setIsDisabled(false) //打開簽到按鈕
                            //上傳即預覽圖片
                            let reader = new FileReader();
                            reader.onload = function (e) {
                                document.getElementById('viewUploadImage').setAttribute('src', e.target.result)
                            }
                            reader.readAsDataURL(files[0])
                            setImgLoading(false)
                            setIsUploadImg(true)
                        } else {
                            setImgLoading(false)
                            setAlertMes({ mes: `圖片上傳失敗!!請重新上傳(${ResponseCode})`, show: true, color: 'danger' })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        setImgLoading(false)
                        setAlertMes({ mes: '網路連線錯誤，請聯繫管理員', show: true, color: 'danger' })
                    })
            }
            handleChange()
        },
        ACTSignIn: function (isDisableState) {
            function runAPI(imgName) {
                setIsLoading(true)
                let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/ACTSignIn`
                let postData = {
                    LineId: userData?.lineId,
                    CU: userData?.cu,
                    ImageName: imgName
                }
                axios.post(API, postData)
                    .then((res) => {
                        let { ResponseCode } = res.data
                        if (ResponseCode == '0') {
                            setCheckStep('cantCheckStep')
                            setCheckState('0')
                            setIsLoading(false)
                        } else if (ResponseCode == '-1') {
                            setCheckStep('cantCheckStep')
                            setCheckState('-1')
                            setIsLoading(false)
                        } else if (ResponseCode == '-94') {
                            setCheckStep('cantCheckStep')
                            setCheckState('-94')
                            setIsLoading(false)
                        } else {
                            setAlertMes({ mes: `簽到過程中出現錯誤，請重新進行簽到(${ResponseCode})`, show: true, color: 'danger' })
                            localStorage.setItem('_eventCheckUrl', `/checkIn/in?cu=${userData?.cu}&cuName=${userData?.cuName}&cuType=${userData?.cuType}`)
                            setTimeout(() => { window.location.href = '/checkIn/login' }, 2000)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        setCheckStep('cantCheckStep')
                        setCheckState('-100')
                        setIsLoading(false)
                    })
            }

            if (userData?.cuType == '0') {
                runAPI('NA')
            } else {
                //判斷是否為 disable 狀態
                if (isDisableState) { //表示尚未上傳圖片
                    setAlertMes({ mes: `請先進行圖片上傳，才可進行簽到`, show: true, color: 'danger' })
                } else {
                    runAPI(responseImgName)
                }
            }
        }
    }
    //@ Event
    const handleEvent = {
        stateText: function (code) {
            switch (code) {
                case '0': return ('簽到成功')
                case '-1': return ('系統內部錯誤')
                case '-89': return ('已超過課程簽到時間')
                case '-90': return ('您已成功簽到，請勿重複簽到')
                case '-91': return ('請先進行簽到')
                case '-93': return ('查無此課程')
                case '-94': return ('查無您業務員資料及iSmart帳號')
                case '-95': return ('學員未在名單內')
                case '-96': return ('尚未開放簽到')
                case '-97': return ('尚未開放簽到')
                case '-99': return ('簽到出現錯誤!!')
                case '-100': return ('網路連線錯誤，請聯繫管理員')
                default: break;
            }
        }
    }

    useEffect(() => { }, [responseImgName])
    return (
        <>
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} setAlertMes={setAlertMes} />
            <Loading isLoading={isLoading} />
            <UiModal>
                <p className='text-center fw-bolder mb-2 text-light'>課程名稱</p>
                <h3 className="fw-bolder text-light text-center px-3" style={{ whiteSpace: 'break-spaces' }}>
                    {userData?.cuName}
                </h3>
                {
                    checkStep == 'goCheckInStep' ?
                        userData?.cuType == '0' ?
                            <>
                                <div className='mt-4'>
                                    <h3 className={`fw-bolder check-state ${checkState == '0' && 'bg-success text-light'}`}>{handleEvent.stateText(checkState)}</h3>
                                </div>
                            </>
                            :
                            <>
                                <div className='my-3'>
                                    <div class="alert alert-danger fw-bolder my-3 mb-4 d-inline-block" role="alert">
                                        <FontAwesomeIcon icon={faInfoCircle} className='mx-2' />照片需包含本人+活動舞台現場背景
                                    </div>
                                    <div className='text-center d-flex flex-column align-items-center position-relative'>
                                        <img src={`${require('../../../assets/images/img-ex.png').default}`} id='viewUploadImage' className='img-fluid upload-img' alt='上傳圖片示意圖' />
                                        {
                                            isUploadImg ?
                                                <p className='mt-1 fw-bolder text-light mt-2'>上傳成功</p>
                                                :
                                                imgLoading ?
                                                    <div className='position-center'>
                                                        <div className="spinner-grow spinner-grow-sm" role="status">
                                                        </div>
                                                        <p className="fw-bold">..上傳中..</p>
                                                    </div>
                                                    :
                                                    <div className='position-center'>
                                                        <button type='button' className='btn btn-golden-brown p-0 upload-button my-1'>
                                                            <input type="file" name="petImage" id="petImage" className="upload-input"
                                                                accept=".jpg,.jpeg,.png,.bmp,.gif"
                                                                onChange={e => handleAPI.ACTImage(e)}
                                                            />
                                                        </button>
                                                    </div>
                                        }
                                    </div>
                                </div>
                                <div class="alert alert-warning fw-bolder my-3 mb-4 px-2 d-inline-block" role="alert">
                                    <FontAwesomeIcon icon={faInfoCircle} className='mx-2' />參加本次活動簽到及上傳照片僅限於本次使用，作為本人參加活動依據活動使用，照片保存1年。
                                </div>
                                <div className='mt-3'>
                                    <button className={`${isDisabled ? 'checkIn-disabled-btn' : 'checkIn-btn'} fw-bolder`} onClick={e => handleAPI.ACTSignIn(isDisabled)}>簽到</button>
                                </div>
                            </>
                        :
                        <div className='my-3'>
                            <h3 className={`fw-bolder check-state ${checkState == '0' && 'bg-success text-light'}`}>{handleEvent.stateText(userData?.ResponseCode)}</h3>
                        </div>
                }
            </UiModal>
        </>
    )
}
export default EventCheck