import React, { useState, useEffect } from "react";
import axios from "axios";

import Loading from "../../../components/Loading";

import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'
import DownloadFile from '../../../assets/javascript/downloadFile'

const Competition = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState([]);
    useEffect(() => {
        LineLogin('/competition_info'); //LINE 登入狀態驗證
        Countfunc('競賽業績_競賽獎勵資訊', 49)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=6`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/competition_info');
                        window.location.href = '/login'
                        return
                    } else {
                        setData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);

    let dataAry = [];
    let filterOptionAry = [];
    const splitData = (data) => {
        let splitAry = []
        data.forEach((item, index, ary) => {
            splitAry.push(item.split('/'))
        });

        splitAry.forEach((item, index) => {
            if (item[1] === '競賽獎勵資訊') {
                dataAry.push({
                    downloadFile: item.join('/'),
                    fileName: item.pop()
                })
            }
        })

        let optionAry = [];
        dataAry.forEach((item, index) => {
            optionAry.push(item.option)
        })

        filterOptionAry = optionAry.filter((item, index, ary) => {
            return ary.indexOf(item) === index
        })
    }
    splitData(data)

    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/competition_info' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setIsLoading)
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-2">
                <div className="row justify-content-start">
                    <ul className="list-unstyled page-content-link mt-2">
                        {
                            dataAry.map((item, index) => {
                                return (
                                    <li className="border-bottom py-3" key={index}>
                                        <a href="#" data-download={item.downloadFile} data-filename={item.fileName} className="d-block fw-bolder file-icon" onClick={handleDownloadFile}>
                                            {item.fileName}
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Competition