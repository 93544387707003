import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap'
//<AlertMes mes='文字' show={alertShow} color='alert 顏色' /> 樣板
const AlertMes = ({ show, color, mes, setAlertMes }) => {
    let alertStyle = {
        zIndex: '10000',
        position: 'fixed',
        top: '85px',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center'
    }

    useEffect(() => { // alert 打開後再 4.5 秒關閉
        if (show) {
            setTimeout(() => {
                setAlertMes({ mes: '', show: false, color: 'transparent' })
            }, 4500)
        }
    }, [show])

    return (
        <>
            <Alert show={show} variant={color} className='col-10 col-xs-10 col-md-5 fw-bolder' style={alertStyle}>
                {mes}
            </Alert>
        </>
    )
}
export default AlertMes