import React, { useState, useEffect } from 'react';
import Banner from "../../components/addMembers/Banner";
import Loading from "../../components/Loading"

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

//image url
import bannerUrl from '../../assets/images/components/publicityFile.jpg';
import axios from 'axios';

import LineLogin from "../../assets/javascript/lineLogin"
import Countfunc from "../../assets/javascript/countfunc"

import DownloadFile from '../../assets/javascript/downloadFile';

const style = { //banner background style
    backgroundImage: `url(${bannerUrl})`,
    backgroundPosition: 'center 20%',
}
const title = "Golden Life\n不只是保險，我們分享愛"; //banner title
const PublicityFiles = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState([])
    useEffect(() => {
        LineLogin('/publicity_files')
        Countfunc('文宣手冊', 41)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/publicity_files');
                        window.location.href = '/login'
                        return
                    } else {
                        setData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, [])

    const handleEvent = {
        getFileList: function (data) {
            let returnList = []
            if (data !== null && Array.isArray(data)) {
                data.forEach((item) => {
                    if (item.indexOf('金皮書') !== -1) {
                        if (item?.split('/')[1] !== '') {
                            returnList.push(item)
                        }
                    }
                })
            }

            return returnList
        }
    }

    const handleDownloadFile = (e, fileName) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/publicity_files' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, fileName, pageName, setIsLoading)
    }
    return (
        <>
            <Loading isLoading={isLoading} />
            <Banner title={title} style={style} />
            <div className="container mt-3">
                <ul className="list-unstyled page-content-link">
                    <li className="border-bottom py-3">
                        <a href="/mission" className="d-block fw-bolder">
                            <FontAwesomeIcon className="me-2 text-dark-blue" icon={faCopy} />
                            公勝使命
                        </a>
                    </li>
                    <li className="border-bottom py-3">
                        <a href="/golden_advantage" className="d-block fw-bolder">
                            <FontAwesomeIcon className="me-2 text-dark-blue" icon={faCopy} />
                            公勝優勢
                        </a>
                    </li>
                    <li className="border-bottom py-3">
                        <a href="/charity" className="d-block fw-bolder">
                            <FontAwesomeIcon className="me-2 text-dark-blue" icon={faCopy} />
                            公益關懷
                        </a>
                    </li>
                    {
                        handleEvent.getFileList(data).map((item, index) => {
                            return (
                                <li className="border-bottom py-3" key={`file-${item}`}>
                                    <a href="#" className="d-block fw-bolder file-icon" data-filename={item} onClick={e => handleDownloadFile(e, item)}>
                                        {item.split('/')[1]}
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    )
}

export default PublicityFiles;