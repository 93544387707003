import axios from "axios";
import React, { useEffect, useState } from "react";

import Loading from "../../../components/Loading";
import InsideNav from '../../../components/InsideNav';

import { toCurrency } from '../../../assets/javascript/calTool'
import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'

const CasesRank = () => {
    const insideNavItem = [
        {
            text: '日',
        },
        {
            text: '月',
        },
        {
            text: '年',
        },
    ];
    let [option, setOption] = useState(insideNavItem[0].text)
    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState([])
    useEffect(() => {
        LineLogin('/cases_rank'); //LINE 登入狀態驗證
        Countfunc('競賽業績_案件排行', 31)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}products_rank`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/cases_rank');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData([res.data.CASE])
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);

    const handleClick = (e) => {
        let { innerText } = e.target;
        setOption(innerText)
    }
    return (
        <>
            <Loading isLoading={isLoading} />
            <InsideNav insideNavItem={insideNavItem} handleClick={handleClick} option={option} />
            <div className="container mt-4">
                {
                    data.map((item, index) => {
                        if (option === '日') {
                            return item.D
                        } else if (option === '月') {
                            return item.M
                        } else if (option === '年') {
                            return item.Y
                        }
                        return true
                    }).map((item) => {
                        if (item.length === 0) {
                            return (
                                <></>
                            )
                        } else {
                            let fyc = 0;
                            let rank = 0; //排名
                            let same = 0; //是否相同 fyc

                            item.forEach((kidItem, kidIndex) => {
                                if (kidItem.fyc === fyc) {
                                    kidItem.rank = rank;
                                    same++;
                                } else {
                                    rank = rank + same;
                                    rank++;
                                    fyc = kidItem.fyc;
                                    same = 0;
                                    kidItem.rank = rank;
                                }
                            })
                            return (
                                <>
                                    <div className={`mt-3`}>
                                        <div className="d-flex justify-content-between fw-bolder text-dark-blue">
                                            <p>每{option}案件業績排行</p>
                                        </div>
                                        <div className="table-responsive-xxl text-center">
                                            <table className="table table-striped" style={{ zIndex: '10' }}>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>排名</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>單位</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>保險公司</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>商品名稱</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>年期</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>保額</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>保費</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>FYC</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>FYA</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>件數</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        item.map((kidItem, kidIndex) => {
                                                            return (
                                                                <tr key={kidIndex}>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{kidItem.rank}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{kidItem.place}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{kidItem.companyAlias}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{kidItem.productName}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{kidItem.years}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>
                                                                        {kidItem.fypAmount}
                                                                        <span>{kidItem.fypUnit === '1' ? '元' : ''}</span>
                                                                        <span>{kidItem.fypUnit === '3' ? '萬' : ''}</span>
                                                                        <span>{kidItem.fypUnit === null ? '' : ''}</span>
                                                                    </td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(Math.round(kidItem.fee))}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(Math.round(kidItem.fyc))}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(Math.round(kidItem.fya))}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(kidItem.qty)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })
                }
            </div>
        </>
    )
}

export default CasesRank