import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LineLogin from '../../assets/javascript/lineLogin';
import Countfunc from '../../assets/javascript/countfunc';
import Loading from '../../components/Loading';

const ProductCompare = () => {
    let [isLoading] = useState(true)
    useEffect(() => {
        LineLogin('/product_compare')
        Countfunc('商品比較', 13)
        const token = document.cookie.replace(/(?:(?:^|.*;\s*)MyToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/product_compare');
                        window.location.href = '/login'
                        return
                    } else if (!token) {
                        localStorage.setItem('page', '/product_compare');
                        window.location.href = '/login'
                        return
                    } else {
                        window.location.href = `https://onecrm.tw/sso?srcSystem=golden&target=3&token=11680fde-4645-4c95-94ef-37a6fca6cde5`
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);
    return (
        <>
            <Loading isLoading={isLoading} />
        </>
    )
}
export default ProductCompare