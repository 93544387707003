import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from '../../components/Loading';
import LineLogin from '../../assets/javascript/lineLogin';
import { toCurrency } from '../../assets/javascript/calTool'
const ChallengesofGoldenRank = () => {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token === '') {
        localStorage.setItem('page', '/Challenges_of_Golden_Rank');
        window.location.href = '/login'
    }
    useEffect(() => {
        LineLogin('/Challenges_of_Golden_Rank')
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/Challenges_of_Golden_Rank');
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                console.log(err)
                // alert('請重新整理')
            })
    }, []);

    let name = JSON.parse(unescape(token)).Name;
    let busCode = JSON.parse(unescape(token)).BusCode
    let lineId = JSON.parse(unescape(token)).LineId

    //LINE login 驗證
    let [isLoading, setIsLoading] = useState(false)
    let [typeData, setTypeData] = useState([{ type: '全部' }, { type: '游泳' }, { type: '跑步' }, { type: '健行' }, { type: '自行車' }, { type: '登山' }])
    let [option, setOption] = useState('全部')
    let [allData, setAllData] = useState([]) //全部噴回來的資料 儲存
    let [rankData, setRankData] = useState([]) //篩選過資料
    let [ownRankData, setOwnRankData] = useState([{
        No: 0,
        total_donate: '0',
        total_medal: '0'
    }])
    const handleEvent = {
        getData: function () { //取得所有 data
            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}madman_list/${busCode}/${name}/${lineId}`
            setIsLoading(true)
            axios.get(API)
                .then((res) => {
                    if (Object.keys(res.data).length > 0) {
                        setIsLoading(false)
                        setAllData(res.data)
                    } else {
                        setIsLoading(false)
                        setAllData([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getRankData: function () {
            if (option === '全部') {
                setRankData(allData.t_data[1])
                if (allData.t_user_data[1].length <= 0) {
                    setOwnRankData([{ No: 0, total_donate: '0', total_medal: '0' }])
                } else {
                    setOwnRankData(allData.t_user_data[1])
                }
            } else if (option === '游泳') {
                setRankData(allData.s_data[1])
                if (allData.s_user_data[1].length <= 0) {
                    setOwnRankData([{ No: 0, total_donate: '0', total_medal: '0' }])
                } else {
                    setOwnRankData(allData.s_user_data[1])
                }
            } else if (option === '跑步') {
                setRankData(allData.j_data[1])
                if (allData.j_user_data[1].length <= 0) {
                    setOwnRankData([{ No: 0, total_donate: '0', total_medal: '0' }])
                } else {
                    setOwnRankData(allData.j_user_data[1])
                }
            } else if (option === '健行') {
                setRankData(allData.h_data[1])
                if (allData.h_user_data[1].length <= 0) {
                    setOwnRankData([{ No: 0, total_donate: '0', total_medal: '0' }])
                } else {
                    setOwnRankData(allData.h_user_data[1])
                }
            } else if (option === '自行車') {
                setRankData(allData.b_data[1])
                if (allData.b_user_data[1].length <= 0) {
                    setOwnRankData([{ No: 0, total_donate: '0', total_medal: '0' }])
                } else {
                    setOwnRankData(allData.b_user_data[1])
                }
            } else if (option === '登山') {
                setRankData(allData.c_data[1])
                if (allData.c_user_data[1].length <= 0) {
                    setOwnRankData([{ No: 0, total_donate: '0', total_medal: '0' }])
                } else {
                    setOwnRankData(allData.c_user_data[1])
                }
            }
        },
        changeEvent: function (e) {
            let { value, nodeName } = e.target
            if (nodeName !== 'BUTTON') {
                return
            }
            setOption(value)
        },
        returnHomePage: function (e) {
            e.preventDefault();
            window.location.href = '/Challenges_of_Golden'
        }
    }

    useEffect(() => {
        handleEvent.getData();
    }, [])
    useEffect(() => {
        if (allData.length <= 0) {
            return
        } else {
            handleEvent.getRankData()
        }
    }, [option, allData])

    let activeStyle = {
        borderBottom: '2px solid #fff',
        paddingBottom: '4px'
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className='container challenge'>
                <div className='d-flex justify-content-between mb-3'>
                    <div>
                        <h3 className='challenge-title text-light'>狂人排行</h3>
                        <p className='bg-challenges-yellow text-challenges-blue text-center fw-bolder mb-2'>個人排行</p>
                        <div>
                            {
                                ownRankData[0].No == 0 ?
                                    <p className='fw-bolder text-center text-light'>無</p>
                                    :
                                    <>
                                        <p className='text-light fw-bolder' style={{ lineHeight: '32px' }}>{option}類型：<span className='text-challenges-yellow'>No.{ownRankData[0].No}</span></p>
                                        <p className='text-light fw-bolder' style={{ lineHeight: '32px' }}>{option === '全部' ? '總' : ''}捐贈額：<span className='text-challenges-yellow'>{toCurrency(ownRankData[0].total_donate)} 元</span></p>
                                        <p className='text-light fw-bolder' style={{ lineHeight: '32px' }}>{option === '全部' ? '總' : ''}獎章：<span className='text-challenges-yellow'>{toCurrency(ownRankData[0].total_medal)} 個</span></p>
                                    </>
                            }
                        </div>
                    </div>
                    <img className='challenge-main-img_rank' src={require('../../assets/images/challengesofGolden/rank.svg').default} alt='狂人排行' />
                </div>
                {
                    allData.length == 0 ?
                        <p className='text-light text-center py-3'>無資料</p> :
                        <>
                            <div className='challenge-type-button d-flex justify-content-between' onClick={handleEvent.changeEvent}>
                                {
                                    typeData.map((item, index) => {
                                        return (
                                            <button type='button' className='btn text-light fw-bolder rounded-0 p-1' style={option === item.type ? activeStyle : {}} value={item.type}>{item.type}</button>
                                        )
                                    })
                                }
                            </div>
                            <div className="table-responsive-xxl text-center mt-3" style={{ height: '450px', overFlowY: 'scroll' }}>
                                <table className="table table-striped table-light align-middle">
                                    <thead>
                                        <tr>
                                            <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>NO.</th>
                                            <th className="text-center" width='200' style={{ whiteSpace: 'nowrap' }}>姓名</th>
                                            <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>捐款金額</th>
                                            <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>獎章</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            rankData.map((item, index) => {
                                                return (
                                                    <tr className={`${item.No == 1 ? 'table-rank1' : ''}${item.No == 2 ? 'table-rank2' : ''}${item.No == 3 ? 'table-rank3' : ''}`}>
                                                        <td className="text-dark-blue fw-bolder fs-6" style={{ whiteSpace: 'nowrap' }}>{item.No}</td>
                                                        <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.r_buscode}/{item.r_username}</td>
                                                        <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.total_donate)} 元</td>
                                                        <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.total_medal)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                }
                <div className='text-end'>
                    <button className='btn btn-outline-light my-3 return-icon' onClick={handleEvent.returnHomePage}>返回首頁</button>
                </div>
            </div>
        </>
    )
}
export default ChallengesofGoldenRank