import React, { useState, useEffect } from 'react';
import axios from 'axios'
import LineLogin from '../../assets/javascript/lineLogin';
import Countfunc from '../../assets/javascript/countfunc';
import Loading from '../../components/Loading';

import BackgroundWrapper from '../../components/BackgroundWrapper';
import goldennetLogo from "../../assets/images/goldennet_logo.svg";

const TrustCalculator = () => {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)iSmartToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const idToken = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    useEffect(() => {
        if (!token || !idToken) {
            localStorage.setItem('page', '/alphaCalculator')
            // 跳轉回 login 頁面
            setTimeout(() => { window.location.href = '/login' }, 1000)
        } else {
            // 將 token 加到 headers 表頭裡
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        Countfunc('阿爾法智能理財工具', 52)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/alphaCalculator');
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                // alert('請重新整理')
            })
    }, []);

    useEffect(() => {
        handleAPI.CRMCheck(JSON.parse(unescape(idToken)).Id)
    }, [])

    let [checkIdentity, setCheckIdentity] = useState(false),
        [alertText, setAlertText] = useState(null),
        [isLoading, setIsLoading] = useState(true)

    const handleAPI = {
        CRMCheck: function (id) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_API5000}CRMCheck`
            let params = new URLSearchParams();
            params.append('Id', id);
            axios.post(API, params)
                .then((res) => {
                    let { ResponseCode } = res.data
                    if (ResponseCode == '0') {
                        setIsLoading(false)
                        setCheckIdentity(true)
                    } else if (ResponseCode == '-7') { //非 CRM 會員
                        setIsLoading(false)
                        setCheckIdentity(false)
                        setAlertText('無使用權限')
                    } else if (ResponseCode == '-10') { //系統內部發生錯誤
                        setIsLoading(false)
                        setCheckIdentity(false)
                        setAlertText(`系統內部發生錯誤(-10)`)
                    } else if (ResponseCode == '-6') { //傳入的參數有誤
                        setIsLoading(false)
                        setCheckIdentity(false)
                        setAlertText(`傳入的參數有誤，請關閉頁面重新點擊功能(-6)`)
                    } else {
                        setIsLoading(false)
                        setCheckIdentity(false)
                        setAlertText(`系統內部發生錯誤(${ResponseCode})`)
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    setCheckIdentity(false)
                    setAlertText(`系統內部發生錯誤(-100)`)
                })
        }

    }

    let style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div>
                {
                    checkIdentity ?
                        <embed type="text/html" src="https://roboadvisor.com.tw/alphaTool/TrustCalculator.html" style={{ width: '100%', height: '100vh' }}></embed>
                        :
                        <BackgroundWrapper>
                            <div style={style}>
                                <div className='text-center mt-5'>
                                    <img src={require('../../assets/images/公勝財顧LOGO.png').default} className='img-fluid' width='185px' />
                                    <p className='text-golden-brown fw-bolder text-center mt-4 mx-3' style={{ fontSize: '28px' }}>{alertText}</p>
                                </div>
                            </div>
                        </BackgroundWrapper>
                }
            </div>
        </>
    )
}
export default TrustCalculator