import React, { useState, useEffect } from 'react';
//from
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Decrypt, Encrypt } from '../../../assets/javascript/AESTool';
import { useHistory, useLocation } from 'react-router-dom';
import Loading from '../../../components/Loading';
import moment from 'moment/moment';
import axios from 'axios';
const CheckInGoldenfp = () => {
    let [isLoading, setIsLoading] = useState(true)

    const history = useHistory();
    const location = useLocation()
    let urlWord = location.search

    let [date, setDate] = useState(''),
        [identity, setIdentity] = useState(null), //身分狀態: success(有身分) error (無身分要填寫表單)
        [state, setState] = useState(null), //
        [userData, setUserData] = useState(null),
        [cuKey, setCuKey] = useState(null)

    useEffect(() => {
        //確認登入狀態 - 是否有 for meeting cookies
        //同時存 localStorage & cookies

        if (urlWord.indexOf('?') != -1) {
            let url = new URL(window.location.href);
            let params = new URLSearchParams(url.search);
            let key = params.get("cu");
            if (key == null || key == undefined || key == '') {
                alert('取得會議代號錯誤，請關閉頁面並重新掃描')
                localStorage.clear()
            } else {
                //@ 判斷是否有登入 cookies & localStorage
                const cookies = document.cookie.replace(/(?:(?:^|.*;\s*)_eventCheckLogin\s*=\s*([^;]*).*$)|^.*$/, '$1');
                if ((localStorage.getItem('_eventCheckLogin') == null || localStorage.getItem('_eventCheckLogin') == undefined) && cookies == '') {
                    localStorage.setItem('_eventCheckUrl', `/checkIn/goldenfp?cu=${key}`)
                    setTimeout(() => { window.location.href = '/checkIn/login' }, 1200)
                } else {
                    let goldenfpUser = ''
                    if (cookies == '') {
                        goldenfpUser = JSON.parse(Decrypt(localStorage.getItem('_eventCheckLogin'), process.env.REACT_APP_KEY, process.env.REACT_APP_IV))
                    } else {
                        goldenfpUser = JSON.parse(Decrypt(cookies, process.env.REACT_APP_KEY, process.env.REACT_APP_IV))
                    }

                    setUserData(goldenfpUser)
                    setIsLoading(false)
                    setCuKey(key)
                    if (key == null || key == undefined || key == '') {
                        alert('取得論壇代碼錯誤，請重新掃描 QRcode')
                        return
                    }
                    handleAPI.FCCheck(goldenfpUser, key)
                }
            }
        } else {
            alert('取得會議代號錯誤，請關閉頁面並重新掃描')
            localStorage.clear()
        }
    }, []);

    const validationSchema = Yup.object().shape({
        Name: Yup.string()
            .required('必填'),
        QMobile: Yup.string()
            .min(10, '請輸入正確的手機')
            .max(10, '請輸入正確的手機')
            .matches(/^09[0-9]{8}$/, '請輸入正確的手機格式')
            .required('必填'),
        QRefName: Yup.string()
            .required('必填'),
        QRsn: Yup.string()
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        handleAPI.FCSign(data, userData, cuKey)
    }
    const handleAPI = {
        FCCheck: function (data, key) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/FCCheck`
            let postData = {
                LineId: data.lineId,
                CU: key
            }
            axios.post(API, postData)
                .then((res) => {
                    let { ResponseCode } = res.data
                    if (ResponseCode == '0') { //簽到成功
                        setIsLoading(false)
                        setIdentity('success')
                        setState('簽到成功')
                    } else if (ResponseCode == '1') { //系統內部出現錯誤
                        setIsLoading(false)
                        setIdentity('success')
                        setState(`系統內部出現錯誤 （${ResponseCode}）`)
                    } else if (ResponseCode == '-99') { //前端傳入數值為空
                        localStorage.setItem('_eventCheckUrl', `/checkIn/goldenfp?cu=${key}`)
                        setTimeout(() => { window.location.href = '/checkIn/login' }, 1200)
                    } else if (ResponseCode == '-96') { //不在開放日期
                        setIsLoading(false)
                        setIdentity('success')
                        setState('論壇日期尚未開放簽到')
                    } else if (ResponseCode == '-93') { //查無此課程
                        setIsLoading(false)
                        setIdentity('success')
                        setState('查無此論壇，請重新掃描 QRcode')
                    } else if (ResponseCode == '-90') { //已經簽到
                        setIsLoading(false)
                        setIdentity('success')
                        setState('已完成簽到，請勿重複簽到')
                    } else if (ResponseCode == '-89') { //課程超過簽到時間
                        setIsLoading(false)
                        setIdentity('success')
                        setState('論壇超過簽到時間')
                    } else if (ResponseCode == '-88') { //請先填寫問卷
                        setIsLoading(false)
                        setIdentity('error')
                    }
                })
                .catch((err) => {
                    console.log(err)
                    alert('網路連線錯誤!請聯繫管理人員')
                })
        },
        FCSign: function (data, userData, key) {
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/FCSign`
            data.LineId = userData.lineId
            data.CU = key
            axios.post(API, data)
                .then((res) => {
                    let { ResponseCode } = res.data
                    if (ResponseCode == '0') { //簽到成功
                        setIsLoading(false)
                        setIdentity('success')
                        setState('簽到成功')
                    } else if (ResponseCode == '-1') { //系統內部出現錯誤
                        setIsLoading(false)
                        setIdentity('success')
                        setState(`系統內部出現錯誤 （${ResponseCode}）`)
                    } else if (ResponseCode == '-99') { //前端傳入為空
                        setIsLoading(false)
                        alert('送出表單錯誤，請重新填寫')
                    } else if (ResponseCode == '-93') {//查無此課程
                        setIsLoading(false)
                        setIdentity('success')
                        setState('查無此論壇，請重新掃描 QRcode')
                    }
                })
                .catch((err) => {
                    console.log(err)
                    alert('網路連線錯誤!請聯繫管理人員')
                })
        }
    }

    useEffect(() => { }, [userData, cuKey])
    return (
        <>
            <Loading isLoading={isLoading} />
            <div className='goldenfp-bg'>
                <div className='container d-flex flex-column justify-content-between align-items-center' style={{ minHeight: '100vh' }}>
                    <div className='row'>
                        {/* header */}
                        <div className='text-center py-5'><img src={require('../../../assets/images/goldenfp/LOGO.png').default} className='img-fluid' width='230px' /></div>
                        <div className='mt-5'>
                            {/* content */}
                            {
                                identity == 'success' &&
                                <div className='fw-bolder fs-3 text-center' style={{ height: '300px', color: '#fff' }}>
                                    {state == '簽到成功' ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faExclamationCircle} />}
                                    <p>{state}</p>
                                </div>
                            }
                            {
                                identity == 'error' &&
                                <div className='col-12 col-md-8 p-3 bg-light rounded mx-auto'>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="mb-3">
                                            <label htmlFor="name" className={`form-label fw-bolder`}>姓名<span className='text-danger'>*</span></label>
                                            <input type="text" className={`form-control ${errors.Name ? 'is-invalid' : ''}`} id="name" placeholder="請填寫姓名" {...register('Name')} />
                                            <div className='invalid-feedback'>{errors.Name?.message}</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="tel" className={`form-label fw-bolder`} >手機<span className='text-danger'>*</span></label>
                                            <input type="tel" className={`form-control ${errors.QMobile ? 'is-invalid' : ''}`} id="tel" placeholder="請填寫手機" {...register('QMobile')} />
                                            <div className='invalid-feedback'>{errors.QMobile?.message}</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="refName" className="form-label fw-bolder">財顧推薦人<span className='text-danger'>*</span></label>
                                            <input type="text" className={`form-control ${errors.QRefName ? 'is-invalid' : ''}`} id="refName" placeholder="請填寫推薦人" {...register('QRefName')} />
                                            <div className='invalid-feedback'>{errors.QRefName?.message}</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bolder">參加原因</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" {...register('QRsn')}></textarea>
                                        </div>
                                        <div className="text-center mt-3">
                                            <button type="submit" className='btn btn-golden-brown'>送出</button>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                    {/* footer */}
                    <div className='text-center my-5'><img src={require('../../../assets/images/goldenfp/公勝LOGO.svg').default} width={'100px'} /></div>
                </div>
            </div>
        </>
    )
}
export default CheckInGoldenfp