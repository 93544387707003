import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading';
import axios from 'axios';
import { busCodeGatArea, busCodeToName } from 'assets/javascript/calTool';
import { default as swal } from 'sweetalert2'
import AlertMes from 'components/Alert';
import moment from 'moment';

const VipService = (props) => {

    let [isLoading, setIsLoading] = useState(false),
        [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' }),
        [data, setData] = useState([]),
        [thisState, setThisState] = useState(0), // 0 (未處理), 1 (處理)
        [filterState, setFilterState] = useState('min'), //max 最大 , min 最小
        [filterArea, setFilterArea] = useState('all')

    // API
    const handleAPI = {
        getReserveRec: function () {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}get_reserve_rec`
            axios.get(API)
                .then((res) => {
                    let { code, data } = res.data
                    if (code == '0') {
                        setIsLoading(false)
                        setData(data)
                    } else {
                        setIsLoading(false)
                        alert(`GET get_reserve_rec 錯誤。(${code})`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    alert(`網路連線錯誤，請聯繫管理員。(get_reserve_rec:err)`)
                })
        },
        updateReserveRec: function (e, item) {
            swal.fire({
                icon: 'info',
                title: '請再次確認是否設為已處理',
                text: '**確認後將會發訊通知該業務員**',
                confirmButtonText: '確認',
            }).then(function () {
                runAPI()
            })

            function runAPI() {
                setIsLoading(true)
                let API = `${process.env.REACT_APP_GOLDEN_NPS_API}update_reserve_rec`
                let postParams = new URLSearchParams();
                postParams.append('SAL_RESERVE_ID', item.SAL_RESERVE_ID);
                postParams.append('SAL_RESERVE_OPER', localStorage?.getItem('userName'));
                axios.post(API, postParams)
                    .then((res) => {
                        let { code } = res.data
                        if (code == '0') {
                            handleAPI.MessagePush(item, localStorage?.getItem('userName'))
                        } else {
                            setIsLoading(false)
                            alert(`POST update_reserve_rec 錯誤。(${code})`)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                        alert(`網路連線錯誤，請聯繫管理員。(update_reserve_rec:err)`)
                    })
            }
        },
        MessagePush: function (data, oper) {
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/Push`;

            function getMesText(type) {
                if (type == '到府') {
                    return `${data.SAL_MST_NAME} 您好,\n感謝您使用 COT/TOT 尊榮預約通道服務，您這次預約的時間是 ${data.SAL_RESERVE_DATE} 由專人取件，我們將會派人於指定日期時間前往取件，再次感謝您的使用。`
                } else {
                    return `${data.SAL_MST_NAME} 您好,\n感謝您使用 COT/TOT 尊榮預約通道服務，您這次預約的時間是 ${data.SAL_RESERVE_DATE} ${data.SAL_RESERVE_TIME} 時段親送文件至 ${busCodeToName(data.SAL_RESERVE_DEP)} 業務中心，我們將會於指定日期時間於業務中心等候您，屆時請直接與鳳凰會服務窗口交付文件即可。`
                }
            }

            let postData = {
                Push_Info: [{
                    Line_Id: data.SAL_MST_LINESN,
                    Sales_Name: data.SAL_MST_NAME
                }],
                Message_Body: [getMesText(data.SAL_RESERVE_TYPE)],
                Message_Image: null,
                Message_Card: null,
                Create_User: oper,
                Audit_User: oper,
                Mode: 0
            }

            axios.post(API, postData)
                .then((res) => {
                    let { ResponseCode, ResponseMsg } = res.data
                    if (ResponseCode == '0') {
                        setIsLoading(false)
                        setAlertMes({ mes: '發訊成功，已通知該業務員', show: true, color: 'primary' })
                        handleAPI.getReserveRec()
                    } else {
                        swal.fire({
                            icon: 'error',
                            title: '發訊失敗',
                            text: `(${ResponseMsg})`,
                            confirmButtonText: '確認',
                        }).then(function () {
                            setIsLoading(false)
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    alert(`網路連線錯誤，請聯繫管理員。(MessagePush:err)`)
                })
        }
    }

    //Event
    const handleEvent = {
        filterData: function (state) {
            let newData = data.filter((item) => {
                if (item.SAL_RESERVE_STATE == state) {
                    if (filterArea == 'all') {
                        return item
                    } else {
                        return busCodeGatArea(item.SAL_MST_LOCATION) == filterArea
                    }
                }
            })
            return newData
        }
    }

    const handleUI = {
        tableList: function () {
            return <>
                <div className="col-12">
                    <div className="container">
                        <table className="table table-striped table-hover mt-2">
                            <thead className="text-golden-brown align-items">
                                <tr>
                                    <th>姓名</th>
                                    <th>實駐單位</th>
                                    <th>預約日期</th>
                                    <th>預約時段</th>
                                    <th>預約方式</th>
                                    <th>預約業務中心</th>
                                    <th>{thisState == 0 ? '狀態' : '處理科長'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    handleEvent.filterData(thisState).sort((a, b) => {
                                        if (filterState == 'max') {
                                            return moment(b.SAL_RESERVE_DATE).valueOf() - moment(a.SAL_RESERVE_DATE).valueOf()
                                        } else {
                                            return moment(a.SAL_RESERVE_DATE).valueOf() - moment(b.SAL_RESERVE_DATE).valueOf()
                                        }
                                    }).map((item) => {
                                        return (
                                            <tr key={`item-${item.SAL_RESERVE_ID}`} className='fw-bolder'>
                                                <td>{item.SAL_MST_NAME}</td>
                                                <td>{busCodeToName(item.SAL_MST_LOCATION)}</td>
                                                <td>{item.SAL_RESERVE_DATE}</td>
                                                <td>{item.SAL_RESERVE_TIME}</td>
                                                <td>{item.SAL_RESERVE_TYPE}</td>
                                                <td>{busCodeToName(item.SAL_RESERVE_DEP)}</td>
                                                <td>
                                                    {
                                                        thisState == 0 ?
                                                            <div className="form-check d-flex justify-content-center">
                                                                <input className="form-check-input" type="checkbox" id={`${item.SAL_RESERVE_ID}-valid`} onChange={e => handleAPI.updateReserveRec(e, item)} />
                                                            </div> : item.SAL_RESERVE_OPER
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        }
    }

    useEffect(() => {
        handleAPI.getReserveRec()
    }, [])

    useEffect(() => { handleAPI.getReserveRec() }, [thisState])
    return (
        <>
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} setAlertMes={setAlertMes} />
            <Loading isLoading={isLoading} />
            <h2 className='fs-4 text-dark-blue fw-bolder p-3'>{props.title}</h2>
            <div className='h-100'>
                <div className='container px-4 pb-4 position-relative'>
                    {/* 篩選 */}
                    <div>
                        <div className='d-flex align-items-center fw-bolder mt-3 me-3' onChange={e => setFilterState(e.target.value)}>
                            <div className='me-2'>預約日期：</div>
                            <div className='form-check form-check-block me-3'>
                                <input className='form-check-input' type='radio' name={`filterState`} id='min' value='min' defaultChecked={filterState == 'min'} />
                                <label className='form-check-label' htmlFor={`min`}>由舊至新</label>
                            </div>
                            <div className='form-check form-check-block me-3'>
                                <input className='form-check-input' type='radio' name={`filterState`} id='max' value='max' defaultChecked={filterState == 'max'} />
                                <label className='form-check-label' htmlFor={`max`}>由新到舊</label>
                            </div>
                        </div>
                        <div className='d-flex align-items-center fw-bolder mt-3' onChange={e => setFilterArea(e.target.value)}>
                            <div className='me-2'>區部：</div>
                            <div className='form-check form-check-block me-3'>
                                <input className='form-check-input' type='radio' name={`filterArea`} id='area0' value='all' defaultChecked={filterArea == 'all'} />
                                <label className='form-check-label' htmlFor={`area0`}>全部</label>
                            </div>
                            <div className='form-check form-check-block me-3'>
                                <input className='form-check-input' type='radio' name={`filterArea`} id='area1' value='北區' defaultChecked={filterArea == '北區'} />
                                <label className='form-check-label' htmlFor={`area1`}>北區</label>
                            </div>
                            <div className='form-check form-check-block me-3'>
                                <input className='form-check-input' type='radio' name={`filterArea`} id='area2' value='中區' defaultChecked={filterArea == '中區'} />
                                <label className='form-check-label' htmlFor={`area2`}>中區</label>
                            </div>
                            <div className='form-check form-check-block me-3'>
                                <input className='form-check-input' type='radio' name={`filterArea`} id='area3' value='南區' defaultChecked={filterArea == '南區'} />
                                <label className='form-check-label' htmlFor={`area3`}>南區</label>
                            </div>
                        </div>
                    </div>
                    <ul className="nav nav-tabs w-100 bg-light pt-3" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active fw-bolder" id="pending-tab" data-bs-toggle="tab" data-bs-target="#pending" type="button" role="tab" aria-controls="pending" aria-selected="true" onClick={e => setThisState(0)}>未處理 ({handleEvent.filterData(0).length})</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link fw-bolder" id="checkOK-tab" data-bs-toggle="tab" data-bs-target="#checkOK" type="button" role="tab" aria-controls="checkOK" aria-selected="false" onClick={e => setThisState(1)}>已處理 ({handleEvent.filterData(1).length})</button>
                        </li>
                    </ul>
                    {
                        <div className='table-responsive-xxl text-center' style={{ height: '80vh', overflow: 'scroll' }}>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="pending" role="tabpanel" aria-labelledby="pending-tab">
                                    {handleUI.tableList()}
                                </div>
                                <div className="tab-pane fade" id="checkOK" role="tabpanel" aria-labelledby="checkOK-tab">
                                    {handleUI.tableList()}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
export default VipService