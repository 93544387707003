import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import Loading from "../../../components/Loading";
import { dateChange, toCurrency } from "../../../assets/javascript/calTool"
import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'

const RenewalM = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState([]);
    useEffect(() => {
        LineLogin('/renewal_m'); //LINE 登入狀態驗證
        Countfunc('重要提醒_續保產險單', 27)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CusList`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/renewal_m');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data.Data)
                        setIsLoading(false)
                    } else if (res.data.ResponseCode === '-20') {
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);
    let [insData, setInsData] = useState([]); //主約:1 ; 附約:0
    const getInsData = (data) => {
        const set = new Set();
        data.forEach((item, index) => {
            item.InsData.forEach((kitem, kIndex, kary) => {
                let sum = 0;
                kitem.forEach((aItem, aIndex, aArray) => {

                    if (!aItem?.Void) {
                        sum += aItem?.FYP
                    }

                    if (aItem.Main == 1 && aItem.Job_Type == 100) {
                        aItem.FYCsum = sum
                        setInsData(old => [...old, aItem])
                    }
                })

                kitem.map(aItem => aItem.FYCsum = sum)
            })
        });
    }
    useEffect(() => {
        getInsData(data)
    }, [data]);

    let showInsData = [];
    const showInsDataFun = () => {
        const set = new Set()
        showInsData = insData.filter(item => !set.has(item.Ins_No) ? set.add(item.Ins_No) : false);
    }
    showInsDataFun()

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-3">
                <p className="text-danger">＊自查詢日起算，於兩個月內即將到期的產險保單清單</p>
                <div className="table-responsive-xxl text-center">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>要保人</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>被保人</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>保單號碼</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>保險公司</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>主約商品</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>主約保額</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>總保費</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>到期日</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={insData.length === 0 ? '' : 'd-none'}>
                                <td className={`text-start ${insData.length === 0 ? '' : 'd-none'}`} colSpan='8'>{insData.length === 0 ? '無資料' : ''}</td>
                            </tr>
                            {
                                showInsData.filter((val, index) => {
                                    let day = dateChange(moment(new Date()).format('YYYY-MM-DD')).split('-')[1] + dateChange(moment(new Date()).format('YYYY-MM-DD')).split('-')[2]
                                    let changeDate = dateChange(val.EDate).split('-')[1] + dateChange(val.EDate).split('-')[2]
                                    let endDay = String(`0${Number(day) + 200}`)
                                    if (changeDate >= day && changeDate <= endDay) {
                                        return val
                                    }
                                }).sort((a, b) => {
                                    return dateChange(a.EDate).split('-')[1] + dateChange(a.EDate).split('-')[2] > dateChange(b.EDate).split('-')[1] + dateChange(b.EDate).split('-')[2] ? 1 : -1
                                }).map((item, index) => {
                                    return (
                                        <tr>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.name}</td>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.IName}</td>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.Ins_No}</td>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.Supplier_Name}</td>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.Pro_Name}</td>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(Math.round(item.Benefits))} {item.Unit}</td>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(Math.round(item.FYCsum))} 元</td>
                                            <td className="text-dark-blue fw-bolder" style={{ whiteSpace: 'nowrap' }}>{dateChange(item.EDate)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default RenewalM;