import React, { useEffect, useState } from "react";
import axios from "axios";

//from
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

//component
import BackgroundWrapper from "components/BackgroundWrapper";

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import goldennetLogo from "assets/images/goldennet_logo.svg";
import Loading from "components/Loading";
import AlertMes from "components/Alert";
import { returnRoute } from "Dashboard/authorization/Authorization";

const DashboardLogin = () => {
    let [isLoading, setIsLoading] = useState(true),
        [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' })
    //表單驗證
    const validationSchema = Yup.object().shape({
        user_Login: Yup.string()
            .required('請正確輸入帳號'),
        user_Password: Yup.string()
            .required('請正確輸入密碼'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    //密碼眼睛
    let [eye, setEye] = useState(true);
    const handleEyeClick = () => {
        if (eye) {
            setEye(false);
        } else {
            setEye(true);
        }
    }

    const handleAPI = {
        getRole: function (name) {
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}Account/GetAllAcc`
            axios.get(API)
                .then((res) => {
                    res.data.forEach((item, index) => {
                        if (name === item.User_Name) {
                            localStorage.setItem('role', item.User_Role)
                            setTimeout(() => {
                                window.location.href = `/dashboard/${returnRoute(item.User_Role.toString())}`
                                //跳轉頁面
                            }, 1500)
                        }
                    })
                })
                .catch((err) => {
                    setAlertMes({ mes: '網路連線失敗！請聯繫管理人員(err)', show: true, color: 'danger' })
                    setIsLoading(false)
                })
        }
    }

    let API = `${process.env.REACT_APP_GOLDEN_BACK_API}Account/LoginCheck`;
    const onSubmit = (data) => {
        setEye(true);

        data.user_Login = data.user_Login.trim()
        data.user_Password = data.user_Password.trim()

        axios.post(API, data)
            .then((res) => {
                if (res.data.ResponseCode === '0') {
                    setIsLoading(true)
                    setAlertMes({ mes: `登入成功`, show: true, color: 'primary' })
                    localStorage.setItem('userName', res.data.ResponseLoginName);
                    handleAPI.getRole(res.data.ResponseLoginName)
                    reset();
                } else {
                    setAlertMes({ mes: `帳號或密碼錯誤!! 請重新輸入(${res.data.ResponseCode})`, show: true, color: 'danger' })
                    setIsLoading(false)
                    reset();
                }
            })
            .catch((err) => {
                setAlertMes({ mes: `網路連線失敗！請聯繫管理人員(err)`, show: true, color: 'danger' })
                setIsLoading(false)
                console.log(err)
            })
    }

    useEffect(() => {
        let checkIsLogin = localStorage.getItem('userName')
        let checkIsRole = localStorage.getItem('role')
        const checkIsLoginFun = () => {
            if (checkIsLogin == null && checkIsRole == null) {
                setIsLoading(false)
                return
            } else {
                window.location.href = '/dashboard/message_center'
            }
        }
        checkIsLoginFun();
    }, [])

    //登入驗證 END
    return (
        <>
            <Loading isLoading={isLoading} />
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} setAlertMes={setAlertMes} />
            <BackgroundWrapper>
                <div className="container-fluid bg-mask vh-100">
                    <div className="container">
                        <div className="text-center py-5"><img src={goldennetLogo} className="img-fluid" alt="Golden-LOGO" width="300px" /></div>
                        <h4 className="text-center fw-bolder text-golden-brown">發訊中心</h4>
                        <div className="d-flex justify-content-center">
                            <form onSubmit={handleSubmit(onSubmit)} className="col-12 col-md-6 col-lg-4">
                                <div className="form-group my-3">
                                    <label htmlFor="user_Login" className="fw-bolder text-golden-brown">請輸入帳號</label>
                                    <input id="user_Login" name="user_Login" type="text" {...register('user_Login')} className={`form-control ${errors.user_Login ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.user_Login?.message}</div>
                                </div>
                                <div className="form-group my-3 position-relative">
                                    <label htmlFor="user_Password" className="fw-bolder text-golden-brown">請輸入密碼</label>
                                    <input id="user_Password" name="user_Password" type={eye ? 'password' : 'text'} className={`form-control ${errors.user_Password ? 'is-invalid' : ''}`}
                                        {...register('user_Password')}
                                    />
                                    <div className="invalid-feedback">{errors.user_Password?.message}</div>
                                    <button type="button" className="btn text-dark-blue position-absolute" style={{ right: '-1px', top: '24px' }} onClick={handleEyeClick}><FontAwesomeIcon icon={eye ? faEye : faEyeSlash} /></button>
                                </div>
                                <button type="submit" className="btn btn-golden-brown w-100 mt-3">
                                    登入
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

            </BackgroundWrapper>
        </>
    )
}

export default DashboardLogin;