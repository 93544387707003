import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Loading from '../../components/Loading';
import { dateTimeChange } from '../../assets/javascript/calTool';
import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc';
import DownloadFile from "../../assets/javascript/downloadFile";

const History = () => {
    let [isLoading, setIsLoading] = useState(true);
    let [data, setData] = useState([])
    let [noDataText, setNoDataText] = useState('')

    useEffect(() => {
        LineLogin('/history'); //LINE 登入狀態驗證
        Countfunc('服務通知', 4)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}history`;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/history');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data.List)
                        setIsLoading(false)
                        if (res.data.List.length === 0) {
                            setNoDataText('無資料')
                        }
                    } else if (res.data.ResponseCode === '-20') {
                        setIsLoading(false)
                        setNoDataText('無資料')
                    } else {
                        setIsLoading(false)
                        setNoDataText('無資料')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT));
            })
            .catch((err) => {
                setIsLoading(false)
                alert('請重新整理')
            })
    }, []);

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className='container'>
                <div className='row'>
                    <p className='mt-3'>{noDataText}</p>
                    {
                        data.map((item, index, ary) => {
                            return (
                                <div className='col-12 col-md-6 col-lg-4 my-3'>
                                    <div className='card h-100'>
                                        <div className='card-header'>{dateTimeChange(item.Send_Datetime)}</div>
                                        <div className='card-body'>
                                            <p className='mb-3'>{item.Message_Body}</p>
                                            <img src={item.Message_Image} className={`${item.Message_Image === '' || item.Message_Image === null || item.Message_Image === undefined ? 'd-none' : 'img-fluid'}`} data-filename={item.Message_Image} data-download={item.Message_Image} width='80%' />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}
export default History;