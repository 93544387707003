import React from 'react';

const Banner = (props) => {
    return (
        <header className="banner" style={props.style}>
            <div className="container d-flex align-items-end h-100">
                <h1 className="banner-title text-light fw-bolder mb-3"
                style={{whiteSpace:'pre-wrap'}}>{props.title}</h1>
            </div>
        </header>
    );
}
export default Banner;