import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Loading from '../../../components/Loading';

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import LineLogin from '../../../assets/javascript/lineLogin';
import Countfunc from '../../../assets/javascript/countfunc';
import DownloadFile from '../../../assets/javascript/downloadFile'

const Topics = () => {

    let [data, setData] = useState([])
    let [isLoading, setIsLoading] = useState(true)
    let [option, setOption] = useState('eGolden教案')
    let [secOption, setSecOption] = useState('')

    useEffect(() => {
        LineLogin('/topics'); //LINE 登入狀態驗證
        Countfunc('課程教案_專題', 35)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=6`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/topics');
                        window.location.href = '/login'
                        return
                    } else {
                        setData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);

    //資料整理
    let dataAry = [];
    let filterOptionAry = [];
    let secondOptionAry = [];
    const splitData = (data) => {
        let splitAry = [];
        data.forEach((item, index) => {
            splitAry.push(item.split('/'))
        });

        //取得切割後為[早會課程]資料
        splitAry.forEach((item, index, ary) => {
            if (item[2] === '2.專題課程') {
                dataAry.push({
                    downloadFile: item.join('/'),
                    option: item[3].split('.')[1],
                    secOption: item.length > 5 ? item[4].split('.')[1] : '',
                    fileName: item.pop()
                })
            }
        })

        //篩選option
        let optionAry = [];
        let optionSecAry = [];
        dataAry.forEach((item, index) => {
            optionAry.push(item.option)
            if (option === item.option) {
                optionSecAry.push(item.secOption)
            }
        })

        filterOptionAry = optionAry.filter((item, index, ary) => {
            return ary.indexOf(item) === index
        })

        secondOptionAry = optionSecAry.filter((item, index, ary) => {
            return ary.indexOf(item) === index
        })
    }
    splitData(data)

    const handleOptionChange = (e) => {
        let { value } = e.target
        setOption(value); //更新值
        dataAry.forEach((item, index) => {
            if (value === item.option) {
                setSecOption(item.secOption)
            }
        })
    }

    const handleSecOptionChange = (e) => {
        let { value } = e.target
        setSecOption(value)
    }

    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/topics' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setIsLoading)
    }

    //洗錢、公平代客(兩層選單)
    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-3">
                <div className="d-flex pb-2">
                    <select className="form-select" onChange={handleOptionChange} value={option}>
                        {
                            filterOptionAry.map((item, index) => {
                                return (
                                    <option value={item} key={item}>{item}</option>
                                )
                            })
                        }
                    </select>
                    <select className={`form-select ms-2 ${secondOptionAry.length === 1 && secOption === '' ? 'd-none' : 'd-block'}`} onChange={handleSecOptionChange} value={secOption}>
                        {
                            secondOptionAry.map((item, index) => {
                                return (
                                    <option value={item} key={item}>{item}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <ul className="list-unstyled page-content-link">
                    {
                        dataAry.map((item, index) => {
                            if (option === item.option && secOption === item.secOption) {
                                return (
                                    <li className="border-bottom py-3" key={index}>
                                        <a href="#" data-download={item.downloadFile} data-filename={item.fileName} className="d-block fw-bolder file-icon" onClick={handleDownloadFile}>
                                            {item.fileName}
                                        </a>
                                    </li>
                                )
                            }
                            return true
                        })
                    }
                </ul>
            </div>
        </>

    );
}
export default Topics;