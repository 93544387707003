import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useRouteMatch } from "react-router-dom";
import InsideNav from '../../components/InsideNav';
//component
import ShowLogo from './ShowLogo';
import Loading from '../../components/Loading';

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import DownloadFile from '../../assets/javascript/downloadFile';
import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc';

const ReportSin = (props) => {
    const match = useRouteMatch();
    let showLogoData = match.url.split('/')[2].split('-')
    let [isLoading, setIsLoading] = useState(true);
    let [company] = useState(showLogoData[1])

    let [allData, setAllData] = useState([])
    let [fileData, setFileData] = useState([])

    useEffect(() => {
        LineLogin(`${match.url}`); //LINE 登入狀態驗證
        Countfunc('報備', 7)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}InsCom/1`
        let fileAPI = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=2`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', `${match.url}`);
                        window.location.href = '/login'
                        return
                    } else {
                        setAllData(res.data)
                        // setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })

        axios.get(fileAPI, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode) {
                        localStorage.setItem('page', `${match.url}`);
                        window.location.href = '/login'
                        return
                    } else {
                        setFileData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);
    //GET API END

    let allPayData = [];
    let payFileAry = [];
    //繳費資料處理
    let payData = () => {
        //繳費 PDF 檔案切割
        let splitFileAry = [];
        fileData.forEach((item, index) => {
            splitFileAry.push(item.split('/'))
        });
        //取得切割後為[報備資訊]資料

        splitFileAry.forEach((item, index) => {
            if (item[1] === '報備資訊' && item[3].split('_')[0].split('.')[1] === showLogoData[1]) {
                payFileAry.push({
                    downloadUrl: item.join('/'),
                    company: item[3].split('_')[0].split('.')[1],
                    fileName: item[3]
                })
            }
        })

        allData.forEach((item, index) => {
            if (item.name === '報備資訊') {
                item.data.forEach((kidItem, kidIndex) => {
                    if (kidItem[1] === showLogoData[1]) {
                        if (kidItem.length <= 2) {
                            return
                        }
                        allPayData.push({
                            no: kidItem[0] === undefined ? null : kidItem[0], //序號
                            company: kidItem[1] === undefined ? null : kidItem[1], //保險公司
                            voice: kidItem[2] === undefined ? null : kidItem[2], //語音報備電話
                            voiceRemark: kidItem[3] === undefined ? null : kidItem[3], //語音報備備註
                            network: kidItem[4] === undefined ? null : kidItem[4],//網路報備
                            networkRemark: kidItem[5] === undefined ? null : kidItem[5],//網路報備備註
                            fax: kidItem[6] === undefined ? null : kidItem[6],//傳真報備電話
                            faxRemark: kidItem[7] === undefined ? null : kidItem[7],//傳真報備備註
                            email: kidItem[8] === undefined ? null : kidItem[8],//Email報備信箱
                            emailRemark: kidItem[9] === undefined ? null : kidItem[9],//Email報備備註
                        })
                    }
                })

            }
        })
    }
    payData();
    //資料處理 END

    const insideNavItem = [];
    const insideNavPush = (data) => {
        data.forEach((item, index) => {
            if (item.voice !== null) {
                insideNavItem.push({ text: '語音' })
            }
            if (item.network !== null) {
                insideNavItem.push({ text: '網路' })
            }
            if (item.fax !== null) {
                insideNavItem.push({ text: '傳真' })
            }
            if (item.email !== null) {
                insideNavItem.push({ text: 'Email' })
            }
        });
    }
    insideNavPush(allPayData)

    const [option, setOption] = useState('傳真');
    useEffect(() => {
        if (showLogoData[1] === '保誠人壽' || showLogoData[1] === '友邦人壽' || showLogoData[1] === '安達人壽' || showLogoData[1] === '凱基人壽' || showLogoData[1] === '臺銀人壽' || showLogoData[1] === '宏泰人壽' || showLogoData[1] === '第一金人壽' || showLogoData[1] === '康健人壽' || showLogoData[1] === '安達產物') {
            setOption('傳真')
        } else if (showLogoData[1] === '全球人壽' || showLogoData[1] === '安聯人壽' || showLogoData[1] === '遠雄人壽' || showLogoData[1] === '元大人壽' || showLogoData[1] === '富邦產物') {
            setOption('語音')
        } else if (showLogoData[1] === '台灣人壽' || showLogoData[1] === '新光人壽' || showLogoData[1] === '法國巴黎人壽') {
            setOption('網路')
        }
    }, [])

    const handleClick = (e) => {
        let { innerText, nodeName } = e.target;
        if (nodeName !== 'A') {
            return
        }
        setOption(innerText)
    }

    //判斷文字是否有 http 字眼
    const ifStrHaveHttp = (str) => {
        if (str === undefined) {
            return
        }
        if (str === null) {
            return
        }
        if (str.indexOf('http') !== -1) {
            return true
        } else {
            return false
        }
    }

    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/report' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setIsLoading)
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="position-fixed w-100 bg-light" style={{ zIndex: '100' }}>
                <ShowLogo PropCompany={showLogoData}></ShowLogo>
                <InsideNav insideNavItem={insideNavItem} handleClick={handleClick} option={option} />
            </div>
            <div className="d-table company-paymentinfo-header-height"></div>
            <div className="container py-4 mt-md-4 mt-lg-0">
                {
                    allPayData.map((item, index) => {
                        if (option === '語音') {
                            if (item.voice == null) {
                                return (<p className="fw-bolder" key={index}>無語音報備</p>)
                            } else {
                                return (
                                    <>
                                        <div key={index}>
                                            <p className="text-center">請撥打免費專線</p>
                                            <div className="fs-5 py-3 text-dark-blue text-center">
                                                <FontAwesomeIcon icon={faPhoneAlt} />
                                                <a href={`tel:${item.voice}`} style={{ whiteSpace: 'pre-wrap' }} className="fw-bolder text-dark-blue ms-2">{item.voice}</a>
                                            </div>
                                            <p style={{ whiteSpace: 'pre-wrap' }} className="fw-bolder">{item.voiceRemark}</p>
                                        </div>
                                    </>
                                )
                            }
                        } else if (option === '網路') {
                            if (item.network == null) {
                                return (<p className="fw-bolder" key={index}>無網路報備</p>)
                            } else {
                                return (
                                    <>
                                        <div key={index}>
                                            <a href={item.network} className={item.network !== null && ifStrHaveHttp(item.network) ? "d-block" : "d-none"} style={{ wordBreak: 'break-all' }}>{item.network}</a>
                                            <p className={`text-start ${item.network !== null && ifStrHaveHttp(item.network) ? "d-none" : "d-block"}`} style={{ letterSpacing: '2px', width: '150px' }}>{item.networkk}</p>
                                            <p style={{ whiteSpace: 'pre-wrap' }} className="fw-bolder py-2">{item.networkRemark}</p>
                                        </div>
                                    </>
                                )
                            }
                        } else if (option === '傳真') {
                            if (item.fax == null) {
                                return (<p className="fw-bolder" key={index}>無傳真報備</p>)
                            } else {
                                return (
                                    <>
                                        <div key={index}>
                                            <p style={{ whiteSpace: 'pre-wrap' }} className="fw-bolder fs-5 text-dark-blue text-center">{item.fax}</p>
                                            <p style={{ whiteSpace: 'pre-wrap' }} className="fw-bolder pt-3">{item.faxRemark}</p>
                                        </div>
                                    </>
                                )
                            }
                        } else if (option === 'Email') {
                            if (item.email == null) {
                                return (<p className="fw-bolder">無 Email 報備</p>)
                            } else {
                                return (
                                    <>
                                        <div>
                                            <div className="fs-5 text-dark-blue text-center">
                                                <FontAwesomeIcon icon={faEnvelope} />
                                                <a href={`mailto:${item.email}`} style={{ whiteSpace: 'pre-wrap' }} className="fw-bolder text-dark-blue ms-2">{item.email}</a>
                                            </div>
                                            <p style={{ whiteSpace: 'pre-wrap' }} className="fw-bolder pt-3">{item.emailRemark}</p>
                                        </div>
                                    </>
                                )
                            }
                        }
                        return true
                    })
                }
            </div>
            <div className='container pb-3'>
                <ul className='list-unstyled'>
                    {
                        payFileAry.map((item, index) => {
                            if (item.company === company) {
                                return (
                                    <li className="border-bottom py-3" key={item.fileName}>
                                        <a href="#" data-download={item.downloadUrl} data-filename={item.fileName} className="d-block fw-bolder file-icon text-dark-blue" onClick={handleDownloadFile}>
                                            {item.fileName}
                                        </a>
                                    </li>
                                )
                            }
                            return true
                        })
                    }
                </ul>
            </div>
        </>
    );
}
export default ReportSin;