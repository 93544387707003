import React, { useState, useEffect } from 'react';
import { useRouteMatch, Switch, Route } from "react-router-dom";
//component
import LogoList from './LogoList';
import PaymentinfoSin from './PaymentinfoSin';

import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc';
import data from '../../json/insurer/Insurer.json'
import Loading from '../../components/Loading'

const Paymentinfo = (props) => {
    let match = useRouteMatch();
    let [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        LineLogin('/paymentinfo'); //LINE 登入狀態驗證
        Countfunc('繳費', 6); //觸及數器
    }, []);
    setTimeout(() => {
        setIsLoading(false)
    }, [1000])
    let propData = data.data[0].Paymentinfo
    return (
        <>
            <Loading isLoading={isLoading} />
            <LogoList data={propData} />
            <Switch>
                <Route path={`${match.path}/:id`}><PaymentinfoSin /></Route>
            </Switch>
        </>
    );
}
export default Paymentinfo;