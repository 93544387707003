import React, { useState, useEffect } from 'react';

import TreeView from 'react-treeview';
import 'react-treeview/react-treeview.css';

import Loading from '../../components/Loading'

const TreeViewMember = (props) => {
    let [isLoading, setLoading] = useState(true)

    let memberData = props.data
    let searchInput = props.searchInput;
    let [justMemberData, setJustMemberData] = useState([]) //儲存只有人員 data

    useEffect(() => {
        if (memberData.length !== 0) {
            setLoading(false)
        }
        memberData.forEach((item, index) => { //撈取所有人員
            item.AllDepts.forEach((kitem, kindex) => {
                kitem.Line.map((aitem, aindex) => {
                    setJustMemberData(old => [...old, aitem])
                })
            })
        })
    }, [memberData]);

    //過濾相同 LINE ID
    const set = new Set();
    let filterMemberAry = justMemberData.filter(item => !set.has(item.ID) && item.Pay === '1' && item.Valid === '1' ? set.add(item.ID) : false); //過濾 Line ID 相同的人員
    useEffect(() => {}, [justMemberData])

    const All = <button type="button" className="btn node earth-btn py-0" value={searchInput === '' ? '全體' : '人員'}>{searchInput === '' ? '全體' : '人員'}</button>;
    return (
        <>
            <Loading isLoading={isLoading} />
            <div className={`MainDiv`} onClick={props.handleMemberClick}>
                {
                    searchInput == '' ?
                        <TreeView nodeLabel={All} defaultCollapsed={false}>
                            {memberData.map((item, index, ary) => {
                                const Loc = <button type="button" className="btn node dept-btn py-0" value={item.Loc}>{item.Loc}</button>;
                                return (
                                    <TreeView key={Loc + '|' + index} nodeLabel={Loc} defaultCollapsed={true} >
                                        {item.AllDepts.map((item, index) => {
                                            const Dept = <button type="button" className="btn node dept-btn py-0" value={item.Dept}>{item.Dept}</button>;
                                            return (
                                                <TreeView nodeLabel={Dept} key={item.Dept} defaultCollapsed={true}>
                                                    {
                                                        item.Line.map((item, index) => {
                                                            if (item.Pay === '1' && item.Valid === '1') {
                                                                const Name = <button type="button" className={`btn node user-btn py-0 fw-bolder`} value={item.ID}>{item.Name}</button>
                                                                return (
                                                                    <TreeView nodeLabel={Name} key={item.ID} defaultCollapsed={true}>
                                                                        <p className={`dollar-icon py-0 ps-3 text-secondary `} >{item.Pay === "1" ? "已繳費" : ""}</p>
                                                                        <p className={`check-icon py-0 ps-3 text-secondary `} >{item.Valid === "1" ? "權限啟用" : ""}</p>
                                                                    </TreeView>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </TreeView>
                                            )
                                        })}
                                    </TreeView>
                                );
                            })}
                        </TreeView>
                        :
                        <TreeView nodeLabel={All} defaultCollapsed={false}>
                            {filterMemberAry.filter(val => {
                                if (val.Name.includes(searchInput)) {
                                    return val
                                }
                            }).map((item, index, ary) => {
                                if (item.Pay === '1' && item.Valid === '1') {
                                    const Name = <button type="button" className={`btn node user-btn py-0 fw-bolder`} value={item.ID}>{item.Name}</button>
                                    return (
                                        <TreeView nodeLabel={Name} key={index} defaultCollapsed={true}>
                                            <p className={`dollar-icon py-0 ps-3 text-secondary `} >{item.Pay === "1" ? "已繳費" : ""}</p>
                                            <p className={`check-icon py-0 ps-3 text-secondary `} >{item.Valid === "1" ? "權限啟用" : ""}</p>
                                        </TreeView>
                                    )
                                }
                            })}
                        </TreeView>
                }
            </div>
        </>
    );
}
export default TreeViewMember