import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LineLogin from '../../assets/javascript/lineLogin';
import Countfunc from '../../assets/javascript/countfunc';
import Loading from '../../components/Loading'
const Emi = () => {
    let [isLoading] = useState(true)
    useEffect(() => {
        LineLogin('/emi')
        Countfunc('電子報聘', 48)
        const token = document.cookie.replace(/(?:(?:^|.*;\s*)lineIdToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/emi');
                        window.location.href = '/login'
                        return
                    } else if (!token) {
                        localStorage.setItem('page', '/emi');
                        window.location.href = '/login'
                        return
                    } else {
                        window.location.href = `https://e-review.goldennet.com.tw:8097/?man_id=${token}`
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);
    return (
        <>
            <Loading isLoading={isLoading} />
        </>
    )
}
export default Emi