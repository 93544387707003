import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from '../../components/Loading';
import LineLogin from '../../assets/javascript/lineLogin';
import { toCurrency } from '../../assets/javascript/calTool'
const ChallengesofGoldenRank = () => {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token === '') {
        localStorage.setItem('page', '/Challenges_of_Golden_RankDept');
        window.location.href = '/login'
    }
    useEffect(() => {
        LineLogin('/Challenges_of_Golden_RankDept')
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/Challenges_of_Golden_RankDept');
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                console.log(err)
                // alert('請重新整理')
            })
    }, []);

    let busCode = JSON.parse(unescape(token)).BusCode

    //LINE login 驗證
    let [isLoading, setIsLoading] = useState(true)
    let [typeData, setTypeData] = useState([{ type: '全部' }, { type: '游泳' }, { type: '跑步' }, { type: '健行' }, { type: '自行車' }, { type: '登山' }])
    let [option, setOption] = useState('全部')
    let [allData, setAllData] = useState([]) //全部噴回來的資料 儲存
    let [rankData, setRankData] = useState([]) //篩選過資料
    let [awardType, setAwardType] = useState('s')
    const handleEvent = {
        getData: function () { //取得所有 data
            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}madman_group_list/${busCode}`
            setIsLoading(true)
            axios.get(API)
                .then((res) => {
                    if (Object.keys(res.data).length > 0) {
                        setIsLoading(false)
                        setAllData(res.data)
                    } else {
                        setIsLoading(false)
                        setAllData([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getRankData: function () {
            if (option === '全部') {
                setRankData(allData.t_data[1])
            } else if (option === '游泳') {
                setRankData(allData.s_data[1])
                setAwardType('s')
            } else if (option === '跑步') {
                setRankData(allData.j_data[1])
                setAwardType('j')
            } else if (option === '健行') {
                setRankData(allData.h_data[1])
                setAwardType('h')
            } else if (option === '自行車') {
                setRankData(allData.b_data[1])
                setAwardType('b')
            } else if (option === '登山') {
                setRankData(allData.c_data[1])
                setAwardType('c')
            }
        },
        changeEvent: function (e) {
            let { value, nodeName } = e.target
            if (nodeName !== 'BUTTON') {
                return
            }
            setOption(value)
        },
        returnHomePage: function (e) {
            e.preventDefault();
            window.location.href = '/Challenges_of_Golden'
        }
    }

    useEffect(() => {
        handleEvent.getData();
    }, [])
    useEffect(() => {
        if (allData.length <= 0) {
            return
        } else {
            handleEvent.getRankData()
        }
    }, [option, allData])

    let activeStyle = {
        borderBottom: '2px solid #fff',
        paddingBottom: '4px'
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className='container challenge'>
                <div className='d-flex justify-content-between mb-3'>
                    <div>
                        <h3 className='challenge-title text-light'>區部排行</h3>
                    </div>
                    <img className='challenge-main-img_rank' src={require('../../assets/images/challengesofGolden/rank.svg').default} alt='狂人排行' />
                </div>
                {
                    allData.length == 0 ?
                        <p className='text-light text-center py-3'>無資料</p> :
                        <>
                            <div className='challenge-type-button d-flex justify-content-between' onClick={handleEvent.changeEvent}>
                                {
                                    typeData.map((item, index) => {
                                        return (
                                            <button type='button' className='btn text-light fw-bolder rounded-0 p-1' style={option === item.type ? activeStyle : {}} value={item.type}>{item.type}</button>
                                        )
                                    })
                                }
                            </div>
                            <div className="table-responsive-xxl text-center mt-3" style={{ height: '450px', overFlowY: 'scroll' }}>
                                <table className="table table-striped table-light align-middle">
                                    {
                                        option === '全部' ?
                                            <>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>NO.</th>
                                                        <th className="text-center" width='200' style={{ whiteSpace: 'nowrap' }}>事業部</th>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>捐款金額</th>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>獎章</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        rankData.map((item, index) => {
                                                            return (
                                                                <tr className={`${item.No == 1 ? 'table-rank1' : ''}${item.No == 2 ? 'table-rank2' : ''}${item.No == 3 ? 'table-rank3' : ''}`}>
                                                                    <td className="text-dark-blue fw-bolder fs-6" style={{ whiteSpace: 'nowrap' }}>{item.No}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.r_buscode}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.total_donate)} 元</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.total_medal)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </>
                                            :
                                            <>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>NO.</th>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>事業部</th>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>總獎牌</th>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                            <img src={require(`../../assets/images/challengesofGolden/award/${awardType}_premium.png`).default} alt='特級' width='38px' />
                                                            <span className='d-block d-md-inline'>特級</span>
                                                        </th>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                            <img src={require(`../../assets/images/challengesofGolden/award/${awardType}_platinum.png`).default} alt='白金' width='38px' />
                                                            <span className='d-block d-md-inline'>白金</span>
                                                        </th>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                            <img src={require(`../../assets/images/challengesofGolden/award/${awardType}_gold.png`).default} alt='金' width='38px' />
                                                            <span className='d-block d-md-inline'>金</span>
                                                        </th>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                            <img src={require(`../../assets/images/challengesofGolden/award/${awardType}_silver.png`).default} alt='銀' width='38px' />
                                                            <span className='d-block d-md-inline'>銀</span>
                                                        </th>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                            <img src={require(`../../assets/images/challengesofGolden/award/${awardType}_copper.png`).default} alt='銀' width='38px' />
                                                            <span className='d-block d-md-inline'>銅</span>
                                                        </th>
                                                        <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                            <img src={require(`../../assets/images/challengesofGolden/award/${awardType}_wood.png`).default} alt='銀' width='38px' />
                                                            <span className='d-block d-md-inline'>木</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        rankData.map((item, index) => {
                                                            return (
                                                                <tr className={`${item.No == 1 ? 'table-rank1' : ''}${item.No == 2 ? 'table-rank2' : ''}${item.No == 3 ? 'table-rank3' : ''}`}>
                                                                    <td className="text-dark-blue fw-bolder fs-6" style={{ whiteSpace: 'nowrap' }}>{item.No}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.r_buscode}</td>
                                                                    <td className="text-dark-blue fw-bolder" style={{ whiteSpace: 'nowrap' }}>{item.TOTAL}</td>
                                                                    <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                                        {awardType === 's' ? item.s_premium : ''}
                                                                        {awardType === 'j' ? item.j_premium : ''}
                                                                        {awardType === 'h' ? item.h_premium : ''}
                                                                        {awardType === 'c' ? item.c_premium : ''}
                                                                        {awardType === 'b' ? item.b_premium : ''}
                                                                    </th>
                                                                    <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                                        {awardType === 's' ? item.s_platinum : ''}
                                                                        {awardType === 'j' ? item.j_platinum : ''}
                                                                        {awardType === 'h' ? item.h_platinum : ''}
                                                                        {awardType === 'c' ? item.c_platinum : ''}
                                                                        {awardType === 'b' ? item.b_platinum : ''}
                                                                    </th>
                                                                    <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                                        {awardType === 's' ? item.s_gold : ''}
                                                                        {awardType === 'j' ? item.j_gold : ''}
                                                                        {awardType === 'h' ? item.h_gold : ''}
                                                                        {awardType === 'c' ? item.c_gold : ''}
                                                                        {awardType === 'b' ? item.b_gold : ''}
                                                                    </th>
                                                                    <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                                        {awardType === 's' ? item.s_silver : ''}
                                                                        {awardType === 'j' ? item.j_silver : ''}
                                                                        {awardType === 'h' ? item.h_silver : ''}
                                                                        {awardType === 'c' ? item.c_silver : ''}
                                                                        {awardType === 'b' ? item.b_silver : ''}
                                                                    </th>
                                                                    <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                                        {awardType === 's' ? item.s_copper : ''}
                                                                        {awardType === 'j' ? item.j_copper : ''}
                                                                        {awardType === 'h' ? item.h_copper : ''}
                                                                        {awardType === 'c' ? item.c_copper : ''}
                                                                        {awardType === 'b' ? item.b_copper : ''}
                                                                    </th>
                                                                    <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>
                                                                        {awardType === 's' ? item.s_wood : ''}
                                                                        {awardType === 'j' ? item.j_wood : ''}
                                                                        {awardType === 'h' ? item.h_wood : ''}
                                                                        {awardType === 'c' ? item.c_wood : ''}
                                                                        {awardType === 'b' ? item.b_wood : ''}
                                                                    </th>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </>
                                    }
                                </table>
                            </div>
                        </>
                }
                <div className='text-end'>
                    <button className='btn btn-outline-light my-3 return-icon' onClick={handleEvent.returnHomePage}>返回首頁</button>
                </div>
            </div>
        </>
    )
}
export default ChallengesofGoldenRank