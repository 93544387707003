import React, { useState, useEffect } from 'react';
import { useRouteMatch, Switch, Route, withRouter } from "react-router-dom";
//component
import LogoList from './LogoList';
import FilesSin from './FilesSin';

import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc';
import Loading from '../../components/Loading'

import data from '../../json/insurer/Insurer.json'

const Files = (props) => {
    let match = useRouteMatch();
    let [isLoading, setIsLoading] = useState(false)
    //GET API
    useEffect(() => {
        LineLogin('/files'); //LINE 登入狀態驗證
        Countfunc('文件', 5); //觸及數器
    }, []);
    //GET API END
    setTimeout(()=>{
        setIsLoading(false)
    },[1000])
    let propData = data.data[0].Files

    return (
        <>
            <Loading isLoading={isLoading} />
            <LogoList data={propData} />
            <Switch>
                <Route path={`${match.path}/:id`}><FilesSin /></Route>
            </Switch>
        </>
    );
}
export default withRouter(Files);