import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const ModelSupervisorState = (props) => {
    let history = useHistory();
    let [stateCode, setStateCode] = useState('-100')
    useEffect(() => {
        if (history.location.state == undefined) {
            setStateCode('-100')
        } else {
            setStateCode(history.location.state.code)
        }
    }, []);
    let style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
    }
    return (
        <>
            <div className='min-vh-100' style={{ background: 'linear-gradient(90deg, #2FABF4, #1671bc)' }}>
                <div style={style} className='text-center'>
                    <h3 className="fw-bolder text-light text-center" style={{ whiteSpace: 'break-spaces' }}>
                        {stateCode == '0' ? '問卷已送出!! 感謝您的回饋~~' : stateCode == '-98' ? '您已經填寫過問卷囉!' : stateCode == '-1' ? '系統內部出現錯誤' : `問卷填寫錯誤(${stateCode})`}
                    </h3>
                    <p className='text-light mt-3'>請直接關閉頁面</p>
                </div>
            </div>
        </>
    )
}
export default ModelSupervisorState