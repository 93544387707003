import React, { useState, useEffect } from "react";
import axios from "axios";

import { toCurrency } from '../../../assets/javascript/calTool'; //千分位逗點轉換
import Loading from '../../../components/Loading'

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'

const Performance = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState([]) //FYC 排名

    useEffect(() => {
        LineLogin('/promotion'); //LINE 登入狀態驗證
        Countfunc('個人資料_晉升進度', 24)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}Promo_performance`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/promotion');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data)
                        setIsLoading(false)
                    } else if (res.data.ResponseCode === '-20') {
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);
    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-2">
                <div className="row justify-content-center">
                    <div className="insurance-box col-md-6">
                        <h6 className="box-header bg-dark-blue border-dark-blue">個人累積業績</h6>
                        <div className="box-body border-dark-blue">
                            <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faDollarSign} />
                            <span className="text-golden-brown box-num">
                                {data.fyc === undefined || data.fyc === null ? '0' : toCurrency(Math.round(data.fyc))} 元</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Performance;