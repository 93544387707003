import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ChallengesofGolden from '../views/challengesofGolden/ChallengesofGolden'
import ChallengesofGoldenForm from '../views/challengesofGolden/ChallengesofGoldenForm'
import ChallengesofGoldenAward from '../views/challengesofGolden/ChallengesofGoldenAward'
import ChallengesofGoldenRank from '../views/challengesofGolden/ChallengesofGoldenRank'
import ChallengesofGoldenRankDept from '../views/challengesofGolden/ChallengesofGoldenRankDept'
const ChallengesOfGoldenRouter = () => {

    return (
        <>
            <div style={{ background: '#00385D', minHeight: '100vh' }}>
                <header className='challenge-header'>
                    <h2 className='fw-bolder'>公勝齊聚 五大挑戰</h2>
                    <p className='fw-bolder'>超越 x 團隊 x 品牌 x 公益</p>
                </header>
                <Router>
                    <Switch>
                        <Route exact path="/Challenges_of_Golden"><ChallengesofGolden /></Route> {/* 首頁 */}
                        <Route exact path="/Challenges_of_Golden_Form"><ChallengesofGoldenForm /></Route> {/* 活動登入 */}
                        <Route exact path="/Challenges_of_Golden_Award"><ChallengesofGoldenAward /></Route> {/* 個人成果 */}
                        <Route exact path="/Challenges_of_Golden_Rank"><ChallengesofGoldenRank /></Route> {/* 狂人排行 */}
                        <Route exact path="/Challenges_of_Golden_RankDept"><ChallengesofGoldenRankDept /></Route> {/* 區部排行 */}
                    </Switch>
                </Router>
            </div>
        </>
    )
}
export default ChallengesOfGoldenRouter