import React from 'react';
const NpsModel = ({ children, name }) => {
    return (
        <div style={{ background: 'linear-gradient(90deg, #2FABF4, #1671bc)', minHeight: '100vh' }}>
            <div className="container">
                <div className="col-12 col-md-8 col-lg-6 mx-auto">
                    <div className='py-5'>
                        <h2 className='text-center fw-bolder text-light mb-3' style={{ fontSize: '24px' }}>{name} 行專<br /> 服務滿意度推薦</h2>
                        {children}
                    </div>
                </div>
            </div>
        </div >
    )
}
export default NpsModel