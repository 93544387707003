import React, { useState, useEffect } from "react";
//components
import BackgroundWrapper from "../../components/BackgroundWrapper";
import { dateChange } from "../../assets/javascript/calTool"
import Loading from "../../components/Loading"

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle, faPaperclip, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc'
import downloadtoBase64url from "../../assets/javascript/downloadtoBase64url";

const Notification = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [stateRadio, setStateRadio] = useState('not');

    let [data, setData] = useState([])
    let [nodataText, setNodataText] = useState('');
    useEffect(() => {
        LineLogin('/notification'); //LINE 登入狀態驗證
        Countfunc('照會查詢', 20)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}Notify`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/notification');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data.Data)
                        setIsLoading(false)
                    } else if (res.data.ResponseCode === '-20') {
                        setNodataText('無資料')
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);

    let [filterData, setFilterData] = useState([]);
    //搜尋
    const searchDoneData = () => {
        data.forEach((val, index) => {
            if (stateRadio === 'all') {
                setFilterData(old => [...old, val])
            } else if (stateRadio === 'not') {
                if (val['狀態'] === 'FALSE') {
                    setFilterData(old => [...old, val])
                }
            } else if (stateRadio === 'done') {
                if (val['狀態'] === 'TRUE') {
                    setFilterData(old => [...old, val])
                }
            }
        })
    }
    let testData = [];
    const set = new Set();
    testData = filterData.filter(item => !set.has(item['照會檔案']) ? set.add(item['照會檔案']) : false).sort((a, b) => {
        let start = dateChange(a['照會日']).split('-');
        let end = dateChange(b['照會日']).split('-');
        return start[0] + start[1] + start[2] - end[0] + end[1] + end[2] ? -1 : 1
    });


    useEffect(() => {
        searchDoneData()
    }, [data, stateRadio]);
    //分頁邏輯 start
    //分頁初始資料
    let [currentPage, setCurrentPage] = useState(1); //當前頁數
    let [itemsPerPage] = useState(10); //預設一頁顯示筆數
    let [pageNumberLimit] = useState(5); //每次顯示頁數限制
    let [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5) //最大頁數限制
    let [minPageNumberLimit, setMinPageNumberLimit] = useState(0) //最小頁數限制

    const handleCurrentPage = (e) => {
        e.preventDefault();
        let { id } = e.target;
        setCurrentPage(Number(id))
    }

    let pages = []; //總共分成幾頁
    for (let i = 1; i <= Math.ceil(testData.length / itemsPerPage); i++) {
        pages.push(i);
    }

    //預設 currentPage 為 1; itemsPerPage 為 5筆
    const indexOfLastItem = currentPage * itemsPerPage; //5
    const indexOfFirstItem = indexOfLastItem - itemsPerPage; //0
    const currentItems = testData.slice(indexOfFirstItem, indexOfLastItem); // MAP 的資料
    const renderPageNumbers = pages.map((item) => {
        if (item < maxPageNumberLimit + 1 && item > minPageNumberLimit) {
            return (
                <li className={`page-item`} key={item} id={item} onClick={handleCurrentPage}>
                    <a href="#" className={`page-link ${currentPage === item ? "page-active" : ""}`} id={item}>{item}</a>
                </li>
            )
        } else {
            return null
        }
    })

    const handleNextBtn = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    }
    const handlePretBtn = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    }

    let pageAddBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageAddBtn = <li className={`page-item`} >
            <a href="#" className={`page-link px-2`} onClick={handleNextBtn}>&hellip;</a>
        </li>
    }
    let pageReduceBtn = null;
    if (minPageNumberLimit >= 1) {
        pageReduceBtn = <li className={`page-item`} >
            <a href="#" className={`page-link px-2`} onClick={handlePretBtn}>&hellip;</a>
        </li>
    }
    //分頁邏輯 end

    const handleStateRadioChange = (e) => {
        let { value } = e.target;
        setStateRadio(value);
        setFilterData([])
        setCurrentPage(Number(1))
    }

    useEffect(() => {
    }, [currentPage]);

    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        downloadtoBase64url('Notify/file=', dataset.filename, setIsLoading)
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <BackgroundWrapper>
                <div className="position-fixed w-100 bg-light" style={{ zIndex: '30' }}>
                    <div className="top-nav w-100 py-3">
                        <p className="text-light text-center fw-bolder">照會查詢</p>
                    </div>
                    <div className="container">
                        <div className="d-flex my-3" onChange={handleStateRadioChange}>
                            <div className="form-check px-4">
                                <input className="form-check-input" type="radio" name="stateRadio" value="all" id="all" checked={stateRadio === 'all' ? 'checked' : ''} />
                                <label className="form-check-label" htmlFor="all">
                                    全部
                                </label>
                            </div>
                            <div className="form-check px-4">
                                <input className="form-check-input" type="radio" name="stateRadio" value="not" id="not" checked={stateRadio === 'not' ? 'checked' : ''} />
                                <label className="form-check-label" htmlFor="not">
                                    未處理
                                </label>
                            </div>
                            <div className="form-check px-4">
                                <input className="form-check-input" type="radio" name="stateRadio" value="done" id="done" checked={stateRadio === 'done' ? 'checked' : ''} />
                                <label className="form-check-label" htmlFor="done">
                                    已處理
                                </label>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className="d-table" style={{ height: '130px' }}></div>
                <div className="container">
                    <div className="table-responsive-md text-center">
                        <table className="table table-striped">
                            {/* <FontAwesomeIcon icon={faExclamationTriangle} className="me-2 text-danger" /> */}
                            <thead>
                                <tr>
                                    <th style={{ whiteSpace: 'nowrap' }}>狀態</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>照會日</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>保險公司</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>要保人</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>被保人</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>照會檔</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={nodataText === '無資料' ? '' : 'd-none'}>
                                    <td className={`text-start ${nodataText === '無資料' ? '' : 'd-none'}`} colSpan='8'>{nodataText}</td>
                                </tr>
                                {
                                    currentItems.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className="text-center text-dark-blue">
                                                    <FontAwesomeIcon icon={item['狀態'] === 'TRUE' ? faCheckCircle : faExclamationTriangle} className={`me-2 ${item['狀態'] === 'TRUE' ? 'text-dark-blue' : 'text-danger'}`} />
                                                </td>
                                                <td className="text-dark-blue fw-bolder" style={{ whiteSpace: 'nowrap' }}>{dateChange(item['照會日'])}</td>
                                                <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item['保險公司']}</td>
                                                <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item['要保人']}</td>
                                                <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item['被保人']}</td>
                                                <td className="text-golden-brown">
                                                    <a href="#" data-download={`${item.INS_DLV_DOC_PATH}/${item['照會檔案']}`} data-filename={`${item.INS_DLV_DOC_PATH}/${item['照會檔案']}`} className="d-block text-golden-brown paperclip-icon" onClick={handleDownloadFile}></a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="py-3">
                    <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center">
                            <li className={`page-item`} >
                                <a href="#" className={`page-link ${currentPage === pages[0] ? "page-disabled d-none" : ""}`} onClick={handlePretBtn}><FontAwesomeIcon icon={faChevronLeft} /></a>
                            </li>
                            {pageReduceBtn}
                            {renderPageNumbers}
                            {pageAddBtn}
                            <li className={`page-item`} >
                                <a href="#" className={`page-link ${currentPage === pages[pages.length - 1] ? "page-disabled d-none" : ""}`} onClick={handleNextBtn}><FontAwesomeIcon icon={faChevronRight} /></a>
                            </li>
                        </ul>
                        {/* <div className="d-flex justify-content-center my-3">
                            <button type="button" className={`btn btn-outline-dark-blue text-center ${currentItems.length < 10 || currentItems.length == resultData.length ? 'd-none' : 'd-block'}`} onClick={handleLoadMore}>一次瀏覽更多</button>
                        </div> */}
                    </nav>
                </div>
            </BackgroundWrapper>
        </>
    )
}

export default Notification