import React from 'react';
import ReactDOM from 'react-dom';
import './assets/stylesheet/all.scss';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import "react-datepicker/dist/react-datepicker.css";
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
);

reportWebVitals();
