import React, { useState, useEffect } from 'react';
import axios from 'axios';

// component
import Loading from '../../components/Loading';
import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc'
import DownloadFile from '../../assets/javascript/downloadFile';

const ProductSummary = () => {
    let [isLoading, setIsLoading] = useState(true); //判斷 loading

    let [allData, setAllData] = useState([])
    // GET API
    useEffect(() => {
        LineLogin('/products/main4'); //LINE 登入狀態驗證
        Countfunc('主力商品_推薦總表', 17)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=4`;
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/products/main4');
                        window.location.href = '/login'
                        return
                    } else {
                        setIsLoading(false);
                        setAllData(res.data);
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, [])
    // GET API END

    //資料處裡
    let filterData = [];
    const splitData = (data) => {
        let splitAry = [];
        data.forEach((item, index) => {
            splitAry.push(item.split('/'))
        });
        splitAry.forEach((item, index) => {
            filterData.push({
                title: item[0],
                fileName: item[1]
            })
        })
    }
    splitData(allData)

    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/products/main4' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setIsLoading)
    }

    //資料處裡 END
    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-4">
                <ul className="list-unstyled page-content-link">
                    {
                        filterData.map((item, index) => {
                            if (item.fileName !== '') {
                                return (
                                    <li className="border-bottom py-3" key={item.fileName}>
                                        <a href="#" data-download={`推薦總表/${item.fileName}`} data-filename={item.fileName} className="d-block fw-bolder file-icon" onClick={handleDownloadFile}>
                                            {item.fileName}
                                        </a>
                                    </li>
                                )
                            }
                            return true
                        })
                    }
                </ul>
            </div>
        </>
    )
}

export default ProductSummary