import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ReactEcharts from "echarts-for-react";
import Loading from 'components/Loading';
import moment from 'moment'
import dateChange from 'assets/javascript/dateChange';
import saveImg from "assets/javascript/saveStatistics";

const PushStatistics = (props) => {
    //登入角色判斷
    useEffect(() => {
        let loginRole = localStorage.getItem('role');
        if (loginRole === '1' || loginRole === '2') {
            window.location.href = '/dashboard/message_center'
        }
    }, []);

    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState([])
    let day = dateChange(moment(new Date()).add(-1, 'days')._d)
    useEffect(() => {
        let API = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/GetPushSum`
        axios.get(API)
            .then((res) => {
                setData(res.data)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);
    const getOption = () => ({
        color: ['#e07b42', '#dab026', '#89a7c2', '#c1395e', '#aec17b', '#8F7140', '#315176'],
        series: [
            {
                name: "細項",
                type: "pie",
                radius: "55%",
                center: ["50%", "35%"],
                animationDuration: 2000,
                data: [
                    { value: Number(data.SENDINS), name: `發單：${Number(data.SENDINS)}` },
                    { value: Number(data.NOTE), name: `照會：${Number(data.NOTE)}` },
                    { value: Number(data.PAY), name: `繳費：${Number(data.PAY)}` },
                    { value: Number(data.SALARY), name: `薪資：${Number(data.SALARY)}` },
                    { value: Number(data.PERFORMANCE), name: `績效：${Number(data.PERFORMANCE)}` },
                    { value: Number(data.RECEIPT), name: `回條：${Number(data.RECEIPT)}` },
                    { value: Number(data.LICENSE), name: `換證：${Number(data.LICENSE)}` }
                ],
                label: {
                    textStyle: {
                        fontSize: '18px',
                    },
                },
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    }
                },
            }
        ]
    });

    useEffect(() => {
        saveImg();
    }, [])

    return (
        <>
            <Loading isLoading={isLoading} />
            <h2 className='fs-4 text-dark-blue fw-bolder p-3'>{props.title}</h2>
            <div className="container pt-2 px-3 position-relative">
                <div className="row" id="downloadJPG">
                    <h1 className='text-center fw-bolder mb-3 fs-3'>{day}相關數據統計</h1>
                    <hr />
                    <div className="col-12 col-lg-8 mx-auto">
                        <ReactEcharts
                            option={getOption()}
                            style={{ height: "550px", width: "100%" }}
                        />
                    </div>
                    <div className="col-12 col-lg-3 mx-auto">
                        <ul className='list-unstyled'>
                            <li className="push-item" style={{ background: '#e07b42' }}>發單：{data.SENDINS}</li>
                            <li className="push-item" style={{ background: '#dab026' }}>照會：{data.NOTE}</li>
                            <li className="push-item" style={{ background: '#89a7c2' }}>繳費：{data.PAY}</li>
                            <li className="push-item" style={{ background: '#c1395e' }}>薪資：{data.SALARY}</li>
                            <li className="push-item" style={{ background: '#aec17b' }}>績效：{data.PERFORMANCE}</li>
                            <li className="push-item" style={{ background: '#8F7140' }}>回條：{data.RECEIPT}</li>
                            <li className="push-item" style={{ background: '#315176' }}>換證：{data.LICENSE}</li>
                        </ul>
                    </div>
                </div>
                <div className="text-center position-absolute" style={{ right: '134px', top: '0' }}>
                    <button type="button" className="btn btn-dark-blue my-2 download-icon" id="save-btn">下載</button>
                </div>
            </div>
            <div id="img-out" className="ecard-img mx-5 pt-5 text-center"></div>
            <div id="text"></div>
        </>
    )
}
export default PushStatistics