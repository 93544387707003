import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
//components
import Loading from "components/Loading";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AlertMes from "components/Alert";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RecommendProduct = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [addShow, setAddShow] = useState(false); // 新增群組
  const [addProduct, setAddProduct] = useState(false); // 新增推薦商品
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [allGroup, setAllGroup] = useState([]); // 所有自訂群組
  const [token, setToken] = useState("");
  const [editShow, setEditShow] = useState(false); // 顯示編輯談窗
  const [groupId, setGroupId] = useState(""); // 取得群組ID
  const [newGroupName, setNewGroupName] = useState(""); // 新群組名稱
  const [defaultGroupName, setDefaultGroupName] = useState(""); // 原群組名稱
  const [searchKeyword, setSearchKeyword] = useState(""); // 搜尋新增公司關鍵字
  const [products, setProducts] = useState([]); // 取得自訂群組商品
  const [insPRD, setInsPRD] = useState([]); // 取得保險公司所有商品
  const [filterInsPRD, setFilterInsPRD] = useState([]); // 取得保險公司所有商品
  const [allInsCompany, setAllInsCompany] = useState([]); // 所有保險公司名稱
  const [filterInsCompany, setFilterInsCompany] = useState([]); // 過濾符合搜尋的公司名稱
  const [showList, setShowList] = useState(false); // 顯示保險公司列表
  const [insName, setInsName] = useState(""); // 顯示表格保險公司名稱
  const [searchCode, setSearchCode] = useState(""); // 搜尋新增商品關鍵字
  const collapseRef = useRef([]); // 收合容器
  const collapseBTNRef = useRef([]); // 收合按鈕
  const [currentPage, setCurrentPage] = useState(1); // 目前頁數
  const itemsPerPage = 10; // 一頁10筆
  const [alertMes, setAlertMes] = useState({
    mes: "",
    show: false,
    color: "transparent",
  });

  const handleAPI = {
    // 取得保險公司名稱
    getInsName: function () {
      let API = `${process.env.REACT_APP_GOLDEN_NPS_API}get_insurance_name`;
      axios
        .get(API)
        .then((res) => {
          setAllInsCompany(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取得保險公司商品
    getInsPRD: function (e, insId) {
      let API = `${process.env.REACT_APP_GOLDEN_NPS_API}get_insurance_prdlist/${insId}`;
      axios
        .get(API)
        .then((res) => {
          setInsPRD(res.data.data);
          setFilterInsPRD(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 登入
    getLogin: function () {
      let API = `${process.env.REACT_APP_RECOMMENDPRD_API}i/Login`;
      axios
        .post(API, {
          UserId: "GoldenNetService",
          UserPwd: "331EgSjr",
        })
        .then((res) => {
          if (res.status === 200) {
            setToken(res.data.data.accessToken);
            handleAPI.getAllInsGroup(res.data.data.accessToken);
          }
        })
        .catch((err) => {
          alert("無法取得資料");
          setIsLoading(false);
        });
    },
    // 取得公勝推薦群組
    getAllInsGroup: function (data) {
      let API = `${process.env.REACT_APP_RECOMMENDPRD_API}/m/ProductGroupClient/GoldenNet/GetAll`;
      axios
        .get(API, {
          headers: {
            Authorization: `Bearer ${data}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setAllGroup(res.data.data);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          alert("取得公勝推薦群組失敗");
          console.log(err);
          setIsLoading(false);
        });
    },
    // 新增群組
    addNewGroup: function (data) {
      let API = `${process.env.REACT_APP_RECOMMENDPRD_API}m/ProductGroupClient/GoldenNet`;
      setAddBtnLoading(true);
      axios
        .post(
          API,
          { GroupName: data.Group_Name },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            reset();
            setAddBtnLoading(false);
            setAddShow(false);
            handleAPI.getLogin();
            setAlertMes({
              mes: `新增成功`,
              show: true,
              color: "primary",
            });
          }
        })
        .catch((err) => {
          alert("新增失敗");
          console.log(err);
          setIsLoading(false);
        });
    },
    // 儲存新群組名稱
    saveGroup: function () {
      let API = `${process.env.REACT_APP_RECOMMENDPRD_API}m/ProductGroupClient/GoldenNet/${groupId}`;
      setAddBtnLoading(true);
      axios
        .put(
          API,
          { GroupName: newGroupName },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            reset();
            setAddBtnLoading(false);
            setEditShow(false);
            handleAPI.getLogin();
          }
        })
        .catch((err) => {
          alert("新增失敗");
          console.log(err);
          setIsLoading(false);
        });
    },
    // 刪除群組
    delGroup: function (e, groupId) {
      let API = `${process.env.REACT_APP_RECOMMENDPRD_API}m/ProductGroupClient/${groupId}`;
      setAddBtnLoading(true);
      axios
        .delete(API, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            setAddBtnLoading(false);
            collapseRef.current[groupId].className =
              "accordion-collapse collapse";
            collapseBTNRef.current[groupId].className =
              "accordion-button collapsed";
            handleAPI.getLogin();
            alert("刪除成功");
          }
        })
        .catch((err) => {
          alert("刪除失敗");
          console.log(err);
          setIsLoading(false);
        });
    },
    // 取得公勝推薦商品
    getRecommendPRD: function (e, groupId) {
      let API = `${process.env.REACT_APP_RECOMMENDPRD_API}m/ProductGroupDetail/${groupId}/GetAll`;
      setAddBtnLoading(true);
      axios
        .get(API, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            setAddBtnLoading(false);
            setProducts(res.data.data);
          }
        })
        .catch((err) => {
          alert("取得失敗");
          console.log(err);
          setIsLoading(false);
        });
    },
    // 新增推薦商品
    addRecommendPRD: function (e, item) {
      const productId = allInsCompany
        ?.filter((item) => item.PRD_SUP_ALIAS === searchKeyword)
        .map((item) => item.OLD_ID);

      let API = `${process.env.REACT_APP_RECOMMENDPRD_API}m/ProductGroupDetail/GoldenNet`;
      axios
        .post(
          API,
          {
            groupId: groupId,
            companyId: productId.toString(),
            policyId: item.PRD_MST_GMI_CODE,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.data.isSuccess === true) {
            handleAPI.getRecommendPRD(e, groupId);
            setAlertMes({
              mes: `新增成功`,
              show: true,
              color: "primary",
            });
          } else {
            alert("新增失敗");
          }
        })
        .catch((err) => {
          alert("新增失敗");
          console.log(err);
          setIsLoading(false);
          setAddBtnLoading(false);
        });
    },
    // 刪除推薦商品
    delRecommendPRD: function (e, item) {
      let API = `${process.env.REACT_APP_RECOMMENDPRD_API}m/ProductGroupDetail/${item.detailId}`;
      axios
        .delete(API, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          handleAPI.getLogin();
          handleAPI.getRecommendPRD(e, item.groupId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };
  useEffect(() => {
    setIsLoading(true);
    handleAPI.getLogin();
    handleAPI.getInsName();
    if(!searchCode){
      setFilterInsPRD(insPRD)
    };
  }, [searchCode]);
  // 表單驗證_新增群組
  const validationSchema = Yup.object().shape({
    Group_Name: Yup.string().required("群組名稱不能為空白"),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  // 顯示新增群組談窗
  const handleShow = () => {
    if (allGroup.length >= 10) {
      setAlertMes({ mes: `最多只能十種組合`, show: true, color: "danger" });
    } else {
      setAddShow(true);
    }
  };
  // 關閉新增群組談窗
  const handleClose = () => {
    reset();
    setAddShow(false);
  };

  // 顯示新增建議商品談窗
  const handleRecommended = (e, id) => {
    setAddProduct(true);
  };
  // 關閉新增推薦商品談窗
  const handleRecommendedClose = () => {
    setSearchKeyword("");
    setFilterInsPRD([]);
    setInsPRD([]);
    setAddProduct(false);
    setSearchCode("");
    setCurrentPage(1);
  };

  // 顯示編輯群組談窗
  const handleEditGroup = (e, id) => {
    setEditShow(true);
    setGroupId(id);
    allGroup
      .filter((item) => item.groupId === id)
      .map((item) => {
        setDefaultGroupName(item.groupName);
      });
  };
  // 關閉編輯群組談窗
  const handleEditClose = () => {
    reset();
    setEditShow(false);
  };

  const onSubmit = (data) => {
    handleAPI.addNewGroup(data);
  };

  // 新群組名稱
  const handleChangeGroupName = (e) => {
    setNewGroupName(e.target.value);
  };
  // 搜尋關鍵字被點擊，展開列表
  const handleClick = () => {
    setShowList(true);
    setFilterInsCompany(allInsCompany?.map((item) => item));
  };
  // 搜尋關鍵字blur，關閉列表
  const handleBlur = () => {
    setTimeout(() => {
      setShowList(false);
    }, 300);
  };

  // 關鍵字
  const handleSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
    setSearchCode("");
    setCurrentPage(1)
    // 篩選出有關鍵字的公司
    setTimeout(() => {
      if (e.target.value) {
        setFilterInsCompany(
          allInsCompany
            ?.filter((item) => item.PRD_SUP_ALIAS.includes(e.target.value))
            .map((item) => item)
        );
      }
    }, 500);
  };
  // 關鍵字
  const handleSearchCode = (e) => {
    setSearchCode(e.target.value);
    setCurrentPage(1);
    // 篩選出有關鍵字的公司
    setTimeout(() => {
      if (e.target.value) {
        setFilterInsPRD(
          insPRD?.filter(
            (item) =>
              item.PRD_MST_GMI_CODE.toLowerCase().includes(
                e.target.value.toLowerCase()
              ) ||
              item.PRD_MST_PRO_NAME.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
          )
        );
      }
    }, 500);
  };

  // 選擇保險公司
  const selectInsCompany = (e, item) => {
    setSearchKeyword(item.PRD_SUP_ALIAS);
    handleAPI.getInsPRD(e, item.PRD_SUP_ID);
    setInsPRD(e, item.OLD_ID);
    setInsName(item.PRD_SUP_ALIAS);
    setSearchCode("");
  };
  // 新增商品到群組
  const addToGroup = (e, item) => {
    handleAPI.addRecommendPRD(e, item);
  };

  // 推薦新增商品總頁數
  const totalPages = Math.ceil(filterInsPRD.length / itemsPerPage);

  // 顯示當前頁數的資料
  const currentData = filterInsPRD.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // 切換到指定頁面
  const handlePageChange = (e) => {
    setCurrentPage(Number(e.target.value));
  };
  // 上一頁
  const Previous = (e) => {
    setCurrentPage(currentPage - 1);
  };
  // 下一頁
  const Next = (e) => {
    setCurrentPage(currentPage + 1);
  };
  return (
    <>
      <Loading isLoading={isLoading} />
      <AlertMes
        mes={alertMes.mes}
        show={alertMes.show}
        color={alertMes.color}
        setAlertMes={setAlertMes}
      />
      <h2 className="fs-4 text-dark-blue fw-bolder p-3">{props.title}</h2>
      <div className="position-relative px-5">
        <div className="d-flex align-items-center">
          <button
            className="btn btn-dark-blue add-btn d-flex align-items-center"
            onClick={handleShow}
          >
            <span className="ms-2 fw-bolder">新增群組</span>
          </button>
          <small className="ms-2">最多新增十組</small>
        </div>
        <h3 className="fs-5 text-dark-blue fw-bolder my-4">自訂組合</h3>
        <div className="accordion dropdown-table" id="accordionExample">
          <div className="accordion-item">
            <div className="table-responsive-xxl text-center">
              <table className="table table-hover trigger">
                <thead className="text-golden-brown">
                  <tr>
                    <th
                      className="text-start"
                      style={{ whiteSpace: "nowrap", width: "40px" }}
                    ></th>
                    <th style={{ whiteSpace: "nowrap", width: "60px" }}>
                      編號
                    </th>
                    <th className="text-start" style={{ whiteSpace: "nowrap" }}>
                      群組名稱
                    </th>
                    <th
                      className="text-start "
                      style={{ whiteSpace: "nowrap" }}
                      colSpan={2}
                    >
                      功能
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allGroup.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        尚無商品
                      </td>
                    </tr>
                  )}
                  {allGroup?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr className="fw-bolder">
                          <td className="text-start">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                onClick={(e) => {
                                  handleAPI.getRecommendPRD(e, item.groupId);
                                }}
                                ref={(el) =>
                                  (collapseBTNRef.current[item.groupId] = el)
                                }
                              ></button>
                            </h2>
                          </td>
                          <td>{index + 1}</td>
                          <td className="text-start">{item.groupName}</td>
                          <td className="text-start">
                            <button
                              className="btn p-0 mx-1 edit-btn"
                              data-fun="edit"
                              onClick={(e) => handleEditGroup(e, item.groupId)}
                            ></button>
                            <button
                              className="btn p-0 ms-5 del-btn text-danger"
                              data-fun="delete"
                              onClick={(e) =>
                                handleAPI.delGroup(e, item.groupId)
                              }
                            ></button>
                          </td>
                        </tr>
                        <tr
                          id={`collapse${index}`}
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                          // ref={collapseRef}
                          ref={(el) => (collapseRef.current[item.groupId] = el)}
                        >
                          <td className="text-start" colSpan={4}>
                            <div
                              id={`collapse${index}`}
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                              // ref={collapseRef[index]}
                              ref={(el) =>
                                (collapseRef.current[item.groupId] = el)
                              }
                            >
                              <div className="accordion-body">
                                <div className="table-responsive-xxl text-center">
                                  <table className="table table-striped table-light">
                                    <thead>
                                      <tr>
                                        <th
                                          width="130"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          保險公司編號
                                        </th>
                                        <th
                                          width="200"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          保險公司
                                        </th>
                                        <th
                                          width="130"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          商品代碼
                                        </th>
                                        <th
                                          className="text-start"
                                          width="130"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          商品名稱
                                        </th>
                                        <th
                                          className="text-start"
                                          width="130"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          功能
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {!products && (
                                        <tr>
                                          <td colSpan={5}>尚無商品</td>
                                        </tr>
                                      )}
                                      {products?.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td
                                              className="text-dark-blue fw-bolder fs-6"
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {item.companyId}
                                            </td>
                                            <td
                                              className="text-dark-blue"
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {item.companyName}
                                            </td>
                                            <td
                                              className="text-dark-blue"
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {item.policyId}
                                            </td>
                                            <td
                                              className="text-dark-blue text-start"
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {item.productName}
                                            </td>
                                            <td className="text-start">
                                              <button
                                                className="btn p-0 del-btn text-danger"
                                                data-fun="delete"
                                                onClick={(e, id) =>
                                                  handleAPI.delRecommendPRD(
                                                    e,
                                                    item
                                                  )
                                                }
                                              ></button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                  <button
                                    className="btn btn-outline-dark-blue mx-auto my-2"
                                    onClick={(e) => {
                                      handleRecommended(e, item.groupId);
                                      setGroupId(item.groupId);
                                    }}
                                  >
                                    新增推薦商品
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <hr />
        <h3 className="fs-5 text-dark-blue fw-bolder my-4">瀏覽公勝推薦商品</h3>
        <div className="form-group my-3 position-relative">
          <input
            id="Keyword"
            name="Keyword"
            type="text"
            className="form-control"
            value={searchKeyword}
            placeholder="請輸入保險公司名稱"
            onChange={handleSearchKeyword}
            onClick={handleClick}
            onBlur={handleBlur}
          />
          {searchKeyword.length > 0 && (
            <input
              id="Code"
              name="Code"
              type="text"
              className="form-control mt-2"
              value={searchCode}
              placeholder="請輸入商品代碼或名稱"
              onChange={handleSearchCode}
              onKeyPress={(e) => {
                if (e.key.toLowerCase() === "enter") {
                  e.preventDefault();
                }
              }}
            />
          )}
          <div
            className={`${
              showList ? "d-flex" : "d-none"
            } flex-column bg-white position-absolute w-100 p-1`}
            style={{
              maxHeight: "20vh",
              overflow: "auto",
              border: "1px solid #ced4da",
              borderRadius: "0.375rem",
              top: "40px",
            }}
          >
            {filterInsCompany.length === 0 && <small>查無保險公司</small>}
            {filterInsCompany.length > 0 && (
              <>
                {filterInsCompany?.map((item, index) => {
                  return (
                    <button
                      className="btn btn-outline-primary py-1 text-start"
                      type="button"
                      onClick={(e) => selectInsCompany(e, item)}
                      key={index}
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        border: "1px solid transparent",
                      }}
                    >
                      {item.PRD_SUP_ALIAS}
                    </button>
                  );
                })}
              </>
            )}
          </div>
        </div>
        <div className="table-responsive-xxl text-center mb-3">
          <table className="table table-hover table-striped">
            <thead className="text-golden-brown">
              <tr>
                <th className="text-start" style={{ whiteSpace: "nowrap" }}>
                  保險公司
                </th>
                <th className="text-start" style={{ whiteSpace: "nowrap" }}>
                  商品代碼
                </th>
                <th className="text-start" style={{ whiteSpace: "nowrap" }}>
                  商品名稱
                </th>
              </tr>
            </thead>
            <tbody>
              {filterInsPRD.length === 0 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    尚無商品
                  </td>
                </tr>
              )}
              {filterInsPRD.length > 0 && (
                <>
                  {currentData?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr className="fw-bolder">
                          <td className="text-start">{insName}</td>
                          <td className="text-start">
                            {item.PRD_MST_GMI_CODE}
                          </td>
                          <td className="text-start">
                            {item.PRD_MST_PRO_NAME}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
          {filterInsPRD.length > 9 && (
            <nav
              aria-label="Page navigation example"
              className=" d-flex align-items-center justify-content-center"
            >
              {currentPage > 1 && (
                <div className="pagination">
                  <div className="page-item">
                    <a
                      className="page-link"
                      onClick={Previous}
                      aria-label="Previous"
                      href="#"
                    >
                      <span aria-hidden="true">
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </span>
                    </a>
                  </div>
                </div>
              )}
              第
              <select
                className="form-select w-25 mx-2"
                name=""
                id=""
                value={currentPage}
                onChange={handlePageChange}
              >
                {Array.from({ length: totalPages }, (_, index) => (
                  <option key={index}>{index + 1}</option>
                ))}
              </select>
              頁
              {currentPage < totalPages && (
                <div className="pagination">
                  <div className="page-item">
                    <a
                      className="page-link"
                      onClick={Next}
                      href="#"
                      aria-label="Next"
                    >
                      <span aria-hidden="true">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                    </a>
                  </div>
                </div>
              )}
            </nav>
          )}
        </div>
      </div>
      {/* 新增談窗 */}
      <Modal show={addShow} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>新增群組</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group my-3">
              <label htmlFor="Group_Name">群組名稱</label>
              <input
                id="Group_Name"
                name="Group_Name"
                type="text"
                placeholder="請輸入群組名稱"
                {...register("Group_Name")}
                className={`form-control ${
                  errors.Group_Name ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors.Group_Name?.message}
              </div>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-outline-golden-brown mx-1"
                onClick={handleClose}
              >
                關閉
              </button>
              <button
                type="submit"
                className="btn btn-golden-brown mx-1"
                disabled={addBtnLoading ? "disabled" : ""}
              >
                <div
                  className={`spinner-border text-light spinner-border--width me-2 ${
                    addBtnLoading ? "d-inline-block" : "d-none"
                  }`}
                  role="status"
                  style={{
                    width: "1rem",
                    height: "1rem",
                  }}
                ></div>
                儲存
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* 編輯談窗 */}
      <Modal show={editShow} onHide={handleEditClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>編輯群組</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <form>
            <div className="form-group my-3">
              <label htmlFor="edit_Group">群組名稱</label>
              <input
                id="edit_Group"
                name="edit_Group"
                type="text"
                className="form-control"
                defaultValue={defaultGroupName}
                onChange={handleChangeGroupName}
              />
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-outline-golden-brown mx-1"
                onClick={handleEditClose}
              >
                關閉
              </button>
              <button
                type="button"
                className="btn btn-golden-brown mx-1"
                disabled={addBtnLoading ? "disabled" : ""}
                onClick={() => handleAPI.saveGroup()}
              >
                <div
                  className={`spinner-border text-light spinner-border--width me-2 ${
                    addBtnLoading ? "d-inline-block" : "d-none"
                  }`}
                  style={{
                    width: "1rem",
                    height: "1rem",
                  }}
                ></div>
                儲存
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* 新增推薦商品 */}
      <Modal show={addProduct} onHide={handleRecommendedClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>新增推薦商品</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <form>
            <div className="form-group my-3 position-relative">
              <input
                id="Keyword"
                name="Keyword"
                type="text"
                className="form-control"
                value={searchKeyword}
                placeholder="請輸入保險公司名稱"
                onChange={handleSearchKeyword}
                onClick={handleClick}
                onBlur={handleBlur}
                onKeyPress={(e) => {
                  if (e.key.toLowerCase() === "enter") {
                    e.preventDefault();
                  }
                }}
              />
              {searchKeyword.length > 0 && (
                <input
                  id="Code"
                  name="Code"
                  type="text"
                  className="form-control mt-2"
                  value={searchCode}
                  placeholder="請輸入商品代碼或名稱"
                  onChange={handleSearchCode}
                  onKeyPress={(e) => {
                    if (e.key.toLowerCase() === "enter") {
                      e.preventDefault();
                    }
                  }}
                />
              )}
              <div
                className={`${
                  showList ? "d-flex" : "d-none"
                } flex-column bg-white position-absolute w-100 p-1`}
                style={{
                  maxHeight: "20vh",
                  overflow: "auto",
                  border: "1px solid #ced4da",
                  borderRadius: "0.375rem",
                  top: "40px",
                }}
              >
                {filterInsCompany.length === 0 && <small>查無保險公司</small>}
                {filterInsCompany.length > 0 && (
                  <>
                    {filterInsCompany?.map((item, index) => {
                      return (
                        <button
                          className="btn btn-outline-primary py-1 text-start"
                          type="button"
                          onClick={(e) => selectInsCompany(e, item)}
                          key={index}
                          style={{
                            textDecoration: "none",
                            fontSize: "14px",
                            border: "1px solid transparent",
                          }}
                        >
                          {item.PRD_SUP_ALIAS}
                        </button>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <div className="mb-3" style={{ height: "50vh", overflow: "auto" }}>
              <table className="table table-hover table-striped">
                <thead className="text-golden-brown">
                  <tr>
                    <th
                      className="text-start"
                      style={{ whiteSpace: "nowrap", width: "80px" }}
                    ></th>
                    <th className="text-start" style={{ whiteSpace: "nowrap" }}>
                      保險公司
                    </th>
                    <th className="text-start" style={{ whiteSpace: "nowrap" }}>
                      商品代碼
                    </th>
                    <th className="text-start" style={{ whiteSpace: "nowrap" }}>
                      商品名稱
                    </th>
                  </tr>
                </thead>
                <tbody style={{ verticalAlign: "middle", overflow: "auto" }}>
                  {filterInsPRD.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        尚無商品
                      </td>
                    </tr>
                  )}
                  {filterInsPRD.length > 0 && (
                    <>
                      {currentData?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr className="fw-bolder">
                              <td className="text-start">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary"
                                  style={{ width: "60px" }}
                                  onClick={(e) => addToGroup(e, item)}
                                >
                                  新增
                                </button>
                              </td>
                              <td className="text-start">{insName}</td>
                              <td className="text-start">
                                {item.PRD_MST_GMI_CODE}
                              </td>
                              <td className="text-start">
                                {item.PRD_MST_PRO_NAME}
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {filterInsPRD.length > 9 && (
              <nav
                aria-label="Page navigation example"
                className=" d-flex align-items-center justify-content-center"
              >
                {currentPage > 1 && (
                  <div className="pagination">
                    <div className="page-item">
                      <a
                        className="page-link"
                        onClick={Previous}
                        aria-label="Previous"
                        href="#"
                      >
                        <span aria-hidden="true">
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </span>
                      </a>
                    </div>
                  </div>
                )}
                第
                <select
                  className="form-select w-25 mx-2"
                  name=""
                  id=""
                  value={currentPage}
                  onChange={handlePageChange}
                >
                  {Array.from({ length: totalPages }, (_, index) => (
                    <option key={index}>{index + 1}</option>
                  ))}
                </select>
                頁
                {currentPage < totalPages && (
                  <div className="pagination">
                    <div className="page-item">
                      <a
                        className="page-link"
                        onClick={Next}
                        href="#"
                        aria-label="Next"
                      >
                        <span aria-hidden="true">
                          <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                      </a>
                    </div>
                  </div>
                )}
              </nav>
            )}
            <div className="text-end">
              <button
                type="button"
                className="btn btn-outline-golden-brown mx-1"
                onClick={handleRecommendedClose}
              >
                關閉
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default RecommendProduct;
