import axios from "axios";
import React, { useState, useEffect } from "react";
import InsideNav from '../../../components/InsideNav';
import Loading from "../../../components/Loading";

import { toCurrency } from "../../../assets/javascript/calTool";
import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'

const PerformanceRank = () => {
    const insideNavItem = [
        {
            text: '日',
        },
        {
            text: '月',
        },
        {
            text: '年',
        },
    ];

    useEffect(() => {
        LineLogin('/performance_rank'); //LINE 登入狀態驗證
        Countfunc('競賽業績_業績排行', 29)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}performance_rank`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/performance_rank');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setInitData(res.data)
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);

    //@ VALUE
    let [isLoading, setIsLoading] = useState(true)
    let [selectOption, setSelectOption] = useState('個人')
    let [option, setOption] = useState("日")
    let [initData, setInitData] = useState(null) //初始資料

    let [showData, setShowData] = useState(null),
        [showPersonData, setShowPersonData] = useState(null)


    //@ EVENT
    const handleEvent = {
        textToCode: function (type, key) { //文字轉換 code
            if (type == 'select') { //下拉選單 - 類型
                switch (key) {
                    case '個人': return 'Person'
                    case '事業部': return 'Div'
                    case '業務中心': return 'Center'
                    default: break;
                }
            }

            if (type == 'option') { //選單 - 年月日
                switch (key) {
                    case '年': return 'Y'
                    case '月': return 'M'
                    case '日': return 'D'
                    default: break;
                }
            }
        },
        getData: function () {
            let type = handleEvent.textToCode('select', selectOption), //類型
                dateType = handleEvent.textToCode('option', option), //年月日
                thisData = initData[type][dateType]


            //@ 抓個人 data - 個人或事業部
            if (type == 'Person' || type == 'Div') {
                let thisPersonData = initData[type][`Person_${dateType}`]
                setShowPersonData(thisPersonData)
            }

            //@ 計算所有業務中心 加總 FYC FYA 件數
            let headquartersSum = {
                fyc: 0,
                fya: 0,
                qty: 0
            }
            if (type == 'Center') {
                thisData.map((item) => {
                    headquartersSum.fyc += item?.fyc
                    headquartersSum.fya += item?.fya
                    headquartersSum.qty += item?.qty
                })
            }


            //@ 抓排行表
            setShowData({
                data: thisData,
                headquarters: headquartersSum
            })
        }
    }

    //select option
    const handleSelectOption = (e) => {
        let { value } = e.target
        setSelectOption(value)
    }

    //insideNav
    const handleClick = (e) => {
        let { innerText, nodeName } = e.target;
        if (nodeName !== 'A') {
            return
        }
        setOption(innerText)
    }

    useEffect(() => {
        if (initData !== null) {
            if (selectOption !== null && option !== null) {
                handleEvent.getData()
            }
        }
    }, [selectOption, option, initData])

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="d-flex pb-2 top-select px-2 pt-3 position-fixed w-100 bg-light" style={{ zIndex: '100' }}>
                <select className="form-select" value={selectOption} onChange={handleSelectOption}>
                    <option value="個人">個人</option>
                    <option value="事業部">事業部</option>
                    <option value="業務中心">業務中心</option>
                </select>
            </div>
            <div className="d-table bg-light" style={{ height: '60px' }}></div>
            <InsideNav insideNavItem={insideNavItem} handleClick={handleClick} option={option} />
            {
                showData !== null &&
                <>
                    <div className={`container mt-3`}>
                        <div className="d-flex justify-content-between fw-bolder text-dark-blue">
                            <p>每{option} {selectOption} FYC 排行</p>
                            <p className={`${selectOption === '業務中心' ? 'd-none' : ''}`}>佔總FYC {showData?.data[0]?.fycPercentTop20?.toFixed(2)}%</p>
                        </div>
                        <div className="table-responsive-xxl text-center">
                            <table className="table table-striped" style={{ zIndex: '10' }}>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>排名</th>
                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>業務中心</th>
                                        <th className={`text-center ${selectOption == '個人' || selectOption == '事業部' ? '' : 'd-none'}`} style={{ whiteSpace: 'nowrap' }}>事業部</th>
                                        <th className={`text-center ${selectOption == '個人' ? '' : 'd-none'}`} style={{ whiteSpace: 'nowrap' }}>業務員姓名</th>
                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>FYC</th>
                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>FYA</th>
                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>件數</th>
                                    </tr>
                                </thead>
                                <tbody className="fw-bolder">
                                    {
                                        selectOption == '業務中心' &&
                                        <tr>
                                            <td className="text-golden-brown" style={{ whiteSpace: 'nowrap' }}>全公司</td>
                                            <td className="text-golden-brown" style={{ whiteSpace: 'nowrap' }}></td>
                                            <td className="text-golden-brown" style={{ whiteSpace: 'nowrap' }}>{toCurrency(Math.round(showData.headquarters.fyc))}</td>
                                            <td className="text-golden-brown" style={{ whiteSpace: 'nowrap' }}>{toCurrency(Math.round(showData.headquarters.fya))}</td>
                                            <td className="text-golden-brown" style={{ whiteSpace: 'nowrap' }}>{Math.round(showData.headquarters.qty)}</td>
                                        </tr>
                                    }
                                    {
                                        showData.data?.map((item, index) => {
                                            return (
                                                <tr className={`fw-bolder`} key={`rank-${index}`}>
                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item?.rank}</td>
                                                    <td className={`text-dark-blue`} style={{ whiteSpace: 'nowrap' }}>{item?.centerName}</td>
                                                    <td className={`text-dark-blue ${selectOption == '業務中心' ? 'd-none' : ''}`} style={{ whiteSpace: 'nowrap' }}>{item?.divName}</td>
                                                    <td className={`text-dark-blue ${selectOption == '個人' ? '' : 'd-none'}`} style={{ whiteSpace: 'nowrap' }}>{item?.salesName}</td>
                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(Math.round(item?.fyc))}</td>
                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(Math.round(item?.fya))}</td>
                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{Math.round(item?.qty)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        selectOption !== '業務中心' &&
                                        showPersonData?.map((item, index) => {
                                            return (
                                                <tr key={`personal-${index}`} className="fw-bolder" style={{ background: '#315176' }}>
                                                    <td className="text-light" style={{ whiteSpace: 'nowrap' }}>{item?.rank === 'NA' ? '' : item?.rank}</td>
                                                    <td className="text-light" style={{ whiteSpace: 'nowrap' }}>{item?.centerName === 'NA' ? '' : item?.centerName}</td>
                                                    <td className="text-light" style={{ whiteSpace: 'nowrap' }}>{item?.divName === 'NA' ? '' : item?.divName}</td>
                                                    <td className={`text-light ${selectOption === '事業部' || selectOption === '業務中心' ? 'd-none' : ''}`} style={{ whiteSpace: 'nowrap' }}>{item?.salesName === 'NA' ? '' : item?.salesName}</td>
                                                    <td className="text-light" style={{ whiteSpace: 'nowrap' }}>{item?.fyc === 'NA' ? '' : toCurrency(Math.round(item?.fyc))}</td>
                                                    <td className="text-light" style={{ whiteSpace: 'nowrap' }}>{item?.fya === 'NA' ? '' : toCurrency(Math.round(item?.fya))}</td>
                                                    <td className="text-light" style={{ whiteSpace: 'nowrap' }}>{item?.qty === 'NA' ? '' : Math.round(item?.qty)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default PerformanceRank;