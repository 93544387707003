import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LineLogin from '../../assets/javascript/lineLogin';
import Countfunc from '../../assets/javascript/countfunc';
import Loading from '../../components/Loading';
import { default as swal } from 'sweetalert2'
const ShareSaving = () => {
    let [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        const token = document.cookie.replace(/(?:(?:^|.*;\s*)lineIdToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
        if (token === '') {
            localStorage.setItem('page', '/share_saving');
            window.location.href = '/login'
        }
        LineLogin('/share_saving')
        Countfunc('儲蓄險分析', 50)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/share_saving');
                        window.location.href = '/login'
                        return
                    } else if (!token) {
                        localStorage.setItem('page', '/share_saving');
                        window.location.href = '/login'
                        return
                    } else {
                        swal.fire({
                            icon: 'info',
                            html: `<h5 class='fw-bolder'>本項功能為系統廠商提供之參考內容，<br/>招攬前請確認公司是否銷售</h5>`,
                            confirmButtonText: '確認',
                        }).then(function () {
                            setIsLoading(true)
                            window.location.href = `https://server2.myapp.net.tw/app/goldennet_line/share_saving.php?uniqueno=${token}`
                        })
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);
    return (
        <>
            <Loading isLoading={isLoading} />
        </>
    )
}
export default ShareSaving