import React, { useState, useEffect } from 'react';
import axios from 'axios';
//component
import NavTabs from 'components/backDashboard/NavTabs';
import { Accordion } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import ifStrHaveHttp from 'assets/javascript/ifStrHaveHttp';
import Loading from 'components/Loading';

const MessageCheck = (props) => {
    let [isLoading, setIsLoading] = useState(true)
    //登入角色判斷
    useEffect(() => {
        let loginRole = localStorage.getItem('role');
        if (loginRole === '1') {
            window.location.href = '/dashboard/message_center'
        } else if (loginRole === '3') {
            window.location.href = '/dashboard/pet'
        }
    }, []);

    // GET API
    let [getMesPushData, setGetMesPushData] = useState([]);
    const getAllPushNoFun = () => {
        let API = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/GetAllPushNo`;
        axios.get(API)
            .then((res) => {
                setGetMesPushData(res.data);
                setIsLoading(false)
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }
    useEffect(() => {
        getAllPushNoFun();
    }, []);

    let [btnSendLoading, setBtnSendLoading] = useState(false);

    const handleCheckBtn = (e) => {
        let { nodeName, value, dataset } = e.target;
        if (nodeName !== 'BUTTON') {
            return
        }

        let data = {
            Push_No: dataset.pushno,
            Audit_User: "審核者",
            Audit_Status: value
        }
        setBtnSendLoading(true)
        let API = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/DrafPush`; //審核儲存訊息(若為核准時則進行推播)
        axios.post(API, data)
            .then((res) => {
                if (res.data.ResponseCode === '0') {
                    if (value === '0') {
                        alert(`已核准審核訊息`)
                    } else if (value === '1') {
                        alert(`已拒絕審核訊息`)
                    }
                    setTimeout(() => { setBtnSendLoading(false) }, 2000)
                    getAllPushNoFun();
                }
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <h2 className='fs-4 text-dark-blue fw-bolder p-3'>{props.title}</h2>
            <div className='px-3'>
                <NavTabs />
                <div className="tab-content" id="myTabContent">
                    {/* 待處理 */}
                    <div className="tab-pane fade show active" id="pending" role="tabpanel" aria-labelledby="pending-tab">
                        <div className="container-fluid">
                            <div className="row justify-content-between fw-bolder text-golden-brown border-bottom py-3">
                                <div className="col-3 ps-5">發訊者</div>
                                <div className="col-2">預定時間</div>
                                <div className="col-1">頻道</div>
                                <div className="col-2 ps-4">審核狀態</div>
                                <div className="col-4">提交時間</div>
                            </div>
                            <div style={{ height: '80vh', overflowY: 'scroll' }}>
                                <Accordion className="my-2">
                                    {getMesPushData.map((item, index) => {
                                        if (item.Audit_Status === '') {
                                            return (
                                                <Accordion.Item eventKey={index} key={index}>
                                                    <Accordion.Header>
                                                        <div className="row justify-content-between w-100">
                                                            <div className="col-3">{item.Create_User}</div>
                                                            <div className="col-2">立即發送</div>
                                                            <div className="col-1 text-center">LINE</div>
                                                            <div className="col-2 text-center fw-bolder text-dark-blue">!待審核</div>
                                                            <div className="col-4">{item.Create_Datetime}</div>
                                                            {/* <div className="col-1"></div> */}
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="row justify-content-between w-100 text-center fw-bolder text-dark-blue border-bottom">
                                                            <div className="col-4">收訊人</div>
                                                            <div className="col-4">訊息內容</div>
                                                            <div className="col-4">文件</div>
                                                        </div>
                                                        {item.pushMsgs.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <div className="row justify-content-between w-100 text-center my-2 border-bottom" key={index}>
                                                                        <div className="col-4 py-2">{item.Sale_Name}</div>
                                                                        <div className="col-4 py-2">
                                                                            {item.Message_Body === '' ? '' : item.Message_Body}
                                                                        </div>
                                                                        <div className="col-4 py-2">
                                                                            {
                                                                                ifStrHaveHttp(item.Message_Image) ? <a href={item.Message_Image} target="_blank" className="text-dark paperclip-icon" style={{ wordBreak: 'break-all' }}>{item.Message_Image}</a> : <p>{item.Message_Image === '' ? '' : item.Message_Image}</p>
                                                                            }
                                                                            <p>{item.Message_Card !== '' ? '卡片推送' : ''}</p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                        <div className="text-end pb-2" onClick={handleCheckBtn}>
                                                            <button type="button" className="btn btn-dark-blue mx-1" value="0" data-pushno={item.Push_No} disabled={btnSendLoading ? 'disabled' : ''}>

                                                                核准
                                                            </button>
                                                            <button type="button" className="btn btn-danger mx-1" value="1" data-pushno={item.Push_No} disabled={btnSendLoading ? 'disabled' : ''}>
                                                                拒絕
                                                            </button>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        }
                                        return true
                                    })
                                    }
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    {/* 已處理 */}
                    <div className="tab-pane fade" id="checkOK" role="tabpanel" aria-labelledby="checkOK-tab">
                        <div className="col-12">
                            <div className="container-fluid">
                                <div className="row justify-content-between fw-bolder text-golden-brown border-bottom px-4 py-3">
                                    <div className="col-2">發訊者</div>
                                    <div className="col-2">審核者</div>
                                    <div className="col-2">發送時間</div>
                                    <div className="col-1">頻道</div>
                                    <div className="col-3">審核狀態</div>
                                    <div className="col-1"></div>
                                </div>
                                <div style={{ height: '80vh', overflowY: 'scroll' }}>
                                    <Accordion className="my-2">
                                        {getMesPushData.map((item, index) => {
                                            if (item.Audit_Status !== '') {
                                                return (
                                                    <Accordion.Item eventKey={index} key={index}>
                                                        <Accordion.Header>
                                                            <div className="row justify-content-between w-100">
                                                                <div className="col-2">{item.Create_User}</div>
                                                                <div className="col-2">{item.Audit_User}</div>
                                                                <div className="col-2">{item.Create_Datetime === item.Send_Datetime ? '立即發送' : '審核發送'}</div>
                                                                <div className="col-1 text-center">LINE</div>
                                                                <div className="col-4">
                                                                    {item.Audit_Status === String(0) ? <span className="text-success"><FontAwesomeIcon icon={faCheckCircle} />已核准</span> : <span className="text-danger"><FontAwesomeIcon icon={faTimes} />未通過</span>}
                                                                    <span className="ps-2" style={{ fontSize: '12px' }}>({item.Create_Datetime})</span>
                                                                </div>
                                                                {/* <div className="col-1"></div> */}
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="row justify-content-between w-100 text-center fw-bolder text-dark-blue border-bottom">
                                                                <div className="col-4">收訊人</div>
                                                                <div className="col-4">訊息內容</div>
                                                                <div className="col-4">文件</div>
                                                            </div>
                                                            <div className={`${item.pushMsgs.length >= 9 ? 'scroll-box' : ''}`}>
                                                                {item.pushMsgs.map((item, index) => {
                                                                    return (
                                                                        <div className="row justify-content-between w-100 text-center my-2 border-bottom" key={index}>
                                                                            <div className="col-4 py-2">{item.Sale_Name}</div>
                                                                            <div className="col-4 py-2">{item.Message_Body === '' ? '' : item.Message_Body}</div>
                                                                            <div className="col-4 py-2">
                                                                                {
                                                                                    ifStrHaveHttp(item.Message_Image) ? <a href={item.Message_Image} target="_blank" className="text-dark paperclip-icon" style={{ wordBreak: 'break-all' }}>{item.Message_Image}</a> : <p>{item.Message_Image === '' ? '' : item.Message_Image}</p>
                                                                                }
                                                                                <p>{item.Message_Card !== '' ? '卡片推送' : ''}</p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )
                                            }
                                            return true
                                        })
                                        }
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MessageCheck
