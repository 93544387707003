import React, { useState, useEffect } from 'react';

import TreeView from 'react-treeview';
import 'react-treeview/react-treeview.css';

import Loading from '../Loading'

const TreeViewList = (props) => {
    let propData = props.data
    let searchInput = props.searchInput

    useEffect(() => {
    }, [propData]);

    const All = <button type="button" className="btn node earth-btn py-0" value={props.title} onClick={props.handleImportClickModal}>{props.title}＋</button>;
    return (
        <>
            <div className={`MainDiv`} onClick={props.handleImportClick}>
                <TreeView nodeLabel={All} defaultCollapsed={false}>
                    {
                        propData.filter((val, valIndex) => {
                            if (searchInput === '') {
                                return val
                            } else if (val.Filetitle.includes(searchInput)) {
                                return val
                            }
                        }).map((item, index) => {
                            const List = <>
                                <button type="button" className="btn node table-btn p-0 my-1" data-filename={item.Filetitle}>{item.Filetitle}</button>
                                <button type="button" className="btn btn-sm trash-icon p-1 ms-2 text-danger" data-fun="del" data-filename={item.Filetitle}></button></>
                            return (
                                <TreeView nodeLabel={List} defaultCollapsed={true}>

                                </TreeView>
                            )
                        })
                    }
                </TreeView>
            </div>
        </>
    );
}
export default TreeViewList