import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useLocation, useRouteMatch } from "react-router-dom";
import InsideNav from '../../components/InsideNav';
//component
import ShowLogo from './ShowLogo';
import Loading from '../../components/Loading';
import DownloadFile from '../../assets/javascript/downloadFile';
import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc';

const PaymentinfoSin = (props) => {
    const match = useRouteMatch();
    let showLogoData = match.url.split('/')[2].split('-')
    let [isLoading, setIsLoading] = useState(true);
    let [company] = useState(showLogoData[1])

    const insideNavItem = [
        {
            text: '劃撥',
        },
        {
            text: '匯款',
        },
        {
            text: '扣款',
        },
    ];
    let [option, setOption] = useState(insideNavItem[0].text)
    let allFiles = [];
    let pdfFiles = [];

    let [allData, setAllData] = useState([])
    let [fileData, setFileData] = useState([])

    useEffect(() => {
        LineLogin(`${match.url}`); //LINE 登入狀態驗證
        Countfunc('繳費', 6); //觸及數器
        //GET API
        let API = `${process.env.REACT_APP_GOLDEN_API5000}InsCom/1`
        let fileAPI = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=2`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', `/paymentinfo/${match.url}`);
                        window.location.href = '/login'
                        return
                    } else {
                        setAllData(res.data)
                        // setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })

        axios.get(fileAPI, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', `/paymentinfo/${match.url}`);
                        window.location.href = '/login'
                        return
                    } else {
                        setFileData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);

    let payData = () => {
        //繳費 PDF 檔案切割
        let splitFileAry = [];
        fileData.forEach((item, index) => {
            splitFileAry.push(item.split('/'))
        });

        //取得切割後為[繳費資訊]資料
        splitFileAry.forEach((item, index) => {
            if (item[1] === '繳費資訊' && item[3].split('_')[0] === showLogoData[1]) {
                pdfFiles.push({
                    downloadUrl: item.join('/'),
                    company: item[3].split('_')[0],
                    fileName: item[3]
                })
            }
        })

        allData.forEach((item, index) => {
            if (item.name === '繳費資訊') {
                item.data.forEach((kidItem, kidIndex) => {
                    if (kidItem[1] === showLogoData[1]) {
                        allFiles.push({
                            no: kidItem[0] === undefined ? null : kidItem[0], //序號
                            company: kidItem[1] === undefined ? null : kidItem[1], //保險公司
                            account: kidItem[2] === undefined ? null : kidItem[2], //劃撥帳號
                            transferAcc: kidItem[3] === undefined ? null : kidItem[3], //匯款帳號
                            bank: kidItem[4] === undefined ? null : kidItem[4],//扣款銀行
                        })
                    }
                })

            }
        })
    }
    payData();

    const handleClick = (e) => {
        let { innerText, nodeName } = e.target;
        if (nodeName !== 'A') {
            return
        }
        setOption(innerText)
    }

    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/paymentinfo' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setIsLoading)
    }
    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="position-fixed w-100 bg-light" style={{ zIndex: '100' }}>
                <ShowLogo PropCompany={showLogoData}></ShowLogo>
                <InsideNav insideNavItem={insideNavItem} handleClick={handleClick} option={option} />
            </div>
            <div className="d-table company-paymentinfo-header-height"></div>
            <div className="container py-4 px-lg-5 mt-md-4 mt-lg-0">
                {
                    allFiles.map((item, index) => {
                        if (option === '劃撥') {
                            if (item.account == null) {
                                return (<p className="fw-bolder" key={index}>無劃撥帳號</p>)
                            } else {
                                return (
                                    <p style={{ whiteSpace: 'pre-wrap' }} className="fw-bolder" key={index}>{item.account}</p>
                                )
                            }
                        } else if (option === '匯款') {
                            if (item.transferAcc == null) {
                                return (<p className="fw-bolder" key={index}>無扣款帳號</p>)
                            } else {
                                return (
                                    <p style={{ whiteSpace: 'pre-wrap' }} className="fw-bolder" key={index}>{item.transferAcc}</p>
                                )
                            }
                        } else if (option === '扣款') {
                            if (item.bank == null) {
                                return (<p className="fw-bolder" key={index}>無扣款銀行</p>)
                            } else {
                                return (
                                    <p style={{ whiteSpace: 'pre-wrap' }} className="fw-bolder" key={index}>{item.bank}</p>
                                )
                            }
                        }
                        return true
                    })
                }
            </div>
            <div className='container pb-3 px-lg-5'>
                <ul className='list-unstyled'>
                    {
                        pdfFiles.map((item, index) => {
                            if (item.company === company) {
                                return (
                                    <li className="border-bottom py-3" key={item.fileName}>
                                        <a href="#" data-download={item.downloadUrl} data-filename={item.fileName} className="d-block fw-bolder file-icon text-dark-blue" onClick={handleDownloadFile}>
                                            {item.fileName}
                                        </a>
                                    </li>
                                )
                            }
                            return true
                        })
                    }
                </ul>
            </div>
        </>
    );
}
export default PaymentinfoSin;