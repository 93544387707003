import React from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
//component
import Nav from '../components/Nav';
import BackgroundWrapper from '../components/BackgroundWrapper';

import Files from "../views/insuranceCompany/Files";
import FilesSin from "../views/insuranceCompany/FilesSin";
import PaymentinfoSin from "../views/insuranceCompany/PaymentinfoSin";
import Paymentinfo from "../views/insuranceCompany/Paymentinfo";
import Contacts from "../views/insuranceCompany/Contacts";
import ContactSin from "../views/insuranceCompany/ContactSin";
import Report from "../views/insuranceCompany/Report";
import ReportSin from "../views/insuranceCompany/ReportSin";
import CIBAgent from '../views/insuranceCompany/CIBAgent';
import CIBAgentSin from '../views/insuranceCompany/CIBAgentSin';


const Insurer = (props) => {
    const navItem = [
        {
            text: '文件',
            to: 'files'
        },
        {
            text: '繳費',
            to: 'paymentinfo'
        },
        {
            text: '窗口',
            to: 'contacts'
        },
        {
            text: '報備',
            to: 'report'
        },
        {
            text: '經代專區',
            to: 'CIB_agent'
        }
    ];

    return (
        <>
            <BackgroundWrapper>
                <Router>
                    <Nav navItem={navItem}></Nav>
                    <Switch>
                        <Route exact path="/files"><Files /></Route>
                        <Route exact path="/files/:id"><FilesSin /></Route>
                        <Route exact path="/paymentinfo"><Paymentinfo /></Route>
                        <Route exact path="/paymentinfo/:id"><PaymentinfoSin /></Route>
                        <Route exact path="/contacts"><Contacts /></Route>
                        <Route exact path="/contacts/:id"><ContactSin /></Route>
                        <Route exact path="/report"><Report /></Route>
                        <Route exact path="/report/:id"><ReportSin /></Route>
                        <Route exact path="/CIB_agent"><CIBAgent /></Route>
                        <Route exact path="/CIB_agent/:id"><CIBAgentSin /></Route>
                    </Switch>
                </Router>
            </BackgroundWrapper>
        </>
    );
}
export default withRouter(Insurer);