import React, { useState, useEffect } from 'react';
import axios from 'axios'

import Loading from '../../../components/Loading';

import LineLogin from '../../../assets/javascript/lineLogin';
import Countfunc from '../../../assets/javascript/countfunc';

import DownloadFile from '../../../assets/javascript/downloadFile'

const Lecture = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState([])

    useEffect(() => {
        LineLogin('/lecture'); //LINE 登入狀態驗證
        Countfunc('課程教案_講座', 36)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=6`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/lecture');
                        window.location.href = '/login'
                        return
                    } else {
                        setData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);


    let dataAry = [];
    let filterOptionAry = [];
    const splitData = (data) => {
        let splitAry = []
        data.forEach((item, index, ary) => {
            splitAry.push(item.split('/'))
        });

        splitAry.forEach((item, index) => {
            if (item[2] === '3.講座課程') {
                dataAry.push({
                    downloadFile: item.join('/'),
                    option: item[3],
                    fileName: item.pop()
                })
            }
        })

        let optionAry = [];
        dataAry.forEach((item, index) => {
            optionAry.push(item.option)
        })

        filterOptionAry = optionAry.filter((item, index, ary) => {
            return ary.indexOf(item) === index
        })
    }
    splitData(data)

    let [option, setOption] = useState('2021-李傑克老師財稅講座')
    const handleOptionChange = (e) => {
        let { value } = e.target;
        setOption(value);//更新值
    }

    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/lecture' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setIsLoading)
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-3">
                <div className="d-flex pb-2">
                    <select className="form-select" value={option} onChange={handleOptionChange}>
                        {
                            filterOptionAry.sort(function (a, b) {
                                return a - b
                            }).map((item, index) => {
                                return (
                                    <option value={item} key={item}>{item}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <ul className="list-unstyled page-content-link">
                    {
                        dataAry.map((item, index) => {
                            if (option === item.option) {
                                return (
                                    <li className="border-bottom py-3" key={index}>
                                        <a href="#" data-download={item.downloadFile} data-filename={item.fileName} className="d-block fw-bolder file-icon" onClick={handleDownloadFile}>
                                            {item.fileName}
                                        </a>
                                    </li>
                                )
                            }
                            return true
                        })
                    }
                </ul>
            </div>
        </>
    );
}
export default Lecture;