import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//component
import Nav from '../../components/Nav';
import BackgroundWrapper from '../../components/BackgroundWrapper';

import Performance from '../../views/insurancePolicy/CompetitionPerformance/Performance';
import Competition from '../../views/insurancePolicy/CompetitionPerformance/Competition';
import CompetitionInfo from '../../views/insurancePolicy/CompetitionPerformance/CompetitionInfo';

const Personal = (props) => {
    const navItem = [
        {
            text: '個人業績',
            to: 'performance'
        },
        {
            text: '競賽',
            to: 'competition'
        },
        {
            text: '競賽獎勵資訊',
            to: 'competition_info'
        }
    ];

    return (
        <>
            <BackgroundWrapper>
                <Router>
                    <Nav navItem={navItem}>
                        {/* <p className="text-light text-center mt-3">[<span>總公司</span>] <span>xxx</span> 業務專員</p> */}
                    </Nav>
                    {/* <div className="d-table" style={{height:'40px'}}></div> */}
                    <Switch>
                        <Route path="/performance"><Performance /></Route>
                        <Route path="/competition"><Competition /></Route>
                        <Route path="/competition_info"><CompetitionInfo /></Route>
                    </Switch>
                </Router>
            </BackgroundWrapper>
        </>
    );
}
export default Personal;