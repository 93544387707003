import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import BackgroundWrapper from "../../components/BackgroundWrapper";
//icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading";

//alwaysOpen

const CurrentProdRead = () => {
    const { state } = useLocation();
    let [propResult] = useState(state); //前頁表單查詢傳過來的結果值
    let [resultData, setResultData] = useState(propResult.ResultData); //axios get 儲存[結果]撈取資料
    let [isLoading, setIsLoading] = useState(false);

    const addOpenAcc = (data) => {
        data.forEach((item, index) => {
            item.annual.forEach((kidItem, kidIndex) => {
                if (kidIndex === 0) {
                    kidItem.isOpen = true
                } else {
                    kidItem.isOpen = false
                }
            })
        })
    }
    addOpenAcc(resultData)

    //分頁邏輯 start
    //分頁初始資料
    let [currentPage, setCurrentPage] = useState(1); //當前頁數
    let [itemsPerPage] = useState(10); //預設一頁顯示筆數
    let [pageNumberLimit] = useState(5); //每次顯示頁數限制
    let [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5) //最大頁數限制
    let [minPageNumberLimit, setMinPageNumberLimit] = useState(0) //最小頁數限制

    const handleCurrentPage = (e) => {
        let { id } = e.target;
        setCurrentPage(Number(id))
    }

    let pages = []; //總共分成幾頁
    for (let i = 1; i <= Math.ceil(resultData.length / itemsPerPage); i++) {
        pages.push(i);
    }

    //預設 currentPage 為 1; itemsPerPage 為 10筆
    const indexOfLastItem = currentPage * itemsPerPage; //10
    const indexOfFirstItem = indexOfLastItem - itemsPerPage; //0
    const currentItems = resultData.slice(indexOfFirstItem, indexOfLastItem); // MAP 的資料
    const renderPageNumbers = pages.map((item) => {
        if (item < maxPageNumberLimit + 1 && item > minPageNumberLimit) {
            return (
                <li className={`page-item`} key={item} id={item} onClick={handleCurrentPage}>
                    <a href="#" className={`page-link ${currentPage === item ? "page-active" : ""}`} id={item}>{item}</a>
                </li>
            )
        } else {
            return null
        }
    })

    const handleNextBtn = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    }
    const handlePretBtn = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    }

    let pageAddBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageAddBtn = <li className={`page-item`} >
            <a href="#" className={`page-link px-2`} onClick={handleNextBtn}>&hellip;</a>
        </li>
    }
    let pageReduceBtn = null;
    if (minPageNumberLimit >= 1) {
        pageReduceBtn = <li className={`page-item`} >
            <a href="#" className={`page-link px-2`} onClick={handlePretBtn}>&hellip;</a>
        </li>
    }

    //分頁邏輯 end

    //年期判斷
    let [annualSelect, setAnnualSelect] = useState('請選擇');
    let annualAry = [];
    const handelYearAge = (e) => {
        let { value } = e.target
        setAnnualSelect(value)
        resultData.forEach((item, index) => {
            if (value !== item.PRD_MST_PRO_NAME - index) {

            }
        })
    }
    //年期判斷 END
    useEffect(() => {
        currentItems.forEach((item, index) => {
            annualAry.push(item.PRD_MST_PRO_NAME + `-0`)
        })
    }, [annualSelect]);

    const ifStrHaveHttp = (str) => {
        if (str === undefined) {
            return
        }
        if (str === null) {
            return
        }
        if (str.indexOf('http') !== -1) {
            return true
        } else {
            return false
        }
    }

    let [proName, setProName] = useState('')
    const handleOpenDetail = (e) => {
        let { dataset } = e.target;
        setProName(dataset.proname)
    }

    const handleSearchReset = (e) => { //重新查詢
        e.preventDefault();
        window.location.href = '/current_prod'
    }
    const SearchReset = {
        bottom: '0',
        width: '100%',
        zIndex: '100'
    }


    return (
        <>
            <Loading isLoading={isLoading} />
            <BackgroundWrapper>
                <div className="container py-3">
                    <div className="row">
                        {
                            currentItems.map((item, index) => {
                                return (
                                    <div className="col-md-6 col-lg-4 my-2" key={index} id={String(currentPage) + index}>
                                        <div className="card bg-light card-shadow">
                                            <h6 className="bg-dark-blue text-light py-2 text-center fw-bolder rounded-top">{item.PRD_MST_PRO_NAME}</h6>
                                            <div className="card-body py-1">
                                                <ul className="list-unstyled">
                                                    <li className="row py-2">
                                                        <span className="d-block col-4">商品狀態</span>
                                                        <span className="d-block col-8 text-golden-brown fw-bolder">
                                                            <span className={`badge small ${item.close == 0 ? 'text-bg-dark-blue' : 'text-bg-danger'}`}>{item.close == 0 ? '現售' : '停售'}</span>
                                                        </span>
                                                    </li>
                                                    <li className="row py-2">
                                                        <span className="d-block col-4">商品類型</span>
                                                        <span className="d-block col-8 text-golden-brown fw-bolder">{item.DRP_INS_TYPE_NAME}</span>
                                                    </li>
                                                    <li className="row py-1">
                                                        <span className="d-block col-4">保險公司</span>
                                                        <span className="d-block col-8 text-golden-brown fw-bolder">{item.PRD_SUP_NAME}</span>
                                                    </li>
                                                    <li className="row py-1">
                                                        <span className="d-block col-4">主附約</span>
                                                        <span className="d-block col-8 text-golden-brown fw-bolder">
                                                            <p>{item.main === 1 ? "主約" : "附約"}</p>
                                                        </span>
                                                    </li>
                                                    <hr className="my-1" />
                                                    <li className="row py-1">
                                                        <span className="d-block col-4">繳別</span>
                                                        <span className="d-block col-8 text-golden-brown fw-bolder">
                                                            {item.pt}
                                                        </span>
                                                    </li>
                                                    <li className={`d-flex justify-content-between align-items-center ${proName === item.PRD_MST_PRO_NAME ? 'd-none' : 'd-block'}`}>
                                                        <span>檢視明細</span>
                                                        <button className="btn w-75 text-end fw-bolder text-golden-brown" data-proname={item.PRD_MST_PRO_NAME} onClick={handleOpenDetail}>•••</button>
                                                    </li>
                                                    <li className={`${proName === item.PRD_MST_PRO_NAME ? 'd-block' : 'd-none'}`}>
                                                        <div className="row py-1">
                                                            <div className="col-12 d-flex justify-content-between align-items-center">
                                                                <span className="d-block">年齡/年期</span>
                                                                <select className="form-select w-75" onChange={handelYearAge} value={annualSelect}>
                                                                    <option defaultValue="請選擇">請選擇</option>
                                                                    {
                                                                        item.annual.map((items, indexs) => {
                                                                            return (
                                                                                <>
                                                                                    <option value={`${item.PRD_MST_PRO_NAME}-${indexs}`}>
                                                                                        {items.a2 === '以上' ? '不拘' : `${items.a1}歲~${items.a2}歲`} &nbsp;&nbsp;
                                                                                        {items.p1 !== items.p2 ? `${`${items.p1}年~${items.p2}年`}` : `${items.p1}年`}
                                                                                    </option></>)
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row py-1">
                                                            {
                                                                item.annual.map((items, indexs) => {
                                                                    if (annualSelect === `${item.PRD_MST_PRO_NAME}-${indexs}`) {
                                                                        return (
                                                                            <>
                                                                                <ul className="list-unstyled">
                                                                                    <li className="row">
                                                                                        <span className="d-block col-4 me-2">首年</span>
                                                                                        <span className="col-4 d-inline-block text-golden-brown fw-bolder" data-id={String(currentPage) + index}>{items.y1 === null ? '' : Number(items.y1).toFixed(1)}</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className="table-responsive text-center">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                    <tr className="text-dark-blue">
                                                                                                        <th>2年</th>
                                                                                                        <th>3年</th>
                                                                                                        <th>4年</th>
                                                                                                        <th>5年</th>
                                                                                                        <th>6年</th>
                                                                                                        <th>7年</th>
                                                                                                        <th>8年</th>
                                                                                                        <th>9年</th>
                                                                                                        <th>10年</th>
                                                                                                        <th width="100" style={{ whiteSpace: 'nowrap' }}>備註</th>
                                                                                                        <th style={{ whiteSpace: 'nowrap' }}>年終<br />獎金</th>
                                                                                                        <th style={{ whiteSpace: 'nowrap' }}>繼續率<br />獎金</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>{items.y2 === null ? '' : Math.round(items.y2)}</td>
                                                                                                        <td>{items.y3 === null ? '' : Math.round(items.y3)}</td>
                                                                                                        <td>{items.y4 === null ? '' : Math.round(items.y4)}</td>
                                                                                                        <td>{items.y5 === null ? '' : Math.round(items.y5)}</td>
                                                                                                        <td>{items.y6 === null ? '' : Math.round(items.y6)}</td>
                                                                                                        <td>{items.y7 === null ? '' : Math.round(items.y7)}</td>
                                                                                                        <td>{items.y8 === null ? '' : Math.round(items.y8)}</td>
                                                                                                        <td>{items.y9 === null ? '' : Math.round(items.y9)}</td>
                                                                                                        <td>{items.y10 === null ? '' : Math.round(items.y10)}</td>
                                                                                                        <td>
                                                                                                            <a href={items.remark} className={items.remark !== null && ifStrHaveHttp(items.remark) ? "d-block" : "d-none"}>連結</a>
                                                                                                            <p className={`text-start ${items.remark !== null && ifStrHaveHttp(items.remark) ? "d-none" : "d-block"}`} style={{ letterSpacing: '2px', width: '150px' }}>{items.remark}</p>

                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <p>{Number(items.ct) === 1 ? 'v' : ''}</p>
                                                                                                        </td>
                                                                                                        <td><p>{Number(items.yr) === 1 ? 'v' : ''}</p></td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>

                                                                            </>
                                                                        )
                                                                    }

                                                                })
                                                            }

                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {/* <div className="d-table" style={{ height: '60px' }}></div> */}
                <div className="py-3">
                    <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center">
                            <li className={`page-item`} >
                                <a href="#" className={`page-link ${currentPage === pages[0] ? "page-disabled d-none" : ""}`} onClick={handlePretBtn}><FontAwesomeIcon icon={faChevronLeft} /></a>
                            </li>
                            {pageReduceBtn}
                            {renderPageNumbers}
                            {pageAddBtn}
                            <li className={`page-item`} >
                                <a href="#" className={`page-link ${currentPage === pages[pages.length - 1] ? "page-disabled d-none" : ""}`} onClick={handleNextBtn}><FontAwesomeIcon icon={faChevronRight} /></a>
                            </li>
                        </ul>
                        {/* <div className="d-flex justify-content-center my-3">
                            <button type="button" className={`btn btn-outline-dark-blue text-center ${currentItems.length < 10 || currentItems.length == resultData.length ? 'd-none' : 'd-block'}`} onClick={handleLoadMore}>一次瀏覽更多</button>
                        </div> */}
                    </nav>
                </div>
                <div className="d-table" style={{ height: '60px' }}></div>
                <button type="button" className="btn btn-golden-brown position-fixed py-3" style={SearchReset} onClick={handleSearchReset}>重新查詢</button>
            </BackgroundWrapper>
        </>
    )
}

export default CurrentProdRead