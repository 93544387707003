import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//component
import Nav from '../components/Nav';
import BackgroundWrapper from '../components/BackgroundWrapper';

import Morningmeet from "../views/groupTraining/trainingCases/Moringmeet";
import Topics from "../views/groupTraining/trainingCases/Topics";
import Lecture from "../views/groupTraining/trainingCases/Lecture";
import Satisfied from '../views/groupTraining/trainingCases/Satisfied'

const TrainingCases = (props) => {
    const navItem = [
        {
            text: '早會',
            to: 'morningmeet'
        },
        {
            text: '專題',
            to: 'topics'
        },
        {
            text: '講座',
            to: 'lecture'
        },
        {
            text: '滿意度',
            to: 'satisfied'
        }
    ];

    return (
        <>
            <BackgroundWrapper>
                <Router>
                    <Nav navItem={navItem}></Nav>
                    <Switch>
                        <Route path="/morningmeet"><Morningmeet /></Route>
                        <Route path="/topics"><Topics /></Route>
                        <Route path="/lecture"><Lecture /></Route>
                        <Route path="/satisfied"><Satisfied /></Route>
                    </Switch>
                </Router>
            </BackgroundWrapper>
        </>
    );
}
export default TrainingCases;