import React,{useEffect} from 'react';
import Countfunc from '../../assets/javascript/countfunc'
//套件
import Slider from "react-slick";
//component
import Banner from '../../components/addMembers/Banner';
//json
import GoldenAdvantageData from "../../json/addMembers/GoldenAdvantage.json";
//image url
import bannerUrl from '../../assets/images/components/goldenAdvantage.jpg';

const GoldenAdvantage = () => {
    useEffect(() => {
        Countfunc('公勝優勢',44)
    }, []);
    const style = { //banner background style
        backgroundImage: `url(${bannerUrl})`,
        backgroundPosition: 'center 20%'
    }
    const dataAry = GoldenAdvantageData.data; //slider data
    const settings = { //slider setting
        dots: true,
        infinite: true,
        focusOnSelect: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        // autoplay:true,
        // autoplaySpeed: 4000,
        row: 0,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    const title = "Golden Life\n菁英雲集的硬實力"; //banner title
    return (
        <div>
            <Banner
                title={title}
                style={style}
            />

            <div className="container">
                <div className="py-4 d-flex flex-column flex-md-row justify-content-center">
                    <p className="d-flex justify-content-center 1h-lg flex-grow-1 align-self-center fs-6 fw-bolder text-golden-brown mb-3 me-md-3">自 1993 年成立以來，一直是台灣保經業領頭羊。<br />從 2016 年開始，新契約保費營收穩居業界之冠。<br />並於 2019 年登錄興櫃公司。</p>
                    <div className="col-12 col-md-6 col-lg-4">
                        <img src={require('../../assets/images/addMembers/goldenAdvantage/photo/taiwan.png').default} alt="全台分布圖" className="img-fluid" />
                    </div>
                </div>
            </div>
            <div className="bg-yellow-light py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 py-3">
                            <h2 className="fs-3 text-golden-brown fw-bolder text-center mb-3">台灣保經第一</h2>
                            <ul className="list-unstyled text-center lh-lg my-2">
                                <li>新契約保費排行第一</li>
                                <li>業務團隊規模最大</li>
                                <li>RFC國際認證財務顧問最多</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6 py-3">
                            <h2 className="fs-3 text-golden-brown fw-bolder text-center mb-3">繼續率品質優異</h2>
                            <div className="d-flex justify-content-around my-2">
                                <p className="text-center">13個月<span className="d-block fs-3 fw-bolder text-golden-yellow">98.25%</span></p>
                                <p className="text-center">25個月<span className="d-block fs-3 fw-bolder text-golden-yellow">95.22%</span></p>
                            </div>
                        </div>
                        <div className="col-12 py-3">
                            <h2 className="fs-3 text-golden-brown fw-bolder text-center mb-3">資訊領先</h2>
                            <div className="row justify-content-center">
                                <div className="col-12 col-xs-10 col-md-6 text-center d-md-flex align-items-center my-2">
                                    <span className="d-block fs-3 fw-bolder text-golden-yellow flex-md-grow-1 ">2016</span>
                                    <p className="flex-md-grow-1 text-center lh-lg">第一家網路投保平台上線</p>
                                </div>
                                <div className="col-12 col-xs-10 col-md-6 text-center d-md-flex align-items-center my-2">
                                    <span className="d-block fs-3 fw-bolder text-golden-yellow flex-md-grow-1">2017</span>
                                    <p className="flex-md-grow-1 text-center lh-lg">第一家通過 BSI 三項驗證
                                        <small className="d-block">ISO09001、ISO27001、BS10012</small>
                                    </p>
                                </div>
                                <div className="col-12 col-xs-10 col-md-6 text-center d-md-flex align-items-center my-2">
                                    <span className="d-block fs-3 fw-bolder text-golden-yellow flex-md-grow-1">2018</span>
                                    <ul className="list-unstyled flex-md-grow-1 text-center lh-lg">
                                        <li>第一家行動投保系統上線</li>
                                        <li>公勝 i 世代智能平台上線</li>
                                    </ul>
                                </div>
                                <div className="col-12 col-xs-10 col-md-6 text-center d-md-flex align-items-center my-2">
                                    <span className="d-block fs-3 fw-bolder text-golden-yellow flex-md-grow-1">2019</span>
                                    <ul className="list-unstyled flex-md-grow-1 text-center">
                                        <li>行動投保-<br /> 客管整合+帶入投保功能上線</li>
                                        <li className="lh-lg">完成串接 13 家保險公司</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-4 awards">
                <div className="container h-100 d-flex align-items-center">
                    <p className="text-light fs-3">公勝<br />榮耀與肯定</p>
                </div>
            </div>
            <div className="container py-4 mb-4">
                <Slider {...settings} >
                    {dataAry.map((item, index) => {
                        return (
                            <div className="px-2" key={index}>
                                <div className="card h-100">
                                    <img src={require(`../../assets/images/addMembers/goldenAdvantage/photo/${item.title}.jpg`).default} className="card-img-top img-fluid" alt={item.title} style={{
                                        height: '250px',
                                        objectFit: 'contain'
                                    }} />
                                    <div className="card-body">
                                        <h3 className="card-title fw-bolder text-golden-brown fs-5 fs-md-3">{item.title}</h3>
                                        <ul>
                                            {item.content.map((item, index) => {
                                                return (
                                                    <li key={index}>{item}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}

export default GoldenAdvantage;