import React,{useEffect} from 'react';
import Countfunc from "../../assets/javascript/countfunc"
//套件
import Slider from "react-slick";
//component
import Banner from "../../components/addMembers/Banner";
//json
import CharityData from "../../json/addMembers/Charity.json";
//image url
import BannerUrl from "../../assets/images/components/charity.jpg";

const Charity = () => {
    useEffect(() => {
        Countfunc('公益關懷',43)
    }, []);
    const style = { //banner component background style
        backgroundImage: `url(${BannerUrl})`,
        backgroundPosition: `center 25%`,

    }
    const dataAry = CharityData.data; //slider data
    const settings = { //Slider setting
        dots: true,
        infinite: true,
        focusOnSelect: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    const title = "Action For Future\n行動創造改變"; //banner title
    return (
        <div>
            <Banner title={title} style={style} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 py-5 d-md-flex align-items-md-center">
                        <img src={require(`../../assets/images/addMembers/charity/photo/上圖.jpg`).default} alt="img" className="d-block mx-auto mb-3 col-md-4 img-fluid " style={{ maxWidth: '80%' }} />
                        <p className="text-center text-golden-brown col-md-8 ms-md-5 fs-6 fw-bolderdevgit ">公勝保經長期投入社會公益，回饋社會。<br />同時成立「公勝文教公益信託」，讓善款專注於教育議題推動，以實際行動與社會服務，致力於為台灣未來種下新的希望，讓保險不只是保險，更將愛分享到每個被需要的角落，點燃一盞盞希望之燈。</p>
                    </div>
                </div>
            </div>
            <div className="bg-yellow-light">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-12 col-md-10 col-lg-7 mx-auto">
                            <h2 className="fw-bolder text-golden-brown text-center mb-4">打造全新公益 CI</h2>
                            <iframe className="w-100" height="315" src="https://www.youtube.com/embed/3CgxF_Sk8-A" title="我有一個夢 / 公益MV  (新版)" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className="col-12 mb-4">
                            <h2 className="fw-bolder text-golden-brown text-center my-4">2020年捐助協力</h2>
                            <div className="row">
                                <div className="col-12 col-sm-4 text-center py-2">
                                    <img src={require(`../../assets/images/addMembers/charity/icon/icon-07.jpg`).default} alt="學校" className="img-fluid" style={{ width: "120px" }} />
                                    <p><span className="fw-bolder text-golden-yellow fs-2 d-block mb-1">13</span>所學校</p>
                                </div>
                                <div className="col-12 col-sm-4 text-center py-2">
                                    <img src={require(`../../assets/images/addMembers/charity/icon/icon-08.jpg`).default} alt="受益人" className="img-fluid" style={{ width: "120px" }} />
                                    <p><span className="fw-bolder text-golden-yellow fs-2 d-block mb-1">26,427</span>為受益人</p>
                                </div>
                                <div className="col-12 col-sm-4 text-center py-2">
                                    <img src={require(`../../assets/images/addMembers/charity/icon/icon-09.jpg`).default} alt="基金會" className="img-fluid" style={{ width: "120px" }} />
                                    <p><span className="fw-bolder text-golden-yellow fs-2 d-block mb-1">18</span>個基金會</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <h2 className="fw-bolder text-golden-brown text-center mb-4">2020年志工參與</h2>
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-4 text-center py-2">
                                    <img src={require(`../../assets/images/addMembers/charity/icon/icon-10.jpg`).default} alt="志工人次" className="img-fluid" style={{ width: "120px" }} />
                                    <p><span className="fw-bolder text-golden-yellow fs-2 d-block mb-1">1,621</span>志工人次</p>
                                </div>
                                <div className="col-12 col-sm-4 text-center py-2">
                                    <img src={require(`../../assets/images/addMembers/charity/icon/icon-11.jpg`).default} alt="志工時數" className="img-fluid" style={{ width: "120px" }} />
                                    <p><span className="fw-bolder text-golden-yellow fs-2 d-block mb-1">6,810</span>志工時數</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-4 mb-4">
                <h2 className="fw-bolder text-golden-brown text-center mb-5">-公勝公益事蹟-</h2>
                <Slider {...settings} className="d-grid">
                    {
                        dataAry.map((item, index) => {
                            return (
                                <div className="px-2" key={index}>
                                    <div className="card">
                                        <img src={require(`../../assets/images/addMembers/charity/photo/${item.title}.jpg`).default} className="card-img-top img-fluid" alt={item.title} style={{
                                            height: '220px',
                                            objectFit: 'cover'}}/>
                                        <div className="card-body">
                                            <h3 className="card-title fw-bolder text-golden-brown fs-5 fs-md-3">{item.title}</h3>
                                            <p>{item.content}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>

    );
}

export default Charity;