import React, { useState, useEffect } from 'react';
import axios from 'axios';

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

//component
import LineLogin from '../../assets/javascript/lineLogin';
import Loading from '../../components/Loading';
import Countfunc from '../../assets/javascript/countfunc';

import DownloadFile from '../../assets/javascript/downloadFile';

const Rules = () => {
    let [isLoading, setIsLoading] = useState(true)

    // GET API
    let [allData, setAllData] = useState([])
    useEffect(() => {
        LineLogin('/golden_rules');
        Countfunc('辦法規定', 2); //觸及數器
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=5`;
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/golden_rules');
                        window.location.href = '/login'
                        return
                    } else {
                        setAllData(res.data);
                        setIsLoading(false)
                        localStorage.removeItem('page');
                    }
                }, Number(process.env.REACT_APP_TIMEOUT));
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);
    // GET API END

    //資料處理
    let filterData = []; //最終資料處理完成 DATA
    let firstOptionAry = []; //下拉選單 Select option
    const splitData = (data) => {
        let splitAry = [];
        data.forEach((item, index) => {
            splitAry.push(item.split('/'))
        });

        splitAry.forEach((item, index) => {
            filterData.push({
                title: item[0],
                firstOption: item[1].split('.')[1],
                fileName: item[2],
                downloadFiles: item.join('/')
            })
        })

        //--- 取出 Select option
        let filterFirstOption = [];
        filterData.forEach((item, index) => {
            filterFirstOption.push(item.firstOption)
        })
        firstOptionAry = filterFirstOption.filter((item, index, ary) => {
            return ary.indexOf(item) === index
        })
        //--- 取出 Select option END
    }
    splitData(allData);
    //資料處理 END

    //事件
    let [firstSelect, setFirstSelect] = useState('人事行政');
    const handleSelectChange = (e) => {
        let { value } = e.target;
        setFirstSelect(value)
    }

    //事件 END

    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/golden_rules' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setIsLoading)
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-3">
                <div className="d-flex pb-2">
                    <select className="form-select" value={firstSelect} onChange={handleSelectChange}>
                        {
                            firstOptionAry.map((item, index) => {
                                return (
                                    <option key={item} value={item}>{item}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <ul className="list-unstyled page-content-link fw-bolder">
                    {
                        filterData.map((item, index) => {
                            if (firstSelect === item.firstOption) {
                                return (
                                    <li className="border-bottom py-3" key={index}>
                                        <a href="#" className="d-block" data-download={item.downloadFiles} data-filename={item.fileName} onClick={handleDownloadFile}>
                                            {item.fileName}
                                        </a>
                                    </li>
                                )
                            }
                            return true
                        })
                    }
                </ul>
            </div>
        </>

    );
}
export default Rules;