import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading';
import { toCurrency } from 'assets/javascript/calTool'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

import ExcelJs from "exceljs";
import moment from 'moment';

const Award = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [typeData, setTypeData] = useState([
        { type: '游泳', code: 's', dataCode: 's_data' },
        { type: '跑步', code: 'j', dataCode: 'j_data' },
        { type: '健行', code: 'h', dataCode: 'h_data' },
        { type: '自行車', code: 'b', dataCode: 'b_data' },
        { type: '登山', code: 'c', dataCode: 'c_data' }])
    let [allData, setAllData] = useState([]) //全部噴回來的資料 儲存
    let [haveData, setHaveData] = useState(false) //篩選過資料
    const handleEvent = {
        getData: function () { //取得所有 data
            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}get_total_challenges`
            setIsLoading(true)
            axios.get(API)
                .then((res) => {
                    if (res.data.code == 0) {
                        setIsLoading(false)
                        typeData.forEach((item, index) => {
                            if (item.dataCode) {
                                item.award = res.data[item.dataCode]
                            }
                        })
                        setAllData(res.data)
                        setHaveData(true)
                    } else {
                        setIsLoading(false)
                        setAllData([])
                        setHaveData(false)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        downloadFile: function (e) {
            e.preventDefault();
            const workbook = new ExcelJs.Workbook();
            downloadData.forEach((sheetData) => {
                const sheet = workbook.addWorksheet(sheetData.sheetName);
                sheet.addTable({
                    name: sheetData.sheetName,
                    ref: `A1`, // 從A1開始
                    headerRow: true,
                    columns: sheetData.thead.map((s) => {
                        return { name: s };
                    }),
                    rows: sheetData.tbody
                });
                if (sheetData.columnWidths) {
                    sheetData.columnWidths.forEach((column) => {
                        sheet.getColumn(column.number).width = column.width;
                    });
                }
            });

            // 表格裡面的資料都填寫完成之後，訂出下載的callback function
            // 異步的等待他處理完之後，創建url與連結，觸發下載
            workbook.xlsx.writeBuffer().then((content) => {
                const link = document.createElement("a");
                const blob = new Blob([content], {
                    type: "application/vnd.ms-excel;charset=utf-8;"
                });
                link.download = `${moment().format('YYYY-MM-DD')}_五大挑戰獎牌榜.xlsx`;
                link.href = URL.createObjectURL(blob);
                link.click();
            });
        }
    }
    let downloadData = haveData &&
        [{
            sheetName: '五大挑戰獎牌榜',
            thead: ["類型", "總捐款額", "特級", "白金", "金", "銀", "銅", "木"],
            tbody: typeData.map((item, index) => {
                return [
                    item.type,
                    item.award[0][`${item.code}_donate`],
                    item.award[0][`${item.code}_premium`],
                    item.award[0][`${item.code}_platinum`],
                    item.award[0][`${item.code}_gold`],
                    item.award[0][`${item.code}_silver`],
                    item.award[0][`${item.code}_copper`],
                    item.award[0][`${item.code}_wood`],
                ]
            }),
            columnWidths: [
                { number: 1, width: 10 },
                { number: 2, width: 20 },
                { number: 3, width: 10 },
                { number: 4, width: 10 },
                { number: 5, width: 10 },
                { number: 6, width: 10 },
                { number: 7, width: 10 },
                { number: 8, width: 10 }
            ]
        }]

    useEffect(() => {
        handleEvent.getData();
    }, [])
    useEffect(() => {
        if (allData.length <= 0) {
            return
        }
    }, [allData, typeData])

    let activeStyle = {
        borderBottom: '2px solid #fff',
        paddingBottom: '4px'
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="bg-challenges-blue h-100">
                <div className='container p-4 challenge position-relative'>
                    <div>
                        <h3 className='challenge-title text-light text-center'>五大挑戰獎牌榜</h3>
                    </div>
                    {
                        allData.length == 0 ?
                            <p className='text-light text-center py-3'>無資料</p> :
                            <>
                                <button className='btn btn-challenges-yellow fw-bolder position-absolute' style={{ right: '12px', top: '24px' }} onClick={handleEvent.downloadFile}>
                                    <FontAwesomeIcon icon={faFileExcel} className='me-2' />
                                    下載 Excel
                                </button>
                                <div className="table-responsive-xxl text-center mt-3" style={{ height: '450px', overFlowY: 'scroll' }}>
                                    <table className="table table-striped table-light align-middle">
                                        <thead>
                                            <tr>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>項目</th>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>總捐款額</th>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>特級</th>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>白金</th>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>金</th>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>銀</th>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>銅</th>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>木</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                typeData.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td className="text-dark-blue fw-bolder fs-6" style={{ whiteSpace: 'nowrap' }}>{item.type}</td>
                                                            <td className="text-dark-blue fw-bolder" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.award[0][`${item.code}_donate`])} 元</td>
                                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.award[0][`${item.code}_premium`])}</td>
                                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.award[0][`${item.code}_platinum`])}</td>
                                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.award[0][`${item.code}_gold`])}</td>
                                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.award[0][`${item.code}_silver`])}</td>
                                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.award[0][`${item.code}_copper`])}</td>
                                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.award[0][`${item.code}_wood`])}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    )
}
export default Award