import React, { useState } from "react";
import { withRouter } from "react-router-dom";

const ShowLogo = (props) => {
    let PropCompany = props.PropCompany;
    let type = PropCompany[0] //life or property
    let company = PropCompany[1] //保險公司名稱

    return (
        <>
            {
                <img key={company} src={require(`../../assets/images/companyLOGO/${type === 'life' ? 'life' : 'property'}/${company}.png`).default} alt={company} className="company-logo mx-auto" style={{ width: '100%' }} />
            }
        </>
    )
}

export default withRouter(ShowLogo)