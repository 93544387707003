import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../../components/Loading";

import { dateChange } from "../../../assets/javascript/calTool";
import moment from 'moment';
import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'

const CustAge = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState([])
    useEffect(() => {
        LineLogin('/cust_age'); //LINE 登入狀態驗證
        Countfunc('重要提醒_近期增歲', 25)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CusList`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/cust_age');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data.Data)
                        setIsLoading(false)
                    } else if (res.data.ResponseCode === '-20') {
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, [])
    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-3">
                <p className="text-danger">＊保險年齡將於此月或下月增加一歲之客戶清單</p>
                <div className="table-responsive-xxl text-center">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>姓名/公司名</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>生日/成立日</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>身分證號/統編</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>性別</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>聯絡電話</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>手機號碼</th>
                                <th className="text-center" style={{ whiteSpace: 'nowrap' }}>通訊地址</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={data.length === 0 ? '' : 'd-none'}>
                                <td className={`text-start ${data.length === 0 ? '' : 'd-none'}`} colSpan='8'>{data.length === 0 ? '無資料' : ''}</td>
                            </tr>
                            {
                                data.filter((val, index) => {
                                    let day = dateChange(moment(new Date()).subtract(5, 'months').format('YYYY-MM-DD')).split('-')[1]
                                    let changeDate = dateChange(val.Birthday).split('-')[1]
                                    if (day === changeDate || String(`0${Number(day) - 1}`) === changeDate) {
                                        return val
                                    }
                                }).sort((a, b) => {
                                    return dateChange(a.Birthday).split('-')[1] + dateChange(a.Birthday).split('-')[2] > dateChange(b.Birthday).split('-')[1] + dateChange(b.Birthday).split('-')[2] ? 1 : -1
                                }).map((item, index, ary) => {
                                    return (
                                        <tr>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.Name}</td>
                                            <td className="text-dark-blue fw-bolder" style={{ whiteSpace: 'nowrap' }}>{dateChange(item.Birthday)}</td>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.ID}</td>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.SEX}</td>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.TEL}</td>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.Mobile}</td>
                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.Address}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default CustAge;