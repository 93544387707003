import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faExclamation } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Loading from '../../../components/Loading';
import { Decrypt } from '../../../assets/javascript/AESTool';

const ConferenceRegistration = () => {
    // /registration?cu=CU2310170006&cuName=2024市場趨勢與公勝策略方針

    const lineId = localStorage.getItem('_eventCheckLogin')
    let [caseData, setCaseData] = useState({ name: null, id: null }),
        [state, setState] = useState(null), //狀態(接 API Response data)
        [circleLoading, setCircleLoading] = useState(true),
        [textLoading, setTextLoading] = useState(false),
        [errorTextText, setErrorTextText] = useState('')

    useEffect(() => {
        //抓課程 id
        const getUrlString = window.location.href;
        const url = new URL(getUrlString);
        let caseId = url.searchParams.get('cu')
        let caseName = url.searchParams.get('cuName')
        document.cookie = `caseId=${caseId};`;
        //抓 line id
        if (caseId == null || caseId == 'null' || caseId == '' || caseId == undefined) {
            setCircleLoading(false)
            setTextLoading(false)
            setErrorTextText('抓取課程錯誤，請關閉重新掃描連結。')
        } else if (lineId == '' || lineId == null || lineId == undefined) {
            document.cookie = `caseId=${caseId};`;
            localStorage.setItem('_eventCheckUrl', `/registration?cu=${caseId}&cuName=${caseName}`)
            setTimeout(() => { window.location.href = '/checkIn/login' }, 1200)
            return
        } else {
            setCaseData({ id: caseId, name: caseName })
        }
    }, [])
    const handleEvent = {
        APIiSmartPlot: function () {
            setTextLoading(true)
            setCircleLoading(false)
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/Plot`
            let data = {
                LineId: JSON.parse(Decrypt(lineId, process.env.REACT_APP_KEY, process.env.REACT_APP_IV))?.lineId,
                CU: caseData.id
            }
            axios.post(API, data)
                .then((res) => {
                    let { ResponseCode } = res.data
                    setTextLoading(false)
                    if (ResponseCode == '0') {
                        setState({ ResponseCode: 'success', ResponseMsg: '簽到成功' })
                    } else if (ResponseCode == '-1') {
                        setState({ ResponseCode: 'error', ResponseMsg: '系統內部出現錯誤' })
                    } else if (ResponseCode == '-93') {
                        setState({ ResponseCode: 'warning', ResponseMsg: '非策略會議課程，請重新掃描連結' })
                    } else if (ResponseCode == '-96') {
                        setState({ ResponseCode: 'warning', ResponseMsg: '課程尚未開放簽到' })
                    } else if (ResponseCode == '-89') {
                        setState({ ResponseCode: 'error', ResponseMsg: '此課程已經結束報到，請與現場人員聯繫' })
                    } else if (ResponseCode == '-95') {
                        setState({ ResponseCode: 'error', ResponseMsg: '學員未在名單內' })
                    } else if (ResponseCode == '-90') {
                        setState({ ResponseCode: 'other', ResponseMsg: '請勿重複簽到' })
                    } else if (ResponseCode == '-94') {
                        setState({ ResponseCode: 'warning', ResponseMsg: '請先加入iSmart' })
                    } else {
                        setState({ ResponseCode: 'error', ResponseMsg: '錯誤' })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setTextLoading(false)
                    setState({ ResponseCode: 'error', ResponseMsg: '系統內部出現錯誤' })
                })
        },
        stateCard: function (code, val) {
            if (code == 'success') {
                return (
                    <>
                        <FontAwesomeIcon icon={faCheck} className="mx-2" style={{ fontSize: '18px' }} />
                        <p className='fs-4 mb-2'>簽到成功</p>
                    </>
                )
            } else if (code == 'error') {
                return (
                    <>
                        <FontAwesomeIcon icon={faTimes} className="mx-2" style={{ fontSize: '18px' }} />
                        <p className='fs-4 mb-2'>簽到失敗</p>
                        <p className='text-challenges-blue'>{val}</p>
                    </>
                )
            } else if (code == 'warning') {
                return (
                    <>
                        <FontAwesomeIcon icon={faExclamation} className="mx-2" style={{ fontSize: '18px' }} />
                        <p className='fs-4 mb-2'>簽到失敗</p>
                        <p className='text-challenges-blue'>{val}</p>
                    </>
                )
            } else if (code == 'other') {
                return (
                    <>
                        <FontAwesomeIcon icon={faExclamation} className="mx-2" style={{ fontSize: '18px' }} />
                        <p className='fs-4 mb-2'>{val}</p>
                    </>
                )
            }
        }
    }

    useEffect(() => {
        if (caseData.id !== null) {
            handleEvent.APIiSmartPlot()
        }
    }, [caseData])

    let style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
    }
    return (
        <>
            <Loading isLoading={circleLoading} />
            <Loading isLoading={textLoading} circleHide={true}>
                <h5 className={`text-center bg-golden-brown p-2 rounded goto-page pe-5 fw-bolder`}
                    data-text="•••">課程簽到中</h5>
            </Loading>
            <div className='min-vh-100' style={{ background: 'linear-gradient(90deg, #2FABF4, #1671bc)' }}>
                <div style={style} className='text-center'>
                    <img src={require('../../../assets/images/golden_chicken.png').default} className='img-fluid mb-3' width='90px' />
                    {
                        caseData.id !== null ?
                            <>
                                <p className='text-center fw-bolder mb-2 text-light'>課程名稱</p>
                                <h3 className="fw-bolder text-light text-center px-3" style={{ whiteSpace: 'break-spaces' }}>
                                    {caseData.name}
                                </h3>
                                {
                                    state !== null &&
                                    <div className='mt-3 d-inline-block text-light p-3 fw-bolder' style={{ background: 'rgba(255,255,255,0.2)', borderRadius: '10px' }}>
                                        {
                                            handleEvent.stateCard(state.ResponseCode, state.ResponseMsg)
                                        }
                                    </div>
                                }
                            </>
                            :
                            <div className='text-center text-light fw-bolder'>
                                <p style={{ fontSize: '18px' }}>{errorTextText}</p>
                            </div>
                    }
                </div>
            </div>
        </>
    );
};

export default ConferenceRegistration