import React,{useEffect} from "react";
import Countfunc from "../../assets/javascript/countfunc"

//component
import Banner from "../../components/addMembers/Banner";

//image url
import BannerUrl from "../../assets/images/components/mission.jpg";

const PublicityVideo = () => {
    const style = { //banner background style
        backgroundImage: `url(${BannerUrl})`,
    }
    const title = "Golden Life\n共同創造，美好人生"; //banner title
    useEffect(() => {
        Countfunc('使命優勢',42)
    }, []);
    return (
        <>
            <Banner title={title} style={style} />
            <div className="video mt-3">
                <div className="d-flex flex-column align-items-center py-3">
                    <h3 className="text-center mb-2 fw-bolder text-golden-brown">公勝精神</h3>
                    <iframe width="100%" src="https://www.youtube.com/embed/VuL94kAcry0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className="d-flex flex-column align-items-center py-3">
                    <h3 className="text-center mb-2 fw-bolder text-golden-brown">公勝公益</h3>
                    <iframe width="100%" src="https://www.youtube.com/embed/3CgxF_Sk8-A" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
        </>
    );
}

export default PublicityVideo;