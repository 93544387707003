import axios from "axios";
import React, { useEffect, useState } from "react";

import Loading from "../../../components/Loading";
import InsideNav from '../../../components/InsideNav';

import { toCurrency } from '../../../assets/javascript/calTool'
import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'

const ProductRank = () => {
    const insideNavItem = [
        {
            text: '日',
        },
        {
            text: '月',
        },
        {
            text: '年',
        },
    ];
    let [option, setOption] = useState(insideNavItem[0].text)
    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState([])
    useEffect(() => {
        LineLogin('/products_rank'); //LINE 登入狀態驗證
        Countfunc('競賽業績_商品排行', 30)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}products_rank`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/products_rank');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData([res.data.PRD])
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);

    const handleClick = (e) => {
        let { innerText } = e.target;
        setOption(innerText)
    }
    return (
        <>
            <Loading isLoading={isLoading} />
            <InsideNav insideNavItem={insideNavItem} handleClick={handleClick} option={option} />
            <div className="container mt-4">
                {
                    data.map((item, index) => {
                        if (option === '日') {
                            return item.D
                        } else if (option === '月') {
                            return item.M
                        } else if (option === '年') {
                            return item.Y
                        }
                        return true
                    }).map((item, index) => {
                        if (item.length === 0) {
                            return (
                                <>
                                    <p></p>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <div className={`mt-3`} key={index}>
                                        <div className="d-flex justify-content-between fw-bolder text-dark-blue">
                                            <p>每{option}商品業績排行</p>
                                            <p>佔總FYC {item[0].fycPercentTop20.toFixed(2)}%</p>
                                        </div>
                                        <div className="table-responsive-xxl text-center">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>排名</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>保險公司</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>商品名稱</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>FYC</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>FYC占率</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>FYA</th>
                                                        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>件數</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        item.map((kidItem, index) => {
                                                            return (
                                                                <tr key={kidItem.productName}>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{kidItem.rank}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{kidItem.companyAlias}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{kidItem.productName}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(Math.round(kidItem.fyc))}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{kidItem.fycPercent}%</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(Math.round(kidItem.fya))}</td>
                                                                    <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(kidItem.qty)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })
                }
            </div>
        </>
    )
}

export default ProductRank