import React, { useState, useEffect } from 'react';
//from
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Decrypt } from '../../../assets/javascript/AESTool';
import Loading from '../../../components/Loading';
import moment from 'moment/moment';
import axios from 'axios';
import AlertMes from '../../../components/Alert';
const CheckInGoldenfp = () => {
    let [isLoading, setIsLoading] = useState(true)

    let [userData, setUserData] = useState(null),
        [cuKey, setCuKey] = useState(null),
        [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' })

    let courseList = [
        { date: '2024-04-18 10:15:00', course: '財務顧問的價值主張與商業模式創新' },
        { date: '2024-04-18 13:30:00', course: '從新人顧問啟蒙的黃金 90Days 與資深顧問領導、輔導藝術看新世代的財務顧問服務' },
        { date: '2024-04-18 15:30:00', course: '以信託建構企業與家族傳承的堅實橋梁' },
        { date: '2024-04-18 17:30:00', course: '其他' },
        { date: '2024-04-19 09:00:00', course: '人工智慧在財富管理與保險業之趨勢與應用' },
        { date: '2024-04-19 10:40:00', course: '錢進AI，財有未來' },
        { date: '2024-04-19 13:30:00', course: '轉型升級專業致勝–邁向財顧成功之路' },
        { date: '2024-04-19 15:30:00', course: '財富管理新思維–從總經出發分析最佳資產配置與財富管理策略' },
        { date: '2024-04-19 16:00:00', course: '其他' }
    ]

    useEffect(() => {
        //確認登入狀態 - 是否有 for meeting cookies
        //同時存 localStorage & cookies

        //@ 判斷是否有登入 cookies & localStorage
        const cookies = document.cookie.replace(/(?:(?:^|.*;\s*)_eventCheckLogin\s*=\s*([^;]*).*$)|^.*$/, '$1');
        if ((localStorage.getItem('_eventCheckLogin') == null || localStorage.getItem('_eventCheckLogin') == undefined) && cookies == '') {
            localStorage.setItem('_eventCheckUrl', `/question/goldenfp`)
            setTimeout(() => { window.location.href = '/checkIn/login' }, 1200)
        } else {
            let goldenfpUser = ''
            if (cookies == '') {
                goldenfpUser = JSON.parse(Decrypt(localStorage.getItem('_eventCheckLogin'), process.env.REACT_APP_KEY, process.env.REACT_APP_IV))
            } else {
                goldenfpUser = JSON.parse(Decrypt(cookies, process.env.REACT_APP_KEY, process.env.REACT_APP_IV))
            }
            setUserData(goldenfpUser)
            setIsLoading(false)
            if (moment().format('YYYY/MM/DD') == '2024/04/18') {
                setCuKey('FC2404181234')
            } else if (moment().format('YYYY/MM/DD') == '2024/04/19') {
                setCuKey('FC2404195678')
            } else {
                setCuKey('TESTCODE001')
            }
        }
    }, []);

    const validationSchema = Yup.object().shape({
        Question: Yup.string()
            .required('尚未填寫提問！'),
        Course: Yup.string()
            .required('尚未選擇！')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        handleAPI.FCQ(data)
    }
    const handleAPI = {
        FCQ: function (submitData) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/FCQ`
            let postData = {
                CU: cuKey,
                LineId: userData.lineId,
                Q: `${submitData.Course}_${submitData.Question}`
            }
            axios.post(API, postData)
                .then((res) => {
                    let { ResponseCode } = res.data
                    if (ResponseCode == '0') {
                        setAlertMes({ mes: '感謝您的提問~我們已收到(0)', show: true, color: 'success' })
                        reset()
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 2500)
                    } else if (ResponseCode == '1') { //系統內部出現錯誤
                        setAlertMes({ mes: '系統內部出現錯誤，請聯繫管理人員(1)', show: true, color: 'danger' })
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 2500)
                    } else if (ResponseCode == '-93') {
                        setAlertMes({ mes: '尚未開放提問！(-93)', show: true, color: 'danger' })
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 2500)
                    } else if (ResponseCode == '-96') {
                        setAlertMes({ mes: '不在課程範圍內！(-96)', show: true, color: 'danger' })
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 2500)
                    } else {
                        setAlertMes({ mes: `送出提問出現錯誤！請重新送出(${ResponseCode})`, show: true, color: 'danger' })
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 2500)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setAlertMes({ mes: '網路連線錯誤!請聯繫管理人員(err)', show: true, color: 'danger' })
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 2500)
                })
        }
    }

    useEffect(() => { }, [userData, cuKey])
    useEffect(() => {
        if (alertMes.show) {
            setTimeout(() => {
                setAlertMes({ mes: '', show: false, color: 'transparent' })
            }, 2000)
        }
    }, [alertMes])

    return (
        <>
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} setAlertMes={setAlertMes} />
            <Loading isLoading={isLoading} />
            <div className='goldenfp-bg'>
                <div className='container d-flex flex-column justify-content-between align-items-center' style={{ minHeight: '100vh' }}>
                    <div className='row'>
                        {/* header */}
                        <div className='text-center py-5'><img src={require('../../../assets/images/goldenfp/LOGO.png').default} className='img-fluid' width='230px' /></div>
                        {/* content */}
                        <div className='col-12 col-md-8 py-3 mx-auto'>
                            {
                                moment().format('YYYY/MM/DD') == '2024/04/18' || moment().format('YYYY/MM/DD') == '2024/04/19' ?
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <select className={`form-select mb-3 ${errors.Course ? 'is-invalid' : ''}`} id="role" name="Course" {...register('Course')}>
                                            <option className='fw-bolder' value='' selected>--請選擇--</option>
                                            {
                                                courseList.filter(val => {
                                                    if (moment().format('YYYY-MM-DD') == moment(val.date).format('YYYY-MM-DD')) {
                                                        return (val)
                                                    }
                                                }).map((item, index) => {
                                                    return (<option key={`course-${index + 1}`} className='fw-bolder' value={`${item.course}`}>{item.course}</option>)
                                                })
                                            }
                                        </select>
                                        <div className='invalid-feedback'>{errors.Course?.message}</div>
                                        <h4 className='fw-bolder mb-3 text-center fs-6' style={{ color: '#fff' }}>請問您想了解什麼呢？請提出您的問題！</h4>
                                        <div className="mb-3">
                                            <textarea type="text" className={`form-control ${errors.Question ? 'is-invalid' : ''}`} id="question" placeholder="" {...register('Question')} rows={10}></textarea>
                                            <div className='invalid-feedback'>{errors.Question?.message}</div>
                                        </div>
                                        <div className="text-center mt-3">
                                            <button type="submit" className='btn btn-golden-brown fw-bolder'>送出</button>
                                        </div>
                                    </form>
                                    :
                                    <div className='fw-bolder fs-3 text-center' style={{ height: '300px', color: '#fff' }}>
                                        <p>尚未開放提問！</p>
                                    </div>
                            }
                        </div>
                    </div>
                    {/* footer */}
                    <div className='text-center my-5'><img src={require('../../../assets/images/goldenfp/公勝LOGO.svg').default} width={'100px'} /></div>
                </div>
            </div>
        </>
    )
}
export default CheckInGoldenfp