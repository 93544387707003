import axios from 'axios';

export default function downloadFile(API, fileName, downloadFile, pageName, loading) {
    // const token = document.cookie.replace(/(?:(?:^|.*;\s*)iSmartToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    let params = new URLSearchParams();
    params.append('file', downloadFile);
    axios.post(API, params)
        .then((res) => {
            setTimeout(() => {
                if (res.data.ResponseCode === '-1') {
                    localStorage.setItem('page', pageName);
                    window.location.href = '/login'
                    return
                } else {
                    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
                        window.location.href = res.data
                    } else {
                        loading(false)
                        let link = document.createElement('a')
                        let body = document.querySelector('body')
                        link.href = res.data
                        link.download = `${fileName}`
                        link.style.display = 'none'
                        link.target = '_blank'
                        body.appendChild(link)
                        link.click()
                        body.removeChild(link)
                        window.URL.revokeObjectURL(link.href)
                    }
                }
            }, Number(process.env.REACT_APP_TIMEOUT))
        })
        .catch((err) => {
            alert('請重新整理')
        })
}