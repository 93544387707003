import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Loading from 'components/Loading';
import moment from 'moment';
import QRCode from "react-qr-code";
import { Modal } from 'react-bootstrap'
import html2canvas from 'html2canvas'

const Assistant = (props) => {
    let [isLoading, setLoading] = useState(false),
        [btnLoading, setBtnLoading] = useState(false), // 按鈕 loading
        [filterState, setFilterState] = useState('all'),
        [assistantData, setAssistantData] = useState([]),
        [salMstData, setSalMstData] = useState(null),
        [modalShow, setModalShow] = useState(false)

    const handleAPI = {
        getAllAssistant: function () {
            setLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}get_all_assistant`
            axios.get(API)
                .then((res) => {
                    console.log(res)
                    let { code, data } = res.data
                    if (code == '0') {
                        setLoading(false)
                        setAssistantData(data)
                    } else {
                        setLoading(false)
                        alert(`GET get_all_assistant 錯誤。(${code})`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setLoading(false)
                    alert(`網路連線錯誤，請聯繫管理員。(get_all_assistant:err)`)
                })
        },
        generateAssistantCode: function (postData) {
            setLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}generate_assistant_code`
            let postParams = new URLSearchParams();
            postParams.append('SAL_MST_PERSONALID', postData.SAL_MST_PERSONALID);
            postParams.append('SAL_MST_NAME', postData.SAL_MST_NAME);
            axios.post(API, postParams)
                .then((res) => {
                    let { code, data } = res.data
                    if (code == '0') {
                        let qrData = {
                            SAL_MST_LAY_OFF_DATE: null,
                            SAL_MST_NAME: postData.SAL_MST_NAME,
                            SAL_MST_PERSONALID: postData.SAL_MST_PERSONALID,
                            SAL_MST_QRCODE: data
                        }
                        handleEvent.createQrCode(qrData)
                        setLoading(false)
                    } else {
                        setLoading(false)
                        alert(`POST generate_assistant_code 錯誤。(${code})`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setLoading(false)
                    alert(`網路連線錯誤，請聯繫管理員。(generate_assistant_code:err)`)
                })
        }
    }
    const handleEvent = {
        filterData: function (filterState) {
            let returnData = assistantData.filter((val) => {
                if (filterState == 'all') {
                    return val
                } else if (filterState == 'layOn') {
                    return val.SAL_MST_LAY_OFF_DATE == null
                } else if (filterState == 'layOff') {
                    return val.SAL_MST_LAY_OFF_DATE !== null
                }
            })
            return returnData
        },
        createQrCode: function (data) {
            if (data.SAL_MST_QRCODE == null) { //尚未產生過 QRCODE 打 API generateAssistantCode
                handleAPI.generateAssistantCode(data)
            } else { //已產生過，直接產生 QRCODE
                setModalShow(true)
                setSalMstData(data)
            }
        },
        downloadImg: function (e, data) {
            setBtnLoading(true)
            e.preventDefault()
            let shareContent = document.getElementById('downloadFile');//截圖範圍
            let width = shareContent.offsetWidth; //寬度 shareContent.offsetWidth
            let height = shareContent.offsetHeight; // 高度 shareContent.offsetHeight

            let canvas = document.createElement("canvas");
            let scale = 4;
            canvas.width = width * scale; //寬度 * 縮放
            canvas.height = height * scale; //高度 * 縮放
            canvas.getContext("2d")
            let ctx = canvas.getContext("2d");
            ctx.webkitImageSmoothingEnabled = false;
            ctx.mozImageSmoothingEnabled = false;
            ctx.imageSmoothingEnabled = false;

            let opts = {
                dpi: 144,
                scale: scale,
                canvas: canvas,
                logging: true,
                width: width, //dom 原始寬度
                height: height, //dom 原始高度
                backgroundColor: 'transparent',
                useCORS: true, //解決圖片跨域問題
                allowTaint: true,
                removeContainer: true,
            };
            html2canvas(shareContent, opts).then(function (canvas) {
                let a = document.createElement('a');
                a.href = canvas.toDataURL("image/jpeg", 0.92).replace("image/jpeg", "image/octet-stream");
                a.download = `${data.SAL_MST_NAME}_NPS_QRCode.jpg`;
                a.click();
                setBtnLoading(false)
            });
        }
    }
    useEffect(() => {
        handleAPI.getAllAssistant()
    }, [])

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" id="viewport" />
            </Helmet>
            <Loading isLoading={isLoading} />
            <h2 className='fs-4 text-dark-blue fw-bolder p-3'>{props.title}</h2>
            <div className="h-100">
                <div className='container p-4 challenge position-relative'>
                    <div className='d-flex align-items-center fw-bolder' onChange={e => setFilterState(e.target.value)}>
                        <div className='me-2'>篩選狀態：</div>
                        <div className='form-check form-check-block me-3'>
                            <input className='form-check-input' type='radio' name={`filterState`} id='all' value='all' defaultChecked={filterState == 'all'} />
                            <label className='form-check-label' htmlFor={`all`}>全部</label>
                        </div>
                        <div className='form-check form-check-block me-3'>
                            <input className='form-check-input' type='radio' name={`filterState`} id='layOn' value='layOn' defaultChecked={filterState == 'layOn'} />
                            <label className='form-check-label' htmlFor={`layOn`}>在職</label>
                        </div>
                        <div className='form-check form-check-block me-3'>
                            <input className='form-check-input' type='radio' name={`filterState`} id='layOff' value='layOff' defaultChecked={filterState == 'layOff'} />
                            <label className='form-check-label' htmlFor={`layOff`}>離職</label>
                        </div>
                        <div>-- 共：{handleEvent.filterData(filterState)?.length} 筆</div>
                    </div>
                    {
                        <div className='table-responsive-xxl text-center mt-1 mt-sm-3' style={{ height: '80vh', overflow: 'scroll' }}>
                            {
                                <table className='table table-striped table-light align-middle'>
                                    <thead>
                                        <tr>
                                            <th width='150'>姓名</th>
                                            <th width='180' className='text-start'>狀態</th>
                                            <th>QRCode</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {assistantData.length > 0 ?
                                            handleEvent.filterData(filterState).map((item, index, arry) => {
                                                return (
                                                    <tr key={`assistantData-${index + 1}`}>
                                                        <td className='fw-bolder'>{item.SAL_MST_NAME}</td>
                                                        <td className='fw-bolder text-start'>{
                                                            item.SAL_MST_LAY_OFF_DATE == null ?
                                                                <span className='text-success'>在職</span> :
                                                                <span className='text-danger'>離職：{moment(item.SAL_MST_LAY_OFF_DATE).format('YYYY-MM-DD')}</span>
                                                        }</td>
                                                        <td>
                                                            {item.SAL_MST_LAY_OFF_DATE == null && <button className='btn fw-bolder text-dark-blue' onClick={e => handleEvent.createQrCode(item)}><u>產生</u></button>}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : '查無資料'
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                    }
                </div>
            </div>
            {
                salMstData !== null &&
                <Modal show={modalShow} onHide={e => (setModalShow(false), handleAPI.getAllAssistant())} style={{ zIndex: '10000' }}>
                    <Modal.Header closeButton>
                        <h5 className='fw-bolder'>{salMstData.SAL_MST_NAME}</h5>
                    </Modal.Header>
                    <Modal.Body id="downloadFile" className='bg-light'>
                        <div className='text-center my-4'>
                            <img src={require('assets/images/goldennet_logo.svg').default} width={250} />
                            <div className='my-3'>
                                <h2 className='fw-bolder text-golden-brown' style={{ fontSize: '28px' }}>喜歡我的服務嗎？</h2>
                                <p className='fw-bolder text-golden-brown' style={{ fontSize: '24px' }}>歡迎您拿起手機幫我評分！</p>
                            </div>
                            <p className='fw-bolder mb-2' style={{ fontSize: '20px' }}>{salMstData.SAL_MST_NAME} 行專</p>
                            <QRCode
                                // fgColor='#8F7140'
                                value={`https://ismartplus.goldennet.com.tw/nps_assistant/${salMstData.SAL_MST_QRCODE}`} />
                        </div>
                    </Modal.Body>
                    <div className='my-3 text-center'>
                        <button className='btn btn-primary fw-bolder' id='save-local' onClick={e => handleEvent.downloadImg(e, salMstData)} disabled={btnLoading}>
                            <div className={`spinner-border text-light spinner-border--width me-2 ${btnLoading ? 'd-inline-block' : 'd-none'}`} style={{ width: '1rem', height: '1rem' }}></div>
                            下載圖片
                        </button>
                    </div>
                </Modal>
            }
        </>
    )
}
export default Assistant