import React, { useEffect, useState } from 'react';
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltH, faArrowsAltV } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../components/Loading'
import { toCurrency } from '../../assets/javascript/calTool'
import axios from 'axios';
import Countfunc from '../../assets/javascript/countfunc';
function CardifFireIns() {

    //* 登入驗證
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)iSmartToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const idToken = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    useEffect(() => {
        if (!token || !idToken) {
            localStorage.setItem('page', '/cardif_fireIns')
            // 跳轉回 login 頁面
            setTimeout(() => { window.location.href = '/login' }, 1000)
        } else {
            // 將 token 加到 headers 表頭裡
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        Countfunc('法巴產火險試算', 53)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/cardif_fireIns');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode == '0') {
                        readExcel('read')
                    } else {
                        alert(`請聯繫管理人員 (${res.data.ResponseCode})`)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                // alert('請重新整理')
            })
    }, []);
    //* -- END

    //* 讀取分析試算表
    let [valueData, setValueData] = useState(null) //儲存 EXCEL 分析後資料
    function readExcel(type) { 
        let url = "excel/火險保額保費試算及須提供之投保資料(含填寫範例).xlsx";
        let oReq = new XMLHttpRequest();
        oReq.open("GET", url, true);
        oReq.responseType = "arraybuffer";

        oReq.onload = function (e) {
            let arraybuffer = oReq.response;
            let data = new Uint8Array(arraybuffer);
            let arr = new Array();
            for (let i = 0; i !== data.length; ++i)
                arr[i] = String.fromCharCode(data[i]);
            let bstr = arr.join("");

            // 呼叫 xlsx
            let workbook = XLSX.read(bstr, {
                type: "binary"
            });

            // 取得工作表 sheet
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];

            if (type == 'read') { //讀取
                const data = XLSX.utils.sheet_to_json(worksheet);

                let initData = { //初始抓值
                    city: [],
                    mCost: {},
                    rate: []
                }
                data.shift()
                data.map((item, index) => {
                    // 刪除 object key
                    delete item.__EMPTY
                    delete item.__EMPTY_1
                    delete item.__EMPTY_2
                    delete item.__EMPTY_3
                    delete item.__EMPTY_4
                    delete item.__EMPTY_5
                    delete item['★此試算表僅供試算，實際內容以法巴正式提供之要保書為準']

                    if (index == 0) { // 取得縣市
                        initData.city = Object.values(item)
                    }
                    if (index >= 1 && index <= 21) { // 取得造價
                        initData.mCost = { ...initData.mCost, [index]: Object.values(item) }
                    }
                    if (item.__EMPTY_7 == '否') { //取得費率 == 鋼骨造為"否"
                        initData.rate = Object.values(item)
                    }
                })
                setValueData(initData)
            }
        };
        oReq.send();
    }
    //* -- END

    //@ DATA
    let floorData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25] //設定樓層

    //@ VALUE
    let [unit, setUnit] = useState(true), //true 平方公尺 , false坪數
        [basicData, setBasicData] = useState({ //基本資料
            m2: null, //平方公尺
            s: null, //坪數
            city: null,
            floor: 1, //樓層
            isSteelFrame: 'N', //是否為鋼骨造
            mCost: 0, //每坪造價
        }),
        [feeData, setFeeData] = useState({ //保費
            totalFee: 0, //總保費
            earthquakeFee: 0, //地震險保費
            fireFee: 0, //火險保費
            fireAmount: 0, //火險保額
            rate: 0.205 //費率
        }),
        [isLoading, setIsLoading] = useState(true)

    //@ EVENT
    const handleEvent = {
        setValue: function (type, val) { //@ 寫入 value
            if (type == 'm2' || type == 's') { //* 平方公尺 , 坪數轉換 (m2:平方公尺 , s:坪數)
                if (isNaN(Number(val))) { //非數字

                } else { //為數字
                    if (val == '') {
                        setBasicData({ ...basicData, m2: null, s: null })
                    } else {
                        if (type == 'm2') {
                            setBasicData({ ...basicData, m2: Number(val), s: (Number(val) * 0.3025).toFixed(3) })
                        } else if (type == 's') {
                            setBasicData({ ...basicData, s: Number(val), m2: (Number(val) / 0.3025).toFixed(3) })
                        }
                    }
                }
            } else if (type == 'floor') { //* 選擇樓層
                if (isNaN(Number(val))) { //非數字

                } else { //為數字
                    if (val == '') {//防呆預設，如果為 '' 預設為 1 樓
                        setBasicData({ ...basicData, floor: 1 })
                    } else {
                        setBasicData({ ...basicData, floor: Math.ceil(Number(val)) })
                        setFeeData({ ...feeData, rate: valueData.rate[val] })
                    }
                }
            }
        },
        getCost: function (val, floor) { //@ 取得造價
            valueData.city?.map((item, index) => {
                if (val == index) {
                    if (floor > 21) { // 如果超過 21 樓，造價都一律抓取 21 樓
                        setBasicData({ ...basicData, city: item, cityKey: val, mCost: Number(valueData.mCost['21'][val]) })
                    } else {
                        setBasicData({ ...basicData, city: item, cityKey: val, mCost: Number(valueData.mCost[floor][val]) })
                    }
                }
            })
        },
        calFee: function () { //@ 計算保費
            if (Object.values(basicData).includes(null)) { //判斷資料內是否有 null
                setFeeData({ rate: feeData.rate, totalFee: 0, earthquakeFee: 0, fireFee: 0, fireAmount: 0 })
                return
            } else {
                let thisFireAmount = 0, //火險保額
                    thisFireFee = 0, // 火險保費
                    thisEarthquakeFee = 0 //地震險保費

                //* 計算保額 >> 公試: Y -- (坪數 * 每坪造價) * (1.16/10000) ; N -- (坪數 * 每坪造價) /10000 
                if (basicData.isSteelFrame == 'Y') { //鋼骨造 -- 是
                    thisFireAmount = Math.ceil((Number(basicData.s) * Number(basicData.mCost)) * (1.16 / 10000))
                } else if (basicData.isSteelFrame == 'N') { //鋼骨造 -- 否
                    thisFireAmount = Math.ceil((Number(basicData.s) * Number(basicData.mCost)) / 10000)
                }

                //* 保費
                thisFireFee = thisFireAmount * feeData.rate * 10 //火險保費 >> 公試: 火險保額*費率*10

                if (thisFireAmount > 150) { //地震險保費，如果火險保額超過 150 ，保費為 1350。>> 公試: 火險保額*1350/150
                    thisEarthquakeFee = 1350
                } else {
                    thisEarthquakeFee = thisFireAmount * 1350 / 150
                }

                setFeeData({
                    ...feeData,
                    totalFee: Math.round(thisFireFee) + Math.round(thisEarthquakeFee), //總保費
                    earthquakeFee: Math.round(thisEarthquakeFee), //地震險保費
                    fireFee: Math.round(thisFireFee), //火險保費
                    fireAmount: thisFireAmount, //火險保額
                })
            }
        }
    }

    useEffect(() => {
        if (valueData !== null) {
            handleEvent?.getCost(0, 1)
            setTimeout(() => { setIsLoading(false) }, 500)
        }
    }, [valueData])

    useEffect(() => {
        handleEvent.calFee()
    }, [basicData])

    return (
        <>
            <Loading isLoading={isLoading} />
            <div style={{ minHeight: '100vh', background: '#00965e' }}>
                <div className='container py-4 cardif-fireins'>
                    {
                        valueData !== null &&
                        <>
                            <div className='rounded bg-light p-3'>
                                <img src={require('../../assets/images/companyLOGO/property/法國巴黎產物-橫.png').default} className='img-fluid mb-4' alt='法國巴黎產險' width='450px' />
                                <h4 className='fw-bolder mb-4' style={{ borderLeft: '5px solid #00965e', paddingLeft: '5px' }}>火險</h4>
                                <div>
                                    <p className='fw-bolder' style={{ fontSize: '18px' }}>保額保費計算</p>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 my-2'>
                                            <label for='cityList' className='form-label fw-bolder'>地區</label>
                                            <select className='form-select fw-bolder' id='cityList' onChange={e => handleEvent?.getCost(e.target.value, basicData?.floor)}>
                                                {valueData.city?.map((item, index) => {
                                                    return (
                                                        <option className='fw-bolder' value={index} selected={basicData?.city == item && 'selected'}>{item}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className='col-12 col-md-6 my-2'>
                                            <label for='floor' className='form-label fw-bolder'>樓層</label>
                                            <select className='form-select fw-bolder' id='cityList' onChange={e => (handleEvent?.setValue('floor', e.target.value), handleEvent?.getCost(basicData.cityKey, e.target.value))}>
                                                {
                                                    floorData?.map((item, index) => {
                                                        return (
                                                            <option className='fw-bolder' value={item} selected={basicData?.floor == item && 'selected'}>{item}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-12 my-2'>
                                            <label className='form-label fw-bolder mb-0'>坪數</label>
                                            <div className='d-flex flex-column flex-md-row align-items-center justify-content-center'>
                                                <div className={`input-group ${unit ? 'order-1' : 'order-3'}`}>
                                                    <input type='number' className='form-control' disabled={!unit && 'disabled'} value={basicData['m2'] == null ? '' : basicData['m2']} onChange={e => handleEvent?.setValue('m2', e.target.value)} />
                                                    <span className='input-group-text fw-bolder text-light' style={{ background: '#00965e' }}>平方公尺</span>
                                                </div>
                                                <div className='text-center order-2'>
                                                    <a href='#' className='text-dark m-2' onClick={e => (e.preventDefault(), setUnit(!unit), setBasicData({ ...basicData, m2: null, s: null }))}>
                                                        <FontAwesomeIcon icon={faArrowsAltH} className='mx-2 d-none d-md-block' />
                                                        <FontAwesomeIcon icon={faArrowsAltV} className='m-2 d-md-none' />
                                                    </a>
                                                </div>
                                                <div className={`input-group ${unit ? 'order-3' : 'order-1'}`}>
                                                    <input type='number' className='form-control' disabled={unit && 'disabled'} value={basicData['s'] == null ? '' : basicData['s']} onChange={e => handleEvent?.setValue('s', e.target.value)} />
                                                    <span className='input-group-text fw-bolder text-light' style={{ background: '#00965e' }}>坪</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 my-2">
                                            <label className='form-label fw-bolder'>
                                                是否為"鋼骨造"
                                                <p style={{ color: '#676767' }}>* 如建築結構為磚、木、石造，請先詢問法巴核保</p>
                                            </label>
                                            <div className='fw-bolder' onChange={e => setBasicData({ ...basicData, isSteelFrame: e.target.value })}>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="isSteelFrame" id="isSteelFrameTrue" value="Y" checked={basicData?.isSteelFrame == 'Y' && 'checked'} />
                                                    <label className="form-check-label" for="isSteelFrameTrue">是</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="isSteelFrame" id="isSteelFrameFalse" value="N" checked={basicData?.isSteelFrame == 'N' && 'checked'} />
                                                    <label className="form-check-label" for="isSteelFrameFalse">否</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 my-2'>
                                            <label for='mcost' className='form-label fw-bolder'>每坪造價</label>
                                            <input type='text' className='form-control fw-bolder' id='mcost'
                                                value={basicData.mCost} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-light p-3 mt-3 fee-box">
                                <div className='mb-3 fw-bolder'>
                                    <div className="row">
                                        <div className="col-6 my-1">
                                            <p className='d-md-flex flex-wrap'>
                                                <span className='d-block'>火險保額：</span>
                                                <span><span className='mx-2' style={{ color: '#00965e', fontSize: '18px' }}>{toCurrency(feeData?.fireAmount)}</span>
                                                萬元</span>
                                            </p>
                                        </div>
                                        <div className="col-6 my-1">
                                            <p className='d-md-flex flex-wrap'>
                                                <span className='d-block'>費率：</span>
                                                <span className='mx-2' style={{ color: '#00965e', fontSize: '18px' }}>{feeData?.rate}</span>
                                            </p>
                                        </div>
                                        <div className="col-6 my-1">
                                            <p className='d-md-flex flex-wrap'>
                                                <span className='d-block'>火險保費：</span>
                                                <span><span className='mx-2' style={{ color: '#00965e', fontSize: '18px' }}>{toCurrency(feeData?.fireFee)}</span>
                                                元</span>
                                            </p>
                                        </div>
                                        <div className="col-6 my-1">
                                            <p className='d-md-flex flex-wrap'>
                                                <span className='d-block'>地震險保費：</span>
                                                <span><span className='mx-2' style={{ color: '#00965e', fontSize: '18px' }}>{toCurrency(feeData?.earthquakeFee)}</span>
                                                元</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='rounded' style={{ background: 'rgba(0,150,94,0.1)' }}>
                                    <p className='fw-bolder p-2 text-center' style={{ color: '#00965e', fontSize: '20px' }}>
                                        總保費：<span className='mx-2' style={{ fontSize: '26px', color: '#00965e' }}>{toCurrency(feeData?.totalFee)}</span> 元
                                    </p>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default CardifFireIns