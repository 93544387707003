import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from "react-router";

import { removeItemAll, toCurrency } from "../../assets/javascript/calTool"
import LineLogin from '../../assets/javascript/lineLogin'
import Loading from '../../components/Loading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const Assessment = (props) => {
    const { state, pathname } = useLocation();
    let [propProName] = useState(pathname.split('/')[3] || state.proName)
    let [isLoading, setIsLoading] = useState(true),
        [originalData, setOriginalData] = useState(null), //取得原始資料
        [newData, setNewData] = useState(null), //新data(整理)
        [isRetired, setIsRetired] = useState(false), //是否為樂退年齡
        [thisAge, setThisAge] = useState(null), //年齡
        [thisYear, setThisYear] = useState(null),
        [thisSex, setThisSex] = useState('male'),
        [ageList, setAgeList] = useState([]),
        [defaultSumins, setDefaultSumins] = useState(0) //預設保額，用來判斷是否符合範圍內

    useEffect(() => {
        LineLogin('/products/main1'); //LINE 登入狀態驗證
        let API = `${process.env.REACT_APP_GOLDEN_API5000}Rate/List=${propProName}`;
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/products/main1');
                        window.location.href = '/login'
                        return
                    } else {
                        setIsLoading(false)
                        if (res.data.Rate[0].data[0][0] == '樂退年齡') {
                            setIsRetired(false)
                            res.data.Rate[0].data.shift()
                            setOriginalData(res.data)
                            handleEvent.getMinAge(res.data)
                        } else {
                            setOriginalData(res.data)
                            handleEvent.getMinAge(res.data)
                        }
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);

    const handleEvent = {
        getNewData: function (originalData, thisAgeVal, thisSexVal) { //整理為 NEW DATA
            let newObj = {}
            //取得年齡 list
            let ageList = []
            originalData.Rate[0].data.map((item, index) => {
                ageList.push(item[0])
            })

            newObj.unit = originalData.unit

            if (originalData.pay == '' || originalData.pay == undefined || originalData.pay == 'NaN') {
                originalData.pay = "0.52,0.262,0.088"
            }
            newObj.pay = originalData.pay.split(',')

            let originRate = JSON.parse(JSON.stringify(originalData))
            let newRate = [] //組成新費率格式
            let obj = {
                ageType: '',
                male: {},
                female: {}
            }

            let filterNotNumberValAry = []
            originRate.Rate[0].data.map((item, index) => {
                removeItemAll(item, null)
                let deleteValueAry = []
                item.map((kitem) => {
                    let val = typeof kitem == 'number' ? kitem : parseFloat(kitem.replace(',', ''))
                    if (isNaN(val)) {
                    } else { deleteValueAry.push(val) }
                })
                filterNotNumberValAry.push(deleteValueAry)
            })

            let filterOriginRate = [] //過濾非規則費率表
            filterNotNumberValAry.map((item, index) => {
                if (item.length == 0) { }
                else {
                    filterOriginRate.push(item)
                }
            })

            //--細分男女費率
            let male = [],
                female = [],
                arrayLength = 0
            filterOriginRate.map((item, index, ary) => {
                item.shift()
                item.map((kitem, kindex, kary) => {
                    arrayLength = kary.length
                })
                obj.ageType = index
                male.push(item.slice(0, arrayLength / 2))
                female.push(item.slice(arrayLength / 2, arrayLength))
                male.map((item, index) => {
                    item.map((kitem, kindex, kary) => {
                        if (typeof (originRate.y) == 'number' || typeof (originRate.y).length == '1') {
                            obj = { ...obj, male: { ...obj.male, [originRate.y]: kitem } }
                        } else {
                            originRate.y.split(',').map((aitem, aindex, aary) => {
                                obj = { ...obj, male: { ...obj.male, [originRate.y.split(',')[kindex]]: kitem } }
                            })
                        }
                    })
                })

                female.map((item, index) => {
                    item.map((kitem, kindex) => {
                        if (typeof (originRate.y) == 'number' || typeof (originRate.y).length == '1') {
                            obj = { ...obj, female: { ...obj.female, [originRate.y]: kitem } }
                        } else {
                            originRate.y.split(',').map((aitem, aindex, aary) => {
                                obj = { ...obj, female: { ...obj.female, [originRate.y.split(',')[kindex]]: kitem } }
                            })
                        }
                    })
                })
                newRate.push(obj)
            })

            let thisRate = []
            ageList.map((item, index) => {
                thisRate.push({ [item]: newRate[index] })
            })

            thisRate.map((item, index) => {
                if (Object.keys(item)[0] == Number(thisAgeVal)) {
                    newObj.rate = item[thisAgeVal][thisSexVal]
                }
            })
            //--細分 end

            //切分 limit
            let limitObj = { age: [], yearTerm: [], amount: [] }
            //age,16,60,yearTerm,20,20,amount,0.6,1000
            originalData.limit.map((item, index) => {
                if (item.split(',')[0] == 'age') {
                    limitObj.age.push({ min: item.split(',')[1], max: item.split(',')[2] })
                    if (item.split(',')[3] == 'yearTerm') {
                        limitObj.yearTerm.push({ min: item.split(',')[4], max: item.split(',')[5] })
                        limitObj.amount.push({ min: item.split(',')[7], max: item.split(',')[8] })
                    } else if (item.split(',')[3] == 'amount') {
                        limitObj.amount.push({ min: item.split(',')[4], max: item.split(',')[5] })
                        limitObj.yearTerm = []
                    } else {
                        alert('格式錯誤')
                        window.location.href = '/products/main1'
                    }
                } else {
                    alert('格式錯誤')
                    window.location.href = '/products/main1'
                }
            })

            let arr = []
            limitObj.age.map((item, index) => {
                arr.push(item.min, item.max)
            })
            let ageMin = Math.min(...arr),
                ageMax = Math.max(...arr)
            let filterAgeList = []
            ageList.map((item, index) => {
                if (item >= ageMin && item <= ageMax) {
                    filterAgeList.push(item)
                }
            })
            newObj.ageList = filterAgeList

            //取得 yearList
            //1. 先塞選出符合 age 條件的索引
            let ageIndex = []
            limitObj.age.map((item, index) => {
                if (Number(thisAgeVal) >= Number(item.min) && Number(thisAgeVal) <= Number(item.max)) {
                    ageIndex.push(index)
                }
            })
            //2.依照 age 索引篩選出 yearTerm
            let getAllPairYearList = []
            let yearList = []
            if (limitObj.yearTerm.length == 0) {
                if (typeof originalData.y == 'number') {
                    yearList.push(Number(originalData.y))
                } else {
                    yearList = originalData.y.split(',')
                }
            } else {
                limitObj.yearTerm.map((item, index) => {
                    ageIndex.map((kitem, kindex) => {
                        if (index == kitem) {
                            getAllPairYearList.push(item)
                        }
                    })
                })
                let minYear = Math.min(...getAllPairYearList.map(item => item.min)),
                    maxYear = Math.max(...getAllPairYearList.map(item => item.max))
                let forYear = []
                for (let i = minYear; i <= maxYear; i++) {
                    forYear.push(i)
                }

                if (typeof (originalData.y) == 'number' || originalData.y.length == 1) {
                    yearList.push(originalData.y)
                } else {
                    originalData.y.split(',').map((item, index) => {
                        forYear.map((kitem, kindex) => {
                            if (item == kitem) {
                                yearList.push(item)
                            }
                        })
                    })
                }
            }

            //3.抓符合年齡條件索引的保額
            let getAllPairAmountList = []
            limitObj.amount.map((item, index) => {
                ageIndex.map((kitem, kindex) => {
                    if (index == kindex) {
                        getAllPairAmountList.push(item)
                    }
                })
            })
            let minAmount = Math.min(...getAllPairAmountList.map(item => item.min)),
                maxAmount = Math.max(...getAllPairAmountList.map(item => item.max))
            let amountObj = { min: minAmount, max: maxAmount }

            newObj.amountObj = amountObj
            newObj.yearList = yearList
            setThisYear(yearList[0])

            //-- 抓保額
            newObj.default = originalData.default == '' ? Math.round(((newObj.amountObj.min + newObj.amountObj.max) / 2)) : originalData.default
            setDefaultSumins(newObj.default)

            //單位(中文)
            if (newObj.unit == 10000) {
                newObj.unitName = '萬'
            } else {
                newObj.unitName = '萬'
            }
            setNewData(newObj)

        },
        getMinAge: function (limitData) {
            let limitObj = { age: [] }
            //age,16,60,yearTerm,20,20,amount,0.6,1000
            limitData.limit.map((item, index) => {
                if (item.split(',')[0] == 'age') {
                    limitObj.age.push({ min: item.split(',')[1], max: item.split(',')[2] })
                } else {
                    alert('格式錯誤')
                    // window.location.href = '/products/main1'
                }
            })

            let arr = []
            limitObj.age.map((item, index) => {
                arr.push(item.min, item.max)
            })
            let ageMin = Math.min(...arr)
            setThisAge(ageMin)
        },
        calButton: function (e, type, defaultValue) {
            let { value } = e.target
            if (isNaN(Number(defaultValue))) {
                setNewData({ ...newData, default: 0 })
                setDefaultSumins(0)
            } else {
                if (type == 'minus') {
                    let finalVal = Number(defaultValue) - Number(value)
                    setNewData({ ...newData, default: finalVal })
                    setDefaultSumins(finalVal)
                } else if (type == 'plus') {
                    let finalVal = Number(defaultValue) + Number(value)
                    setNewData({ ...newData, default: finalVal })
                    setDefaultSumins(finalVal)
                }
            }
        },
        calRate: function (rate, defaultValue, unit, pay, amount, unitName) {
            // money 費用; pay 繳費模式(年、季、月)
            let total = Math.round((((defaultValue * 10000) / unit) * rate))
            if (defaultSumins < newData.amountObj.min || defaultSumins > newData.amountObj.max) {
                return 0
            } else {
                if (pay == 'year') {
                    return (toCurrency(Math.round((((defaultValue * 10000) / unit) * rate))))
                }
                if (amount == 'none') {
                    if (pay == 'halfYear') {
                        return (toCurrency(Math.round(total / 2)))
                    } else if (pay == 'seasone') {
                        return (toCurrency(Math.round(total / 3)))
                    } else if (pay == 'month') {
                        return (toCurrency(Math.round(total / 12)))
                    }
                } else {
                    if (pay == 'halfYear') {
                        return (toCurrency(Math.round((((defaultValue * 10000) / unit) * rate) * amount[0])))
                    } else if (pay == 'seasone') {
                        return (toCurrency(Math.round((((defaultValue * 10000) / unit) * rate) * amount[1])))
                    } else if (pay == 'month') {
                        return (toCurrency(Math.round((((defaultValue * 10000) / unit) * rate) * amount[2])))
                    }
                }
            }

        },
        changeAmount: function (e) { //更改保額
            let { value } = e.target
            if (isNaN(Number(value))) { //判斷是否為文字
                value = 0
            }
            if (value < 0) {
                value = 0
            }
            setNewData({ ...newData, default: value })
            setDefaultSumins(value)
        }
    }

    useEffect(() => {
        if (originalData !== null && thisAge !== null) {
            handleEvent.getNewData(originalData, thisAge, thisSex)
        }
    }, [originalData, thisAge, thisSex])

    useEffect(() => {

    }, [newData, thisAge, thisYear])
    //重新搜尋
    const handleSearchReset = () => {
        window.location.href = `/products/${pathname.split('/')[2]}`
    }
    const SearchReset = {
        bottom: '0',
        width: '100%',
        zIndex: '90'
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container py-3 col-lg-6 mt-xs-0 mt-4">
                <div className="text-center">
                    <p className="fw-bolder text-golden-brown">保費試算<br />{propProName}</p>
                </div>
                <div className="form-group d-flex justify-content-center py-3">
                    <div className="form-check mx-3">
                        <input className="form-check-input" type="radio" name="sex" id="male" value="male" onChange={e => setThisSex('male')} checked={thisSex == 'male' && 'checked'} />
                        <label className="form-check-label" htmlFor="male">男性</label>
                    </div>
                    <div className="form-check mx-3">
                        <input className="form-check-input" type="radio" name="sex" id="female" value="female" onChange={e => setThisSex('female')} checked={thisSex == 'female' && 'checked'} />
                        <label className="form-check-label" htmlFor="female">女性</label>
                    </div>
                </div>
                {newData !== null &&
                    <>
                        <div className="form-group row mb-3">
                            <div className="col-6">
                                <label htmlFor="age">年齡：</label>
                                <select className="form-select" id="age" onChange={e => (setThisAge(e.target.value))}>
                                    {newData.ageList.map((item, index) => {
                                        return (
                                            <option value={item} selected={thisAge == item && 'selected'}>{item} 歲</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="col-6">
                                <label htmlFor="year">繳費年期：</label>
                                <select className="form-select" id="year" onChange={e => (setThisYear(e.target.value))}>
                                    {
                                        newData.yearList.map((item, index) => {
                                            return (
                                                <option value={item} selected={item == thisYear && 'selected'}>{item} 年</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className={`col-6 mt-3 ${isRetired ? 'd-block' : 'd-none'}`}>
                                <label htmlFor="yeretiredar">樂退年齡：</label>
                                <select className="form-select" id="retired" onChange={e => (setThisAge(e.target.value))}>
                                    {
                                        propProName == '友邦人壽_薪富人生美元利率變動型終身壽險' ?
                                            <>
                                                <option value='60'>60 歲</option>
                                            </> :
                                            <>
                                                <option value='65'>65 歲</option>
                                                <option value='70'>70 歲</option>
                                            </>
                                    }
                                </select>
                            </div>
                        </div>
                        {/* 保額 */}
                        <div className="form-group">
                            <p className="d-inline">保額：</p>
                            <div className="d-flex insured-amount--group">
                                <button type="button" className="btn btn-dark-blue mx-1" value={200} onClick={e => handleEvent.calButton(e, 'minus', newData.default)} disabled={(Number(defaultSumins) - 200) < newData.amountObj.min && 'disabled'}>-200</button>
                                <button type="button" className="btn btn-dark-blue mx-1" value={1} onClick={e => handleEvent.calButton(e, 'minus', newData.default)} disabled={(Number(defaultSumins) - 1) < newData.amountObj.min && 'disabled'}>-1</button>
                                <div class="input-group">
                                    <input type="text" className="form-control" max={newData.amountObj.max} value={defaultSumins} onChange={handleEvent.changeAmount} />
                                    <span className="input-group-text p-2 fw-bolder text-dark-blue" id="basic-addon2">{newData.unitName}</span>
                                </div>
                                <button type="button" className="btn btn-dark-blue mx-1" value={1} onClick={e => handleEvent.calButton(e, 'plus', newData.default)} disabled={(Number(defaultSumins) + 1) > newData.amountObj.max && 'disabled'}>+1</button>
                                <button type="button" className="btn btn-dark-blue mx-1" value={200} onClick={e => handleEvent.calButton(e, 'plus', newData.default)} disabled={(Number(defaultSumins) + 200) > newData.amountObj.max && 'disabled'}>+200</button>
                            </div>
                        </div>
                        <hr />
                        {
                            (defaultSumins < newData.amountObj.min || defaultSumins > newData.amountObj.max) &&
                            <div className='text-center'>
                                <div className="alert alert-danger p-2 d-inline-block" role="alert">
                                    <div className='d-flex align-items-center' style={{ fontSize: '15px' }}>
                                        <FontAwesomeIcon icon={faExclamationCircle} className="mx-2 text-danger" />
                                        <p className='text-danger fw-bolder me-3'>已低於或大於保額範圍，建議修改</p>
                                    </div>
                                </div>
                            </div>
                        }
                        <p className={`text-danger text-center fw-bolder`} style={{ fontSize: '15px' }}>保額範圍為：{toCurrency(newData.amountObj.min)}{newData.unitName} ~ {toCurrency(newData.amountObj.max)}{newData.unitName}</p>
                        {
                            (defaultSumins >= newData.amountObj.min && defaultSumins <= newData.amountObj.max) &&
                            <>
                                <p className={`text-danger text-center fw-bolder`} style={{ fontSize: '15px' }}>保費試算未包含折扣，結果僅供參考</p>
                                <ul className="list-unstyled py-3 d-flex flex-column align-items-center">
                                    <li className="d-flex align-items-center">
                                        <div className="text-end" style={{ width: '80px' }}><span className="badge bg-golden-brown fw-light my-1">年繳</span></div>
                                        <span className="d-block text-end fw-bolder text-dark-blue" style={{ width: '150px' }}>{handleEvent.calRate(Number(newData.rate[thisYear]), Number(newData.default), Number(newData.unit), 'year', 'none', newData.unitName)} 元</span>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="text-end" style={{ width: '80px' }}><span className="badge bg-golden-brown fw-light my-1">半年繳</span></div>
                                        <span className="d-block text-end fw-bolder text-dark-blue" style={{ width: '150px' }}>{handleEvent.calRate(Number(newData.rate[thisYear]), Number(newData.default), Number(newData.unit), 'halfYear', newData.pay, newData.unitName)}  元</span>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="text-end" style={{ width: '80px' }}><span className="badge bg-golden-brown fw-light my-1">季繳</span></div>
                                        <span className="d-block text-end fw-bolder text-dark-blue" style={{ width: '150px' }}>{handleEvent.calRate(Number(newData.rate[thisYear]), Number(newData.default), Number(newData.unit), 'seasone', newData.pay, newData.unitName)}  元</span>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="text-end" style={{ width: '80px' }}><span className="badge bg-golden-brown fw-light my-1">月繳</span></div>
                                        <span className="d-block text-end fw-bolder text-dark-blue" style={{ width: '150px' }}>{handleEvent.calRate(Number(newData.rate[thisYear]), Number(newData.default), Number(newData.unit), 'month', newData.pay, newData.unitName)}  元</span>
                                    </li>
                                </ul>
                            </>
                        }
                    </>
                }
            </div>
            <div className="d-table" style={{ height: '60px' }}></div>
            <button type="button" className="btn btn-golden-brown position-fixed py-3" style={SearchReset} onClick={handleSearchReset}>重新查詢</button>
        </>
    );
}
export default Assessment;
