import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const QuestionnaireState = (props) => {
    let history = useHistory();
    let [stateCode, setStateCode] = useState('-999') //預設狀態
    let [showText, setShowTest] = useState('')

    /* error code
    0    Success  通用 
    -1   系統內部錯誤  通用 
    -99  前端傳入數值為空值 
    -98  已完成問卷
    -97  不在問卷填寫時間範圍內
    -96  不在問卷填寫開放日期內
    -95  學員未在名單內 
    -94  找不到此業務員資料
    -93  查無此課程
    -92  簽到時間比對錯誤 
    -91  請先簽到後再進行問卷  
    
    -100 API 錯誤
    666 填寫問卷成功
    */

    const codeToShowText = (code) => {
        switch (code) {
            case '0':
                setShowTest('早會問卷已送出!! 感謝您的回饋~~')
                break;
            case '-1':
                setShowTest('系統內部錯誤!!')
                break;
            case '-98':
                setShowTest('您已填寫早會問卷~')
                break;
            case '-97':
                setShowTest('不在問卷填寫時間範圍內')
                break;
            case '-96':
                setShowTest('不在問卷填寫開放日期內')
                break;
            case '-95':
                setShowTest('學員未在名單內')
                break;
            case '-94':
                setShowTest('找不到此業務員資料')
                break;
            case '-93':
                setShowTest('查無此課程')
                break;
            case '-92':
                setShowTest('簽到時間比對錯誤')
                break;
            case '-91':
                setShowTest('請先簽到後再進行問卷')
                break;
            case '-99':
                setShowTest('填寫問卷錯誤!!')
                break;
            case '666':
                setShowTest('填寫早會問卷成功!!')
                break;
            case '-100':
                setShowTest('系統連線錯誤!!')
                break;
            default:
                setShowTest('填寫問卷錯誤!!')
            // break;
        }
    }

    useEffect(() => {
        if (history.location.state == undefined || history.location.state == null || history.location.state == '') {
            setStateCode('-999')
            codeToShowText('-999')
        } else {
            setStateCode(history.location.state.code)
            codeToShowText(history.location.state.code)
        }
    }, []);

    let style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
    }
    return (
        <>
            <div className='min-vh-100' style={{ background: 'linear-gradient(90deg, #2FABF4, #1671bc)' }}>
                <div style={style} className='text-center'>
                    <img src={require('../../assets/images/golden_chicken.png').default} className='img-fluid mb-3' width='90px' />
                    <h3 className="fw-bolder text-light text-center" style={{ whiteSpace: 'break-spaces' }}>
                        {showText}
                    </h3>
                    {
                        stateCode == '-999' || stateCode == '-99' ?
                            <button className='btn btn-golden-yellow fw-bolder mt-3' onClick={e=>window.location.href='/ans_quest'}>重新填寫</button>
                            :
                            <p className='text-light mt-3'>請直接關閉頁面</p>
                    }
                </div>
            </div>
        </>
    )
}
export default QuestionnaireState