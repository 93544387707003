import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

import { default as swal } from 'sweetalert2';

//component
import BackgroundWrapper from "../../components/BackgroundWrapper";

//icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield, faHouseDamage } from "@fortawesome/free-solid-svg-icons";

import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc'
import Loading from "../../components/Loading";

const CurrentProd = () => {
    const history = useHistory();
    const { state } = useLocation();
    let [isLoading, setIsLoading] = useState(true);

    //useState 資料們
    let [InsuranceData, setInsuranceData] = useState([]); //axios get 儲存[保險公司]撈取資料
    let [TypeData, setTypeData] = useState([]); //axios get 儲存[商品類型]撈取資料

    //--要查詢的資料--//
    let [ResultData, setResultData] = useState((state && state.ResultData) || ''); //跨頁傳送的資料(表單查詢結果)
    let [postData, setPostData] = useState({ ins: '請選擇', type: '請選擇', prd: '' })
    let [CatOption, setCatOption] = useState("1"); //壽險,產險 button 
    //axios get 保險公司
    const getData = () => {
        LineLogin('/current_prod'); //LINE 登入狀態驗證
        Countfunc('商品佣金', 11)
        let insuranceAPI = `${process.env.REACT_APP_GOLDEN_API5000}Query/Ins`;
        let typeAPI = `${process.env.REACT_APP_GOLDEN_API5000}Query/Type`;
        // axios.default.withCredentials = true;
        axios.get(insuranceAPI, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/current_prod');
                        window.location.href = '/login'
                        return
                    } else {
                        setInsuranceData(res.data);
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
                swal.fire({
                    icon: 'error',
                    title: '錯誤',
                    text: '請聯繫人員',
                })
            })

        axios.get(typeAPI, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                if (res.data.ResponseCode === '-1') {
                    localStorage.setItem('page', '/current_prod');
                    window.location.href = '/login'
                    return
                } else {
                    setTypeData(res.data)
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                alert('請重新整理')
                swal.fire({
                    icon: 'error',
                    title: '錯誤',
                    text: '請聯繫人員',
                })
            })
    }
    const showInsuranceData = InsuranceData.map((item, index) => {
        return <option value={item.PRD_SUP_NAME} key={index}>{item.PRD_SUP_NAME}</option>
    })
    const showTypeData = TypeData.map((item, index) => {
        return <option value={item.DRP_INS_TYPE_NAME} key={index}>{item.DRP_INS_TYPE_NAME}</option>
    })

    useEffect(() => {
        getData();
    }, []);

    //-- 每次觸發就會產生一個陣列
    const handleSearchChange = (e) => {
        let { value, dataset } = e.target;
        setCatOption(value);
    }
    const handleSearchClick = (e) => {
        if (postData.ins !== '請選擇' || postData.type !== '請選擇' || postData.prd !== '') {
        } else {
            swal.fire({
                icon: 'info',
                title: '提醒',
                text: '保險公司、商品類型、商品名稱此三項至少要輸入一種',
            })
            return
        }

        let params = new URLSearchParams();
        if (CatOption !== '') {
            params.append('cat', CatOption);
        }
        if (postData.ins !== '請選擇') {
            params.append('ins', postData.ins);
        }
        if (postData.type !== '請選擇') {
            params.append('type', postData.type);
        }
        if (postData.prd !== '') {
            params.append('prd', postData.prd);
        }
        setIsLoading(true)
        axios.post(`${process.env.REACT_APP_GOLDEN_API5000}Query/Commission`, params)
            .then((res) => {
                if (res.data.ResponseCode === '-1') {
                    localStorage.setItem('page', '/current_prod');
                    window.location.href = '/login'
                    return
                } else {
                    ResultData = res.data
                    if (ResultData.length !== 0) {
                        history.push({
                            pathname: `/current_prod_read`,
                            state: { from: '/current_prod_read', ResultData },
                        });
                    }
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)
                swal.fire({
                    icon: 'info',
                    title: '提醒',
                    text: '查無資料',
                    confirmButtonText: '重新查詢',
                }).then(function () {
                    setPostData({ ins: '請選擇', type: '請選擇', prd: '' })
                    setIsLoading(false)
                })
            })
    }

    useEffect(() => {
    }, [postData, ResultData])

    return (
        <>
            <Loading isLoading={isLoading} />
            <BackgroundWrapper>
                <div className="top-nav w-100 py-3">
                    <p className="text-light text-center fw-bolder">現售商品查詢</p>
                </div>
                <div className="container mt-4">
                    <div className="col-12 col-md-8 col-lg-6 mx-auto">
                        <div className="form-group d-flex flex-wrap text-light py-1 justify-content-around">
                            <div className="form-check col-5 ps-0 bg-light">
                                <label className={`form-check-label catOption w-100 ${CatOption === '1' ? 'catOption-active' : ''}`} htmlFor="1">
                                    <p><FontAwesomeIcon icon={faUserShield} className="fa-lg my-1" /></p>
                                    壽險
                                    <input className="form-check-input" type="radio" name="cat" id="1" value="1" onChange={handleSearchChange} data-search="保險種類" checked={CatOption === '1'} />
                                </label>
                            </div>
                            <div className="form-check col-5 ps-0 bg-light">
                                <label className={`form-check-label catOption w-100 ${CatOption === '100' ? 'catOption-active' : ''}`} htmlFor="100">
                                    <p><FontAwesomeIcon icon={faHouseDamage} className="fa-lg my-1" /></p>
                                    產險
                                    <input className="form-check-input" type="radio" name="cat" id="100" value="100" onChange={handleSearchChange} data-search="保險種類" checked={CatOption === '100'} />
                                </label>
                            </div>
                        </div>
                        <div className="form-group my-2">
                            <label htmlFor="company" className="my-2">保險公司:</label>
                            <select id="company" className="form-select w-100" data-search="保險公司"
                                value={postData.ins}
                                onChange={e => setPostData({ ...postData, ins: e.target.value.replace(/\s+/g, "") })}>
                                <option defaultValue="請選擇" selected disabled>請選擇</option>
                                {showInsuranceData}
                            </select>
                        </div>
                        <div className="form-group my-2">
                            <label htmlFor="prodType" className="my-2">商品類型:</label>
                            <select id="prodType" className="form-select w-100" data-search="商品類型"
                                value={postData.type}
                                onChange={e => setPostData({ ...postData, type: e.target.value.replace(/\s+/g, "") })}>
                                <option defaultValue="請選擇" selected disabled>請選擇</option>
                                {showTypeData}
                            </select>
                        </div>
                        <div className="form-group my-2">
                            <label htmlFor="prodName" className="my-2">商品名稱:</label>
                            <input type="text" id="prodName" className="form-control" placeholder="輸入商品名稱" data-search="商品名稱" value={postData.prd}
                                onChange={e => setPostData({ ...postData, prd: e.target.value.replace(/\s+/g, "") })} />
                        </div>
                        <button type="button" id="search" className="btn btn-dark-blue w-100 mt-4" onClick={handleSearchClick}>查詢</button>
                    </div>
                </div>
            </BackgroundWrapper>
        </>
    )
}

export default CurrentProd