import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//component
import Nav from '../../components/Nav';
import BackgroundWrapper from '../../components/BackgroundWrapper';

import License from '../../views/insurancePolicy/personal/License'; //證照
import Ecard from '../../views/insurancePolicy/personal/Ecard'; //電子名片
import Promotion from '../../views/insurancePolicy/personal/Promotion'; //晉升進度

const Personal = (props) => {
    const navItem = [
        {
            text: '證照',
            to: 'license'
        },
        {
            text: '電子名片',
            to: 'e_card'
        },
        {
            text: '晉升進度',
            to: 'promotion'
        }
    ];
    return (
        <>
            <BackgroundWrapper>
                <Router>
                    <Nav navItem={navItem}>
                        {/* <p className="text-light text-center mt-3">[<span>總公司</span>] <span>xxx</span> 業務專員</p> */}
                    </Nav>
                    {/* <div className="d-table" style={{height:'40px'}}></div> */}
                    <Switch>
                        <Route path="/license"><License /></Route>
                        <Route path="/e_card"><Ecard /></Route>
                        <Route path="/promotion"><Promotion /></Route>
                    </Switch>
                </Router>
            </BackgroundWrapper>
        </>
    );
}
export default Personal;