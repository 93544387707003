import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Loading from '../../../components/Loading';

import LineLogin from '../../../assets/javascript/lineLogin';
import Countfunc from '../../../assets/javascript/countfunc';
import DownloadFile from '../../../assets/javascript/downloadFile'

const Moringmeet = () => {

    let [data, setData] = useState([])
    let [isLoading, setIsLoading] = useState(true)
    let [typeAllData, setTypeAllData] = useState([]) //取得所有該類型 data
    let [optionList, setOptionList] = useState([]) //取得下拉選單
    let [thisOption, setThisOption] = useState(null)
    let [renderList, setRenderList] = useState([]) //已經過濾過的資料，渲染到畫面

    useEffect(() => {
        LineLogin('/morningmeet'); //LINE 登入狀態驗證
        Countfunc('課程教案_早會', 34)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=6`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/morningmeet');
                        window.location.href = '/login'
                        return
                    } else {
                        setData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);

    useEffect(() => {
        let typeAllData = [], //取得該類型資料
            optionList = [] //取得下拉選單資料

        data.shift()
        data.map((item) => {
            if (item?.split('/')[2] == '1.早會課程') {
                typeAllData.push({
                    downloadFile: item?.split('/').join('/'),
                    option: item?.split('/')[3]?.split('.')[1],
                    fileName: item?.split('/')[4]
                })

                optionList.push(item?.split('/')[3]?.split('.')[1])
            }
        })

        let filterOptionList = optionList.sort(function (a, b) {
            return b - a
        }).filter((item, index, ary) => {
            return ary.indexOf(item) === index
        })
        setOptionList(filterOptionList)
        setThisOption(filterOptionList[0])
        setTypeAllData(typeAllData)
    }, [data])

    useEffect(() => {
        let newAry = []
        typeAllData?.map((item) => {
            if (thisOption == item.option) {
                newAry.push(item)
            }
        })
        setRenderList(newAry)
    }, [thisOption, typeAllData])

    const handleDownloadFile = (e) => { //下載文件
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/morningmeet' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setIsLoading)
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-3">
                <div className="d-flex pb-2 top-select bg-light">
                    <select className="form-select" id='optionSelect' onChange={e => setThisOption(e.target.value)}>
                        {optionList.map((item, index) => {
                            return (
                                <option key={item} value={item}>{item}</option>
                            )
                        })
                        }
                    </select>
                </div>
                <ul className="list-unstyled page-content-link">
                    {
                        renderList?.filter((val) => {
                            if (val.fileName !== '') {
                                return val
                            }
                        })?.map((item, index) => {
                            return (
                                <li className="border-bottom py-3" key={`morning-${index}`}>
                                    <a href="#" data-download={item.downloadFile} data-filename={item.fileName} className="d-block fw-bolder file-icon" onClick={handleDownloadFile}>
                                        {item.fileName}
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    );
}
export default Moringmeet;