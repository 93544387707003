import React, { useState } from 'react';
import DashboardNav from 'Dashboard/model/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { getRole } from 'Dashboard/authorization/Authorization';
import packageJson from '../../../package.json'
const BasicModel = ({ children }) => {

    let [loginRole, setLoginRole] = useState(localStorage?.getItem('role'));
    let loginCheck = localStorage.getItem('userName');
    if (loginCheck === null) {
        window.location.href = '/dashboard/login'
    }

    const handleLoginOut = (e) => {
        e.preventDefault();
        localStorage.removeItem('userName');
        localStorage.removeItem('role');
        window.location.href = '/dashboard/login'
    }
    return (
        <div className='bg-golden-brown' style={{ minHeight: '100vh', overflow: 'hidden' }}>
            <div className='container-fluid p-0'>
                <div className="row g-0">
                    {/* header */}
                    <div className='col-12'>
                        <div className='main-header'>
                            <div className='row g-0 align-items-center h-100'>
                                <div className='col-2'>
                                    <div className='brand my-2'>
                                        <div className='text-center'>
                                            <a className='navbar-brand' href='#' onClick={e => e.preventDefault()}>
                                                <h1 className='fw-bolder text-light' style={{ fontSize: '22px' }}>iSmart +</h1>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-10'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='d-flex'>
                                            {/* <button className='btn' onClick={e => (e.preventDefault(), setAsideNavShow(switchEvent(asideNavShow)))}
                                                data-tour='navbar-telescopic'>
                                                {asideNavShow ? <Icon icon='bars-staggered' size={20} className='transition-half' /> : <Icon icon='hamburger_bar' size={24} className='transition-half' />}
                                            </button>
                                            <button className='btn fw-bolder tour-open-btn' onClick={e => setIsTourShow(true)}>
                                                <p>導航看指引</p>
                                                <Icon icon='question' className='tour-open-btn-question-icon' color='' size={15} />
                                            </button> */}
                                        </div>
                                        <div className='d-flex me-4'>
                                            <div className='dropdown-menu-block primary-navigation' data-tour='personal-box'>
                                                <ul className='menu-list d-none d-lg-block'>
                                                    {/* <li className='position-relative'>
                                                            <a href='#' className='is-dropdown' onClick={e => (e.preventDefault())}><Icon color='#000' icon='circle-user' size={20} className='mx-2' />{userName}▾</a>
                                                            <div className='menu-dropdown'>
                                                                <div><ul><li>身份：{getRole(role)}</li></ul></div>
                                                                <div className='d-flex'>
                                                                    <ul>
                                                                        <li><p className='dropdown-title text-primary' style={{ fontSize: '18px' }}>個人設定</p></li>
                                                                        <li>
                                                                            <a href='#' onClick={e => (e.preventDefault(), router('/personal/changePassword'))}>
                                                                                變更密碼
                                                                            </a>
                                                                        </li>
                                                                        {
                                                                            (role == '1' || role == '2') &&
                                                                            <li>
                                                                                <a href='#' onClick={e => (e.preventDefault(), router('/personal/signature'))}>
                                                                                    簽名檔設定
                                                                                </a>
                                                                            </li>
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li> */}
                                                </ul>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='fw-bolder text-light mx-4'>
                                                    <FontAwesomeIcon icon={faUserCircle} className="mx-2" />
                                                    <span className="me-2 fw-bolder">{loginCheck}</span>
                                                    <span>{getRole(loginRole)}</span>
                                                </div>
                                                <a href='#' className='logout fw-bolder text-light' onClick={e => handleLoginOut(e)}>
                                                    <u>登出</u>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <DashboardNav />
                    </div>
                    <div className="col-10 bg-light">
                        <div className='content-scroll bg-light'>
                            <div className='rounded' style={{ minHeight: '90vh', background: '#fff' }}>
                                {children}
                            </div>
                            <div className='bg-dark-blue text-light fw-bolder py-3 text-center small'>
                                公勝保險經紀人股份有限公司版權所有<br />
                                Copyright © 2021 Golden Insurance Brokers Co., Ltd. All rights reserved.（Update : {packageJson.version}）
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BasicModel