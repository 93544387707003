import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, Switch, Route, useLocation, useHistory } from "react-router-dom";
import axios from 'axios';

//components
import Loading from '../../components/Loading';
import ProductFooter from '../../components/ProductFooter';
import Assessment from './Assessment';

import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc'

const Retired = (props) => {
    let match = useRouteMatch();
    const history = useHistory();
    let { state } = useLocation();

    let [isLoading, setIsLoading] = useState(true);
    let [data, setData] = useState([]);

    const getData = () => {
        LineLogin('/products/main1'); //LINE 登入狀態驗證
        Countfunc('主力商品_財富傳承', 15)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}Gcd/MainList`;
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/products/main1');
                        window.location.href = '/login'
                        return
                    } else {
                        setData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }
    useEffect(() => {
        getData();
    }, []);

    //過濾 篩選出 main_type 為 1
    const filterData = data.filter(item => item.main_type === '1');
    let [footerTitle, setFooterTitle] = useState(''); //頁尾商品名
    let [proName] = useState((state && state.proName) || null)
    let [main] = useState((state && state.main) || '1')
    const handleOptionClick = (e) => {
        e.preventDefault();
        let { innerText, nodeName, dataset } = e.target
        if (nodeName === 'BUTTON' || nodeName === 'SPAN') {
            setFooterTitle(innerText)
        } else if (nodeName === 'A') {
            proName = dataset.proname
            history.push({
                state: { proName, main }
            })
        }
    }
    useEffect(() => {
        let ary = []
        data.map((item, index) => {
            if (item.main_type == '1') {
                ary.push(item)
            }
        })
        if (ary.length !== 0) {
            setFooterTitle(ary[0].main_name)
        }
    }, [data])
    useEffect(() => { }, [footerTitle])
    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-4">
                <ul className="list-unstyled row px-2 bg-mask">
                    {
                        filterData.sort((a, b) => {
                            return a.main_name.localeCompare(b.main_name, 'zh-CN-u-co-pinyin');
                        }).map((item, index) => {
                            return (
                                <li key={item.main_name} onClick={handleOptionClick} className={`px-0 ${footerTitle === item.main_name ? 'bg-yellow-light' : ''}`}>
                                    <button className="btn d-flex justify-content-between align-items-center text-dark-blue py-1 px-2 col-12 fw-bolder text-start">
                                        <span className="d-inline-block pe-3">{item.main_name}</span>
                                        <Link to={`${match.url}/${item.main_name}`} className={`text-dark-blue cal-icon ${item.main_name === '中國人壽_享退年年美元利率變動型養老保險' || item.main_name === '中國人壽_悠退年年美元利率變動型養老保險' ? 'd-none' : 'd-inline-block'}`} data-proname={item.main_name}>
                                        </Link>
                                    </button>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <ProductFooter title={footerTitle} />
            <Switch>
                <Route path={`${match.path}/:id`}><Assessment /></Route>
            </Switch>
        </>
    );
}
export default Retired;