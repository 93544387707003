import React, { useState, useEffect } from 'react';
import axios from 'axios';

// component
import LineLogin from '../../assets/javascript/lineLogin';
import Loading from '../../components/Loading';
import Countfunc from '../../assets/javascript/countfunc';

import DownloadFile from '../../assets/javascript/downloadFile';

const Docs = () => {
    let [isLoading, setIsLoading] = useState(true); //判斷 loading

    //GET API
    let [allData, setAllData] = useState([]);

    useEffect(() => {
        LineLogin('/golden_docs');
        Countfunc('作業文件', 1); //觸及數器
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=1`;
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/golden_docs');
                        window.location.href = '/login'
                        return
                    } else {
                        setAllData(res.data)
                        setIsLoading(false)
                        localStorage.removeItem('page');
                    }
                }, Number(process.env.REACT_APP_TIMEOUT));
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);

    //資料處理
    let filterData = []; //最終資料處理完成 DATA
    const splitData = (data) => {
        let splitAry = [];
        data.forEach((item, index) => {
            splitAry.push(item.split('/'))
        })

        splitAry.forEach((item, index) => {
            filterData.push({
                title: item[0],
                firstOption: item[1].split('.')[1],
                secondOption: item.length > 3 ? item[2].split('.')[1] : '',
                fileName: item.length > 3 ? item[3] : item[2],
                downloadFile: item.join('/')
            })
        })
    }
    splitData(allData)

    //資料處理 END

    //放入資料
    let [firstSelect, setFirstSelect] = useState('公勝表單');
    let [secondSelect, setSecondSelect] = useState('');

    //--- 處理 Select
    let firstOptionAry = [];
    let secondOptionAry = [];

    const filterFun = (data) => {
        let firstAry = [];
        let secondAry = [];
        data.forEach((item, index) => {
            firstAry.push(item.firstOption)

            if (firstSelect === item.firstOption) {
                secondAry.push(item.secondOption)
            }
        })
        firstOptionAry = firstAry.filter((item, index, ary) => {
            return ary.indexOf(item) === index
        })

        secondOptionAry = secondAry.filter((item, index, ary) => {
            return ary.indexOf(item) === index
        })
    }
    filterFun(filterData)
    //--- 處理 Select END

    //--- 事件

    const handleSelectChange = (e) => {
        let { value } = e.target
        setFirstSelect(value);
        filterData.map((item, index) => {
            if (value === item.firstOption) {
                setSecondSelect(item.secondOption)
            }
            return true
        })
    }


    const handleSecondChange = (e) => {
        let { value } = e.target
        setSecondSelect(value);
    }
    //---事件 END
    //放入資料 END

    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/golden_docs' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setIsLoading)
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-3">
                <div className="d-flex pb-2">
                    <select className="form-select" value={firstSelect} onChange={handleSelectChange} >
                        {
                            firstOptionAry.map((item, index) => {
                                return (
                                    <option key={item} value={item} data-first={`${String(index + 1 + '.' + item)}`}>{item}</option>
                                )
                            })
                        }
                    </select>
                    <select className={`form-select ms-2 ${secondOptionAry.length === 1 && secondSelect === '' ? 'd-none' : 'd-block'}`} value={secondSelect} onChange={handleSecondChange}>
                        {
                            secondOptionAry.map((item, index) => {
                                return (
                                    <option key={item} value={item} data-second={`${String(index + 1 + '.' + item)}`}>{item}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <ul className="list-unstyled page-content-link fw-bolder">
                    {
                        filterData.map((item, index) => {
                            if (firstSelect === item.firstOption && secondSelect === item.secondOption) {
                                return (
                                    <li className="border-bottom py-3" key={index}>
                                        <a href="#" data-download={item.downloadFile} data-filename={item.fileName} className="d-block fw-bolder file-icon" onClick={handleDownloadFile}>
                                            {item.fileName}
                                        </a>
                                    </li>
                                )
                            }
                            return true
                        })
                    }
                </ul>
            </div>
        </>
    );
}
export default Docs;