import React, { useEffect, useState } from 'react';
import randomBytes from "randombytes"
import { default as swal } from 'sweetalert2'

import axios from 'axios';
import url from 'url';
import qs from 'qs';
import jwt from 'jsonwebtoken';
import Loading from '../../components/Loading';
import { Encrypt } from '../../assets/javascript/AESTool';
import UiModal from './components/uiModal';

const Login = () => {
    let [circleLoading, setCircleLoading] = useState(true)

    const state = randomBytes(32).toString('hex');
    const nonce = randomBytes(32).toString('hex');

    const lineLogin = () => {
        let link = 'https://access.line.me/oauth2/v2.1/authorize?';
        link += 'response_type=code';
        link += `&client_id=${process.env.REACT_APP_LINE_LOGIN_CHANNEL_ID}`;
        link += `&redirect_uri=${process.env.REACT_APP_LINE_LOGIN_CALLBACK_URL2}`;
        link += `&state=${state}`;
        link += `&nonce=${nonce}`;
        link += '&scope=openid%20profile';
        link += '&bot_prompt=normal';
        window.location.href = link;
    };
    useEffect(() => {
        var urlParts = url.parse(window.location.href, true);
        var query = urlParts.query;
        const isLogin = () => {
            if (Object.keys(query).length === 0) {
                lineLogin();
            }
        }
        isLogin()
    });

    const getAccessToken = (callbackURL) => {
        var urlParts = url.parse(callbackURL, true);
        var query = urlParts.query;
        var hasCodeProperty = Object.prototype.hasOwnProperty.call(query, 'code');
        if (hasCodeProperty) {
            const reqBody = {
                grant_type: 'authorization_code',
                code: query.code,
                redirect_uri: `${process.env.REACT_APP_LINE_LOGIN_CALLBACK_URL2}`,
                client_id: `${process.env.REACT_APP_LINE_LOGIN_CHANNEL_ID}`,
                client_secret: `${process.env.REACT_APP_LINE_LOGIN_CHANNEL_SECRET}`
            };
            const reqConfig = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };

            axios
                .post(
                    'https://api.line.me/oauth2/v2.1/token',
                    qs.stringify(reqBody),
                    reqConfig
                )
                .then((res) => {
                    try {
                        const decodedIdToken = jwt.verify(res.data.id_token, `${process.env.REACT_APP_LINE_LOGIN_CHANNEL_SECRET}`, {
                            algorithms: ['HS256'],
                            audience: `${process.env.REACT_APP_LINE_LOGIN_CHANNEL_ID}`.toString(),
                            issuer: 'https://access.line.me',
                            // nonce: `${newId}`
                        });
                        //@ 同時存 lineId,姓名至 cookies & localStorage
                        let lineData = { name: decodedIdToken.name, lineId: decodedIdToken.sub }
                        localStorage.setItem('_eventCheckLogin', Encrypt(JSON.stringify(lineData), process.env.REACT_APP_KEY, process.env.REACT_APP_IV))
                        var now = new Date();
                        var expireTime = new Date(now.getTime() + 24 * 60 * 60 * 1000);
                        document.cookie = `_eventCheckLogin=${Encrypt(JSON.stringify(lineData), process.env.REACT_APP_KEY, process.env.REACT_APP_IV)}; expires=${expireTime}`;
                        //@ 取得 存在 localStorage 的 returnUrl
                        let returnUrl = localStorage.getItem('_eventCheckUrl')
                        if (returnUrl == null || returnUrl == undefined) {
                            alert('登入錯誤!! 請關閉頁面重新點擊')
                        } else {
                            setTimeout(() => {
                                window.location.href = returnUrl
                            }, 1200)
                        }
                        //@ goBack

                    } catch (err) {
                        // If token is invalid.
                        console.log(err);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (query.error) {
            swal.fire({
                icon: 'error',
                title: '登入失敗 ><',
                text: '需「許可」授權，才能驗證登入',
                confirmButtonText: '重新登入',
            }).then(function () {
                lineLogin()
            })
        }
    };
    useEffect(() => {
        getAccessToken(window.location.href)
    }, []);
    let style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
    }
    return (
        <>
            <Loading isLoading={circleLoading} />
            <UiModal><p className='text-light fw-bolder'>...帳號登入中...</p></UiModal>
        </>
    );
};

export default Login