import React from 'react';
import { NavLink } from "react-router-dom";

const Nav = (props) => {
    return (
        <>
            <div className="top-nav w-100 pb-3 position-fixed">
                <div className="px-1 px-sm-1 px-md-3">
                    <ul className="list-unstyled d-flex justify-content-around text-light pt-3">
                        {props.navItem.map((item) => { //選單列
                            return (
                                <li key={item.text} className="list-item">
                                    <NavLink to={`/${item.to}`} activeClassName="top_nav--active" className="rounded-pill px-2 px-sm-3 px-md-4">{item.text}</NavLink>
                                </li>
                            )
                        })}
                    </ul>
                    {props.children}
                </div>
            </div>
            <div className="d-table" style={{height:'66px'}}></div>
        </>
    )
}

export default Nav;