import React, { useState, useEffect } from 'react';
import { useRouteMatch, Switch, Route } from "react-router-dom";
//component
import LogoList from './LogoList';
import Loading from '../../components/Loading';
import ReportSin from './ReportSin';

import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc';
import data from '../../json/insurer/Insurer.json'

const Report = (props) => {
    let match = useRouteMatch();
    useEffect(() => {
        LineLogin('/report'); //LINE 登入狀態驗證
        Countfunc('報備', 7)
    }, []);
    //GET API END
    let propData = data.data[0].Report

    return (
        <>
            <LogoList data={propData} />
            <Switch>
                <Route path={`${match.path}/:id`}><ReportSin /></Route>
            </Switch>
        </>
    );
}
export default Report;