import React, { useState } from 'react';

const UiModal = ({ children, title }) => {

    let style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%'
    }

    return (
        <>
            <div style={{ background: 'linear-gradient(90deg, #2FABF4, #1671bc)', minHeight: '110vh' }}>
                <div style={style} className='text-center'>
                    <img src={require('../../../assets/images/golden_chicken.png').default} className='img-fluid mb-3' width='115px' />
                    <div>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default UiModal