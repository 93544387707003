import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import Loading from '../../../components/Loading'
import LineLogin from '../../../assets/javascript/lineLogin'
import busCodeData from '../../../assets/document/busCode.json'

//from
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
const ModelSupervisor = () => {
    let history = useHistory();
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)lineIdToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token === '' || token === null || token === undefined) {
        localStorage.setItem('page', '/modelSupervisor');
        window.location.href = '/login'
    }

    useEffect(() => {
        LineLogin('/modelSupervisor')
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/modelSupervisor');
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                console.log(err)
                // alert('請重新整理')
            })
    }, []);

    //取得資訊
    const infoToken = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    let name = JSON.parse(unescape(infoToken)).Name,
        busCode = JSON.parse(unescape(infoToken)).BusCode,
        lineId = JSON.parse(unescape(infoToken)).LineId

    //問項
    const questionList = [
        {
            option: '誠信',
            title: '以身作則，遵循法規與職業道德來管理團隊與做決策；以誠實、守諾行事。',
            desList: [
                '宣揚文化價值觀及法規的重要性，鼓勵團隊成員始終如一地遵循法令及作業規範。',
                '在壓力下，以身作則,面對各項業務事宜仍能堅守法令與公司規範，不做假。',
                '勇於指出團隊成員需要改善之處，不偏袒的秉公處理。',
                '誠實面對自己，真誠表達自己的限制；說到做到，不過度承諾。'
            ],
            Q1: {
                title: '在【誠信】價值觀之表現?',
                option: ['推薦，符合「誠信」價值觀。', '無意見。']
            },
            Q1Desc: {
                title: '在【誠信】價值觀表現之相關描述說明：'
            }
        },
        {
            option: '熱情',
            title: '對公司、團隊及工作擁有強大的動力與渴望，能長時間保持高專注力及自我覺察力，以因應市場各種變化。能夠正向看待挑戰，激發他人的動力及渴望，協助他人超越自我表現。',
            desList: [
                '相信公司方向，正向看待公司政策及改變，積極傳達願景、使命及價值觀。',
                '採取行動激發他人對公勝企業品牌的信心與熱情，激勵全心投入。',
                '鼓勵他人正向看待挑戰，積極採取行動解決問題，展現對目標的承諾及毅力。',
                '引導他人找到工作意義及價值，並鼓勵超越自我設限，持續進步成長。'
            ],
            Q2: {
                title: '在【熱情】價值觀之表現?',
                option: ['推薦，符合「熱情」價值觀。', '無意見。']
            },
            Q2Desc: {
                title: '在【熱情】價值觀表現之相關描述說明：'
            }
        },
        {
            option: '創新',
            title: '面對改變時，以身作則，保持開放態度，採行新的作法；鼓勵他人以不同的方式來處理問題或機會，以優化現行的運作模式。',
            desList: [
                '主動找出創新或需要改善之處，提出建議作法。',
                '對他人所提的觀點保持開放的態度，支持及採用好的想法。',
                '說明創新及改變的好處，協助他人突破熟悉感及舒適圈，採行不同的做法。',
                '鼓勵他人勇於嘗試，並允許犯錯，與他討論從中的學習。'
            ],
            Q3: {
                title: '在【創新】價值觀之表現?',
                option: ['推薦，符合「創新」價值觀。', '無意見。']
            },
            Q3Desc: {
                title: '在【創新】價值觀表現之相關描述說明：'
            }
        },
        {
            option: '共享',
            title: '將公司共同目標列為優先，整合所有資源，建立跨部門／單位／團隊的合作模式，協助彼此成長及成功。',
            desList: [
                '以公司的角度出發，將公司利益放在個人或部門利益之前。',
                '收集市場或同業訊息，建立內外勤或跨團隊分享及合作的機制。',
                '跨部門合作出現不同意見時，找出雙方共同目標、需求及期望，尋求共識。',
                '採取行動以鼓勵同仁主動分享資訊及好的作法。'

            ],
            Q4: {
                title: '在【共享】價值觀之表現?',
                option: ['推薦，符合「共享」價值觀。', '無意見。']
            },
            Q4Desc: {
                title: '在【共享】價值觀表現之相關描述說明：'
            }
        }
    ]

    //主管 LIST
    const modelSupervisorList = [ //如果區副總兼主管 areaSupervisor 留空 arr
        {
            area: '業務北一區部', //區部
            dept: {
                busCode: 'FU06', //單位碼
                busCenter: '台北業務中心', //業務中心
                sectionSupervisor: ['林人杰 業務副總'], //單位主管
                areaSupervisor: [] //區部主管
            }
        },
        {
            area: '業務北一區部',
            dept: {
                busCode: 'GOA5',
                busCenter: '北五業務中心',
                sectionSupervisor: ['賴政良 業務行政經理'],
                areaSupervisor: ['林人杰 業務副總']
            }
        },
        {
            area: '業務北一區部',
            dept: {
                busCode: 'GOA6',
                busCenter: '中山業務中心',
                sectionSupervisor: ['蔡欣燕 體系協理'],
                areaSupervisor: ['林人杰 業務副總']
            }
        },
        {
            area: '業務北一區部',
            dept: {
                busCode: 'FU12',
                busCenter: '花蓮業務中心',
                sectionSupervisor: ['葉美如 業務行政經理'],
                areaSupervisor: ['林人杰 業務副總']
            }
        },
        {
            area: '業務北一區部',
            dept: {
                busCode: 'GOF1',
                busCenter: '板橋業務中心',
                sectionSupervisor: ['陳韋婷 業務行政經理'],
                areaSupervisor: ['林人杰 業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'GOA2',
                busCenter: '北二業務中心',
                sectionSupervisor: ['林美伶 業務行政經理'],
                areaSupervisor: ['孫羚沄 業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'FU05',
                busCenter: '中壢業務中心',
                sectionSupervisor: ['胡峻業 業務行政經理'],
                areaSupervisor: ['孫羚沄 業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'FU15',
                busCenter: '桃園業務中心',
                sectionSupervisor: ['簡大鈞 業務總監'],
                areaSupervisor: ['孫羚沄 業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'FU21',
                busCenter: '新竹業務中心',
                sectionSupervisor: ['張昌榮 資深體系總監'],
                areaSupervisor: ['孫羚沄 業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'FU10',
                busCenter: '羅東業務中心',
                sectionSupervisor: ['黃紋鈴 業務行政經理'],
                areaSupervisor: ['孫羚沄 業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'GOG2',
                busCenter: '宜蘭業務中心',
                sectionSupervisor: ['簡現程 資深體系總監'],
                areaSupervisor: ['孫羚沄 業務副總']
            }
        },
        {
            area: '業務中區部',
            dept: {
                busCode: 'FU03',
                busCenter: '台中業務中心',
                sectionSupervisor: ['汪峻佑 業務副總'],
                areaSupervisor: []
            }
        },
        {
            area: '業務中區部',
            dept: {
                busCode: 'GOB2',
                busCenter: '市政業務中心',
                sectionSupervisor: ['鄭雅馨 體系副總'],
                areaSupervisor: ['汪峻佑 業務副總']
            }
        },
        {
            area: '業務中區部',
            dept: {
                busCode: 'FU08',
                busCenter: '嘉義業務中心',
                sectionSupervisor: ['江盈昇 資深體系總監'],
                areaSupervisor: ['汪峻佑 業務副總']
            }
        },
        {
            area: '業務中區部',
            dept: {
                busCode: 'FU3-5',
                busCenter: '彰化業務中心',
                sectionSupervisor: ['陳肇煌 體系總監'],
                areaSupervisor: ['汪峻佑 業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'FU02',
                busCenter: '台南業務中心',
                sectionSupervisor: ['陳精一 業務總監'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'FU2-3',
                busCenter: '新營業務中心',
                sectionSupervisor: ['陳精一 業務總監'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'GOD3',
                busCenter: '安平業務中心',
                sectionSupervisor: ['鍾宜臻 副理'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'FU01',
                busCenter: '高雄業務中心',
                sectionSupervisor: ['鄭志弘 業務協理'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'GOE2',
                busCenter: '高二業務中心',
                sectionSupervisor: ['潘柔君 體系協理'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'GOE5',
                busCenter: '北高業務中心',
                sectionSupervisor: ['邱鼎承 業務總監'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'FU07',
                busCenter: '屏東業務中心',
                sectionSupervisor: ['張簡欽全 業務行政經理'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'FU',
                busCenter: '高雄業務中心',
                sectionSupervisor: ['鄭志弘 業務協理'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'GO',
                busCenter: '高雄業務中心',
                sectionSupervisor: ['鄭志弘 業務協理'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        }
    ]

    let [isLoading, setIsLoading] = useState(false),
        [data, setData] = useState({ center: '', dept: [], area: [] }),
        [isCenter, setIsCenter] = useState(null), //隸屬的業務中心
        [centerName, setCenterName] = useState(null), //隸屬業務中心(縮寫)
        [stepPage, setStepPage] = useState('one'), //one:第一頁 ; two:第二頁
        [isOpen, setIsOpen] = useState(true) // 是否打開問卷


    const validationSchema = Yup.object().shape({
        deptQ1: Yup.string()
            .required('必填'),
        deptQ2: Yup.string()
            .required('必填'),
        deptQ3: Yup.string()
            .required('必填'),
        deptQ4: Yup.string()
            .required('必填'),
        areaQ1: Yup.string()
            .required('必填'),
        areaQ2: Yup.string()
            .required('必填'),
        areaQ3: Yup.string()
            .required('必填'),
        areaQ4: Yup.string()
            .required('必填')
    });
    const validationSchemaModalIsOne = Yup.object().shape({
        deptQ1: Yup.string()
            .required('必填'),
        deptQ2: Yup.string()
            .required('必填'),
        deptQ3: Yup.string()
            .required('必填'),
        deptQ4: Yup.string()
            .required('必填')
    });
    const formOptions = { resolver: yupResolver(busCode == 'FU06' || busCode == 'FU03' ? validationSchemaModalIsOne : validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const onSubmit = (val) => {
        val.LineId = lineId
        val.SalesName = name
        val.dept = centerName

        handleAPI.modelQuestion(val)
    }

    const handleAPI = {
        modelQuestion: function (postData) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/ModelQuestion`
            axios.post(API, postData)
                .then((res) => {
                    let { ResponseCode } = res.data
                    if (ResponseCode == '0') {
                        history.push({
                            pathname: '/modelSupervisorState',
                            state: { code: '0' }
                        })
                        setIsLoading(false)

                    } else if (ResponseCode == '-98') {
                        history.push({
                            pathname: '/modelSupervisorState',
                            state: { code: '-98' }
                        })
                        setIsLoading(false)
                    } else {
                        history.push({
                            pathname: '/modelSupervisorState',
                            state: { code: '-100' }
                        })
                        setIsLoading(false)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    history.push({
                        pathname: '/modelSupervisorState',
                        state: { code: '-100' }
                    })
                    setIsLoading(false)
                })
        }

    }
    const handleEvent = {
        getCenter: function () {
            modelSupervisorList.map((item, index) => {
                if (busCode == item.dept.busCode) {
                    setIsCenter(item)
                    setData({
                        center: item.dept.busCenter,
                        dept: item.dept.sectionSupervisor,
                        area: item.dept.areaSupervisor
                    })
                }
            })

            busCodeData.map((item, index) => {
                if (busCode == item.busCode) {
                    setCenterName(item.name)
                } else if (busCode == 'GO') {
                    setCenterName('總公司')
                }
            })
        }
    }

    let underLineStyle = {
        textDecoration: 'underline #fec842 double 2px'
    }

    useEffect(() => { handleEvent.getCenter() }, [])
    useEffect(() => { }, [isCenter, data])
    useEffect(() => {
        let now = moment().valueOf()
        let end = moment('2023/12/08 23:50:00').valueOf()
        if (now > end) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }, [])
    return (
        <>
            <Loading isLoading={isLoading}></Loading>
            <div className={`${isCenter == null ? 'vh-100' : 'h-100'} questionnaire`} style={{ background: 'linear-gradient(90deg, #2FABF4, #1671bc)' }}>
                <div className='container'>
                    <div className={`row`}>
                        {
                            isOpen ? <>
                                <div className='col-12'>
                                    <h2 className='text-center text-center title py-3 fs-3'>2023年<br /> 業務單位典範主管推薦（{centerName}）</h2>
                                    {
                                        stepPage == 'one' &&
                                        <>
                                            <div className='py-3 px-2 bg-light rounded mb-5'>
                                                <div className='text-question-blue fw-bolder px-1'>
                                                    <p>歡迎參與 2023 年業務單位典範主管推薦調查。</p>
                                                    <br />
                                                    <p>公勝四大價值觀【誠信】【熱情】【創新】【共享】，將之奉為圭臬，也期許所有夥伴共同努力。<br />
                                                        您認為自身業務中心及區部主管在四大價值觀的表現如何？您是否給予推薦？<br />
                                                        推薦的同時，也歡迎回饋我們推薦的理由及相關說明！</p>
                                                </div>
                                                <hr />
                                                {
                                                    isCenter !== null ?
                                                        <ul className='fw-bolder' style={{ lineHeight: '35px' }}>
                                                            <li>調查對象：{data.center}同仁</li>
                                                            {
                                                                busCode == 'FU06' || busCode == 'FU03' ?
                                                                    <li>推薦對象（單位&區部）：{data.dept}</li>
                                                                    :
                                                                    <>
                                                                        {
                                                                            data.dept.map((item, index) => {
                                                                                return (
                                                                                    <li>推薦對象（單位）：{item}</li>
                                                                                )
                                                                            })
                                                                        }
                                                                        <li>推薦對象（區部）：{data.area}</li>
                                                                    </>
                                                            }
                                                        </ul> : '無調查對象'
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    isCenter !== null &&
                                    <>
                                        <div className='w-100 mb-5' style={{ borderBottom: '1.5px solid #fff' }}></div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            {/* 單位主管 */}
                                            <div className={'d-block'}>
                                                {
                                                    data.dept.map((item, index) => {
                                                        return (
                                                            <p className='text-light my-2 fw-bolder ps-2' style={{ borderLeft: '2px solid #fff', fontSize: '20px' }}>
                                                                單位主管 -
                                                                <span className='mx-1'>
                                                                    {item}
                                                                </span>
                                                            </p>
                                                        )
                                                    })
                                                }

                                                <p className='py-2 text-light fw-bolder'>依您對自身<span style={underLineStyle}>{busCode == 'FU03' || busCode == 'FU06' ? '單位&區部主管' : '業務中心主管'}</span>的了解，煩請參閱下列價值觀的相關說明，並回答相關問題，謝謝。</p>
                                                {
                                                    questionList.map((item, index) => {
                                                        return (
                                                            <div className='col-12 my-3'>
                                                                <div className='bg-light rounded p-3'>
                                                                    <div className='row align-items-center question-box mb-3'>
                                                                        <span className='col-2 number-box bg-golden-yellow'>Q{index + 1}</span>
                                                                        <span className='col-10 fw-bolder'>
                                                                            <span style={{ fontSize: '18px' }}>價值觀【{item.option}】：</span><br />
                                                                            {item.title}
                                                                        </span>
                                                                    </div>
                                                                    <ul className='mb-3 text-question-blue'>
                                                                        {item.desList.map((kitem, kindex) => {
                                                                            return (
                                                                                <li>{kitem}</li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                    <hr />
                                                                    <div className='ms-2'>
                                                                        <div className='answer-box'>
                                                                            <p className='fw-bolder mb-2'>您是否推薦<span style={underLineStyle}>{busCode == 'FU03' || busCode == 'FU06' ? '單位&區部主管' : '單位主管'}</span>{item[`Q${index + 1}`].title}<span className='text-danger mx-1'>*</span></p>
                                                                            <div className="invalid-feedback d-block fw-bolder">{errors[`deptQ${index + 1}`] && '*必填'}</div>
                                                                            {
                                                                                data.dept.length >= 2 ?
                                                                                    <>
                                                                                        <div className='form-check mb-2'>
                                                                                            <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`Q${index + 1}-1`} value='Y1' {...register(`deptQ${index + 1}`)} />
                                                                                            <label className='form-check-label' htmlFor={`Q${index + 1}-1`}>推薦，{data.dept[0]}</label>
                                                                                        </div>
                                                                                        <div className='form-check mb-2'>
                                                                                            <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`Q${index + 1}-2`} value='Y2' {...register(`deptQ${index + 1}`)} />
                                                                                            <label className='form-check-label' htmlFor={`Q${index + 1}-2`}>推薦，{data.dept[1]}</label>
                                                                                        </div>
                                                                                        <div className='form-check mb-2'>
                                                                                            <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`Q${index + 1}-3`} value='Y3' {...register(`deptQ${index + 1}`)} />
                                                                                            <label className='form-check-label' htmlFor={`Q${index + 1}-3`}>推薦，{data.dept[0]}&{data.dept[1]}</label>
                                                                                        </div>
                                                                                        <div className='form-check mb-2'>
                                                                                            <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`Q${index + 1}-4`} value='N' {...register(`deptQ${index + 1}`)} />
                                                                                            <label className='form-check-label' htmlFor={`Q${index + 1}-4`}>無意見</label>
                                                                                        </div>
                                                                                        {
                                                                                            data.dept.map((kitem, kindex) => {
                                                                                                return (
                                                                                                    <div className='mt-3'>
                                                                                                        <p className='fw-bolder mb-2'><span style={underLineStyle}>{busCode == 'FU03' || busCode == 'FU06' ? '單位&區部主管' : '單位主管'}</span> - {kitem} 在【{item.option}】價值觀表現之相關描述說明：</p>
                                                                                                        <div className='answer-box fw-bolder'>
                                                                                                            <textarea className='form-control' cols="50" rows="5" {...register(`deptQ${index + 1}Desc${kindex + 1}`)} placeholder='請描述說明'></textarea>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            item[`Q${index + 1}`].option.map((aitem, aindex) => {
                                                                                                return (
                                                                                                    <div className='form-check mb-2'>
                                                                                                        <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`Q${index + 1}-${aindex + 1}`} value={`${aindex == 0 ? 'Y1' : 'N'}`} {...register(`deptQ${index + 1}`)} />
                                                                                                        <label className='form-check-label' htmlFor={`Q${index + 1}-${aindex + 1}`}>{aitem}</label>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        <div className='mt-3'>
                                                                                            <div>
                                                                                                <p className='fw-bolder mb-2'><span style={underLineStyle}>{busCode == 'FU03' || busCode == 'FU06' ? '單位&區部主管' : '單位主管'}</span>{item[`Q${index + 1}Desc`].title}</p>
                                                                                                <div className='answer-box fw-bolder'>
                                                                                                    <textarea className='form-control' cols="50" rows="5" {...register(`deptQ${index + 1}Desc1`)} placeholder='請描述說明'></textarea>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='d-none'>
                                                                                                <textarea className='form-control' cols="50" rows="5" {...register(`deptQ${index + 1}Desc2`)}></textarea>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {/* 區部主管 */}
                                            {
                                                data.area.length >= 1 &&
                                                <div>
                                                    <div className='w-100 my-5' style={{ borderBottom: '1.5px solid #fff' }}></div>
                                                    <p className='text-light my-2 fw-bolder ps-2' style={{ borderLeft: '2px solid #fff', fontSize: '20px' }}>
                                                        區部主管 -
                                                        <span className='mx-1'>
                                                            {
                                                                data.area.map((item, index) => {
                                                                    return item
                                                                })
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className='py-2 text-light fw-bolder'>依您對自身<span style={underLineStyle}>區部主管</span>的了解，煩請參閱下列價值觀的相關說明，並回答相關問題，謝謝。</p>
                                                    {
                                                        questionList.map((item, index) => {
                                                            return (
                                                                <div className='col-12 mb-3'>
                                                                    <div className='bg-light rounded p-3'>
                                                                        <div className='row align-items-center question-box mb-3'>
                                                                            <span className='col-2 number-box bg-golden-yellow'>Q{index + 1 + 4}</span>
                                                                            <span className='col-10 fw-bolder'>
                                                                                <span style={{ fontSize: '18px' }}>價值觀【{item.option}】：</span><br />
                                                                                {item.title}
                                                                            </span>
                                                                        </div>
                                                                        <ul className='mb-3 text-question-blue'>
                                                                            {item.desList.map((kitem, kindex) => {
                                                                                return (
                                                                                    <li>{kitem}</li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                        <hr />
                                                                        <div className='ms-3'>
                                                                            <div className='answer-box' >
                                                                                <p className='fw-bolder mb-2'>您是否推薦<span style={underLineStyle}>區部主管</span>{item[`Q${index + 1}`].title}<span className='text-danger mx-1'>*</span></p>
                                                                                <div className="invalid-feedback d-block fw-bolder">{errors[`areaQ${index + 1}`] && '*必填'}</div>
                                                                                {
                                                                                    item[`Q${index + 1}`].option.map((aitem, aindex) => {
                                                                                        return (
                                                                                            <div className='form-check mb-2'>
                                                                                                <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`areaQ${index + 1}-${aindex + 1}`} value={aindex == 0 ? 'Y1' : 'N'} {...register(`areaQ${index + 1}`)} />
                                                                                                <label className='form-check-label' htmlFor={`areaQ${index + 1}-${aindex + 1}`}>{aitem}</label>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <div className='mt-4'>
                                                                                    <p className='fw-bolder mb-2'><span style={underLineStyle}>區部主管</span>{item[`Q${index + 1}Desc`].title}</p>
                                                                                    <div className='answer-box fw-bolder'>
                                                                                        <textarea className='form-control' cols="50" rows="5" {...register(`areaQ${index + 1}Desc`)}></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            <div className='text-center py-3'>
                                                <button type='submit' className='btn btn-light fw-bolder'>送出</button>
                                            </div>
                                        </form>
                                    </>
                                }
                            </> :
                                <div className="col-12">
                                    <div className='vh-100'><p className='fs-5 text-center text-light my-5 fw-bolder'>問卷已結束</p></div>
                                </div>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}
//answerList
export default ModelSupervisor