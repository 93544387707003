import React, { useState, useEffect } from "react";
import axios from "axios";

import Loading from "../../../components/Loading";

import { percentCal, dateChange } from "../../../assets/javascript/calTool";//百分比轉換

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercentage } from '@fortawesome/free-solid-svg-icons';

import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'

const Competition = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [comItem, setComItem] = useState([]); //競賽項目 data: competitionData2
    useEffect(() => {
        LineLogin('/competition'); //LINE 登入狀態驗證
        Countfunc('競賽業績_競賽', 33)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}Promo`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/competition');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setIsLoading(false)
                        setComItem(res.data.Data.competitionData2)
                    } else if (res.data.ResponseCode === '-20') {
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);

    //判斷競賽名稱
    const comItemName = (itemNum) => {
        if (itemNum === '0') {
            return '總經理杯'
        } else if (itemNum === '1') {
            return '群英杯'
        } else if (itemNum === '2') {
            return 'IDA'
        } else if (itemNum === '3') {
            return ''
        } else if (itemNum === '4') {
            return '天勤獎'
        }
    }

    //目前 FYC 業績 : 實際核發 FYC + 實際受理未核發 FYC
    const nowFYC = (isGave, notGave) => {
        return `${Math.round((isGave + notGave) / 10000)}萬`
    }

    //下階段目標值 FYC : 下階段目標值 FYC (以萬元計算)
    const nextGoalFYC = (val) => {
        return `${Math.round(val / 10000)}萬`
    }

    //下階段目標值 FYC 達成百分比 : 目前 FYC 業績 / 下階段目標值 FYC
    const nextGoalFYCPercent = (isGave, notGave, nextGoal) => {
        let nowFYC = isGave + notGave
        return percentCal(nowFYC, nextGoal)
    }

    //目前件數 : 實際核發件數 + 實際受理未核發件數 (com3 - 0,1,3 不顯示)
    const goalCase = (type, isGave, notGave) => {
        if (type === '0' || type === '1' || type === '3') {
            return ''
        } else {
            return Math.round(Number(isGave + notGave))
        }
    }

    //下階段目標件數 : 下階段目標件數 (com3 - 0,1,3 不顯示)
    const nextGoalCase = (type, val) => {
        if (type === '0' || type === '1' || type === '3') {
            return ''
        } else {
            return `/ ${Math.round(Number(val))}件`
        }
    }


    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-2">
                <p className={`my-3 ${comItem.length === 0 ? 'd-block' : 'd-none'}`}>無資料</p>
                <div className="row justify-content-start">
                    {
                        comItem.map((item, index) => {
                            return (
                                <div className="insurance-box col-md-6 col-lg-4">
                                    <h6 className="box-header bg-dark-blue border-dark-blue">{`${comItemName(item['競賽類型'])} ${item['已達成階段名稱']}`}</h6>
                                    <div className="box-body border-dark-blue">
                                        <span className="text-golden-brown box-num">
                                            {
                                                nextGoalFYCPercent(item['實際核發FYC'], item['實際受理未核發FYC'], item['下階段目標值FYC'])
                                            }
                                        </span>
                                        <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faPercentage} />
                                        <p>( {`${nowFYC(item['實際核發FYC'], item['實際受理未核發FYC'])}/ ${nextGoalFYC(item['下階段目標值FYC'])}`})</p>
                                        <span className="text-dark-blue">{`${goalCase(item['競賽類型'], item['實際核發件數'], item['實際受理未核發件數'])} ${nextGoalCase(item['競賽類型'], item['下階段目標件數'])}`}</span>
                                    </div>
                                    <p className="fs-sm">資料更新日：{dateChange(item['更新日期'])}</p>
                                </div>
                            )
                        })
                    }
                    <p className={`text-dark-blue fw-bolder text-center py-2 ${comItem.length === 0 ? 'd-none' : ''}`}>本業績採受理+核發預況報表。實際達成資格以各獎項標準為主</p>
                </div>
            </div>
        </>
    )
}

export default Competition