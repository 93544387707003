import React from 'react';
import BasicModel from 'Dashboard/model/BasicModel';
import { useLocation } from 'react-router-dom';
const DashboardRouterWrapper = ({ children }) => {
    const location = useLocation()

    return (
        <div className='dashboard'>
            {
                location.pathname !== '/dashboard/login' ? <BasicModel>{children}</BasicModel> : <div>{children}</div>
            }
        </div>
    )
}
export default DashboardRouterWrapper