import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading';
//* 日期選擇器
import DatePicker, { registerLocale } from 'react-datepicker'
import zhTW from 'date-fns/locale/zh-TW';
import moment from 'moment';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const NPSStatistics = (props) => {
    registerLocale('zhTW', zhTW)

    let [isLoading, setIsLoading] = useState(false),
        [searchData, setSearchData] = useState({ date: moment().subtract(1, 'months').valueOf() }),
        [data, setData] = useState([]), // nps
        [filterState, setFilterState] = useState('max') //max 最大 , min 最小

    // API
    const handleAPI = {
        getNpsData: function (date) {
            setIsLoading(true)
            setSearchData({ ...searchData, date: moment(date).valueOf() })

            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}get_nps_data/${moment(date).format('YYYY')}/${moment(date).format('MM')}`
            axios.get(API)
                .then((res) => {
                    console.log(res)
                    let { code, data } = res.data
                    if (code == '0') {
                        setIsLoading(false)
                        setData(data[1])
                    } else {
                        setIsLoading(false)
                        alert(`GET get_nps_data 錯誤。(${code})`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    alert(`網路連線錯誤，請聯繫管理員。(get_nps_data:err)`)
                })
        },
        getNpsDetailData: function (val) {
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}get_nps_detail_data/${moment(searchData.date).format('YYYY')}/${moment(searchData.date).format('MM')}/${val}`

            axios.get(API)
                .then((res) => {
                    let { code, data } = res.data
                    if (code == '0') {
                        setIsLoading(false)
                        handleEvent.pushDataDetail(val, data)
                    } else {
                        setIsLoading(false)
                        alert(`GET get_nps_detail_data 錯誤。(${code})`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    alert(`網路連線錯誤，請聯繫管理員。(get_nps_detail_data:err)`)
                })
        }
    }

    //Event
    const handleEvent = {
        isNegativeNum: function (val) {
            return Math.sign(Number(val)) == -1
        },
        pushDataDetail: function (name, getDetail) {
            if (data.length > 0) {
                let saveData = data.map((item, index) => {
                    if (item.SAL_MST_NAME == name) {
                        item.detail = getDetail
                    }
                    return item
                })

                setData(saveData)
            }
        }
    }

    useEffect(() => {
        handleAPI.getNpsData(searchData?.date)
    }, [])

    return (
        <>
            <Loading isLoading={isLoading} />
            <h2 className='fs-4 text-dark-blue fw-bolder p-3'>{props.title}</h2>
            <div className='h-100'>
                <div className='container p-4 challenge position-relative'>
                    <div className='d-flex align-items-center'>
                        <label htmlFor='dateRange' className='form-label fw-bolder m-0 me-2'>查詢時間</label>
                        <div className='calendar-input-container'>
                            <DatePicker locale='zhTW'
                                id='Start_time'
                                className={`form-control col-auto flex-grow-1 fw-bolder`}
                                dateFormat='yyyy/MM'
                                selected={searchData?.date}
                                onChange={(date) => ((setData([]), handleAPI.getNpsData(date)))}
                                maxDate={new Date()}
                                showMonthYearPicker
                                placeholderText='YYYY/MM'
                                autoComplete='off'
                            >
                            </DatePicker>
                        </div>
                        <p className='fw-bolder ms-2'>-- 共：{data.length} 筆</p>
                    </div>
                    {/* 篩選 */}
                    <div className='d-flex align-items-center fw-bolder mt-3' onChange={e => setFilterState(e.target.value)}>
                        <div className='me-2'>總評分數：</div>
                        <div className='form-check form-check-block me-3'>
                            <input className='form-check-input' type='radio' name={`filterState`} id='max' value='max' defaultChecked={filterState == 'max'} />
                            <label className='form-check-label' htmlFor={`max`}>由高到低</label>
                        </div>
                        <div className='form-check form-check-block me-3'>
                            <input className='form-check-input' type='radio' name={`filterState`} id='min' value='min' defaultChecked={filterState == 'min'} />
                            <label className='form-check-label' htmlFor={`min`}>由低到高</label>
                        </div>
                    </div>
                    {
                        <div className='table-responsive-xxl text-center mt-1 mt-sm-3' style={{ height: '80vh', overflow: 'scroll' }}>
                            {
                                <table className='table table-striped table-light align-middle'>
                                    <thead>
                                        <tr>
                                            <th width='100px'>姓名</th>
                                            <th>總評分數</th>
                                            <th className='text-end'>推薦(Best)</th>
                                            <th className='text-end'>批評(Low)</th>
                                            <th className='text-end'>NPS</th>
                                            <th className='text-center'>評分、建議原因</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.sort((a, b) => {
                                                if (filterState == 'max') {
                                                    return b.total_nps - a.total_nps || b.best_nps - a.best_nps
                                                } else {
                                                    return a.total_nps - b.total_nps || a.best_nps - b.best_nps
                                                }
                                            }).map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={`data-${index + 1}`}>
                                                            <td className='fw-bolder'>{item.SAL_MST_NAME}</td>
                                                            <td className='fw-bolder'>{item.total_nps}</td>
                                                            <td className={`fw-bolder text-end ${handleEvent.isNegativeNum(item.best_nps) && 'text-danger'}`}>{(item.best_nps * 100).toFixed(2)}%</td>
                                                            <td className={`fw-bolder text-end ${handleEvent.isNegativeNum(item.low_nps) && 'text-danger'}`}>{(item.low_nps * 100).toFixed(2)}%</td>
                                                            <td className={`fw-bolder text-end ${handleEvent.isNegativeNum(item.nps) && 'text-danger'}`}>{(item.nps * 100).toFixed(2)}%</td>
                                                            <td className='fw-bolder'>
                                                                <button
                                                                    onClick={e => handleAPI.getNpsDetailData(item.SAL_MST_NAME)}
                                                                    className='accordion-button collapsed d-block text-center' type='button' data-bs-toggle='collapse' data-bs-target={`#data${index}-collapseOne`} aria-expanded='false' aria-controls={`data${index}-collapseOne`}>
                                                                    <FontAwesomeIcon icon={faAngleDown} className='mx-2' />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan='6'>
                                                                <div className='accordion' id='accordionPanelsStayOpenExample'>
                                                                    <div className='accordion-item'>
                                                                        <div id={`data${index}-collapseOne`} className='accordion-collapse collapse' aria-labelledby={`data${index}-headingOne`}>
                                                                            <div className='accordion-body fw-bolder text-start'>
                                                                                <table className="table">
                                                                                    <thead>
                                                                                        <tr style={{ background: 'rgb(248, 237, 217)' }}>
                                                                                            <th className='fw-bolder' width='180'>評分原因</th>
                                                                                            <th className='fw-bolder' width='180'>建議</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {item?.detail?.filter(val => {
                                                                                            return val.SAL_NUM_REASON !== "null" || val.SAL_NUM_IMPROVE !== "null"
                                                                                        }).map((detailItem, detailIndex) => {
                                                                                            return (
                                                                                                <tr key={`detail-${detailIndex + 1}`}>
                                                                                                    <th scope="row">{detailItem.SAL_NUM_REASON == 'null' ? '' : detailItem.SAL_NUM_REASON}</th>
                                                                                                    <td>{detailItem.SAL_NUM_IMPROVE == 'null' ? '' : detailItem.SAL_NUM_IMPROVE}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        })}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                        {
                                            data.length == 0 && <tr><td colSpan={5} className='fw-bolder'>無資料</td></tr>
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
export default NPSStatistics