import React, { useState, useEffect } from "react";
import { useHistory, Link, useRouteMatch, useLocation } from "react-router-dom";

//components
import BackgroundWrapper from "../../components/BackgroundWrapper";
import InsideNav from "../../components/InsideNav";
import dataChange from "../../assets/javascript/dateChange"
import Loading from "../../components/Loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

import LineLogin from "../../assets/javascript/lineLogin";
import AlertMes from "../../components/Alert";

const CustomerQuery = () => {
    let [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' })
    let match = useRouteMatch();
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token === '') {
        localStorage.setItem('page', '/customer_query');
        window.location.href = '/login'
    }
    const history = useHistory();
    let code = history.location.pathname.split('/').pop();
    let [isLoading, setIsLoading] = useState(true) //Loading component
    // insideNav component 選單選項
    let insideNavItem = [
        { text: '基本資料' }, { text: '保單資料' }, { text: '約訪紀錄' }
    ]
    let [option, setOption] = useState(insideNavItem[0].text); //預設為第一個

    const handleClick = (e) => {
        let { innerText, nodeName } = e.target
        if (nodeName !== 'A') {
            return
        }
        setOption(innerText);
    }
    // insideNav component 選單選項 end
    let [cusData, setCusData] = useState({})
    let [insData, setInsData] = useState([])
    let [userId, setUserId] = useState(JSON.parse(unescape(token)).Id);
    let [visData, setVisData] = useState({});

    const getCusData = () => {
        let cusType = sessionStorage.getItem('cusType')
        if (cusType == null) {
            setIsLoading(true)
            setAlertMes({ mes: '類型取得錯誤，請重新嘗試', show: true, color: 'danger' })
            setTimeout(() => {
                window.location.href = '/customer_query'
            }, 500)
        } else {
            LineLogin('/customer_query');

            let API = `${process.env.REACT_APP_GOLDEN_API5000}CusList`
            if(cusType == '1'){
                API = `${API}/Type=1`
            }else if(cusType == '100'){
                API = `${API}/InsType`
            }

            axios.get(API, {
                withCredentials: true,
                headers: {
                    'Access-Control-Allow-Credentials': 'true',
                }
            })
                .then((res) => {
                    setTimeout(() => {
                        if (res.data.ResponseCode === '-1') {
                            localStorage.setItem('page', '/customer_query');
                            window.location.href = '/login'
                            return
                        } else if (res.data.ResponseCode === '0') {
                            res.data.Data.map((item, index) => {
                                if (code == item.Code) {
                                    sessionStorage.setItem('lorem', escape(JSON.stringify({
                                        user: userId,
                                        cus: item.ID,
                                        name: item.Name
                                    })))
                                    setCusData(item)
                                    setIsLoading(false)
                                    item.InsData.forEach((item, index) => {
                                        item.forEach((detailItem, index) => {
                                            setInsData(oldArray => [...oldArray, detailItem]);
                                        })
                                    })
                                }
                            })
                        } else if (res.data.ResponseCode === '-20') {
                            setIsLoading(false)
                        } else {
                            alert('查詢失敗!')
                            setIsLoading(false)
                        }
                    }, Number(process.env.REACT_APP_TIMEOUT))
                })
                .catch((err) => {
                    alert('請重新整理')
                })
        }
    }

    useEffect(() => {
        getCusData()
    }, []);

    let showInsData = [];
    const showInsDataFun = () => {
        const set = new Set();
        showInsData = insData.filter(item => !set.has(item.Ins_No) ? set.add(item.Ins_No) : false); //過濾 code 相同的保單
    }
    showInsDataFun();

    useEffect(() => {
        if (option === '約訪紀錄') {
            window.location.href = `${match.url}/visit`
        }
    }, [option]);

    //重新查詢 start
    const handleSearchReset = (e) => {
        e.preventDefault();
        window.location.href = '/customer_query'; //返回指定頁面
        sessionStorage.clear();
    }
    const SearchReset = {
        bottom: '0',
        width: '100%',
        zIndex: '40'
    }
    //重新查詢 end

    return (
        <>
            <Loading isLoading={isLoading} />
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} setAlertMes={setAlertMes} />
            <BackgroundWrapper>
                <div className="position-fixed w-100 bg-light" style={{ zIndex: '30' }}>
                    <div className="top-nav w-100 py-3">
                        <p className="text-light text-center fw-bolder">客戶明細</p>
                    </div>
                    <InsideNav insideNavItem={insideNavItem} option={option} handleClick={handleClick}></InsideNav>
                </div>
                <div className="d-table" style={{ height: '130px' }}></div>
                <div className={`container ${option === '基本資料' ? 'd-block' : 'd-none'}`} style={{ background: 'rgba(255,255,255,50%)' }}>
                    <ul className="list-unstyles p-0 pb-4">
                        <li className="row px-2">
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">身分證/統編</span>
                                <span className="d-block text-dark-blue fw-bolder">{cusData.ID}</span>
                            </div>
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">姓名/公司名</span>
                                <span className="d-block text-dark-blue fw-bolder">{cusData.Name}</span>
                            </div>
                        </li>
                        <li className="row px-2">
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">生日/成立日</span>
                                <span className="d-block text-dark-blue fw-bolder">{cusData.Birthday === null ? '' : dataChange(cusData.Birthday)}</span>
                            </div>
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">性別</span>
                                <span className="d-block text-dark-blue fw-bolder">{cusData.SEX}</span>
                            </div>
                        </li>
                        <li className="row px-2">
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">婚姻狀況</span>
                                <span className="d-block text-dark-blue fw-bolder">
                                    {cusData.Marriage === null || cusData.Marriage == '' ? '' : cusData.Marriage == 1 ? '已婚' : '未婚'}
                                </span>
                            </div>
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">客戶等級</span>
                                <span className="d-block text-dark-blue fw-bolder">
                                    {cusData.CLevel === null || cusData.CLevel == '' ? '' : cusData.CLevel}</span>
                            </div>
                        </li>
                        <li className="row px-2">
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">通訊地址</span>
                                <span className="d-block text-dark-blue fw-bolder">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                    <span className="ms-2">{cusData.Address === null || cusData.Address == '' ? '' : cusData.Address}</span>
                                </span>
                            </div>
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">連絡電話</span>
                                <span className="d-block text-dark-blue fw-bolder">
                                    <FontAwesomeIcon icon={faCommentDots} />
                                    <span className="ms-2">{cusData.TEL === null ? '' : cusData.TEL}</span>
                                </span>
                            </div>
                        </li>
                        <li className="row px-2">
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">手機號碼</span>
                                <span className="d-block text-dark-blue fw-bolder">
                                    <FontAwesomeIcon icon={faCommentDots} />
                                    <span className="ms-2">{cusData.Mobile === null ? '' : cusData.Mobile}</span>
                                </span>
                            </div>
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">Email</span>
                                <span className="ms-2">{cusData.EMail === null ? '' : cusData.EMail}</span>
                            </div>
                        </li>
                        <li className="row px-2">
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">通訊軟體ID</span>
                                <span className="ms-2">
                                    {cusData.SKYPE === null ? '' : cusData.SKYPE}
                                </span>
                            </div>
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">公司地址</span>
                                <span className="d-block text-dark-blue fw-bolder">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </span>
                                <span className="ms-2">
                                    {cusData.Address2 === null ? '' : cusData.Address2}
                                </span>
                            </div>
                        </li>
                        <li className="row px-2">
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">公司電話</span>
                                <a href="tel:" className="d-block text-dark-blue fw-bolder text-end">
                                    <FontAwesomeIcon icon={faCommentDots} />
                                    <span className="ms-2">{cusData.CTEL === null ? '' : cusData.CTEL}</span>
                                </a>
                            </div>
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">傳真號碼</span>
                                <span className="ms-2">{cusData.FAX === null ? '' : cusData.FAX}</span>
                            </div>
                        </li>
                        <li className="row px-2">
                            <div className="col-md-6 d-flex border-bottom py-3">
                                <span className="d-block query-title">備用通訊資料</span>
                                <span className="d-block text-dark-blue fw-bolder">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                    <span className="ms-2">{cusData.Address3 === null ? '' : cusData.Address3}</span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className={`container ${option === '保單資料' ? 'd-block' : 'd-none'}`} style={{ background: 'rgba(255,255,255,50%)' }}>
                    <div className="row">
                        {
                            showInsData.map((item, index, ary) => {
                                if (item.Main == 1 || item.Main == null) {
                                    return (
                                        <Link to={`/insurance_policy_read/${item.PayerCode}/${item.Ins_No}`} className="col-md-6 col-lg-4 my-3 cursor-pointer d-block" key={index}>
                                            <div className="card bg-light card-shadow">
                                                <h6 className="bg-dark-blue px-3 py-2 d-flex flex-wrap justify-content-between align-items-center text-light text-center fw-bolder rounded-top">
                                                    <span className="d-block">{item.Supplier_Name}</span>
                                                    <span className="d-block">{item.Ins_No}</span>
                                                </h6>
                                                <div className="card-body py-1">
                                                    <ul className="list-unstyled">
                                                        {
                                                            item.Main == 1 && item.MainVoid &&
                                                            <li>
                                                                <span className="badge bg-secondary">已註銷</span>
                                                            </li>
                                                        }
                                                        <li className="row py-2">
                                                            <span className="d-block text-golden-brown fw-bolder" style={{ fontSize: '18px' }}>{item.Pro_Name}</span>
                                                        </li>
                                                        <li className="row py-1">
                                                            <span className="d-block col-4 text-dark">要/被保人</span>
                                                            <span className="d-block col-8 text-golden-brown fw-bolder">{item.name}/{item.IName}</span>
                                                        </li>
                                                        <li className="row py-1">
                                                            <span className="d-block col-4 text-dark">生效日</span>
                                                            <span className="d-block col-8 text-golden-brown fw-bolder">{dataChange(item.Effe_Date)}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
                <div className="d-table" style={{ height: '60px' }}></div>
                <button type="button" className="btn btn-golden-brown position-fixed py-3" style={SearchReset} onClick={handleSearchReset}>重新查詢</button>

            </BackgroundWrapper>
        </>
    )
}

export default CustomerQuery