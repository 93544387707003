import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../components/Loading.js'
import moment from 'moment';
import LineLogin from '../../assets/javascript/lineLogin';
import Countfunc from '../../assets/javascript/countfunc';
const MorningAnsQuest = () => {
    let history = useHistory();
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    let [isLoading, setIsLoading] = useState(true)
    let [week, setWeek] = useState('')

    if (token === '' || token === null || token === undefined) {
        localStorage.setItem('page', '/ans_quest');
        window.location.href = '/login'
    }
    useEffect(() => {
        LineLogin('/ans_quest')
        Countfunc('早會問卷', 38)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode == '0') {
                        handleAPI.Qcheck();
                        return
                    } else if (res.data.ResponseCode == '-1') {
                        localStorage.setItem('page', '/ans_quest');
                        window.location.href = '/login'
                        return
                    } else if (!token) {
                        localStorage.setItem('page', '/ans_quest');
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);

    let [questData, setQuestData] = useState({
        QS_SDCODE: '', //課程代號
        QS_BGCODE: '', //業務中心代碼
        QS_UCODE: '', //業務代碼(Sales Id) 
        TRS_MST_NAME: '' //課程名稱
    })

    let [question, setQuestion] = useState({ //問卷填寫
        QS_SCORE1: '',
        QS_SCORE2: '',
        QS_SCORE3: '',
        QS_SCORE4: '',
        QS_MEMO: ''
    })

    const goToStatePage = (resCode) => {
        history.push({
            pathname: '/ans_quest_state',
            state: { code: resCode }
        })
        setIsLoading(false)
    }

    const handleAPI = {
        Qcheck: function () { //確定早會狀態
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/QCheck`
            let data = {
                Id: JSON.parse(unescape(token)).Id
            }
            axios.post(API, data)
                .then((res) => {
                    let { ResponseCode } = res.data
                    if (ResponseCode == '0') {
                        setQuestData({
                            QS_SDCODE: res.data.QS_SDCODE, //課程代號
                            QS_BGCODE: res.data.QS_BGCODE, //業務中心代碼
                            QS_UCODE: res.data.QS_UCODE, //業務代碼(Sales Id) 
                            TRS_MST_NAME: res.data.TRS_MST_NAME //課程名稱
                        })
                        setIsLoading(false)
                    } else {
                        goToStatePage(ResponseCode)
                    }
                })
                .catch((err) => {
                    goToStatePage('-100') //API 錯誤
                    console.log(err)
                })
        },
        QFillin: function () { //送出早會問卷
            //表單驗證
            let arr = [];
            for (let i = 1; i <= 4; i++) {
                if (question[`QS_SCORE${i}`] == '') {
                    arr.push(`Q${i}`)
                }
            }
            if (arr.length !== 0) {
                alert(`您尚未填寫${arr}`)
                return
            }
            //發送 API
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/QFillin`
            let data = {
                QS_SDCODE: questData.QS_SDCODE, //課程代碼
                QS_BGCODE: questData.QS_BGCODE, //業務中心代碼
                QS_UCODE: questData.QS_UCODE, //業務代碼(Sales Id)
                QS_SCORE1: question.QS_SCORE1, //Q1 早會內容實用
                QS_SCORE2: question.QS_SCORE2, //Q2 早會內容有趣
                QS_SCORE3: question.QS_SCORE3, //Q3 主持人、音控表現稱職
                QS_SCORE4: question.QS_SCORE4, //Q4 整體來說有收穫，願意邀請準增員對象參與
                QS_MEMO: question.QS_MEMO //建議與回饋
            }
            setIsLoading(true)
            axios.post(API, data)
                .then((res) => {
                    let { ResponseCode } = res.data
                    if (ResponseCode == '0') {
                        goToStatePage(ResponseCode)
                        setIsLoading(false)
                    } else if (ResponseCode == '-99') {
                        alert('錯誤的問卷填寫! 請重新填寫問卷')
                        window.location.reload();
                    } else {
                        goToStatePage(ResponseCode)
                    }
                })
                .catch((err) => {
                    goToStatePage('-100')
                    console.log(err)
                })
        }
    }

    //問卷題
    let questionList = [
        {
            num: '01',
            id: 'QS_SCORE1',
            Q: '早會內容實用',
        },
        {
            num: '02',
            id: 'QS_SCORE2',
            Q: '早會內容有趣',
        },
        {
            num: '03',
            id: 'QS_SCORE3',
            Q: '主持人、音控表現稱職',
        },
        {
            num: '04',
            id: 'QS_SCORE4',
            Q: '整體來說有收穫，願意教請準增員對象參與',
        }
    ]

    const handleQuestionChange = (e, id) => { //表單 set data
        setQuestion({ ...question, [id]: e.target.value })
    }

    useEffect(() => {
    }, [question])

    useEffect(()=>{ //取得星期
        switch (new Date().getDay()) {
            case 0:
                setWeek('日')
                break;
            case 1:
                setWeek('一')
                break;
            case 2:
                setWeek('二')
                break;
            case 3:
                setWeek('三')
                break;
            case 4:
                setWeek('四')
                break;
            case 5:
                setWeek('五')
                break;
            case 6:
                setWeek('六')
                break;
            default:
                setWeek('')
            // break;
        }
    },[])
    return (
        <>
            <Loading isLoading={isLoading}></Loading>
            <div className='h-100 questionnaire' style={{ background: 'linear-gradient(90deg, #2FABF4, #1671bc)' }}>
                <div className='container'>
                    <div className={`row`}>
                        <div className='col-12 text-center text-light fw-bolder'>
                            <h4 className='bg-golden-yellow px-4 py-2 mt-3 d-inline-block fw-bolder rounded'>早會問卷</h4>
                            <p className='my-3'>日期：{moment().format('YYYY/MM/DD')} 星期{week}</p>
                            <h5 className='mb-3 fw-bolder'>課程名稱：{questData.TRS_MST_NAME}</h5>
                        </div>
                        { //給分題
                            questionList.map((item, index) => {
                                return (
                                    <>
                                        <div className='col-12 mb-3'>
                                            <div className='bg-light rounded p-3'>
                                                <div className='row align-items-center question-box mb-3'>
                                                    <span className='col-2 number-box bg-golden-yellow'>{item.num}</span>
                                                    <span className='col-10 fw-bolder'>
                                                        {item.Q}
                                                        <span className='text-danger'>*</span>
                                                    </span>
                                                </div>
                                                <div className='answer-box fw-bolder' onChange={e => handleQuestionChange(e, item.id)}>
                                                    <div className='form-check form-check-block mb-2'>
                                                        <input className='form-check-input' type='radio' name={`Q-${item.id}`} id={`Q-${item.id}-1`} value='5' />
                                                        <label className='form-check-label' htmlFor={`Q-${item.id}-1`}>非常認同</label>
                                                    </div>
                                                    <div className='form-check form-check-block mb-2'>
                                                        <input className='form-check-input' type='radio' name={`Q-${item.id}`} id={`Q-${item.id}-2`} value='4' />
                                                        <label className='form-check-label' htmlFor={`Q-${item.id}-2`}>認同</label>
                                                    </div>
                                                    <div className='form-check form-check-block mb-2'>
                                                        <input className='form-check-input' type='radio' name={`Q-${item.id}`} id={`Q-${item.id}-3`} value='3' />
                                                        <label className='form-check-label' htmlFor={`Q-${item.id}-3`}>普通</label>
                                                    </div>
                                                    <div className='form-check form-check-block mb-2'>
                                                        <input className='form-check-input' type='radio' name={`Q-${item.id}`} id={`Q-${item.id}-4`} value='2' />
                                                        <label className='form-check-label' htmlFor={`Q-${item.id}-4`}>不認同</label>
                                                    </div>
                                                    <div className='form-check form-check-block mb-2'>
                                                        <input className='form-check-input' type='radio' name={`Q-${item.id}`} id={`Q-${item.id}-5`} value='1' />
                                                        <label className='form-check-label' htmlFor={`Q-${item.id}-5`}>非常不認同</label>
                                                    </div>
                                                </div>
                                                <p className='text-end fw-bolder' style={{ color: '#898989' }}>必填</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        {/*  onChange={e => handleQuestionChange(e, item.id)} */}
                        <div className='col-12 pb-4'>
                            <div className='bg-light rounded p-3'>
                                <div className='row align-items-center question-box mb-3'>
                                    <span className='col-10 fw-bolder'>建議與回饋</span>
                                </div>
                                <div className='answer-box fw-bolder' onChange={e => handleQuestionChange(e, 'QS_MEMO')}>
                                    <textarea className='form-control' cols="50" rows="8" placeholder='字數請勿超過 500 個字'></textarea>
                                </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <button type='button' className='btn btn-golden-yellow text-light py-2 fw-bolder w-50 rounded-pill my-3'
                                onClick={handleAPI.QFillin}>送出問卷</button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default MorningAnsQuest