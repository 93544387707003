import React, { useState, useEffect } from "react";
import axios from "axios";

import { toCurrency, percentCal, dateChange } from "../../../assets/javascript/calTool"

import Loading from '../../../components/Loading'

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faPercentage } from '@fortawesome/free-solid-svg-icons';

import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'

const Performance = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [goalYearData, setGoalYearData] = useState({}) //年:設定業績目標
    let [goalMonthData, setGoalMonthData] = useState({}) //月:設定業績目標
    let [comData, setComData] = useState([]) //FYC 排名

    useEffect(() => {
        LineLogin('/performance'); //LINE 登入狀態驗證
        Countfunc('競賽業績_個人業績', 32)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}Promo`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/performance');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        if (res.data.Data.Goal.M === undefined) {
                            setGoalMonthData({ "總公司設定業績目標": null, "業務員設定業績目標": null })
                        } else {
                            setGoalMonthData(res.data.Data.Goal.M)
                        }
                        if (res.data.Data.Goal.Y === undefined) {
                            setGoalYearData({ "總公司設定業績目標": null, "業務員設定業績目標": null })
                        } else {
                            setGoalYearData(res.data.Data.Goal.Y)
                        }
                        setComData(res.data.Data.competitionData1)
                        setIsLoading(false)
                    } else if (res.data.ResponseCode === '-20') {
                        setGoalMonthData({ "總公司設定業績目標": 0, "業務員設定業績目標": 0 })
                        setGoalYearData({ "總公司設定業績目標": 0, "業務員設定業績目標": 0 })
                        setIsLoading(false)
                    } else {
                        setGoalMonthData({ "總公司設定業績目標": 0, "業務員設定業績目標": 0 })
                        setGoalYearData({ "總公司設定業績目標": 0, "業務員設定業績目標": 0 })
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);

    let [dayFYC, setDayFYC] = useState({})
    let [monthFYC, setMonthFYC] = useState({})
    let [yearFYC, setYearFYC] = useState({})
    const finalDataFun = (data) => {
        data.forEach((item, index) => {
            if (item['日期區間類型'] === 'D') {
                setDayFYC(item)
            } else if (item['日期區間類型'] === 'M') {
                setMonthFYC(item)
            } else if (item['日期區間類型'] === 'Y') {
                setYearFYC(item)
            }
        })
    }
    useEffect(() => {
        finalDataFun(comData);
    }, [comData, dayFYC, monthFYC, yearFYC]);

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-2">
                <div className="row justify-content-center">
                    <div className="insurance-box col-md-6">
                        <h6 className="box-header bg-dark-blue border-dark-blue">本日 FYC</h6>
                        <div className="box-body border-dark-blue">
                            <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faDollarSign} />
                            <span className="text-golden-brown box-num">
                                {Object.keys(dayFYC).length === 0 ? '0' : toCurrency(Math.round(dayFYC['FYC']))} 元</span>
                            <p>({`${Object.keys(dayFYC).length === 0 ? '0' : toCurrency(dayFYC['單位排名'])}名 / ${Object.keys(dayFYC).length === 0 ? '0' : toCurrency(dayFYC['全公司排名'])}名`})</p>
                        </div>
                    </div>
                    <div className="insurance-box col-md-6">
                        <h6 className="box-header bg-dark-blue border-dark-blue">本月 FYC</h6>
                        <div className="box-body border-dark-blue">
                            <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faDollarSign} />
                            <span className="text-golden-brown box-num">
                                {Object.keys(monthFYC).length === 0 ? '0' : toCurrency(Math.round(monthFYC['FYC']))} 元</span>
                            <p>({`${Object.keys(monthFYC).length === 0 ? '0' : toCurrency(monthFYC['單位排名'])}名 / ${Object.keys(monthFYC).length === 0 ? '0' : toCurrency(monthFYC['全公司排名'])}名`})</p>
                        </div>
                    </div>
                    <div className="insurance-box col-md-12">
                        <h6 className="box-header bg-dark-blue border-dark-blue">年度 FYC</h6>
                        <div className="box-body border-dark-blue">
                            <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faDollarSign} />
                            <span className="text-golden-brown box-num">
                                {Object.keys(yearFYC).length === 0 ? '0' : toCurrency(Math.round(yearFYC['FYC']))} 元</span>
                            <p>({`${Object.keys(yearFYC).length === 0 ? '0' : toCurrency(yearFYC['單位排名'])}名 / ${Object.keys(yearFYC).length === 0 ? '0' : toCurrency(yearFYC['全公司排名'])}名`})</p>
                        </div>
                    </div>
                    <p>(單位排名 / 全公司排名)</p>
                    <div className={`insurance-box col-md-6 ${goalMonthData['業務員設定業績目標'] === null && goalMonthData['總公司設定業績目標'] === null ? 'd-none' : ''}`}>
                        <h6 className="box-header bg-dark-blue border-dark-blue">月達成率</h6>
                        <div className="box-body border-dark-blue">
                            <span className="text-golden-brown box-num">
                                {
                                    isNaN(percentCal(monthFYC['FYC'], goalMonthData['總公司設定業績目標'])) ? '0' : percentCal(monthFYC['FYC'], goalMonthData['總公司設定業績目標'])

                                }
                            </span>
                            <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faPercentage} />
                            <p>{`(${toCurrency(Math.round(monthFYC['FYC']))}/${goalMonthData['業務員設定業績目標'] === null ? toCurrency(goalMonthData['總公司設定業績目標']) : toCurrency(goalMonthData['業務員設定業績目標'])})`}</p>
                        </div>
                        <p>資料更新日：{dateChange(monthFYC['資料更新日']) === 'Invalid date' ? '' : dateChange(monthFYC['資料更新日'])}</p>
                    </div>
                    <div className={`insurance-box col-md-6 ${goalYearData['業務員設定業績目標'] === null && goalYearData['總公司設定業績目標'] === null ? 'd-none' : ''}`}>
                        <h6 className="box-header bg-dark-blue border-dark-blue">年達成率</h6>
                        <div className="box-body border-dark-blue">
                            <span className="text-golden-brown box-num">
                                {
                                    isNaN(percentCal(yearFYC['FYC'], goalYearData['總公司設定業績目標'])) ? '0' : percentCal(yearFYC['FYC'], goalYearData['總公司設定業績目標'])
                                }
                            </span>
                            <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faPercentage} />
                            <p>{`(${toCurrency(Math.round(yearFYC['FYC']))}/${goalYearData['業務員設定業績目標'] === null ? toCurrency(goalYearData['總公司設定業績目標']) : toCurrency(goalYearData['業務員設定業績目標'])})`}</p>
                        </div>
                        <p>資料更新日：{dateChange(yearFYC['資料更新日']) === 'Invalid date' ? '' : dateChange(yearFYC['資料更新日'])}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Performance;