import base64url from 'base64url'
import axios from 'axios'
import { Encrypt } from '../../assets/javascript/AESTool'

export default function downloadtoBase64url(url, filename, loading) {
    const isHaveStr = (str) => {
        if (str.indexOf('PDF') !== -1) {
            return true
        } else {
            return false
        }
    }
    let key = process.env.REACT_APP_KEY;
    let iv = process.env.REACT_APP_IV;
    Encrypt(filename, key, iv)
    let fileURL = base64url(Encrypt(filename, key, iv).toString())
    let API = `${process.env.REACT_APP_GOLDEN_API5000}${url}${fileURL}`
    axios.get(API)
        .then((res) => {
            if(res.data.ResponseCode === '-27'){
                alert('檔案已不存在!!')
                loading(false)
            }else{
                loading(false)
                window.location.href = API
            }
        })
        .catch((err) => {
            alert('請重新整理')
        })
}