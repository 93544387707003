import moment from 'moment';
import busCodeData from 'assets/document/busCode.json'

//數字逗號轉換
export function toCurrency(num) {
    if (num === undefined || num === null) {
        return
    } else {
        const parts = num.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }
}

//百分比計算
/* Math.round() 四捨五入 / Math.ceil() 無條件進位 / Math.floor() 無條件捨去 */
//(num/num*10000)/100
export function percentCal(num, num2) {
    return Math.round((Number(Math.round(num)) / Number(Math.round(num2)) * 10000) / 100)
}

export function dateChange(day) {
    let dayDay = Date.parse(day)
    return moment(dayDay).format('YYYY-MM-DD')
}

export function dateTimeChange(day) {
    let dayDay = Date.parse(day)
    if (moment(dayDay).format('a') === 'pm') {
        return moment(dayDay).format('YYYY-MM-DD / 下午 h:mm')
    } else {
        return moment(dayDay).format('YYYY-MM-DD / 上午 h:mm')
    }
}

export function minYearsToDate(date, years) {
    var res = new Date(date);
    res.setFullYear(res.getFullYear() - years);
    return res;
}


export function removeItemAll(arr, value) { //刪除陣列不必要的參數
    var i = 0;
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arr;
}

export function busCodeToName(val) { // busCode 轉名稱
    let returnVal = val
    busCodeData.forEach((item) => {
        if (val == item.busCode) {
            returnVal = item.name
        }
    })
    return returnVal
}

export function busCodeGatArea(val) {
    let returnVal = null
    busCodeData.forEach((item) => {
        if (val == item.busCode) {
            returnVal = item.area
        }
    })
    return returnVal
}