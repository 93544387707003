import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//component
import Nav from '../components/Nav';
import BackgroundWrapper from '../components/BackgroundWrapper';

import CustAge from '../views/insurancePolicy/remind/CustAge'; //近期歲增
import CustBirthday from '../views/insurancePolicy/remind/CustBirthday'; //近期生日
import RenewalM from '../views/insurancePolicy/remind/RenewalM'; //續保產險單
import AnnualM from '../views/insurancePolicy/remind/AnnualM'; //週年壽險單

const Remind = (props) => {
    const navItem = [
        {
            text: '近期增歲',
            to: 'cust_age'
        },
        {
            text: '近期生日',
            to: 'cust_birthday'
        },
        {
            text: '續保產險',
            to: 'renewal_m'
        },
        {
            text: '週年壽險',
            to: 'annual_m'
        }
    ];

    return (
        <>
            <BackgroundWrapper>
                <Router>
                    <Nav navItem={navItem}></Nav>
                    <Switch>
                        <Route exact path="/cust_age"><CustAge /></Route>
                        <Route exact path="/cust_birthday"><CustBirthday /></Route>
                        <Route exact path="/renewal_m"><RenewalM /></Route>
                        <Route exact path="/annual_m"><AnnualM /></Route>
                    </Switch>
                </Router>
            </BackgroundWrapper>
        </>
    );
}
export default Remind;