import React from 'react';

import BackgroundWrapper from 'components/BackgroundWrapper';
import goldennetLogo from "assets/images/goldennet_logo.svg";

const NoFound = () => {
    let style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
    }

    return (
        <BackgroundWrapper>
            <div style={style}>
                <div className="text-center py-5"><img src={goldennetLogo} className="img-fluid" alt="Golden-LOGO" width="300px" /></div>
                <h3 className="fw-bolder text-golden-brown text-center" style={{ whiteSpace: 'break-spaces' }}>
                    找無此頁面(404)<br /><br />請返回 Line 選單，重新點選功能
                </h3>
            </div>
        </BackgroundWrapper>
    )
}
export default NoFound