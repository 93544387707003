import React from 'react';

const NavTabs = (props) => {
    return (
        <>
            <ul className="nav nav-tabs w-100 bg-light pt-3" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pending-tab" data-bs-toggle="tab" data-bs-target="#pending" type="button" role="tab" aria-controls="pending" aria-selected="true">待處理</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="checkOK-tab" data-bs-toggle="tab" data-bs-target="#checkOK" type="button" role="tab" aria-controls="checkOK" aria-selected="false">已處理</button>
                </li>
            </ul>
        </>
    )
}
export default NavTabs