import React, { useEffect } from 'react';
import Loading from '../../../components/Loading';
import { useState } from 'react';
const Satisfied = () => {
    let [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            window.location.href = '/ans_quest'
        }, 500)
    }, [])
    return (
        <Loading isLoading={isLoading} />
    )
}
export default Satisfied