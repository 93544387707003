import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import Icon from '../components/Icon'
const CalendarList = ({ children, data }) => { //日歷列表
    let [thisMonth, setThisMonth] = useState(moment().format('MM')), //目前月份
        [thisYear, setThisYear] = useState(moment().format('YYYY')),
        [calendarData, setCalendarData] = useState([])

    const handleEvent = {
        getWeek: function (val) { //取得星期
            let weekList = ['日', '一', '二', '三', '四', '五', '六']
            return `週${weekList[Number(val)]}`
        },
        getMonthList: function () {
            //資料格式
            /*{
                date:'2023-11-01',
                week:'週一',
                day:'01',
                month:'11'
            }*/
            let ary = []

            let dayList = []
            for (let i = 1; i <= moment(`${thisYear}-${thisMonth}`, "YYYY-MM").daysInMonth(); i++) {
                dayList?.push(i)
            }

            dayList?.map((item, index) => {
                let thisWeek = moment(`${moment().format(`${thisYear}-${thisMonth}`)}-${item}`).format('d')
                ary?.push({
                    date: `${`${thisYear}-${thisMonth <= 9 ? `0${thisMonth}` : thisMonth.toString()}`}-${item <= 9 ? `0${item}` : item.toString()}`,
                    week: handleEvent?.getWeek(thisWeek),
                    day: item <= 9 ? `0${item}` : item.toString(),
                    month: thisMonth <= 9 ? `0${thisMonth}` : thisMonth.toString()
                })
            })
            setCalendarData(ary)
        }
    }

    useEffect(() => {
        handleEvent?.getMonthList()
    }, [thisMonth])

    const handleStyle = {
        dayTarget: { //日期目標
            background: '#8f7140',
            borderRadius: '8px',
            fontSize: '20px',
            color: '#fff'
        }
    }

    return (
        <div className='container'>
            <p className='my-4'>*點擊客戶姓名按鈕，可顯示基本訊息</p>
            <div className='d-flex align-items-center'>
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-primary py-1 px-2" onClick={e => setThisMonth(Number(thisMonth) - 1)} disabled={thisMonth <= 1 && 'disabled'}>
                        <Icon icon='lineArrowLeft' size={24} color='#fff' />
                    </button>
                    <button type="button" className="btn btn-primary py-1 px-2" onClick={e => setThisMonth(Number(thisMonth) + 1)} disabled={thisMonth >= 12 && 'disabled'}>
                        <Icon icon='lineArrowRight' size={24} color='#fff' />
                    </button>
                </div>
                <p className='fw-bolder ms-3'>{thisMonth} 月</p>
            </div>
            <div className='mt-3'>
                {calendarData?.map((item, index) => {
                    return (
                        <>
                            <div className='row' key={`calendarList-${index}`}>
                                <div className='col-2 text-center'>
                                    <p className='small'>{item?.month}月</p>
                                    <p className='fw-bolder text-center' style={moment().format('MM-DD') == `${item?.month}-${item?.day}` ? handleStyle?.dayTarget : { fontSize: '20px', color: '#315176' }}>{item?.day}</p>
                                    <p className='small'>{item?.week}</p>
                                </div>
                                <div className='col-10'>
                                    {
                                        data?.map((kItem, kIndex) => {
                                            if (moment(kItem?.Birthday).format('MM-DD') == `${item?.month}-${item?.day}`) {
                                                return (
                                                    <>
                                                        <button type="button" className="btn btn-primary d-block my-2" data-bs-toggle="modal" data-bs-target={`#data-modal-${kIndex}`}>
                                                            {kItem?.Name}
                                                        </button>
                                                        <div className="modal fade" id={`data-modal-${kIndex}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-centered">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h1 className="modal-title fs-5 fw-bolder" id="exampleModalLabel">{kItem?.Name}</h1>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <table className="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="table-active">身分證號/統編</td>
                                                                                    <td>{kItem?.ID}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="table-active">性別</td>
                                                                                    <td>{kItem?.SEX}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="table-active">聯絡電話</td>
                                                                                    <td>{kItem?.TEL}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="table-active">手機號碼</td>
                                                                                    <td>{kItem?.Mobile}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="table-active">地址</td>
                                                                                    <td>{kItem?.Address}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <hr />
                        </>
                    )
                })}
            </div>
        </div>
    )
}
export default CalendarList