import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useHistory, useLocation, withRouter } from "react-router-dom";
//component
import InsideNav from '../../components/InsideNav';

const LogoList = (props) => {
    let match = useRouteMatch();
    const insideNavItem = [{ text: '壽險', }, { text: '產險', }] //inside 切換選單

    const { state } = useLocation();

    let propsData = props.data; //包括保險類型,公司,所有文件資料

    //都皆為需傳到子頁面的 state
    let [id] = useState((state && state.id) || null);
    let [option, setOption] = useState((state && state.option) || propsData[0].option); //預設為:壽險
    let [company] = useState((state && state.company) || null);
    let [allFiles] = useState((state && state.allFiles) || []); //所有文件資料要傳入內頁

    let [companyType] = useState([])

    //GET 保險公司列表
    const getCompany = (data) => {
        data.forEach((item, index) => {
            if (option === item.option) {
                companyType = item.companyType
            }
        })
    }
    getCompany(propsData)
    //GET 保險公司列表 END

    //事件
    //--- 保險類型切換
    const handleTypeClick = (e) => {
        e.preventDefault();
        let { nodeName, innerText } = e.target
        if (nodeName !== 'A') {
            return
        }
        setOption(innerText)
    }
    //--- 保險類型切換 END

    //保險公司自訂排序
    let companyList = []
    const newCompanyListNum = () => {
        companyType.forEach((item, index) => {
            //壽險
            if (item === '保誠人壽') {
                companyList.push({
                    num: 1,
                    name: item
                })
            } else if (item === '友邦人壽') {
                companyList.push({
                    num: 2,
                    name: item
                })
            } else if (item === '全球人壽') {
                companyList.push({
                    num: 3,
                    name: item
                })
            } else if (item === '安聯人壽') {
                companyList.push({
                    num: 4,
                    name: item
                })
            } else if (item === '安達人壽') {
                companyList.push({
                    num: 5,
                    name: item
                })
            } else if (item === '凱基人壽') {
                companyList.push({
                    num: 6,
                    name: item
                })
            } else if (item === '台灣人壽') {
                companyList.push({
                    num: 7,
                    name: item
                })
            } else if (item === '遠雄人壽') {
                companyList.push({
                    num: 8,
                    name: item
                })
            } else if (item === '新光人壽') {
                companyList.push({
                    num: 9,
                    name: item
                })
            } else if (item === '元大人壽') {
                companyList.push({
                    num: 10,
                    name: item
                })
            } else if (item === '法國巴黎人壽') {
                companyList.push({
                    num: 11,
                    name: item
                })
            } else if (item === '臺銀人壽') {
                companyList.push({
                    num: 12,
                    name: item
                })
            } else if (item === '宏泰人壽') {
                companyList.push({
                    num: 13,
                    name: item
                })
            } else if (item === '第一金人壽') {
                companyList.push({
                    num: 14,
                    name: item
                })
            } else if (item === '南山人壽') {
                companyList.push({
                    num: 16,
                    name: item
                })
            } else if (item === '國泰人壽') {
                companyList.push({
                    num: 17,
                    name: item
                })
            } else if (item === '台新人壽') {
                companyList.push({
                    num: 18,
                    name: item
                })
            } else if (item === '富邦人壽') {
                companyList.push({
                    num: 19,
                    name: item
                })
            }

            //產險
            if (item === '富邦產物') {
                companyList.push({
                    num: 1,
                    name: item
                })
            } else if (item === '新光產物') {
                companyList.push({
                    num: 2,
                    name: item
                })
            } else if (item === '新安東京') {
                companyList.push({
                    num: 3,
                    name: item
                })
            } else if (item === '泰安產物') {
                companyList.push({
                    num: 4,
                    name: item
                })
            } else if (item === '華南產物') {
                companyList.push({
                    num: 5,
                    name: item
                })
            } else if (item === '兆豐產物') {
                companyList.push({
                    num: 6,
                    name: item
                })
            } else if (item === '安達產物') {
                companyList.push({
                    num: 7,
                    name: item
                })
            } else if (item === '旺旺友聯') {
                companyList.push({
                    num: 8,
                    name: item
                })
            } else if (item === '第一產物') {
                companyList.push({
                    num: 9,
                    name: item
                })
            } else if (item === '中國信託產險') {
                companyList.push({
                    num: 10,
                    name: item
                })
            } else if (item === '法國巴黎產物') {
                companyList.push({
                    num: 12,
                    name: item
                })
            } else if (item === '明台產物') {
                companyList.push({
                    num: 13,
                    name: item
                })
            } else if (item === '和泰產物') {
                companyList.push({
                    num: 14,
                    name: item
                })
            } else if (item === '臺灣產物') {
                companyList.push({
                    num: 15,
                    name: item
                })
            } else if (item === '國泰產物') {
                companyList.push({
                    num: 16,
                    name: item
                })
            }
        })
    }
    newCompanyListNum();

    useEffect(() => {
        // handleGoSinPage();
    }, [id, company, allFiles]);
    //---進入子頁面 END
    //事件 END

    return (
        <>
            <InsideNav option={option} insideNavItem={insideNavItem} handleClick={handleTypeClick}></InsideNav>
            <div className="container">
                <ul className="list-unstyled row">
                    {
                        companyList.sort((a, b) => {
                            return a.num - b.num;
                        }).map((item, index) => {
                            if (option === '壽險') {
                                if (item.name === '遠雄人壽') {
                                    return (
                                        <li key={item.name} className="col-4 col-md-3 col-lg-2 py-3 text-center">
                                            <Link to={`${match.url}/life-${item.name}`} className="text-dark-blue d-block" data-id={index} data-company={item.name} data-cat="life">
                                                <img src={require(`../../assets/images/companyLOGO/life/${item.name}.png`).default} alt={`${item.name} logo`} className="img-fluid" style={{ width: "97%" }} data-id={index} data-company={item.name} data-cat="life" />
                                            </Link>
                                        </li>
                                    )
                                }
                                return (
                                    <li key={item.name} className="col-4 col-md-3 col-lg-2 py-3 text-center">
                                        <Link to={`${match.url}/life-${item.name}`} className="text-dark-blue d-block" data-id={index} data-company={item.name} data-cat="life">
                                            <img src={require(`../../assets/images/companyLOGO/life/${item.name}.png`).default} alt={`${item.name} logo`} className="img-fluid" style={{ width: "80%" }} data-id={index} data-company={item.name} data-cat="life" />
                                        </Link>
                                    </li>
                                )
                            } else if (option === '產險') {
                                return (
                                    <li key={item.name} className="col-4 col-md-3 col-lg-2 py-3 text-center">
                                        <Link to={`${match.url}/property-${item.name}`} className="text-dark-blue d-block" data-id={index} data-company={item.name} data-cat="property">
                                            <img src={require(`../../assets/images/companyLOGO/property/${item.name}.png`).default} alt={`${item.name} logo`} className="img-fluid" style={{ width: "80%" }} data-id={index} data-company={item.name} data-cat="property" />
                                        </Link>
                                    </li>
                                )
                            }
                            return true
                        })

                    }
                </ul>
            </div>
        </>
    )
}

export default withRouter(LogoList)