import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//component
import Nav from '../../components/Nav';
import BackgroundWrapper from '../../components/BackgroundWrapper';

import PerformanceRank from '../../views/insurancePolicy/CompetitionPerformance/PerformanceRank';
import ProductsRank from '../../views/insurancePolicy/CompetitionPerformance/ProductsRank';
import CasesRank from '../../views/insurancePolicy/CompetitionPerformance/CasesRank';

const Ranking = (props) => {
    const navItem = [
        {
            text: '業績排行',
            to: 'performance_rank'
        },
        {
            text: '商品排行',
            to: 'products_rank'
        },
        {
            text: '案件排行',
            to: 'cases_rank'
        }
    ];

    return (
        <>
            <BackgroundWrapper>
                <Router>
                    <Nav navItem={navItem}></Nav>
                    <Switch>
                        <Route path="/performance_rank"><PerformanceRank /></Route>
                        <Route path="/products_rank"><ProductsRank /></Route>
                        <Route path="/cases_rank"><CasesRank /></Route>
                    </Switch>
                </Router>
            </BackgroundWrapper>
        </>
    );
}
export default Ranking;