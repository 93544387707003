import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//component
import Nav from '../components/Nav';
import BackgroundWrapper from '../components/BackgroundWrapper';

import Docs from '../views/golden/Docs';
import Rules from '../views/golden/Rules';
import Directory from '../views/golden/Directory';
import History from '../views/golden/History'; //服務通知


const Golden = (props) => {
    const navItem = [
        {
            text: '作業文件',
            to: 'golden_docs'
        },
        {
            text: '辦法規定',
            to: 'golden_rules'
        },
        {
            text: '公司通訊',
            to: 'golden_directory'
        },
        {
            text: '通知',
            to: 'history'
        }
    ];

    return (
        <>
            <BackgroundWrapper>
                <Router>
                    <Nav navItem={navItem}></Nav>
                    <Switch>
                        <Route path="/golden_docs"><Docs /></Route>
                        <Route path="/golden_rules"><Rules /></Route>
                        <Route path="/golden_directory"><Directory /></Route>
                        <Route path="/history"><History /></Route>
                    </Switch>
                </Router>
            </BackgroundWrapper>
        </>
    );
}
export default Golden;