import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LineLogin from '../assets/javascript/lineLogin'
//component
import Loading from "../components/Loading"
import { default as swal } from 'sweetalert2'
import BackGround from '../assets/images/conference/Conference-bg.PNG'
import moment from 'moment';

const Conference = () => {
    let [isLoading, setIsLoading] = useState(true)
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token === '') {
        localStorage.setItem('page', '/Conference');
        window.location.href = '/login'
    }

    useEffect(() => {
        LineLogin('/Conference')
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/Conference');
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                console.log(err)
                // alert('請重新整理')
            })
    }, []);

    const postAPI = () => {
        let API = `${process.env.REACT_APP_OUTSIDE_API}notify/broadcast/line`;
        let data = {
            message: JSON.parse(unescape(token)).Name,
            lineId: JSON.parse(unescape(token)).LineId
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${process.env.REACT_APP_OUTSIDE_TOKEN}`;
        axios.post(API, data, {
            ContentType: 'application/json'
        })
            .then((res) => {
                setIsLoading(false)
            })
            .catch((err) => {
                LineLogin('/Conference')
                console.log(err)
            })
    }
    useEffect(() => {
        postAPI();
    }, []);

    const dayByDate = () => {
        var todaysdate = moment().format('YYYY-MM-DD');
        var eventdate = moment('2022-07-06');
        return eventdate.diff(todaysdate, 'days');
    }

    let style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className='conference' style={{ backgroundImage: `url(${BackGround})` }}>
                <div style={style}>
                    <p className='text text-center'>2022 業務表揚大會</p>
                    {
                        dayByDate() == 0 ?
                            <p className='text text-center'>展開盛宴</p>
                            :
                            <>
                                <p className='text text-center my-2'>倒數 {dayByDate()} 天</p>
                                <p className='text text-center'>敬請期待</p>
                            </>
                    }
                </div>
            </div>
        </>
    )
}
export default Conference