import React, { useState, useEffect } from "react";
import axios from "axios";

import { dateChange } from '../../../assets/javascript/calTool'; //日期轉換

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

//component
import Loading from '../../../components/Loading'

import LineLogin from '../../../assets/javascript/lineLogin'
import Countfunc from '../../../assets/javascript/countfunc'

const License = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [data, setData] = useState(
        {
            '壽險換證日': '',
            '產險換證日': '',
            'SAL_TRAIN_Reg_RNO': '',
            'SAL_TRAIN_PPT_RNO': ''
        })

    useEffect(() => {
        LineLogin('/license'); //LINE 登入狀態驗證
        Countfunc('個人資料_證照', 22)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}license`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/license');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data.Data[0])
                        setIsLoading(false)
                    } else if (res.data.ResponseCode === '-20') {
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);
    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-2">
                <div className="row">
                    <div className="insurance-box col-md-6">
                        <h6 className="box-header bg-dark-blue border-dark-blue">壽險登錄證到期日</h6>
                        <div className="box-body border-dark-blue">
                            <p className={`text-golden-brown fw-bolder h6 mb-2 ${data.SAL_TRAIN_Reg_RNO == null || data.SAL_TRAIN_Reg_RNO == '' || data.SAL_TRAIN_Reg_RNO == undefined ? 'd-none' : ''}`} style={{ fontSize: '19px' }}>登錄證字號：{data.SAL_TRAIN_Reg_RNO}</p>
                            <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faArrowCircleRight} />
                            <span className="text-golden-brown box-num">{dateChange(data['壽險換證日']) === 'Invalid date' ? '' : dateChange(data['壽險換證日'])}</span>
                            <span className={`text-golden-brown fw-bolder ${data['壽險換證日'] === '' ? '' : 'd-none'}`}>{data['壽險換證日']}</span>
                        </div>
                    </div>
                    <div className="insurance-box col-md-6">
                        <h6 className="box-header bg-dark-blue border-dark-blue">產險登錄證到期日</h6>
                        <div className="box-body border-dark-blue">
                            <p className={`text-golden-brown fw-bolder h6 mb-2 ${data.SAL_TRAIN_PPT_RNO == null || data.SAL_TRAIN_PPT_RNO == '' || data.SAL_TRAIN_PPT_RNO == undefined ? 'd-none' : ''}`} style={{ fontSize: '19px' }}>登錄證字號：{data.SAL_TRAIN_PPT_RNO}</p>
                            <FontAwesomeIcon className="fa-lg text-golden-brown" icon={faArrowCircleRight} />
                            <span className="text-golden-brown box-num">{dateChange(data['產險換證日']) === 'Invalid date' ? '' : dateChange(data['產險換證日'])}</span>
                            <span className={`text-golden-brown fw-bolder ${data['產險換證日'] === '' ? '' : 'd-none'}`}>{data['產險換證日']}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default License