import React from "react";


//未來如果每個目錄背景圖不相同，可以利用 props 傳入
import BackgroundUrl from "../assets/images/pagebg.png";

const BackgroundWrapper = (props) => {
    const BackgroundStyle = {
        backgroundImage: `url(${BackgroundUrl})`
    }
    return (
        <>
            <div className="bg-fixed-bottom min-vh-100" style={BackgroundStyle}>
                {props.children}
            </div>
        </>
    )
}

export default BackgroundWrapper;