import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading';
import { toCurrency } from 'assets/javascript/calTool'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

import ExcelJs from "exceljs";
import moment from 'moment';

const AllRank = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [typeData, setTypeData] = useState([
        { type: '全部', code: 't_data' },
        { type: '游泳', code: 's_data' },
        { type: '跑步', code: 'j_data' },
        { type: '健行', code: 'h_data' },
        { type: '自行車', code: 'b_data' },
        { type: '登山', code: 'c_data' }])
    let [option, setOption] = useState('全部')
    let [allData, setAllData] = useState([]) //全部噴回來的資料 儲存
    let [rankData, setRankData] = useState([]) //篩選過資料
    const handleEvent = {
        getData: function () { //取得所有 data
            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}madman_list_all`
            setIsLoading(true)
            axios.get(API)
                .then((res) => {
                    if (Object.keys(res.data).length > 0) {
                        setIsLoading(false)
                        setAllData(res.data)
                    } else {
                        setIsLoading(false)
                        setAllData([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getRankData: function () {
            if (option === '全部') {
                setRankData(allData.t_data[1])
            } else if (option === '游泳') {
                setRankData(allData.s_data[1])
            } else if (option === '跑步') {
                setRankData(allData.j_data[1])
            } else if (option === '健行') {
                setRankData(allData.h_data[1])
            } else if (option === '自行車') {
                setRankData(allData.b_data[1])
            } else if (option === '登山') {
                setRankData(allData.c_data[1])
            }
        },
        changeEvent: function (e) {
            let { value, nodeName } = e.target
            if (nodeName !== 'BUTTON') {
                return
            }
            setOption(value)
        },
        downloadFile: function (e) {
            e.preventDefault();
            const workbook = new ExcelJs.Workbook();
            downloadData.forEach((sheetData) => {
                const sheet = workbook.addWorksheet(sheetData.sheetName);
                sheet.addTable({
                    name: sheetData.sheetName,
                    ref: `A1`, // 從A1開始
                    headerRow: true,
                    columns: sheetData.thead.map((s) => {
                        return { name: s };
                    }),
                    rows: sheetData.tbody
                });
                if (sheetData.columnWidths) {
                    sheetData.columnWidths.forEach((column) => {
                        sheet.getColumn(column.number).width = column.width;
                    });
                }
            });

            // 表格裡面的資料都填寫完成之後，訂出下載的callback function
            // 異步的等待他處理完之後，創建url與連結，觸發下載
            workbook.xlsx.writeBuffer().then((content) => {
                const link = document.createElement("a");
                const blob = new Blob([content], {
                    type: "application/vnd.ms-excel;charset=utf-8;"
                });
                link.download = `${moment().format('YYYY-MM-DD')}_狂人全排名.xlsx`;
                link.href = URL.createObjectURL(blob);
                link.click();
            });
        }
    }

    let downloadData = typeData.map((item, index) => {
        if(Object.keys(allData).length >0 ){
            return { //單位、姓名、總捐款數、總活動次數、總獎牌數
                sheetName: item.type,
                thead: ["姓名", "單位", "總捐款數", "總獎牌數", "總活動數"],
                tbody: allData[item.code][1].map((kItem, kIndex) => {
                    return [kItem.r_username, kItem.r_buscode, kItem.total_donate, kItem.total_medal, kItem.total_active]
                }),
                columnWidths: [
                    { number: 1, width: 20 },
                    { number: 2, width: 20 },
                    { number: 3, width: 10 },
                    { number: 4, width: 10 },
                    { number: 5, width: 10 }
                ]
            }
        }
    })

    useEffect(() => {
        handleEvent.getData();
    }, [])
    useEffect(() => {
        if (allData.length <= 0) {
            return
        } else {
            handleEvent.getRankData()
        }
    }, [option, allData])

    let activeStyle = {
        borderBottom: '2px solid #fff',
        paddingBottom: '4px'
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="bg-challenges-blue h-100">
                <div className='container p-4 challenge position-relative'>
                    <div>
                        <h3 className='challenge-title text-light text-center'>狂人全排行</h3>
                    </div>
                    {
                        allData.length == 0 ?
                            <p className='text-light text-center py-3'>無資料</p> :
                            <>
                                <div className='challenge-type-button d-flex justify-content-evenly' onClick={handleEvent.changeEvent}>
                                    {
                                        typeData.map((item, index) => {
                                            return (
                                                <button type='button' className='btn text-light fw-bolder rounded-0 p-1' style={option === item.type ? activeStyle : {}} value={item.type}>{item.type}</button>
                                            )
                                        })
                                    }
                                </div>
                                <button className='btn btn-challenges-yellow fw-bolder position-absolute' style={{ right: '12px', top: '24px' }} onClick={handleEvent.downloadFile} value={option}>
                                    <FontAwesomeIcon icon={faFileExcel} className='me-2' />
                                    下載 Excel
                                </button>
                                <div className="table-responsive-xxl text-center mt-3" style={{ height: '75vh', overflow: 'scroll' }}>
                                    <table className="table table-striped table-light align-middle">
                                        <thead>
                                            <tr>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>NO.</th>
                                                <th className="text-center" width='200' style={{ whiteSpace: 'nowrap' }}>姓名</th>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>捐款金額</th>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>獎章</th>
                                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>活動數</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                rankData.map((item, index) => {
                                                    return (
                                                        <tr className={`${item.No == 1 ? 'table-rank1' : ''}${item.No == 2 ? 'table-rank2' : ''}${item.No == 3 ? 'table-rank3' : ''}`}>
                                                            <td className="text-dark-blue fw-bolder fs-6" style={{ whiteSpace: 'nowrap' }}>{item.No}</td>
                                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{item.r_buscode}/{item.r_username}</td>
                                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.total_donate)} 元</td>
                                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.total_medal)}</td>
                                                            <td className="text-dark-blue" style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.total_active)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    )
}
export default AllRank