import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useRouteMatch } from "react-router-dom";
//component
import ShowLogo from './ShowLogo';

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc';
import Loading from '../../components/Loading';

const PaymentinfoSin = (props) => {
    const match = useRouteMatch();
    let showLogoData = match.url.split('/')[2].split('-')
    let [isLoading, setIsLoading] = useState(true)

    let [AllData, setAllData] = useState([])

    //GET API
    useEffect(() => {
        LineLogin(`${match.url}`);//LINE 登入狀態驗證
        Countfunc('窗口', 8)

        let API = `${process.env.REACT_APP_GOLDEN_API5000}`; //壽險"3" 產險"2"
        if (showLogoData[0] === 'life') {
            API = `${API}InsCom/3`
        } else if (showLogoData[0] === 'property') {
            API = `${API}InsCom/2`
        }
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', `${match.url}`);
                        window.location.href = '/login'
                        return
                    } else {
                        setAllData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);
    //GET API END

    //繳費資料處理
    let allFiles = []
    let payData = () => {
        let allContact = [];

        //壽險公司
        AllData.forEach((item, index) => {
            allContact.push(item.name)
        })
        allContact = AllData.map(o => { return { company: o.name, data: o.data } }); //更改 object key 值
        allContact.forEach((item, index) => {
            if (item.company === showLogoData[1]) {
                allFiles = item.data
            }
        })
    }
    //資料處理 END
    payData()

    //整理資料
    let contactData = [];
    const contactDataFun = (data) => {
        data.forEach((item, index) => {
            if (item.length > 0) {
                if (index === 0) {
                    return
                }
                contactData.push({
                    no: item[0] === undefined ? null : item[0],//序號
                    loc: item[1] === undefined ? null : item[1],//區域
                    dept: item[2] === undefined ? null : item[2],//部門
                    name: item[3] === undefined ? null : item[3],//姓名
                    phone: item[4] === undefined ? null : item[4],//行動電話
                    tel: item[5] === undefined ? null : item[5],//公司電話
                    mail: item[6] === undefined ? null : item[6],//mail (產險公司)
                })
            }
        })
    }
    contactDataFun(allFiles)

    let filterLocAry = []; //[地區]過濾重複的值。用於select
    let filterDeptAry = []; //[部門]過濾重複的值。用於select

    let [location, setLocation] = useState('台北'); //地區
    let [dept, setDept] = useState('業推'); //部門

    useEffect(() => {
        if (showLogoData[0] === 'life') {
            setDept('業推')
        } else {
            setDept('窗口')
            if (showLogoData[1] === '法國巴黎產物') {
                setDept('業推')
            }
        }
    }, [])

    const getLocAndDeptAry = (data) => {
        let LocAry = [];
        let DeptAry = [];
        data.forEach((item, index, ary) => {
            LocAry.push(item.loc)
            if (location === item.loc) {
                DeptAry.push(item.dept)
            }
        })
        filterLocAry = LocAry.filter((item, index, ary) => {
            return ary.indexOf(item) === index
        })
        filterDeptAry = DeptAry.filter((item, index, ary) => {
            return ary.indexOf(item) === index
        })
    }

    getLocAndDeptAry(contactData)

    //事件
    const handleLocChange = (e) => {
        let { value } = e.target
        setLocation(value)
        contactData.map((item) => {
            if (value === item.loc) {
                setDept(item.dept)
            }
            return true
        })
    }

    const handleDepChange = (e) => {
        let { value } = e.target
        setDept(value);
    }
    return (
        <>
            <Loading isLoading={isLoading}></Loading>
            <div className="w-100 bg-light position-fixed" style={{ zIndex: '100' }}>
                <ShowLogo PropCompany={showLogoData}></ShowLogo>
                <div className="container px-lg-5">
                    <div className="d-flex pb-2">
                        <select className="form-select" value={location} onChange={handleLocChange}>
                            {
                                filterLocAry.map((item, index) => {
                                    return <option key={item} value={item}>{item}</option>
                                })
                            }
                        </select>
                        <select className="form-select ms-2" value={dept} onChange={handleDepChange}>
                            {
                                filterDeptAry.map((item, index) => {
                                    return <option key={item} value={item}>{item}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="d-table company-header-height"></div>
            <div className="container px-lg-5 mt-md-6 mt-lg-0 mt-3">
                <ul className="list-unstyled page-content-link fw-bolder">
                    {
                        contactData.map((item, index) => {
                            if (location === item.loc && dept === item.dept) {
                                return (
                                    <li className="border-bottom py-3" key={index}>
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div className="col-6">
                                                <FontAwesomeIcon className="me-2 text-dark-blue" icon={faUser} />
                                                <span style={{ whiteSpace: 'break-spaces', lineHeight: '2' }}>{item.name}</span>
                                            </div>
                                            <div className={`col-6 text-end ${item.tel == null ? 'd-none' : 'd-block'}`}>
                                                <a href={`tel:+886-${item.tel}`} className="ms-2" style={{ whiteSpace: 'break-spaces', lineHeight: '2' }}>{item.tel}</a>
                                            </div>
                                            <div className={`${item.tel === null ? 'col-6' : 'col-12'} text-end ${item.phone == null ? 'd-none' : 'd-block'}`}>
                                                <a href={`tel:${item.phone}`} className="ms-2">{item.phone}</a>
                                            </div>
                                            <div className={`${item.mail === null ? 'col-6' : 'col-12'} text-end ${item.mail == null ? 'd-none' : 'd-block'}`}>
                                                <a href='#' className="ms-2" onClick={e => e.preventDefault()}>{item.mail}</a>
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                            return true
                        })
                    }
                </ul>
            </div>
        </>
    );
}
export default PaymentinfoSin;