import React, { useState, useEffect } from "react";
//* 日期選擇器
import DatePicker, { registerLocale } from 'react-datepicker'
import zhTW from 'date-fns/locale/zh-TW';
import moment from "moment";
//components
import BackgroundWrapper from "../../components/BackgroundWrapper";
import Loading from '../../components/Loading'
import Countfunc from '../../assets/javascript/countfunc'

//from
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from "axios";
import LineLogin from "assets/javascript/lineLogin";
import AlertMes from "components/Alert";
import { default as swal } from 'sweetalert2'

const InsuranceSend = () => {
    registerLocale('zhTW', zhTW)

    let [isLoading, setIsLoading] = useState(false)
    let [insDate, setInsDate] = useState(null)
    let [userData, setUserData] = useState(null)
    let [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' })

    // 登入驗證
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token === '') {
        localStorage.setItem('page', window.location.pathname);
        window.location.href = '/login'
    }

    useEffect(() => {
        LineLogin(window.location.pathname)
        Countfunc('電子保單回報', 56)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', window.location.pathname);
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                console.log(err)
                // alert('請重新整理')
            })

        setUserData(JSON?.parse(unescape(token)))
    }, []);

    //表單驗證
    const validationSchema = Yup.object().shape({
        policyNum: Yup.string()
            .required('請正確輸入保單號碼'),
        proName: Yup.string()
            .required('請正確輸入要保人姓名'),
        insName: Yup.string()
            .required('請正確輸入被保人姓名')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        //判斷是否有填日期
        if (insDate == null || insDate == 'Invalid date') {
            setAlertMes({ mes: '尚未填寫保單簽收日', show: true, color: 'danger' })
            return
        }

        data.signDate = moment(insDate).format('yyyy/MM/DD')
        data.salId = userData?.Id

        //判斷是否差過一個月
        if (moment(insDate).diff(moment(), 'days') <= -30) {
            setIsLoading(true)
            setAlertMes({ mes: '提醒：保單簽收日已超過 30 天', show: true, color: 'primary' })
            setTimeout(() => { handleAPI.sendAppDataPlus(data) }, 4000)
            return
        }
        handleAPI.sendAppDataPlus(data)
    }

    const handleAPI = {
        sendAppDataPlus: function (postData) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_CIBagent_API}sales/rest/ePolicyfb/sendAppDataPlus`
            console.log(API)
            console.log(postData)
            axios.post(API, postData)
                .then((res) => {
                    let { respCode } = res.data
                    if (respCode == '00') {
                        setIsLoading(false)
                        setAlertMes({ mes: '送出成功', show: true, color: 'primary' })
                        setInsDate(null)
                        reset();
                    } else if (respCode == '40') {
                        setIsLoading(false)
                        swal.fire({
                            icon: 'info',
                            html: `<div class='fs-5 fw-bolder'>要保人、被保人資料系統比對不同，請再確認</div>`,
                            confirmButtonText: '關閉',
                        }).then(function () {
                            reset();
                            setInsDate(null)
                        })
                    } else if (respCode == '50') {
                        setIsLoading(false)
                        setAlertMes({ mes: '保單相關日期已有資料，請洽行專確認保單結案', show: true, color: 'danger' })
                        swal.fire({
                            icon: 'info',
                            html: `<div class='fs-5 fw-bolder'>保單相關日期已有資料，請洽行專確認保單結案</div>`,
                            confirmButtonText: '關閉',
                        }).then(function () {
                            reset();
                            setInsDate(null)
                        })
                    } else if (respCode == '60') {
                        setIsLoading(false)
                        swal.fire({
                            icon: 'info',
                            html: `<div class='fs-5 fw-bolder'>保單簽收日不可早於生效日</div>`,
                            confirmButtonText: '關閉',
                        }).then(function () {
                            reset();
                            setInsDate(null)
                        })
                    } else if (respCode == '99') {
                        setIsLoading(false)
                        swal.fire({
                            icon: 'info',
                            html: `<div class='fs-5 fw-bolder'>保單號碼異常，請洽行專確認保號後再行回報</div>`,
                            confirmButtonText: '關閉',
                        }).then(function () {
                            reset();
                            setInsDate(null)
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setAlertMes({ mes: '網路連線失敗！請聯繫管理人員(err)', show: true, color: 'danger' })
                    setIsLoading(false)
                    setInsDate(null)
                    reset()
                })
        }
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} setAlertMes={setAlertMes} />
            <BackgroundWrapper>
                <div className="position-fixed w-100" style={{ zIndex: '30' }}>
                    <div className='top-nav w-100 py-3 fw-bolder text-center text-light'>電子保單回報</div>
                    <div className="container">
                        <div className="py-3">
                            <form onSubmit={handleSubmit(onSubmit)} className="col-12 col-md-6 col-lg-4 mx-auto">
                                <div className="form-group my-3">
                                    <label htmlFor="policyNum" className="fw-bolder">保單號碼</label>
                                    <input id="policyNum" name="policyNum" type="text" {...register('policyNum')} className={`form-control ${errors.policyNum ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.policyNum?.message}</div>
                                </div>
                                <div className="form-group my-3">
                                    <label htmlFor="proName" className="fw-bolder">要保人姓名</label>
                                    <input id="proName" name="proName" type="text" {...register('proName')} className={`form-control ${errors.proName ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.proName?.message}</div>
                                </div>
                                <div className="form-group my-3">
                                    <label htmlFor="insName" className="fw-bolder">被保人姓名</label>
                                    <input id="insName" name="insName" type="text" {...register('insName')} className={`form-control ${errors.insName ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.insName?.message}</div>
                                </div>
                                <div className="form-group my-3">
                                    <label htmlFor="signDate" className="fw-bolder">保單簽收日</label>
                                    <div className='calendar-input-container'>
                                        <DatePicker locale='zhTW'
                                            id='signDate'
                                            className={`form-control`}
                                            dateFormat='yyyy/MM/dd'
                                            selected={insDate}
                                            onChange={(date) => (setInsDate(date))}
                                            maxDate={moment().valueOf()}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode='select'
                                            placeholderText='年/月/日'>
                                        </DatePicker>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-dark-blue w-100 fw-bolder mt-3">送出</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </BackgroundWrapper>
        </>
    )
}

export default InsuranceSend