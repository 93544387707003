import React, { useEffect, useState } from 'react';
import imgBg from 'assets/images/vipService/vip_BG.jpg'
import imgTitle from 'assets/images/vipService/vip_Title.png'
//日期選擇器
import DatePicker from 'react-datepicker'
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import zhTW from 'date-fns/locale/zh-TW';
//form
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import axios from 'axios';
import Loading from 'components/Loading';
import LineLogin from 'assets/javascript/lineLogin';
import Countfunc from 'assets/javascript/countfunc';
import AlertMes from 'components/Alert';
import { busCodeToName, busCodeGatArea } from 'assets/javascript/calTool';
const VipService = () => {
    registerLocale('zhTW', zhTW)
    let bgStyle = {
        minHeight: '100vh',
        backgroundImage: `url(${imgBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }

    // 問項選擇
    let optionData = {
        time: {
            list: ['09:00 - 10:00', '10:00 - 11:00', '11:00 - 12:00', '13:00 - 14:00', '14:00 - 15:00'],
            name: 'SAL_RESERVE_TIME'
        },
        type: {
            list: ['到府', '親送業務中心'],
            name: 'SAL_RESERVE_TYPE'
        }
    }

    let [resDate, setResDate] = useState(null),
        [userData, setUserData] = useState(null),
        [isLoading, setIsLoading] = useState(false),
        [alertMes, setAlertMes] = useState({ mes: '', show: false, color: 'transparent' })
    // 登入驗證
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token === '') {
        localStorage.setItem('page', window.location.pathname);
        window.location.href = '/login'
    }

    useEffect(() => {
        LineLogin(window.location.pathname)
        Countfunc('尊榮預約通道', 56)
        setIsLoading(true)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', window.location.pathname);
                        window.location.href = '/login'
                        return
                    } else {
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                console.log(err)
                // alert('請重新整理')
            })

        setUserData(JSON?.parse(unescape(token)))
    }, []);

    //表單驗證
    const validationSchema = Yup.object().shape({
        SAL_RESERVE_TIME: Yup.string().required('必填'),
        // SAL_RESERVE_TYPE: Yup.string().required('必填')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const onSubmit = (data) => {
        if (resDate == null) {
            setAlertMes({ mes: '尚未選擇收/送件日期', show: true, color: 'danger' })
            return
        }
        data.SAL_RESERVE_DATE = moment(resDate).format('yyyy-MM-DD')
        data = {
            ...data,
            SAL_MST_NAME: userData?.Name,
            SAL_MST_LINESN: userData?.LineId,
            SAL_MST_LOCATION: userData?.BusCode,
            SAL_RESERVE_LOCATION: null,
            SAL_RESERVE_DEP: userData?.BusCode
        }

        if (data.SAL_RESERVE_TYPE == '到府') {
            data.SAL_RESERVE_DEP = '到府收送'
        }
        handleAPI.addReserveRec(data)
    }

    //-- PUSH MES DATA
    let pushList = {
        '北區': { name: '呂之羚', lineId: 'Uaeaeee0b7884d4e2c1c2be16b10de09d' },
        '中區': { name: '黃明娟', lineId: 'U7ef4248e95677807a98007fcf042bd46' },
        '南區': { name: '孫秀玲', lineId: 'Uaa8b0ae842f5954c06ab5725b35f1eda' }
    }

    const handleAPI = {
        addReserveRec: function (postData) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_NPS_API}add_reserve_rec`
            let postParams = new URLSearchParams();
            postParams.append('SAL_MST_NAME', postData?.SAL_MST_NAME);
            postParams.append('SAL_MST_YEAR', moment().format('YYYY'));
            postParams.append('SAL_MST_LINESN', postData?.SAL_MST_LINESN)
            postParams.append('SAL_MST_LOCATION', postData?.SAL_MST_LOCATION)
            postParams.append('SAL_RESERVE_LOCATION', postData?.SAL_RESERVE_LOCATION)
            postParams.append('SAL_RESERVE_TYPE', postData?.SAL_RESERVE_TYPE)
            postParams.append('SAL_RESERVE_DATE', postData?.SAL_RESERVE_DATE)
            postParams.append('SAL_RESERVE_TIME', postData?.SAL_RESERVE_TIME)
            postParams.append('SAL_RESERVE_DEP', postData?.SAL_RESERVE_DEP)
            axios.post(API, postParams)
                .then((res) => {
                    let { code } = res.data
                    if (code == '0') {
                        setAlertMes({ mes: '您已預約成功', show: true, color: 'primary' })
                        setResDate(null)
                        reset()
                        handleAPI.messagePush(postData)
                    } else if (code == '-1') {
                        setIsLoading(false)
                        alert(`送出表單錯誤。(add_reserve_rec:${code})`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    alert(`網路連線錯誤，請聯繫管理員。(add_reserve_rec:err)`)
                })
        },
        messagePush: function (data) {
            setIsLoading(true)
            if (busCodeGatArea(data?.SAL_MST_LOCATION) == null) {
                setIsLoading(false)
                return
            } else {
                function getMesText(type) {
                    if (type == '到府') {
                        return `${data?.SAL_MST_NAME}業務，預約 ${data?.SAL_RESERVE_DATE} ${data?.SAL_RESERVE_TIME} 取件，請盡速聯繫。`
                    } else {
                        return `${data?.SAL_MST_NAME}業務，預約 ${data?.SAL_RESERVE_DATE} ${data?.SAL_RESERVE_TIME} 親送 ${busCodeToName(data?.SAL_MST_LOCATION)}業務中心，請盡速聯繫`
                    }
                }

                let postData = {
                    Push_Info: [
                        { //業服部協理
                            Line_Id: 'U1d7fda9d74cabb85910860e7fa9372e0',
                            Sales_Name: '鄭志弘'
                        },
                        { //三區科長
                            Line_Id: pushList[busCodeGatArea(data?.SAL_MST_LOCATION)]?.lineId,
                            Sales_Name: pushList[busCodeGatArea(data?.SAL_MST_LOCATION)]?.name
                        }
                    ],
                    Message_Body: [getMesText(data?.SAL_RESERVE_TYPE)],
                    Message_Image: null,
                    Message_Card: null,
                    Create_User: 'iSmart+ COT/TOT',
                    Audit_User: 'iSmart+ COT/TOT',
                    Mode: 0
                }
                let API = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/Push`;
                axios.post(API, postData)
                    .then((res) => {
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        setIsLoading(false)
                    })
            }
        }
    }

    const handleEvent = {
        isWeekday: function (date) {
            let thisDate = moment(date).day()
            return thisDate !== 0 && thisDate !== 6
        }
    }
    return (
        <>
            <AlertMes mes={alertMes.mes} show={alertMes.show} color={alertMes.color} setAlertMes={setAlertMes} />
            <Loading isLoading={isLoading} />
            <div style={bgStyle}>
                <div className="container py-4">
                    <div className="row">
                        <div className="col-md-6 mx-auto">
                            <div className='text-center'>
                                <img src={imgTitle} alt='尊榮預約通道' className='img-fluid' width='328' />
                                <p className='text-light fw-bolder my-4' style={{ lineHeight: '30px' }}>{userData?.Name} 您好<br />感謝您使用尊榮預約通道服務<br />三區科長、業務服務部<br />為您提供全天候尊榮服務</p>
                            </div>
                            <div className='bg-light px-3 py-4 fw-bolder' style={{ borderRadius: '20px', color: '#332017' }}>
                                <p className='text-danger'>＊注意，預約服務僅限壽險新契約件</p>
                                {/* 問項 */}
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='my-3'>
                                        <p>請選擇欲收 / 送件日期：</p>
                                        <DatePicker locale='zhTW'
                                            className={`form-control mt-2`}
                                            dateFormat='yyyy-MM-dd'
                                            selected={resDate}
                                            minDate={moment().add(1, 'd').valueOf()}
                                            // maxDate={moment().valueOf()}
                                            onChange={(date) => setResDate(date)}
                                            filterDate={handleEvent.isWeekday}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode='select'
                                            placeholderText='年/月/日'>
                                        </DatePicker>
                                    </div>
                                    <div className='my-3'>
                                        <p>請選擇欲收 / 送件時間：</p>
                                        <div className='fw-bolder mt-2'>
                                            {
                                                optionData.time.list.map((item, index) => {
                                                    return (
                                                        <div className='form-check form-check-block me-3' key={`time-${index}`}>
                                                            <input className='form-check-input' type='radio' name={optionData.time.name} id={`${item}_${index}`} value={item} {...register('SAL_RESERVE_TIME')} />
                                                            <label className='form-check-label' htmlFor={`${item}_${index}`}>{item}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className='invalid-feedback d-block'>{errors.SAL_RESERVE_TIME && '必填'}</div>
                                        </div>
                                    </div>
                                    <div className='my-3'>
                                        <p>請選擇收 / 送件方式：</p>
                                        <div className='fw-bolder mt-2'>
                                            {
                                                optionData.type.list.map((item, index) => {
                                                    return (
                                                        <div className='form-check form-check-block me-3' key={`type-${index}`}>
                                                            <input className='form-check-input' type='radio' name={optionData.type.name} id={`${item}_${index}`} value={item} {...register('SAL_RESERVE_TYPE')}
                                                                disabled={item == '到府' && 'disabled'} checked={item == '親送業務中心' && 'checked'} />
                                                            <label className='form-check-label' htmlFor={`${item}_${index}`}>{item}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className='invalid-feedback d-block'>{errors.SAL_RESERVE_TYPE && '必填'}</div>
                                        </div>
                                    </div>
                                    <div className='text-center mt-4'>
                                        <button type='submit' className='btn btn-primary fw-bolder'>送出</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default VipService