import React, { useState, useEffect } from 'react';
import { useRouteMatch, Switch, Route } from "react-router-dom";
import axios from 'axios';
//component
import LogoList from './LogoList';
import Loading from '../../components/Loading';
import PaymentinfoSin from './PaymentinfoSin';

import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc'
import data from '../../json/insurer/Insurer.json'

const Paymentinfo = (props) => {
    let match = useRouteMatch();
    let [isLoading, setIsLoading] = useState(false)

    //GET API
    useEffect(() => {
        LineLogin('/contacts');//LINE 登入狀態驗證
        Countfunc('窗口', 8)
    }, []);
    //GET API END
    let propData = data.data[0].Contact

    return (
        <>
            <Loading isLoading={isLoading} />
            <LogoList data={propData} />
            <Switch>
                <Route path={`${match.path}/:id`}><PaymentinfoSin /></Route>
            </Switch>
        </>
    );
}
export default Paymentinfo;