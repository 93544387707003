import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { default as swal } from 'sweetalert2'
import Loading from '../../components/Loading';
//日期選擇器
import DatePicker from 'react-datepicker'
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import zhTW from 'date-fns/locale/zh-TW';
import moment from 'moment';
const ChallengesofGoldenForm = () => {
    registerLocale('zhTW', zhTW)
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token === '') {
        localStorage.setItem('page', '/Challenges_of_Golden');
        window.location.href = '/login'
    }

    //LINE login 驗證
    let [isLoading, setIsLoading] = useState(false)
    let [premiumStatus, setPremiumStatus] = useState('0')// 1:是 ; 0:否
    let [date, setDate] = useState('')
    let [data, setData] = useState({
        r_username: JSON.parse(unescape(token)).Name,
        r_premium_status: '',
        r_type: '',
        r_records: '',
        r_lineid: JSON.parse(unescape(token)).LineId,
        r_date: '',
        r_challenges_name: '',
        r_buscode: JSON.parse(unescape(token)).BusCode
    })
    //特殊活動
    let [isDateToEvent, setIsDateToEvent] = useState(false); //是否有符合特殊活動加碼期間
    let [isJoinEvent, setIsJoinEvent] = useState(false) //是否有參加特殊活動
    let [eventData, setEventData] = useState([]),
        [chooseEventId, setChooseEventId] = useState(null),
        [eventDonate, setEventDonate] = useState(0)

    const handleEvent = {
        changeMilage: function (e) {
            let { value } = e.target
            setData({ ...data, r_records: value })
        },
        maxRadio: function (e) { //特級認定
            let { value } = e.target
            if (value == '1') {
                swal.fire({
                    icon: 'info',
                    title: '提醒',
                    text: '"特級加碼"不能刪除，請謹慎操作!!!',
                    confirmButtonText: '我了解了',
                })
            }
            setPremiumStatus(e.target.value)
        },
        isJoinEvent: function (e, type) { //是否有參加特殊活動
            let { value } = e.target
            if (type == 'isCheck') {
                setIsJoinEvent(true)
                setPremiumStatus('0')
                setChooseEventId(Number(value.split(',')[0]))
                setEventDonate(Number(value.split(',')[1]))
            } else {
                setIsJoinEvent(false)
                setChooseEventId(null)
                setEventDonate(0)
            }
        },
        clickSend: function (e) {
            if (data.r_type === '' || data.r_type == '0') {
                alert('請選擇【登記之挑戰項目】!!')
                return
            } else if (data.r_date === '') {
                alert('請輸入【活動日期】!!')
                return
            } else if (premiumStatus == '1' && data.r_challenges_name === '') {
                alert('請輸入【完成活動名稱】!!')
                return
            } else if (data.r_records === '') {
                alert('請輸入【里程數】!!')
                return
            }
            if (Number(data.r_records) % 1 !== 0) {
                alert('里程數只能以【整數】輸入!!')
                setData({ ...data, r_records: '' })
                return
            }
            if (data.r_username === '' || data.r_lineid === '' || data.r_buscode === '' ||
                data.r_username === undefined || data.r_lineid === undefined || data.r_buscode === undefined ||
                data.r_username === null || data.r_lineid === null || data.r_buscode === null) {
                localStorage.setItem('page', '/Challenges_of_Golden');
                window.location.href = '/login'
                return
            }

            let params = new URLSearchParams();
            if (isJoinEvent) {
                params.append('s_id', chooseEventId);
            }
            params.append('r_username', data.r_username);
            params.append('r_type', data.r_type);
            params.append('r_records', data.r_records);
            params.append('r_lineid', data.r_lineid);
            params.append('r_date', data.r_date);
            params.append('r_challenges_name', data.r_challenges_name);
            params.append('r_buscode', data.r_buscode);
            params.append('r_premium_status', premiumStatus);

            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}provide_records`
            setIsLoading(true)
            axios.post(API, params)
                .then((res) => {
                    if (res.data.code == '0') {
                        setIsLoading(false)
                        //清空
                        setData({
                            r_username: JSON.parse(unescape(token)).Name,
                            r_type: '',
                            r_records: '',
                            r_lineid: JSON.parse(unescape(token)).LineId,
                            r_date: '',
                            r_challenges_name: '',
                            r_buscode: JSON.parse(unescape(token)).BusCode,
                            r_premium_status: '',
                        })
                        setDate('')
                        if (isJoinEvent) {
                            swal.fire({
                                icon: 'success',
                                text: `感謝您的愛心💗本次公益提撥 ${Number(res.data.data) + Number(eventDonate)} 元`,
                                confirmButtonText: '好的',
                            }).then(function () {
                                window.location.href = '/Challenges_of_Golden'
                            })
                        } else {
                            swal.fire({
                                icon: 'success',
                                text: `感謝您的愛心💗本次公益提撥 ${Number(res.data.data)} 元`,
                                confirmButtonText: '好的',
                            }).then(function () {
                                window.location.href = '/Challenges_of_Golden'
                            })
                        }
                    } else if (res.data.code == '-1') {
                        setData({
                            r_username: JSON.parse(unescape(token)).Name,
                            r_type: '',
                            r_records: '',
                            r_lineid: JSON.parse(unescape(token)).LineId,
                            r_date: '',
                            r_challenges_name: '',
                            r_buscode: JSON.parse(unescape(token)).BusCode,
                            r_premium_status: '',
                        })
                        if (res.data.msg == 'duplicate_data') {
                            setDate('')
                            setIsLoading(false)
                            swal.fire({
                                icon: 'info',
                                text: `您已登記過特殊活動，特殊活動登記僅限一次`,
                                confirmButtonText: '好的',
                            }).then(function () {
                                window.location.href = '/Challenges_of_Golden_Form'
                            })
                        } else {
                            setDate('')
                            setIsLoading(false)
                            swal.fire({
                                icon: 'error',
                                text: `儲存登記錯誤!!`,
                                confirmButtonText: '好的',
                            }).then(function () {
                                window.location.href = '/Challenges_of_Golden_Form'
                            })
                        }
                    } else {
                        //清空
                        setData({
                            r_username: JSON.parse(unescape(token)).Name,
                            r_type: '',
                            r_records: '',
                            r_lineid: JSON.parse(unescape(token)).LineId,
                            r_date: '',
                            r_challenges_name: '',
                            r_buscode: JSON.parse(unescape(token)).BusCode,
                            r_premium_status: '',
                        })
                        setDate('')
                        setIsLoading(false)
                        swal.fire({
                            icon: 'error',
                            text: `儲存登記錯誤!!`,
                            confirmButtonText: '好的',
                        }).then(function () {
                            window.location.href = '/Challenges_of_Golden'
                        })
                    }
                })
                .catch((err) => {
                    //清空
                    setData({
                        r_username: JSON.parse(unescape(token)).Name,
                        r_type: '',
                        r_records: '',
                        r_lineid: JSON.parse(unescape(token)).LineId,
                        r_date: '',
                        r_challenges_name: '',
                        r_buscode: JSON.parse(unescape(token)).BusCode,
                        r_premium_status: '',
                    })
                    setDate('')
                    console.log(err)
                })
        },
        returnHomePage: function (e) {
            e.preventDefault();
            window.location.href = '/Challenges_of_Golden'
        },
        changeDate: function (val) { //選擇活動日期 - 判斷是否有在加碼活動期間
            setData({ ...data, r_date: val })
            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}check_event_date/${val}`
            setIsLoading(true)
            axios.get(API)
                .then((res) => {
                    if (res.data.code == '0') {
                        setIsLoading(false)
                        if (res.data.data == '0') {
                            setIsDateToEvent(false)
                            setEventData([])
                        } else {
                            setIsDateToEvent(true)
                            setEventData(res.data.data)
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setEventData([])
                })
        }
    }

    useEffect(() => { }, [premiumStatus, date, chooseEventId, eventDonate])

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className='container'>
                <div className="row flex-column align-items-center justify-content-center mx-1">
                    <div className="col-12 col-md-8 col-lg-6 py-2 rounded" style={{ background: '#fff' }}>
                        <div className="my-3">
                            <label htmlFor="item" className="fw-bolder">請選擇登記之挑戰項目<span className='text-danger'>*</span></label>
                            <select className="form-select" id="item" name="item" onChange={e => (setData({ ...data, r_type: e.target.value }), setIsJoinEvent(false), setChooseEventId(null), setEventDonate(0))}>
                                <option value="0" disabled selected>五大挑戰項目-累計里程</option>
                                <option value="1">健行 - 1元/K</option>
                                <option value="2">跑步 - 1元/K</option>
                                <option value="3">自行車 - 0.5元/K</option>
                                <option value="4">登山 - 3元/爬升100M</option>
                                <option value="5">游泳 - 2元/100M</option>
                            </select>
                        </div>
                        <div className="my-3">
                            <label htmlFor="date" className="fw-bolder">活動日期<span className='text-danger'>*</span></label>
                            <DatePicker locale='zhTW'
                                // disabled={calFormDis ? 'disabled' : ''}
                                className={`form-control`}
                                dateFormat='yyyy-MM-dd'
                                selected={date}
                                onChange={(date) => (setDate(date), setIsJoinEvent(false), setChooseEventId(null), setEventDonate(0), handleEvent.changeDate(moment(date).format('YYYY-MM-DD')))}
                                minDate={moment('2024-01-01').valueOf()}
                                maxDate={moment().valueOf()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                                placeholderText='年/月/日'>
                            </DatePicker>
                        </div>
                        {
                            isDateToEvent &&
                            <div className="my-3">
                                <label htmlFor="done" className="fw-bolder">特殊活動</label>
                                <div>
                                    {
                                        eventData.filter((val) => {
                                            if (moment(date).valueOf() >= moment('2023-06-08').valueOf() && moment(date).valueOf() <= moment('2023-06-30').valueOf()) {
                                                if (data.r_type == '1' && val.s_id == 47) {
                                                    return val
                                                } else if (data.r_type == '2' && val.s_id == 46) {
                                                    return val
                                                } else if (data.r_type == '3' && val.s_id == 50) {
                                                    return val
                                                } else if (data.r_type == '4' && val.s_id == 49) {
                                                    return val
                                                } else if (data.r_type == '5' && val.s_id == 45) {
                                                    return val
                                                }
                                            } else {
                                                return val
                                            }
                                        }).map((item, index) => {
                                            return (
                                                <div className="form-check" onChange={e => handleEvent.isJoinEvent(e, 'isCheck')}>
                                                    <input className="form-check-input" type="radio" name="event" value={`${item.s_id},${item.s_event_donate}`} id={`joinEvent${index}`} />
                                                    <label className="form-check-label" for={`joinEvent${index}`}>
                                                        {item.s_event_name}
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="form-check" onChange={e => handleEvent.isJoinEvent(e, 'notCheck')}>
                                        <input className="form-check-input" type="radio" name="event" id='joinEvent_notCheck' />
                                        <label className="form-check-label" for={`joinEvent_notCheck`}>不參加特殊活動</label>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="my-3">
                            <label htmlFor="done" className="fw-bolder">達成特級標準</label>
                            <div className="d-flex" onChange={handleEvent.maxRadio}>
                                {
                                    !isJoinEvent &&
                                    <div className="form-check me-4">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value='1' checked={premiumStatus == '1' ? 'checked' : ''} />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            是
                                        </label>
                                    </div>
                                }
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value='0' checked={premiumStatus == '0' ? 'checked' : ''} />
                                    <label className="form-check-label" for="flexRadioDefault2">
                                        否
                                    </label>
                                </div>
                            </div>
                        </div>
                        {
                            premiumStatus == '1' &&
                            <div className="my-3">
                                <label htmlFor="done" className="fw-bolder">完成活動名稱</label>
                                <input id="done" name="done" type="text" className={`form-control`}
                                    value={data.doneName}
                                    onChange={e => setData({ ...data, r_challenges_name: e.target.value })} />
                            </div>
                        }
                        <div className="my-3">
                            <label htmlFor="milage" className="fw-bolder">請輸入里程數
                                <span className='text-danger'>*</span>
                            </label>
                            <div className='input-group'>
                                <input id="milage" name="milage" type="tel" className={`form-control`}
                                    onChange={handleEvent.changeMilage}
                                    value={data.r_records} />
                                <span className="input-group-text bg-challenges-blue text-light">
                                    {data.r_type === '4' ? 'M（實際海拔爬升）' : ''}
                                    {data.r_type === '5' ? 'M' : ''}
                                    {data.r_type === '1' || data.r_type === '2' || data.r_type === '3' ? 'K（里程數）' : ''}
                                </span>
                            </div>
                        </div>
                        <div className='text-center py-3'>
                            <button type='button' className='btn btn-challenges-yellow fw-bolder w-100'
                                onClick={handleEvent.clickSend}>登記</button>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 p-0">
                        <div className='text-end'>
                            <button className='btn btn-outline-light my-3 return-icon' onClick={handleEvent.returnHomePage}>返回首頁</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChallengesofGoldenForm