import React from 'react';
const AppPrivacy = () => {
    return (
        <>
            <div className="container py-3">
                <h1 className="fw-bolder text-center fs-2 mb-4">公勝® mAgent+ APP PRIVACY POLICY</h1>
                <p className='small fw-bolder py-1'>LAST UPDATED: 02/07/2022</p>
                <h2 className='fs-3 mb-5'>Privacy Policy For 公勝® mAgent+ APP</h2>

                <p>Welcome to the 公勝mAgent+ ! This privacy policy discloses the privacy practices for the 公勝® mAgent+ app ("APPS"),  all developed by 公勝保險經紀人股份有限公司 ("We", "us", "our"). This privacy policy applies solely to information collected by the APPS. By downloading and using the APPS, YOU are accepting the practices described in this Privacy Policy. This Privacy Policy is meant to help YOU understand what personal identifiable information or data we, or our subsidiaries or affiliates, may collect from YOU through the APPS and SITE, why we, or our subsidiaries or affiliates, collect it, and what we, or our subsidiaries or affiliates, do with it.</p>

                <div className="my-5">
                    <h3 className="my-3">1. Information Collection, Use, and Sharing</h3>
                    <p>We, or our subsidiaries or affiliates, are the sole owners of the information collected in the APPS. These third parties may pass certain information to us if YOU have permitted the third parties to share with us , including any information YOU have made public in connection with that service. We may also have access to YOUR nonpersonally identifiable information, such as geographic location, language, types of device YOU use, screen size of YOUR device, and the date and time YOU use our APPS. We will use YOUR information to respond to YOUR requests, and to improve our APPS. We will not share YOUR information with any third party outside of our company, other than as necessary to fulfill YOUR request. We will not sell or rent this information to anyone.</p>

                    <p>Unless YOU ask us not to, we may contact you via email in the future to tell YOU about specials, new products or services, or changes to this privacy policy.</p>
                </div>

                <div className="my-5">
                    <h3 className="my-3">2. YOUR Access to and Control Over Information</h3>
                    <p>YOU may opt out of any future contacts from us at any time. YOU can do the following at any time by contacting us via email address or phone:</p>
                    <ul>
                        <li>See what data we have about YOU, if any.</li>
                        <li>Change/correct/delete any data we have about YOU.</li>
                        <li>Express any concern YOU have about our use of YOUR data.</li>
                    </ul>
                </div>

                <div className="my-5">
                    <h3 className="my-3">3. Security and Data Transfer</h3>
                    <p>We recognize the importance of maintaining the security of our customers' personal information. We take precautions and reasonable efforts to protect YOUR information. No security system is impenetrable, however. We cannot guarantee the security of our databases, nor can we guarantee that information YOU supply won't be intercepted while being transmitted to us over the Internet. In order to provide better services and to improve the APPS, personal identifiable information may be transferred to, processed, stored and accessed by us, or our affiliates in the Taiwan and in other jurisdictions where we or they operate. By using the APPS, YOU understand and agree to this transfer, processing, storage and access of YOUR personal identifiable information in and/or outside of the jurisdiction in which you reside.</p>
                </div>

                <div className="my-5">
                    <h3 className="my-3">4. Information Protection Measures</h3>
                    <p>We attach great importance to the security of your personal data and do our best to protect it. At the same time, please keep your personal information or passwords safe, and remind you not to provide any personal information (especially passwords) to any person or other organization. After using the various service functions provided by this website, please be sure to log out.</p>
                    <p>In order to avoid affecting your user experience, we will not mask your account (including your name) when you log in with 公勝® mAgent+ application (APP). Please avoid taking screenshots or Video recording, or use in public to prevent others from reading or peeping at your personal information.</p>
                </div>

                <div className="my-5">
                    <h3 className="my-3">5. Children</h3>
                    <p>Our service is not directed towards anyone under the age of 18.</p>
                </div>

                <div className="my-5">
                    <h3 className="my-3">6. Updates</h3>
                    <p>Our Privacy Policy may change at any time for any reason, without prior notifications. We encourage YOU to periodically review it to ensure familiarity with the most current version of this Privacy Policy. YOU can determine when this Privacy Policy was last revised by referring to the "LAST UPDATED" legend at the top of this policy.</p>
                </div>

                <div className="my-5">
                    <h3 className="my-3">7. Contact Us</h3>
                    <p>If YOU feel that we are not abiding by this Privacy Policy, YOU should contact us immediately via phone at 0800-077-090 , via email at golden_cloud@mail.goldennet.com.tw , or write to 813 台灣高雄市左營區大順一路93 號 4 樓之 4.</p>
                </div>
            </div>
        </>
    )
}
export default AppPrivacy