import React, { useState, useEffect } from "react";
import { Switch, Route, Link, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import axios from "axios";

import Loading from '../../components/Loading';
import ProductFooter from '../../components/ProductFooter';

import Assessment from "./Assessment";

import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc'

const LongTermCare = () => {
    let match = useRouteMatch();
    const history = useHistory();
    const { state } = useLocation();
    let [isLoading, setIsLoading] = useState(true)

    // GET API
    let [getData, setGetData] = useState([]);
    useEffect(() => {
        LineLogin('/products/main2'); //LINE 登入狀態驗證
        Countfunc('主力商品_分紅保單', 14)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}Gcd/MainList`;
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/products/main2');
                        window.location.href = '/login'
                        return
                    } else {
                        setGetData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })

    }, [])
    // GET API END

    //篩選 main_type 為 2
    let [footerTitle, setFooterTitle] = useState('');
    let filterData = [];
    const getFooterTitle = (data) => {
        data.forEach((item, index) => {
            if (item.main_type === '2') {
                filterData.push(item)
            }
        })
    }
    getFooterTitle(getData)

    let [proName] = useState((state && state.proName) || null)
    let [main] = useState((state && state.main) || '2')

    useEffect(() => {
        let ary = []
        getData.map((item, index) => {
            if (item.main_type == '2') {
                ary.push(item)
            }
        })
        if (ary.length !== 0) {
            setFooterTitle(ary[0].main_name)
        }
    }, [getData])
    useEffect(() => { }, [footerTitle])
    //
    const handleOptionClick = (e) => {
        e.preventDefault();
        let { innerText, nodeName, dataset } = e.target;
        if (nodeName === 'BUTTON' || nodeName === 'SPAN') {
            setFooterTitle(innerText);
        }
        if (nodeName === 'A') {
            proName = dataset.proname
            history.push({
                state: { proName, main }
            })
        }
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="container mt-4">
                <ul className="list-unstyled row px-2 bg-mask">
                    {
                        filterData.sort((a, b) => {
                            return a.main_name.localeCompare(b.main_name, 'zh-CN-u-co-pinyin');
                        }).map((item, index) => {
                            return (
                                <li key={item.main_name} className={`px-0 ${footerTitle === item.main_name ? 'bg-yellow-light' : ''}`}>
                                    <button className="btn d-flex justify-content-between align-items-center text-dark-blue py-1 px-2 col-12 fw-bolder text-start" onClick={handleOptionClick}>
                                        <span className="d-inline-block pe-3">{item.main_name}</span>
                                        <Link to={`${match.url}/${item.main_name}`} className="text-dark-blue cal-icon" data-proname={item.main_name}>
                                        </Link>
                                    </button>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <ProductFooter title={footerTitle} />
            <Switch>
                <Route path={`${match.path}/:id`}><Assessment /></Route>
            </Switch>
        </>
    )
}

export default LongTermCare