import React, { useState, useEffect } from 'react';
import axios from 'axios';

//components
import BackgroundWrapper from '../../components/BackgroundWrapper';
import Loading from '../../components/Loading';

import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc'
import DownloadFile from '../../assets/javascript/downloadFile';

const Summary = (props) => {
    let [isLoading, setIsLoading] = useState(true)
    //GET API
    let [allData, setAllData] = useState([])
    useEffect(() => {
        LineLogin('/summary'); //LINE 登入狀態驗證
        Countfunc('作業總表', 9)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=2`;
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                if (res.data.ResponseCode === '-1') {
                    localStorage.setItem('page', '/summary');
                    window.location.href = '/login'
                    return
                } else {
                    setAllData(res.data);
                    setIsLoading(false)
                }
            }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }, []);
    //GET API END

    //資料處理
    let filterData = []; //最終資料處理完成 DATA
    const splitData = (data) => {
        let splitAry = [];
        data.forEach((item, index) => {
            splitAry.push(item.split('/'))
        });
        splitAry.forEach((item, index) => {
            if (item[1] === '彙整總表') {
                filterData.push({
                    title: item[1],
                    fileName: item[2],
                    downloadFile: item.join('/')
                })
            }
        })
    }
    splitData(allData)
    //資料處理 END

    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/summary' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setIsLoading)
    }
    return (
        <>
            <Loading isLoading={isLoading} />
            <BackgroundWrapper>
                <div className="top-nav w-100 py-3 position-fixed">
                    <p className="text-light text-center fw-bolder">作業總表</p>
                </div>
                <div className="d-table" style={{ height: '56px' }}></div>
                <div className="container mt-3">
                    <ul className="list-unstyled page-content-link fw-bolder">
                        {
                            filterData.map((item, index) => {
                                return (
                                    <li className="border-bottom py-3" key={index}>
                                        <a href="#" data-download={item.downloadFile} data-filename={item.fileName} className="d-block fw-bolder file-icon" onClick={handleDownloadFile}>
                                            {item.fileName}
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </BackgroundWrapper>
        </>
    );
}
export default Summary;