import axios from "axios";
import React, { useState, useEffect } from "react";
import { FV, PV, PMT, RATE } from 'formulajs'
//components
import BackgroundWrapper from "../../components/BackgroundWrapper";
import Loading from '../../components/Loading'
import InsideNav from '../../components/InsideNav';

import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc'
import { toCurrency } from '../../assets/javascript/calTool'
const FinancialCalculator = () => {
    useEffect(() => {
        LineLogin('/financial_calculator')
        Countfunc('財務計算機', 50)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/financial_calculator');
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                // alert('請重新整理')
            })
    }, []);
    const insideNavItem = [{ text: '終值計算' }, { text: '現值計算' }, { text: `年金計算` }, { text: '利率計算' }] //inside 切換選單
    let [isLoading, setIsLoading] = useState(false)
    let [option, setOption] = useState(insideNavItem[0].text);

    const handleTypeClick = (e) => {
        if (e.target.nodeName !== 'A') {
            return
        }
        setOption(e.target.innerText)
        handleClickRes()
    }

    //---終值計算 FV
    let [FVcalValue, setFVcalValue] = useState({ n: '', r: '', pv: '', pmt: '', type: '0', total: '' })
    //---現值計算 PV
    let [PVcalValue, setPVcalValue] = useState({ n: '', r: '', fv: '', pmt: '', type: '0', total: '' })
    //---PMT 計算
    let [PMTcalValue, setPMTcalValue] = useState({ n: '', r: '', fv: '', pv: '', type: '0', total: '' })
    //---利率計算
    let [RATEcalValue, setRATEcalValue] = useState({ n: '', pmt: '', fv: '', pv: '', type: '0', total: '' })
    //
    let [show, setShow] = useState(false)
    const handleChangeStartAndEndRadio = (e) => { //切換分頁
        let { value, nodeName } = e.target
        if (nodeName !== 'INPUT') {
            return
        }
        if (option === '終值計算') {
            setFVcalValue({ ...FVcalValue, type: value })
        } else if (option === '現值計算') {
            setPVcalValue({ ...PVcalValue, type: value })
        } else if (option === '年金計算') {
            setPMTcalValue({ ...PMTcalValue, type: value })
        } else if (option === '利率計算') {
            setRATEcalValue({ ...RATEcalValue, type: value })
        }
    }

    const handleClickCal = (e) => { //onClick 計算
        let { value } = e.target
        if (value === '終值計算') {
            if (FVcalValue.n === '' && FVcalValue.r === '' && FVcalValue.pv === '' && FVcalValue.pmt === '') {
                alert('請先填入數值!!')
                return
            }
            setFVcalValue({ ...FVcalValue, total: Math.round(FV(Number(FVcalValue.r / 100), Number(FVcalValue.n), Number(FVcalValue.pmt), Number(FVcalValue.pv), Number(FVcalValue.type))) })
            setShow(true)
        } else if (value === '現值計算') {
            if (PVcalValue.n === '' && PVcalValue.r === '' && PVcalValue.fv === '' && PVcalValue.pmt === '') {
                alert('請先填入數值!!')
                return
            }
            setPVcalValue({ ...PVcalValue, total: Math.round(PV(Number(PVcalValue.r / 100), Number(PVcalValue.n), Number(PVcalValue.pmt), Number(PVcalValue.fv), Number(PVcalValue.type))) })
            setShow(true)
        } else if (value === '年金計算') {
            if (PMTcalValue.n === '' && PMTcalValue.r === '' && PMTcalValue.pv === '' && PMTcalValue.fv === '') {
                alert('請先填入數值!!')
                return
            }
            setPMTcalValue({ ...PMTcalValue, total: Math.round(PMT(Number(PMTcalValue.r / 100), Number(PMTcalValue.n), Number(PMTcalValue.pv), Number(PMTcalValue.fv), Number(PMTcalValue.type))) })
            setShow(true)
        } else if (value === '利率計算') {
            if (RATEcalValue.n === '' && RATEcalValue.pmt === '' && RATEcalValue.pv === '' && RATEcalValue.fv === '') {
                alert('請先填入數值!!')
                return
            }
            setRATEcalValue({ ...RATEcalValue, total: (RATE(Number(RATEcalValue.n), Number(RATEcalValue.pmt), Number(RATEcalValue.pv), Number(RATEcalValue.fv), Number(RATEcalValue.type)) * 100).toFixed(2) })
            setShow(true)
        }
    }
    const handleClickRes = () => { //reset 所有值
        setShow(false)
        setFVcalValue({ n: '', r: '', pv: '', pmt: '', type: '0', total: '' })
        setPVcalValue({ n: '', r: '', fv: '', pmt: '', type: '0', total: '' })
        setPMTcalValue({ n: '', r: '', fv: '', pv: '', type: '0', total: '' })
        setRATEcalValue({ n: '', pmt: '', fv: '', pv: '', type: '0', total: '' })
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <BackgroundWrapper>
                <div className="position-fixed w-100 bg-light" style={{ zIndex: '30' }}>
                    <div className={`top-nav w-100 py-3`}>
                        <button className="btn w-100 p-0">
                            <p className="text-light text-center fw-bolder">財務計算機</p>
                            <span className={` position-absolute`} style={{ right: '23px', top: '16px' }}></span>
                        </button>
                    </div>
                </div>
                <div className="d-table" style={{ height: '60px' }}></div>
                <InsideNav option={option} insideNavItem={insideNavItem} handleClick={handleTypeClick} ulClassName="align-items-center" linkClassName="px-0"></InsideNav>
                <div className="container cal">
                    { //終值計算 FV
                        option === `終值計算` ?
                            <div className="row justify-content-center">
                                <div className="mt-4 col-12 col-md-8 col-lg-6">
                                    <div className="input-group mb-3">
                                        <label htmlFor="FVn" className="w-100">期數〔n〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="tel" id="FVn" className="form-control"
                                            value={FVcalValue.n}
                                            onChange={e => setFVcalValue({ ...FVcalValue, n: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">期</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="FVr" className="w-100">利率〔r〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="FVr" className="form-control"
                                            value={FVcalValue.r}
                                            onChange={e => setFVcalValue({ ...FVcalValue, r: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">%</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="FVpv" className="w-100">現值〔PV〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="FVpv" className="form-control"
                                            value={FVcalValue.pv}
                                            onChange={e => setFVcalValue({ ...FVcalValue, pv: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">元</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="FVpmt" className="w-100">付款〔PMT〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="FVpmt" className="form-control"
                                            value={FVcalValue.pmt}
                                            onChange={e => setFVcalValue({ ...FVcalValue, pmt: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">元</span>
                                    </div>
                                    <div className="d-flex mb-3" onChange={handleChangeStartAndEndRadio}>
                                        <div className="form-check mx-2">
                                            <input className="form-check-input" type="radio" name="PVradio" id="start" value="1"
                                            />
                                            <label className="form-check-label" htmlFor="start">期初</label>
                                        </div>
                                        <div className="form-check mx-2">
                                            <input className="form-check-input" type="radio" name="PVradio" id="end" value="0" defaultChecked
                                            />
                                            <label className="form-check-label" htmlFor="end">期末</label>
                                        </div>
                                    </div>
                                    <button className="btn btn-dark-blue w-100" onClick={handleClickCal} value='終值計算'>終值計算為多少〔FV〕?</button>
                                    <div className="mt-2 text-end">
                                        <button className="btn btn-outline-golden-brown" onClick={handleClickRes}>重新計算</button>
                                    </div>
                                </div>
                                {
                                    show ?
                                        <div className="mt-3 d-md-flex justify-content-center">
                                            <div className="text-center my-4">
                                                <p>終值計算：</p>
                                                <p className="text-dark-blue fw-bolder fs-4">{toCurrency(FVcalValue.total)} 元</p>
                                            </div>
                                        </div>
                                        : ''
                                }
                            </div>
                            : ''
                    }
                    { //現值計算
                        option === `現值計算` ?
                            <div className="row justify-content-center">
                                <div className="mt-4 col-12 col-md-8 col-lg-6">
                                    <div className="input-group mb-3">
                                        <label htmlFor="FVn" className="w-100">期數〔n〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="tel" id="FVn" className="form-control"
                                            value={PVcalValue.n}
                                            onChange={e => setPVcalValue({ ...PVcalValue, n: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">期</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="FVr" className="w-100">利率〔r〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="FVr" className="form-control"
                                            value={PVcalValue.r}
                                            onChange={e => setPVcalValue({ ...PVcalValue, r: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">%</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="PVpmt" className="w-100">付款〔PMT〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="PVpmt" className="form-control"
                                            value={PVcalValue.pmt}
                                            onChange={e => setPVcalValue({ ...PVcalValue, pmt: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">元</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="PVpv" className="w-100">終值〔FV〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="PVpv" className="form-control"
                                            value={PVcalValue.fv}
                                            onChange={e => setPVcalValue({ ...PVcalValue, fv: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">元</span>
                                    </div>
                                    <div className="d-flex mb-3" onChange={handleChangeStartAndEndRadio}>
                                        <div className="form-check mx-2">
                                            <input className="form-check-input" type="radio" name="PVradio" id="start" value="1"
                                            />
                                            <label className="form-check-label" htmlFor="start">期初</label>
                                        </div>
                                        <div className="form-check mx-2">
                                            <input className="form-check-input" type="radio" name="PVradio" id="end" value="0" defaultChecked
                                            />
                                            <label className="form-check-label" htmlFor="end">期末</label>
                                        </div>
                                    </div>
                                    <button className="btn btn-dark-blue w-100" onClick={handleClickCal} value='現值計算'>現值計算為多少〔PV〕?</button>
                                    <div className="mt-2 text-end">
                                        <button className="btn btn-outline-golden-brown" onClick={handleClickRes}>重新計算</button>
                                    </div>
                                </div>
                                {
                                    show ?
                                        <div className="mt-3 d-md-flex justify-content-center">
                                            <div className="text-center my-4">
                                                <p>現值計算：</p>
                                                <p className="text-dark-blue fw-bolder fs-4">{toCurrency(PVcalValue.total)} 元</p>
                                            </div>
                                        </div>
                                        : ''
                                }
                            </div>
                            : ''
                    }
                    { //PMT
                        option === '年金計算' ?
                            <div className="row justify-content-center">
                                <div className="mt-4 col-12 col-md-8 col-lg-6">
                                    <div className="input-group mb-3">
                                        <label htmlFor="FVn" className="w-100">期數〔n〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="tel" id="FVn" className="form-control"
                                            value={PMTcalValue.n}
                                            onChange={e => setPMTcalValue({ ...PMTcalValue, n: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">期</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="FVr" className="w-100">利率〔r〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="FVr" className="form-control"
                                            value={PMTcalValue.r}
                                            onChange={e => setPMTcalValue({ ...PMTcalValue, r: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">%</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="PVpv" className="w-100">現值〔PV〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="PVpv" className="form-control"
                                            value={PMTcalValue.pv}
                                            onChange={e => setPMTcalValue({ ...PMTcalValue, pv: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">元</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="PVfv" className="w-100">終值〔FV〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="PVfv" className="form-control"
                                            value={PMTcalValue.fv}
                                            onChange={e => setPMTcalValue({ ...PMTcalValue, fv: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">元</span>
                                    </div>

                                    <div className="d-flex mb-3" onChange={handleChangeStartAndEndRadio}>
                                        <div className="form-check mx-2">
                                            <input className="form-check-input" type="radio" name="PVradio" id="start" value="1"
                                            />
                                            <label className="form-check-label" htmlFor="start">期初</label>
                                        </div>
                                        <div className="form-check mx-2">
                                            <input className="form-check-input" type="radio" name="PVradio" id="end" value="0" defaultChecked
                                            />
                                            <label className="form-check-label" htmlFor="end">期末</label>
                                        </div>
                                    </div>
                                    <button className="btn btn-dark-blue w-100" onClick={handleClickCal} value='年金計算'>年金計算為多少?</button>
                                    <div className="mt-2 text-end">
                                        <button className="btn btn-outline-golden-brown" onClick={handleClickRes}>重新計算</button>
                                    </div>
                                </div>
                                {
                                    show ?
                                        <div className="mt-3 d-md-flex justify-content-center">
                                            <div className="text-center my-4">
                                                <p>年金計算：</p>
                                                <p className="text-dark-blue fw-bolder fs-4">{toCurrency(PMTcalValue.total)} 元</p>
                                            </div>
                                        </div>
                                        : ''
                                }
                            </div>
                            : ''
                    }
                    { //利率計算
                        option === '利率計算' ?
                            <div className="row justify-content-center">
                                <div className="mt-4 col-12 col-md-8 col-lg-6">
                                    <div className="input-group mb-3">
                                        <label htmlFor="FVn" className="w-100">期數〔n〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="tel" id="FVn" className="form-control"
                                            value={RATEcalValue.n}
                                            onChange={e => setRATEcalValue({ ...RATEcalValue, n: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">期</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="FVr" className="w-100">現值〔PV〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="FVr" className="form-control"
                                            value={RATEcalValue.pv}
                                            onChange={e => setRATEcalValue({ ...RATEcalValue, pv: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">元</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="PVpv" className="w-100">付款〔PMT〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="PVpv" className="form-control"
                                            value={RATEcalValue.pmt}
                                            onChange={e => setRATEcalValue({ ...RATEcalValue, pmt: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">元</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <label htmlFor="PVfv" className="w-100">終值〔FV〕
                                            {/* <span className="text-danger fw-bolder small ms-2">*必填</span> */}
                                        </label>
                                        <input type="number" id="PVfv" className="form-control"
                                            value={RATEcalValue.fv}
                                            onChange={e => setRATEcalValue({ ...RATEcalValue, fv: e.target.value })} />
                                        <span className="input-group-text bg-golden-brown text-light">元</span>
                                    </div>

                                    <div className="d-flex mb-3" onChange={handleChangeStartAndEndRadio}>
                                        <div className="form-check mx-2">
                                            <input className="form-check-input" type="radio" name="PVradio" id="start" value="1"
                                            />
                                            <label className="form-check-label" htmlFor="start">期初</label>
                                        </div>
                                        <div className="form-check mx-2">
                                            <input className="form-check-input" type="radio" name="PVradio" id="end" value="0" defaultChecked
                                            />
                                            <label className="form-check-label" htmlFor="end">期末</label>
                                        </div>
                                    </div>
                                    <button className="btn btn-dark-blue w-100" onClick={handleClickCal} value='利率計算'>利率計算為多少?</button>
                                    <div className="mt-2 text-end">
                                        <button className="btn btn-outline-golden-brown" onClick={handleClickRes}>重新計算</button>
                                    </div>
                                </div>
                                {
                                    show ?
                                        <div className="mt-3 d-md-flex justify-content-center">
                                            <div className="text-center my-4">
                                                <p>利率計算：</p>
                                                <p className="text-dark-blue fw-bolder fs-4">{RATEcalValue.total} %</p>
                                            </div>
                                        </div>
                                        : ''
                                }
                            </div>
                            : ''
                    }

                </div>
            </BackgroundWrapper>
        </>
    )
}
export default FinancialCalculator