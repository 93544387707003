import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faSearch, faBars, faClipboardList, faUserCircle } from '@fortawesome/free-solid-svg-icons'

//components
import TreeViewMember from 'components/backDashboard/TreeView'
import TreeViewList from 'components/backDashboard/TreeList'
import { default as swal } from 'sweetalert2'
import Loading from 'components/Loading'
import ifStrHaveHttp from 'assets/javascript/ifStrHaveHttp';

const MessageCenter = (props) => {
    let history = useHistory();

    //*---取得登入角色(轉頁)---*//
    let userRole = localStorage.getItem('role');//取得登入角色
    let userName = localStorage.getItem('userName');//取得登入人員
    if (userRole == '3') { //寵物險後勤
        history.push('/dashboard/pet')
    } else if (userRole == '4') { //隊長
        history.push('/dashboard/challenges')
    } else if (userRole == '5') {
        history.push('/dashboard/goldenfp_list')
    }

    //*---參數列---*//
    let [searchInput, setSearchInput] = useState(''); //搜尋組織/名單/批次
    let [isLoading, setIsLoading] = useState(true)
    //@ 左側 "模式列表"
    let [mode, setMode] = useState() //模式編號。組織:0 ; 批次:1 ; 名單:2
    let [getMode, setGetMode] = useState(); // 功能為"取得" 及 "刪除"。批次:0 ; 名單:1
    let btnAry = [{ title: '組織' }, { title: '名單' }, { title: '批次' }] //按鈕列
    let [mainBtn, setMainBtn] = useState(btnAry[0].title); //目前模式。預設為:組織
    //@ 取得 DATA
    let [memberData, setMemberData] = useState([]) // 取得"全體人員"
    let [batchData, setBatchData] = useState([]); //批次
    let [listData, setListData] = useState([]); //名單
    //@ 發訊"卡片"
    let [isCardRadio, setIsCardRadio] = useState('noCard'); //haveCard:有卡片; noCard:無卡片
    let [cardData, setCardData] = useState({
        templateName: '範本',
        title: "",
        subtitle: "",
        btntype: "url",
        btntitle: "",
        btndata: "",
        tags: null,
        templateType: "card"
    })
    //@ 發訊"整體內容" (API 回送)
    let [postMesData, setPostMesData] = useState({
        Push_Info: [],
        Message_Body: '',
        Create_User: userName,
        Message_Image: null,
        Message_Card: null
    })
    //@ 上傳檔案
    let [disabledBtn, setDisabledBtn] = useState(true);
    let [fileLoading, setFileLoading] = useState(false);
    let [fileUpload, setFileUpload] = useState(null);
    let [fileAry, setFileAry] = useState([]) //圖片檔 ary
    //@ 卡片訊息
    let [cardTitle, setCardTitle] = useState(true); //卡片標題驗證 --  true 有填字; false 未填字
    let [cardBtn, setCardBtn] = useState(true); //卡片按鈕驗證 -- true 有填字; false 未填字
    let [cardBtnUrl, setCardBtnUrl] = useState(true); //卡片按鈕連結驗證 --true 有填字; false 未填字
    //@ 卡片字數驗證
    let [titleLen, setTitleLen] = useState(false); // true 超過;false 不超過
    let [subtitleLen, setSubtitleLen] = useState(false); // true 超過;false 不超過
    let [btntitleLen, setBtnTitleLen] = useState(false); // true 超過;false 不超過
    //@ Modal
    let [reviewModalShow, setReviewModalShow] = useState(false) //* 預覽 modal
    //@ 
    let [pushTimeRadio, setPushTimeRadio] = useState(null);
    let [sendMesLoading, setSendMesLoading] = useState(false);
    let [nowSendPostData, setNowSendPostData] = useState()
    let [treeOpen, setTreeOpen] = useState(false) //組織 TREE，mobile版展開縮小
    //@ --- 名單 & 批次 --- //
    let [addShow, setAddShow] = useState(false)
    let [listTitle, setListTitle] = useState('')
    let [batchRow, setBatchRow] = useState('')

    //*切換模式*//
    const handleMainBtn = (e) => {
        let { innerText } = e.target;
        setMainBtn(innerText)
        resetMes();
    }
    useEffect(() => {
        if (mainBtn === '組織') {
            setMode(0)
            setTheArray([])
            setSearchInput('')
            postMesData.Message_Body = '';
            resetMes();
        } else if (mainBtn === '批次') {
            getListAndBatchData(0)
            setMode(1)
            setGetMode(0)
            setTheArray([])
            setSearchInput('')
            // postMesData.Message_Body = '';
            resetMes();
        } else if (mainBtn === '名單') {
            getListAndBatchData(1)
            setMode(2)
            setGetMode(1)
            setTheArray([])
            setSearchInput('')
            postMesData.Message_Body = '';
            resetMes();
        }
    }, [mainBtn]);

    //* 取得批次 & 名單 DATA *//
    const getListAndBatchData = (mode) => { //mode --> 0:批次 ; 1:名單
        setIsLoading(true)
        let GetBatchAPI = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/GetAllBatchs`;
        axios.post(GetBatchAPI, { Mode: mode })
            .then((res) => {
                if (mode === 0) {
                    if (res.data.All !== null) {
                        setBatchData(res.data.All)
                    }
                } else if (mode === 1) {
                    if (res.data.All !== null) {
                        setListData(res.data.All)
                    }
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                // alert('請重新整理')
            })
    }

    //* 取得所有業務員 *//
    useEffect(() => {
        let API = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/GetAllSales`;
        axios.get(API)
            .then((res) => {
                setMemberData(res.data);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setMemberData([]);
                // alert('請重新整理')
            })

        // getListAndBatchData(); //取得名單 & 批次 DATA

    }, [getMode]);

    //* 是否有發訊"卡片" *//
    useEffect(() => {
        if (isCardRadio === 'haveCard') {
            setPostMesData({ ...postMesData, Message_Card: cardData })
        }
    }, [cardData]);

    //* 過濾成員 *//
    const [theArray, setTheArray] = useState([]);
    let filterMemberAry = []
    const handleMemberClick = (e) => {
        e.preventDefault();
        let { nodeName, value, innerText, dataset } = e.target

        if (mode === 0) { //組織模式
            if (nodeName !== 'BUTTON') {
                return
            }
            if (value === '人員') {
                memberData.filter(val => {
                    val.AllDepts.forEach((item, index) => {
                        item.Line.forEach((item, index) => {
                            if (item.Name.includes(searchInput))
                                setTheArray(oldArray => [...oldArray, item]);
                        })
                    })
                })
                return true
            }
            memberData.forEach((item, index) => {
                if (value === '全體') {
                    item.AllDepts.forEach((item, index) => {
                        item.Line.forEach((item, index, ary) => {
                            setTheArray(oldArray => [...oldArray, item]);
                        })
                    })
                } else if (value === item.Loc) {
                    item.AllDepts.forEach((item, index) => {
                        item.Line.forEach((item, index) => {
                            setTheArray(oldArray => [...oldArray, item]);
                        })
                    })
                }
                item.AllDepts.forEach((item, index) => {
                    if (value === item.Dept) {
                        item.Line.forEach((item, index) => {
                            setTheArray(oldArray => [...oldArray, item]);
                        })
                    }
                    item.Line.forEach((item, index) => {
                        if (value === item.ID) {
                            setTheArray(oldArray => [...oldArray, item]);
                        }
                    })
                })
            })
        } else if (mode === 2) { //名單模式
            if (dataset.fun === 'del') {
                setIsLoading(true)
                deleteFile('名單檔', 1, dataset.filename)
            }
            //取得清單成員
            listData.map((item, index) => {
                if (innerText === item.Filetitle) {
                    item.items.map((kitem, kindex) => {
                        setTheArray(oldArray => [...oldArray, kitem]);
                    })
                }
            })
        }
    }
    const set = new Set();
    const filterMemberFun = () => {
        if (mode === 0) { //組織模式
            filterMemberAry = theArray.filter(item => !set.has(item.ID) && item.Pay === '1' && item.Valid === '1' ? set.add(item.ID) : false); //過濾 Line ID 相同的人員
        } else if (mode === 2) { //名單模式
            filterMemberAry = theArray.filter(item => !set.has(item.LineId) && item.LineId !== null ? set.add(item.Name) : false);
        }
    }
    filterMemberFun();

    useEffect(() => {
        if (mode === 0) { //組織模式
            setPostMesData({ ...postMesData, Push_Info: filterMemberAry.map(item => ({ Sales_Name: item.Name, Line_Id: item.ID })) })
        } else if (mode === 2) { //名單模式
            setPostMesData({ ...postMesData, Push_Info: filterMemberAry.map(item => ({ Sales_Name: item.Name, Line_Id: item.LineId })) })
        }
    }, [theArray]);

    //*刪除單一收訊人*//
    const handleDelSinMember = (e) => {
        let { value } = e.target
        let spliceAry = [];
        filterMemberAry.forEach((item, index, ary) => {
            if (value === item.Name) {
                ary.splice(index, 1);
                spliceAry = ary
            }
        })
        setTheArray(spliceAry)
    }

    //*上傳圖檔*//
    const handleFileUpload = (e) => { //選取檔案
        e.preventDefault();
        let { files } = e.target;
        setFileUpload(files[0]);
        setDisabledBtn(false)
    }
    const handleSubmit = (e) => { //上傳檔案 一般圖片:0、批次:1、名單:2
        e.preventDefault();
        let { dataset } = e.target;
        const formData = new FormData();
        formData.append('fileUpload', fileUpload);
        if (dataset.upload === 'img') {
            formData.append('Mode', 0);
        } else if (dataset.upload === 'list') {
            formData.append('Mode', mode);
        }

        setFileLoading(true)
        let API = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/UploadFile`;
        axios.post(API, formData)
            .then((res) => {
                console.log(res)
                if (res.data.ResponseCode === '0') {
                    alert('上傳成功 ~')
                    setDisabledBtn(true)
                    setFileLoading(false)
                    if (dataset.upload === 'img') {
                        setFileAry(oldArray => [...oldArray, fileUpload])
                    } else if (dataset.upload === 'list') {
                        getListAndBatchData(getMode);
                    }
                    e.target.reset();
                } else {
                    alert('上傳失敗! 請重新上傳')
                }
            })
            .catch((err) => {
                console.log(err)
                // alert('請重新整理')
            })
    }
    const reviewFile = () => { //預覽上傳圖檔
        if (fileAry.length == 0) {
            return
        } else {
            fileAry.map((item, index) => {
                //上傳即預覽圖片
                let reader = new FileReader();
                reader.onload = function (e) {
                    document.getElementById(`viewUploadImage_${index}`).setAttribute('src', e.target.result)
                }
                reader.readAsDataURL(item)
            })
        }
    }

    useEffect(() => {
        if (reviewModalShow) {
            reviewFile()
        }
    }, [fileAry, reviewModalShow])

    //*過濾相同檔名 檔案*//
    let [showFileName, setShowFileName] = useState([])
    const filterSameFileName = () => {
        let ary = [];
        let filterAry = [];
        if (fileAry.length <= 0) {
            return
        } else {
            const set = new Set();
            fileAry.forEach((item, index) => {
                ary.push(item.name)
            })
            filterAry = ary.filter(item => !set.has(item) ? set.add(item) : false);
            setPostMesData({ ...postMesData, Message_Image: filterAry })
            setShowFileName(filterAry)
        }
    }
    useEffect(() => {
        filterSameFileName();
    }, [fileAry]);


    //*卡片*//
    const handleIsCard = (e) => {
        setIsCardRadio(e.target.value)
        if (e.target.value === 'noCard') {
            setCardData({
                templateName: '範本',
                title: "",
                subtitle: "",
                btntype: "url",
                btntitle: "",
                btndata: "",
                tags: null,
                templateType: "card"
            })
            setPostMesData({
                ...postMesData,
                Message_Card: null
            })
        }
    }

    //*卡片限制驗證*//
    const checkCardLength = () => {
        //字數驗證
        if (cardData.title.length > 40) {
            setTitleLen(true)
        } else if (cardData.title.length <= 40) {
            setTitleLen(false)
        }
        if (cardData.subtitle.length > 60) {
            setSubtitleLen(true)
        } else if (cardData.subtitle.length <= 60) {
            setSubtitleLen(false)
        }
        if (cardData.btntitle.length > 20) {
            setBtnTitleLen(true)
        } else if (cardData.btntitle.length <= 20) {
            setBtnTitleLen(false)
        }

        //必填驗證
        if (cardData.title.length >= 1) {
            setCardTitle(true)
        }
        if (cardData.btntitle.length >= 1) {
            setCardBtn(true)
        }
        if (cardData.btndata.length >= 1) {
            setCardBtnUrl(true)
        }
    }
    useEffect(() => {
        checkCardLength();
    }, [cardData]);

    //* "立即審核"須帶入參數 Audit_User *//
    useEffect(() => {
        setNowSendPostData({ ...postMesData, Audit_User: '審核者', Mode: mode })
    }, [postMesData]);

    //*重置訊息
    const resetMes = () => {
        setCardData({
            templateName: '範本',
            title: "",
            subtitle: "",
            btntype: "url",
            btntitle: "",
            btndata: "",
            tags: null,
            templateType: "card"
        })
        setPostMesData({
            Push_Info: [],
            Message_Body: '',
            Create_User: userName,
            Message_Image: null,
            Message_Card: null
        })
        filterMemberAry = []
        setTheArray([])
        setShowFileName([]);
        setFileAry([]);
        fileUpload = []
    }
    useEffect(() => { }, [postMesData]);

    //*發送訊息按鈕
    const handleSendMes = (e) => {
        e.preventDefault();
        if (mode === 0 || mode === 2) { //* 組織模式 & 名單模式 驗證
            if (postMesData.Message_Body[0] === '' || postMesData.Message_Body.length <= 0 || isCardRadio === 'noCard' || fileUpload === null || postMesData.Message_Image === null || pushTimeRadio === null || filterMemberAry.length <= 0) {
                if (postMesData.Message_Body[0] === '' || postMesData.Message_Body.length <= 0 && isCardRadio === 'noCard' && postMesData.Message_Image === null) {
                    swal.fire({
                        icon: 'info',
                        title: '提醒',
                        text: '訊息、檔案、卡片至少需傳送一個!!',
                        confirmButtonText: '好的',
                    })
                    return
                } else if (filterMemberAry.length <= 0) {
                    swal.fire({
                        icon: 'info',
                        title: '提醒',
                        text: '收訊人至少選擇一位!!',
                        confirmButtonText: '好的',
                    })
                    return
                }
            }
            if (titleLen || subtitleLen || btntitleLen) {
                swal.fire({
                    icon: 'info',
                    title: '提醒',
                    text: '卡片標題、小標題或按鈕名稱超過字數 !!',
                    confirmButtonText: '好的',
                })
                return
            }

            if (isCardRadio === 'haveCard') {
                if (cardData.title.length < 1) {
                    setCardTitle(false)
                    swal.fire({
                        icon: 'info',
                        title: '提醒',
                        text: '卡片標題為「必填」 !!',
                        confirmButtonText: '好的',
                    })
                    return
                }
                if (cardData.btntitle.length < 1) {
                    setCardBtn(false)
                    swal.fire({
                        icon: 'info',
                        title: '提醒',
                        text: '按鈕名稱為「必填」 !!',
                        confirmButtonText: '好的',
                    })
                    return
                }
                if (cardData.btndata.length < 1) {
                    setCardBtnUrl(false)
                    swal.fire({
                        icon: 'info',
                        title: '提醒',
                        text: '按鈕連結網址為「必填」 !!',
                        confirmButtonText: '好的',
                    })
                    return
                }
            }
        } else if (mode === 1) { //* 批次模式
            if (postMesData.Push_Info.length === 0) {
                swal.fire({
                    icon: 'info',
                    title: '提醒',
                    text: '尚未選擇【批次檔】 !!',
                    confirmButtonText: '好的',
                })
                return
            }
        }

        if (pushTimeRadio === null) { //* 選擇發送時刻
            swal.fire({
                icon: 'info',
                title: '提醒',
                text: '尚未選擇發送時刻 !!',
                confirmButtonText: '好的',
            })
            return
        }

        if (pushTimeRadio === '0') { //* 立即發送
            //! 發送訊息存入 sessionStorage (發訊 API)，會到 backDashboard > DashboardNav.js 內執行 
            setSendMesLoading(true)
            nowSendPostData.Mode = mode;
            if (mode === 0 || mode === 2) { //組織模式 & 名單模式
                nowSendPostData.Mode = 0; // 一般儲存:0 ; 批次儲存:1
                if (typeof (nowSendPostData.Message_Body) === 'string') {
                    nowSendPostData.Message_Body = [nowSendPostData.Message_Body];
                }
            }
            setReviewModalShow(true)
        } else if (pushTimeRadio === '1') { //* 審核發送
            setSendMesLoading(true)
            let POSTMESAPI = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/Draf`; //審核推播
            if (mode === 0 || mode === 2) { //組織模式 & 名單模式
                postMesData.Mode = 0; // 一般儲存:0 ; 批次儲存:1
                if (typeof (postMesData.Message_Body) === 'string') {
                    postMesData.Message_Body = [postMesData.Message_Body];
                }
            }
            axios.post(POSTMESAPI, postMesData)
                .then((res) => {
                    if (res.data.ResponseCode === '0') {
                        alert('審核訊息發送成功 !!');
                        setSendMesLoading(false);
                        resetMes();
                    } else {
                        alert('審核訊息發送失敗 ><');
                        setSendMesLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err)
                    // alert('請重新整理')
                })
        }
    }
    //* 立即發訊(預覽後確認發訊)
    const nowSendPost = () => {
        sessionStorage.setItem('nowSendPostData', JSON.stringify(nowSendPostData))
        postMesData.Message_Body = ''
        setSendMesLoading(false);
        resetMes();
        window.location.reload();
        setReviewModalShow(false)
    }

    //* mobile 版 function
    const handleOpenMember = (e) => { //展開成員
        let { innerText } = e.target
        if (innerText === '＜組織圖') {
            setTreeOpen(true)
        } else if (innerText === 'X') {
            setTreeOpen(false)
        }
    }
    //* 名單 & 批次 匯入檔案 modal *//
    const handleImportClickModal = (e) => { // "open" import modal
        setListTitle(e.target.value)
        setAddShow(true)
    }
    const handleModalClose = () => { // "close" import modal
        setAddShow(false)
    }
    //* 名單 & 批次 *//
    const deleteFile = (title, mode, filename) => { //"刪除檔案"。mode --> 0:批次 ; 1:名單
        let API = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/DeleteBatchs`
        axios.delete(API, {
            data: {
                filename: filename,
                mode: mode
            }
        })
            .then((res) => {
                if (res.data.ResponseCode === '0') {
                    alert(`成功刪除【${filename}】${title}`)
                    setIsLoading(false)
                    // setBatchRow(innerText)
                    getListAndBatchData(mode);
                }
            })
            .catch((err) => {
                console.log(err)
                // alert('請重新整理')
            })
    }
    const handleBatchFile = (e) => { //批次
        let { innerText, dataset } = e.target
        //刪除檔案
        if (dataset.fun === 'del') {
            setIsLoading(true)
            deleteFile('批次檔', 0, dataset.filename)
        }

        //判斷 click 是否為非 filename
        if (innerText === '批次＋' || dataset.fun === 'del') {
            return
        } else {
            setBatchRow(innerText)
        }

        //setMessage
        let pushInfoMember = [];
        let messageBody = [];
        let messageImage = [];
        batchData.filter((val, valIndex) => {
            if (val.Filetitle === innerText) {
                return val
            }
        }).map((item, index) => {
            item.items.map((kitem, kindex) => {
                if (kitem.LineId === null) {
                    return
                } else {
                    pushInfoMember.push({ Line_Id: kitem.LineId, Sales_Name: kitem.Name })
                    messageBody.push(kitem.Message)
                    messageImage.push(kitem.Url)
                }
            })
            let Mesresult = JSON.stringify(messageBody).replace(/""/g, "null");
            let Imgresult = JSON.stringify(messageImage).replace(/""/g, "null");

            //! 判斷是否有 http 目前暫未需要卡 (先註解)
            // JSON.parse(Imgresult).forEach((reItem, reIndex) => {
            //     if (reItem !== "" && reItem !== null && !ifStrHaveHttp(reItem)) {
            //         swal.fire({
            //             icon: 'info',
            //             title: '麻煩請重新上傳批次檔',
            //             text: '【網址】欄位只能為「網址」或 空 !! ',
            //             confirmButtonText: '刪除此檔案',
            //         }).then(function () {
            //             setIsLoading(true)
            //             deleteFile('批次檔', 0, dataset.filename)
            //             setBatchRow('')
            //         })
            //     }
            // })

            setPostMesData({
                Push_Info: pushInfoMember,
                Message_Body: JSON.parse(Mesresult),
                Create_User: userName,
                Message_Image: JSON.parse(Imgresult),
                Message_Card: null,
                Mode: 1
            })
        })
    }
    useEffect(() => {
    }, [batchRow, postMesData]);

    //*清除*//
    const handleClearAll = (e) => {
        e.preventDefault();
        let { value } = e.target
        if (value == 'memberReset') { //@ 清除所有收訊人
            filterMemberAry = []
            setTheArray([])
        } else if (value == 'fileReset') { //@ 清除所有上傳圖檔
            console.log('清除檔案')
            setFileAry([]);
            fileUpload = []
            setShowFileName([])
            setPostMesData({ ...postMesData, Message_Image: null })
        }
    }

    //* 關閉"預覽發訊" modal *//
    const reviewModalClose = () => {
        setReviewModalShow(false)
        setSendMesLoading(false)
    }

    useEffect(() => { }, [mainBtn])

    return (
        <>
            <Loading isLoading={isLoading} />
            <h2 className='fs-4 text-dark-blue fw-bolder p-3'>{props.title}</h2>
            <div className="container-fluid message-center p-0">
                <div className="row g-0 reverse-box">
                    <div className='col-3'>
                        <div className='pt-3 px-2'>
                            <div className={`position-relative d-lg-none`}>
                                <button className="btn btn-golden-brown btn-sm px-2 position-absolute" style={{ left: '4px' }} onClick={handleOpenMember}>X</button>
                            </div>
                            <ul className="list-unstyled d-flex justify-content-center">
                                {
                                    btnAry.map((item, index) => {
                                        return (
                                            <li className='mx-2' key={index}>
                                                <button className={`btn btn-outline-golden-brown btn-sm rounded-pill px-3 ${mainBtn === item.title ? 'active' : ''}`} onClick={handleMainBtn}>{item.title}</button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            {/* 組織 */}
                            <div className={`${mainBtn === '組織' ? ' ' : 'd-none '}`}>
                                <div className={`my-3`}>
                                    <input type="text" className="form-control" placeholder="搜尋"
                                        onChange={e => setSearchInput(e.target.value.replace(/\s+/g, " "))} />
                                </div>
                                <div className="scroll-box">
                                    <TreeViewMember data={memberData} searchInput={searchInput} handleMemberClick={handleMemberClick} />
                                </div>
                            </div>
                            {/* 名單 */}
                            <div className={`${mainBtn === '名單' ? ' ' : 'd-none '}`}>
                                <div className={`my-3`}>
                                    <input type="text" className="form-control" placeholder="搜尋"
                                        onChange={e => setSearchInput(e.target.value.replace(/\s+/g, " "))} />
                                </div>
                                <div className="scroll-box">
                                    <TreeViewList title="名單" data={listData} searchInput={searchInput} handleImportClickModal={handleImportClickModal} handleImportClick={handleMemberClick} />
                                </div>
                            </div>
                            {/* 批次 */}
                            <div className={` ${mainBtn === '批次' ? ' ' : 'd-none '}`}>
                                <div className={`my-3`}>
                                    <input type="text" className="form-control" placeholder="搜尋"
                                        onChange={e => setSearchInput(e.target.value.replace(/\s+/g, " "))} />
                                </div>
                                <div className="scroll-box">
                                    <TreeViewList title="批次" data={batchData} searchInput={searchInput} handleImportClickModal={handleImportClickModal} handleImportClick={handleBatchFile} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-9'>
                        <div className="bg-gray p-3">
                            <div className={`${mainBtn === '組織' || mainBtn === '名單' ? '' : 'd-none'}`}>
                                <h6 className="fw-bolder text-dark-blue fs-5">來自《公勝小編》</h6>
                                <div className="col-12 my-2">
                                    <textarea className="w-100" value={postMesData.Message_Body}
                                        onChange={e => setPostMesData({ ...postMesData, Message_Body: e.target.value })}>
                                    </textarea>
                                </div>
                                <div className="col-12 my-2">
                                    <div className="d-flex align-items-center mb-2">
                                        <p className="fw-bolder text-dark-blue me-3">收訊人 {filterMemberAry.length} 位</p>
                                        <button type="button" className="btn btn-danger p-1" onClick={handleClearAll} value='memberReset'>清除所有</button>
                                    </div>
                                    <div className="w-100 box p-2">
                                        {
                                            filterMemberAry.map((item, index) => {
                                                return (
                                                    <button className={`btn btn-golden-brown p-0 text-light m-1 rounded-pill px-2 py-1`} key={index} value={item.Name} onClick={handleDelSinMember}>
                                                        {item.Name} x
                                                    </button>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                                <div className="col-12 my-2">
                                    <div className="d-flex align-items-center mb-2">
                                        <p className="fw-bolder text-dark-blue me-3">檔案 {showFileName.length} 件</p>
                                        <button type="button" className="btn btn-danger p-1" onClick={handleClearAll} value='fileReset'>清除檔案</button>
                                    </div>
                                    <div className="w-100 box p-2 row mx-0">
                                        <div className="col-12 col-lg-8">
                                            <form onSubmit={handleSubmit} encType="multipart/form-data" method="post" data-upload="img">
                                                <div className="input-group mb-3 w-80">
                                                    <input type="file" name="message-file" id="messageFile" className="p-2 form-control w-50"
                                                        // multiple="multiple"
                                                        onChange={handleFileUpload} />
                                                    <button type="submit" className="btn btn-dark-blue" disabled={disabledBtn ? 'disabled' : ''}>
                                                        <div className={`spinner-border text-light spinner-border--width me-2 ${fileLoading ? 'd-inline-block' : 'd-none'}`} style={{ width: '1rem', height: '1rem' }} role="status"></div>
                                                        {disabledBtn ? '尚未選擇檔案' : '上傳檔案'}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <p className="text-golden-brown fw-bolder">{showFileName.length === 0 ? '尚未上傳任何檔案' : '已上傳下列檔案'}</p>
                                            <ul className="">
                                                {
                                                    showFileName.map((item, index) => {
                                                        return (
                                                            <li key={item}>{item}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 my-2">
                                    <span className="fw-bolder text-dark-blue">卡片</span>
                                    <label htmlFor="haveCard" className="px-2">
                                        <input className="form-check-input me-2" type="radio" name="sendCard" id="haveCard" value="haveCard"
                                            onChange={handleIsCard} />
                                        加卡片
                                    </label>
                                    <label htmlFor="noCard" className="px-2">
                                        <input className="form-check-input me-2" type="radio" name="sendCard" id="noCard" value="noCard" checked={isCardRadio === "noCard" ? 'checked' : ''}
                                            onChange={handleIsCard} />
                                        無卡片
                                    </label>
                                    <div className={`box p-2 row d-flex mx-0 ${isCardRadio === null || isCardRadio === 'noCard' ? 'd-none' : 'd-block'}`} style={{ height: 'auto' }}>
                                        <div className="col-12 col-lg-7">
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="title" className="col-3 col-lg-2">標題</label>
                                                <input type="text" id="title" className={`form-control w-50 ${titleLen ? 'is-invalid' : ''} ${cardTitle ? '' : 'is-invalid'}`}
                                                    value={cardData.title}
                                                    onChange={e => setCardData({ ...cardData, title: e.target.value.replace(/\s+/g, "") })} />
                                                <p className={`string-len ${cardData.title.length > 40 ? 'text-danger' : ''}`}>{cardData.title.length}/40</p>
                                                <span className="ms-2">(必填)</span>
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="subtitle" className="col-3 col-lg-2">子標題</label>
                                                <input type="text" id="subtitle" className={`form-control w-50 ${subtitleLen ? 'is-invalid' : ''}`}
                                                    value={cardData.subtitle}
                                                    onChange={e =>
                                                        setCardData({ ...cardData, subtitle: e.target.value.replace(/\s+/g, "") })}
                                                />
                                                <p className={`string-len ${cardData.subtitle.length > 60 ? 'text-danger' : ''}`}>{cardData.subtitle.length}/60</p>
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="btntitle" className="col-3 col-lg-2">按鈕名稱</label>
                                                <input type="text" id="btntitle" className={`form-control w-50 ${btntitleLen ? 'is-invalid' : ''} ${cardBtn ? '' : 'is-invalid'}`}
                                                    value={cardData.btntitle}
                                                    onChange={e => setCardData({ ...cardData, btntitle: e.target.value.replace(/\s+/g, "") })} />
                                                <p className={`string-len ${cardData.btntitle.length > 20 ? 'text-danger' : ''}`}>{cardData.btntitle.length}/20</p>
                                                <span className="ms-2">(必填)</span>
                                            </div>
                                            <div className="d-flex align-items-center my-2">
                                                <label htmlFor="btndata" className="col-3 col-lg-2">按鈕連結</label>
                                                <input type="url" id="btndata" className={`form-control w-50 ${cardBtnUrl ? '' : 'is-invalid'}`}
                                                    value={cardData.btndata}
                                                    onChange={e => setCardData({ ...cardData, btndata: e.target.value.replace(/\s+/g, "") })} />
                                                <span className="ms-2">(必填)</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3">
                                            <div className="card" style={{ width: '18rem' }}>
                                                <img src="https://upload.cc/i1/2021/12/29/bxwAIi.png" className="card-img-top" alt="" />
                                                <div className="card-body">
                                                    <h5 className="card-title">{cardData.title}</h5>
                                                    <p className="card-text">{cardData.subtitle}</p>
                                                    <a href={cardData.btndata} className="btn w-100 mt-2">{cardData.btntitle}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 批次 table */}
                            <div className={`${mainBtn === '批次' ? '' : 'd-none'}`}>
                                <h6 className='text-dark-blue fw-bolder fs-5 mb-3'>{`批次：${batchRow}`}</h6>
                                <div className="table-responsive-xxl" style={{ height: '350px', overflowY: 'scroll' }}>
                                    <table className="table table-striped table-hover" width="100%">
                                        <thead className="text-golden-brown">
                                            <tr>
                                                <th className="text-start" style={{ whiteSpace: 'nowrap' }}>收件人</th>
                                                <th className="text-start" style={{ whiteSpace: 'nowrap' }}>訊息</th>
                                                <th className="text-start" style={{ whiteSpace: 'nowrap' }}>網址</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ tableLayout: "fixed", wordBreak: "break-word" }}>
                                            {
                                                batchData.map((item, index) => {
                                                    if (batchRow === item.Filetitle)
                                                        return (
                                                            item.items.map((kitem, kindex) => {
                                                                return (
                                                                    <>
                                                                        <tr className={kitem.Name}>
                                                                            <td width="20%">{kitem.Name}</td>
                                                                            <td width="50%">{kitem.Message}</td>
                                                                            <td width="50%"><a href={kitem.Url} target="_blank" className="text-dark">{kitem.Url}</a></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9 my-2">
                                <p className="d-inline-block me-3">發送時刻:</p>
                                <label htmlFor="sendNow" className={`px-2 ${userRole == 1 ? 'd-none' : ''}`}>
                                    <input className="form-check-input me-2" type="radio" name="send" id="sendNow" value="0"
                                        onChange={e => setPushTimeRadio(e.target.value)} />
                                    立即發送
                                </label>
                                <label htmlFor="sendReserve" className="px-2">
                                    <input className="form-check-input me-2" type="radio" name="send" id="sendReserve" value="1"
                                        onChange={e => setPushTimeRadio(e.target.value)}
                                    />
                                    審核發送
                                </label>
                            </div>
                            <div className="col-12 col-md-8 col-lg-6 mt-5 mx-auto d-flex">
                                {
                                    mode == '1' ? '' :
                                        <button className="btn btn-outline-dark-blue w-50 me-3" onClick={resetMes}>清除</button>
                                }
                                <button className={`btn btn-dark-blue ${mode == '1' ? 'w-100' : 'w-50'}`} onClick={handleSendMes} disabled={sendMesLoading ? 'disabled' : ''}>
                                    <div className={`spinner-border text-light spinner-border--width me-2 ${sendMesLoading ? 'd-inline-block' : 'd-none'}`} style={{ width: '1rem', height: '1rem' }} role="status"></div>
                                    發訊
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <div className={`col-9 colkn-md-5 col-lg-3 p-3 position-fixed bg-light`} style={{ zIndex: '5', minHeight: '100vh' }}>

                    </div> */}
                    {/* <div className="d-table col-9 col-md-5 col-lg-3 bg-light"></div> */}
                </div>
            </div>
            {/* 匯入名單 & 批次 */}
            <Modal show={addShow} onHide={handleModalClose} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{listTitle}匯入</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <form onSubmit={handleSubmit} encType="multipart/form-data" method="post" data-upload="list">
                        <div className="input-group mb-3 w-100">
                            <input type="file" name="listandbatch-file" id="listandbatchFile" className="p-2 form-control w-50"
                                accept=".ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={handleFileUpload} />
                            <button type="submit" className="btn btn-dark-blue" disabled={disabledBtn ? 'disabled' : ''}>
                                <div className={`spinner-border text-light spinner-border--width me-2 ${fileLoading ? 'd-inline-block' : 'd-none'}`} style={{ width: '1rem', height: '1rem' }} role="status"></div>
                                {disabledBtn ? '尚未選擇檔案' : '上傳檔案'}
                            </button>
                        </div>
                    </form>
                    <div className="text-end">
                        <button type="button" class="btn btn-outline-golden-brown mx-1" onClick={handleModalClose}>關閉</button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* 發訊預覽 */}
            <Modal show={reviewModalShow} onHide={reviewModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>預覽發訊</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='rounded reviewmes-container' style={{ background: '#8DABD8' }}>
                        <div className='reviewmes-header d-flex justify-content-between py-2'>
                            <div>
                                <FontAwesomeIcon icon={faStar} className='mx-2' style={{ color: '#FFE707' }} />
                                <span className='fw-bolder'>公勝 iSmart+ 智能秘書</span>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faSearch} className='mx-2' />
                                <FontAwesomeIcon icon={faClipboardList} className='mx-2' />
                                <FontAwesomeIcon icon={faBars} className='mx-2' />
                            </div>
                        </div>
                        {
                            nowSendPostData !== undefined &&
                            <>
                                {/* 收訊人數 */}
                                <div>
                                    <p className='text-dark-blue fw-bolder'><FontAwesomeIcon icon={faUserCircle} className='mx-2' />收訊人數：{nowSendPostData.Push_Info.length} 位</p>
                                </div>
                                <div className='reviewmes-body py-3' style={{ overflowY: 'scroll', height: '350px' }}>
                                    {
                                        mainBtn == '批次' ?
                                            <>
                                                { //* 訊息文字
                                                    nowSendPostData.Message_Body.length == 0 ? '' :
                                                        nowSendPostData.Message_Body[0] == null ? '' :
                                                            <div className='me-2'>
                                                                <div className='reviewmes-pic'></div>
                                                                <div className='reviewmes-mes ms-3' style={{ whiteSpace: 'break-spaces' }}>
                                                                    {nowSendPostData.Message_Body[0]}
                                                                </div>
                                                            </div>
                                                }
                                                { //* 圖片
                                                    nowSendPostData.Message_Image == null ? '' :
                                                        <div className='me-2 mt-2'>
                                                            <div className='reviewmes-pic'></div>
                                                            <div className='reviewmes-mes ms-3' style={{ whiteSpace: 'break-spaces' }}>
                                                                <span className='d-block small text-danger'>批次檔無法預覽圖片檔</span>
                                                            </div>
                                                        </div>
                                                }
                                            </>
                                            :
                                            <>
                                                { //* 訊息文字
                                                    nowSendPostData.Message_Body == '' || nowSendPostData.Message_Body == null || nowSendPostData.Message_Body == undefined ? '' :
                                                        <div className='me-2'>
                                                            <div className='reviewmes-pic'></div>
                                                            <div className='reviewmes-mes ms-3' style={{ whiteSpace: 'break-spaces' }}>
                                                                {nowSendPostData.Message_Body}
                                                            </div>
                                                        </div>
                                                }
                                                { //* 圖片
                                                    fileAry.length > 0 &&
                                                    <div className='me-2 mt-3'>
                                                        <div className='reviewmes-pic'></div>
                                                        <div className='ms-3'>
                                                            {
                                                                fileAry.map((item, index) => {
                                                                    return (
                                                                        <img id={`viewUploadImage_${index}`} className='img-fluid mb-2' width='60%' />
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                { //* 卡片
                                                    nowSendPostData.Message_Card !== null &&
                                                    <div className='me-2 mt-3'>
                                                        <div className='reviewmes-pic'></div>
                                                        <div className='ms-3'>
                                                            <div className="card" style={{ width: '18rem' }}>
                                                                <img src="https://upload.cc/i1/2021/12/29/bxwAIi.png" className="card-img-top" alt="" />
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{nowSendPostData.Message_Card.title}</h5>
                                                                    <p className="card-text">{nowSendPostData.Message_Card.subtitle}</p>
                                                                    <a href={nowSendPostData.Message_Card.btndata} className="btn w-100 mt-2">{nowSendPostData.Message_Card.btntitle}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                    }

                                </div>
                            </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-outline-dark-blue' onClick={reviewModalClose}>關閉</button>
                    <button className='btn btn-dark-blue' onClick={nowSendPost}>確認發訊</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default MessageCenter