import React,{useEffect} from 'react';
//component
import Banner from '../../components/addMembers/Banner';
//json
import MissionData from "../../json/addMembers/Mission.json";

//images url
import BannerUrl from "../../assets/images/components/mission.jpg";
import ListStyle from "../../assets/images/addMembers/list-style_1x.png";
import Countfunc from "../../assets/javascript/countfunc"

const Mission = () => {
    const style = { //banner background style
        backgroundImage: `url(${BannerUrl})`,
    }
    const title = "Golden Life\n站在巨人的肩膀上"; //banner title
    const dataAry = MissionData.data;

    useEffect(() => {
        Countfunc('使命優勢',40)
    }, []);

    return (
        <div>
            <Banner title={title} style={style} />

            <div className="container">
                <div className="row py-5 px-3 px-md-0">
                    <div className="col-12 col-md-4 py-3">
                        <h2 className="fs-3 text-golden-brown fw-bolder text-center mb-3">使命 Mission</h2>
                        <div className="text-center d-sm-flex flex-md-column justify-content-around">
                            <div className="mb-3 col-sm-4 col-md-12">
                                <img src={require(`../../assets/images/addMembers/mission/icon/icon-01.jpg`).default} alt="使命" className="img-fluid" style={{ width: "100px" }} />
                            </div>
                            <ul className="list-unstyled lh-lg col-sm-8 col-md-12">
                                <li>成為客戶夢想的守護天使</li>
                                <li>做為夥伴事業的有利靠山</li>
                                <li>實踐公益型企業</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 py-3">
                        <h2 className="fs-3 text-golden-brown fw-bolder text-center mb-3">願景 Vision</h2>
                        <div className="text-center d-sm-flex flex-md-column justify-content-around">
                            <div className="mb-3 col-sm-4 col-md-12">
                                <img src={require(`../../assets/images/addMembers/mission/icon/icon-02.jpg`).default} alt="願景" className="img-fluid" style={{ width: "100px" }} />
                            </div>
                            <p className="lh-lg col-sm-8 col-md-12">打造業界領先的優質品牌，<br />成為最受信賴的推崇的金融保險平台</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 py-3">
                        <h2 className="fs-3 text-golden-brown fw-bolder text-center mb-3">價值觀 Values</h2>
                        <div className="text-center d-sm-flex flex-md-column justify-content-around">
                            <div className="mb-3 col-sm-4 col-md-12">
                                <img src={require(`../../assets/images/addMembers/mission/icon/icon-03.jpg`).default} alt="價值觀" className="img-fluid" style={{ width: "120px" }} />
                            </div>
                            <ul className="list-unstyled d-flex flex-wrap lh-lg col-sm-8 col-md-12 values-list">
                                <li className="col-6">
                                    <img src={ListStyle} alt="icon" className="me-1" />誠信</li>
                                <li className="col-6">
                                    <img src={ListStyle} alt="icon"
                                        className="me-1" />創新</li>
                                <li className="col-6">
                                    <img src={ListStyle} alt="icon"
                                        className="me-1" />熱情</li>
                                <li className="col-6">
                                    <img src={ListStyle} alt="icon"
                                        className="me-1" />共享</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-yellow-light">
                <div className="container">
                    <div className="row py-5">
                        {
                            dataAry.map((item, index) => {
                                return (
                                    <div className="col-12 d-flex d-md-block grow-item" key={index}>
                                        <div className="grow-line align-self-start"><span className="text-yellow-light">line</span></div>
                                        <div className={`row align-items-center justify-content-end mb-3 ${index % 2 === 0 ? "flex-md-row-reverse" : null}`}>
                                            <div className="col-11 col-md-6 mb-3 mb-md-0 text-center">
                                                <h2 className="fs-3 text-golden-brown fw-bolder mb-3">{item.title}</h2>
                                                <ul className="list-unstyled">
                                                    {
                                                        item.content.map((item, index) => {
                                                            return (
                                                                <li key={index}>{item}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="col-11 col-md-6 mb-5 mb-md-0">
                                                <img src={require(`../../assets/images/addMembers/mission/photo/${item.title}.jpg`).default} alt={item.year} className="img-fluid d-block mx-auto" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>

    );
}
export default Mission;